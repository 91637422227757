import React, { useState, useEffect } from "react";
import { find } from "lodash";
import Pusher from "pusher-js";
import { Grid, Typography, Button, Link } from "@material-ui/core";
import useWindowDimensions from "@utils/windowDimensions";
import ResponseCard from "@global/ResponseCard";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    center: {
        textAlign: "center"
    }
}));

const PromptResponses = props => {
    const classes = useStyles();
    const { width } = useWindowDimensions();
    const {
        token,
        deck,
        prompts,
        flipped,
        handlePromptChange,
        toggleShowResponses,
        isFacilitator,
        setShowResponses,
        toggleFlipped,
        session
    } = props;
    const {
        pusher_app_key,
        pusher_app_cluster,
        pusher_app_host,
        pusher_app_scheme,
        auth,
        userToken
    } = reactProps;
    const [currentPrompt, setCurrentPrompt] = useState(
        find(prompts, function(prompt) {
            return prompt.order === parseInt(props.currentPrompt);
        })
    );
    const [pusherChannel, setPusherChannel] = useState(null);
    const [subscribed, setSubscribed] = useState(false);
    const [responses, setResponses] = useState(null);

    const attemptToggleShowResponses = () => {
        if (pusherChannel) {
            pusherChannel.unbind_global();
        }
        toggleShowResponses();
    };

    const fetchResponses = show => {
        if (Boolean(currentPrompt)) {
            axios
                .get(`/api/v1/prompts/${currentPrompt.uuid}`)
                .then(response => {
                    setResponses(response.data.responses);

                    if (subscribed) {
                        handlePromptChange();
                    }
                    if (show) {
                        setShowResponses(true);
                    }
                })
                .catch(error => {
                    console.log("error", error);
                });
        }
    };

    const applyNewResponse = data => {
        if (Boolean(currentPrompt)) {
            if (currentPrompt.uuid !== data.prompt.uuid) {
                return;
            }
            return setResponses(responses => {
                let found = false;
                let BreakException = {};
                let responsesCopy = { ...responses };

                if (Object.keys(responsesCopy).length) {
                    try {
                        Object.values(responsesCopy).forEach(response => {
                            if (response.id === data.id) {
                                found = true;
                                throw BreakException;
                            }
                        });
                    } catch (e) {
                        if (e !== BreakException) throw e;
                    }
                }

                responsesCopy = Object.values(responsesCopy);

                if (found === false) {
                    if (!responsesCopy) {
                        responsesCopy = [];
                    }
                    responsesCopy.push(data);
                }

                return setResponses(responsesCopy);
            });
        } else {
            return;
        }
    };

    // const toggleFlipped = () => {
    //     console.log("flip cards function");
    //     console.log("current flipped state", flipped);

    //     axios
    //         .get(`/api/v1/sessions/${reactProps.session.uuid}/flip-status`)
    //         .then(response => {
    //             console.log("response", response);
    //             setFlipped(response.data.flipped);
    //         })
    //         .catch(error => {
    //             console.log("error", error);
    //         });
    // };

    // const flipCards = () => {
    //     console.log("flip all cards");
    //     axios
    //         .post(`/api/v1/flip-session-cards/${session.uuid}`, null, {
    //             headers: {
    //                 Authorization: `Bearer ${userToken}`
    //             }
    //         })
    //         .then(response => {
    //             console.log("response", response);
    //         })
    //         .catch(error => {
    //             console.log("error", error);
    //         });
    // };

    useEffect(() => {
        if (!subscribed) {
            setSubscribed(true);
            let params = {
                cluster: pusher_app_cluster,
                forceTLS: false
            };
            if (pusher_app_scheme === "https") {
                params.forceTLS = true;
                params.enabledTransports = ["ws", "wss"];
            }
            if (!!pusher_app_host && pusher_app_host !== "") {
                params.wsHost = pusher_app_host;
            }
            let pusher = new Pusher(pusher_app_key, params);
            let channel = pusher.subscribe(
                `response-added-${reactProps.session.uuid}`
            );
            channel.bind_global(function(event, data) {
                if (data && data.hasOwnProperty("id")) {
                    applyNewResponse(data);
                } else {
                    fetchResponses();
                }
            });
            setPusherChannel(channel);
        }

        if (
            currentPrompt &&
            parseInt(currentPrompt.order) != parseInt(props.currectPrompt)
        ) {
            setCurrentPrompt(
                find(prompts, function(prompt) {
                    return prompt.order == parseInt(props.currentPrompt);
                })
            );
        }
    }, []);

    // useEffect(() => {
    //     let pusher = new Pusher(pusher_app_key, {
    //         cluster: pusher_app_cluster,
    //         forceTLS: false
    //     });
    //     let channel = pusher.subscribe(`${reactProps.session.uuid}-flip`);
    //     channel.bind_global(function() {
    //         toggleFlipped();
    //     });
    // }, []);

    return (
        <>
            <Grid container spacing={1} justify="center">
                <Grid item xs={12}>
                    <div className={classes.center}>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={attemptToggleShowResponses}
                        >
                            Show Cards
                        </Button>
                    </div>
                </Grid>
                {/* {Boolean(auth) &&
                    (auth.id == session.user_id ||
                        (auth.organization_id &&
                            auth.organization_id == session.organization_id)) &&
                    Boolean(session.allow_facilitator_flip) &&
                    window.location.href.includes("presentation") && (
                        <Grid item xs={12}>
                            <div className={classes.center}>
                                <Link
                                    href="#"
                                    color="primary"
                                    onClick={flipCards}
                                >
                                    {flipped == "front"
                                        ? "Flip all to Back"
                                        : "Flip all to Front"}
                                </Link>
                            </div>
                        </Grid>
                    )} */}
            </Grid>
            {responses != null && (
                <>
                    {responses.length > 0 ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Current Responses:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                spacing={3}
                                xs={12}
                                style={{ marginBottom: 25 }}
                            >
                                {responses.map(response => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        xl={2}
                                        key={response.id}
                                    >
                                        {deck && (
                                            <ResponseCard
                                                token={token}
                                                key={flipped}
                                                response={response}
                                                session={session}
                                                deck={deck}
                                                allFlipped={flipped}
                                                isFacilitator={isFacilitator}
                                                fetchResponses={fetchResponses}
                                                setShowResponses={
                                                    setShowResponses
                                                }
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography gutterBottom align="center">
                            No responses to show
                        </Typography>
                    )}
                </>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={attemptToggleShowResponses}
                >
                    Add New Response
                </Button>
            </Grid>
        </>
    );
};

export default PromptResponses;
