import React from "react";
import { Typography, Grid } from "@material-ui/core";
import moment from "moment";

const AffiliatlyInfo = () => {
    const { user } = reactProps;

    const getKeyLabel = param => {
        if (param.includes("id_token")) return "Token ID";
        if (param.includes("id_user")) return "User ID";
        if (param.includes("aff_uid")) return "Affiliate ID";
        if (param.includes("duration")) return "Duration";
        if (param.includes("expire_time")) return "Expires On";
        return "";
    };

    const getValueLabel = param => {
        const value = param.substring(param.indexOf("=") + 1);
        if (param.includes("duration")) {
            return `${moment.duration(parseInt(value), "s").asDays()} days`;
        }
        if (param.includes("expire_time")) {
            return moment.unix(value).format("LLL");
        }
        return value;
    };

    return (
        <div>
            <Typography variant="h5" component="h3" gutterBottom>
                Affiliatly Info
            </Typography>
            <Grid container spacing={2}>
                {user.affiliatly_blob.split("&").map(item => (
                    <React.Fragment key={item}>
                        <Grid item xs={6}>
                            <Typography>{getKeyLabel(item)}</Typography>
                            <Typography variant="caption">
                                {item.substring(0, item.indexOf("="))}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{ overflowWrap: "break-word" }}>
                                {getValueLabel(item)}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </div>
    );
};

export default AffiliatlyInfo;
