import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
    IconButton,
    Button,
    Typography,
    List,
    ListItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        minWidth: 300,
        padding: theme.spacing(2)
    },
    alignRight: {
        textAlign: "right"
    },
    endSection: {
        textAlign: "center",
        "& a": {
            marginBottom: theme.spacing(2)
        }
    },
    mainItem: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: theme.spacing(2)
    },
    subItem: {
        fontSize: 18,
        marginTop: theme.spacing(1)
    }
}));

const MobileMenu = props => {
    const { toggleDrawer } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.alignRight}>
                    <IconButton onClick={toggleDrawer}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <List>
                    {/* <ListItem button component="a" href="/about">
                        <span className={classes.mainItem}>About</span>
                    </ListItem> */}
                    <ListItem button component="a" href="/">
                        <span className={classes.mainItem}>Home</span>
                    </ListItem>
                    {/*<ListItem button component="a" href="/pricing#how-to-use">*/}
                    {/*    <span className={classes.mainItem}>How to Use</span>*/}
                    {/*</ListItem>*/}
                    <ListItem
                        button
                        component="a"
                        href="https://climercards.com/virtual#signup"
                    >
                        <span className={classes.mainItem}>Pricing</span>
                    </ListItem>
                    <ListItem
                        button
                        component="a"
                        href="https://climercards.com/contact-us/"
                    >
                        <span className={classes.subItem}>Contact</span>
                    </ListItem>
                    <ListItem
                        button
                        component="a"
                        href="https://climercards.com/privacy-policy/"
                    >
                        <span className={classes.subItem}>Privacy Policy</span>
                    </ListItem>
                    <ListItem
                        button
                        component="a"
                        href="https://climercards.com/terms-and-conditions/"
                    >
                        <span className={classes.subItem}>Terms of Use</span>
                    </ListItem>
                </List>
            </div>
            <div className={classes.endSection}>
                <Button
                    variant="outlined"
                    color="primary"
                    href="https://climercards.com/virtual#signup"
                    fullWidth
                >
                    Sign Up
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    href="/login"
                    fullWidth
                >
                    Login
                </Button>
                <Typography variant="body1">
                    &copy;
                    {" " + new Date().getFullYear() + " - "}
                    Climer Cards
                </Typography>
            </div>
        </div>
    );
};

export default MobileMenu;
