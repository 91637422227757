import React, { useState, useEffect } from "react";
import {
    Grid,
    Link,
    TextField,
    Typography,
    MenuItem,
    InputAdornment,
    IconButton,
    Chip
} from "@material-ui/core";
import { Search } from "react-feather";
import LoadingBackdrop from "@global/LoadingBackdrop";
import { filter, forEach, find } from "lodash";
import Alert from "@material-ui/lab/Alert";
import Card from "./Card";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {},
    listRoot: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center"
        }
    }
}));

const CardsTable = () => {
    const classes = useStyles();
    const { userToken, decks, auth, plan } = reactProps;
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState(reactProps.cards);
    const [searchQuery, setSearchQuery] = useState("");
    const [cardsFilter, setCardsFilter] = useState("all-cards");
    const [filteredCards, setFilteredCards] = useState(null);
    const [reFilter, setReFilter] = useState(false);
    const [text, setText] = useState("");

    const handleSearchTextChange = event => {
        setText(event.target.value);
        event.persist();
    };

    const handleSearchKeyUp = event => {
        if (event.keyCode === 13 && event.target.value.length > 0) {
            handleSearchSubmit(event.target.value);
        }
        event.persist();
    };

    const clearSearch = () => {
        setSearchQuery("");
        setText("");
        setCards(reactProps.cards);

        if (cardsFilter !== null) {
            setTimeout(
                function() {
                    setReFilter(true);
                }.bind(this),
                500
            );
        }
    };

    const handleSearchSubmit = text => {
        console.log("text", text);
        setLoading(true);
        let params = new URLSearchParams();
        params.append("searchQuery", text);

        axios
            .get(`/api/v1/cards/search`, {
                params: params,
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                setCards(response.data.cards);
                setSearchQuery(text);
                setText("");
                if (cardsFilter != null) {
                    setTimeout(
                        function() {
                            setReFilter(true);
                            setLoading(false);
                        }.bind(this),
                        500
                    );
                }
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleReFilter = () => {
        setReFilter(false);
        console.log("should re-filter");
        let newFilteredCards;

        if (cardsFilter == "all-cards") {
            newFilteredCards = null;
        } else if (cardsFilter == "my-uploads") {
            newFilteredCards = filter(cards, function(card) {
                return card.user_id == auth.id;
            });
        } else {
            let selectedDeck = find(decks, function(deck) {
                return deck.id == cardsFilter;
            });

            newFilteredCards = filter(cards, function(card) {
                let containsCard = false;

                forEach(selectedDeck.cards, function(c) {
                    if (c.id == card.id) {
                        containsCard = true;
                    }
                });

                return containsCard;
            });
        }
        setFilteredCards(newFilteredCards);
    };

    useEffect(() => {
        handleReFilter();
    }, [cardsFilter]);

    useEffect(() => {
        if (reFilter == true) {
            handleReFilter();
        }
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={8}>
                <div>
                    <TextField
                        fullWidth
                        label="Search"
                        margin="normal"
                        value={text}
                        onKeyUp={handleSearchKeyUp}
                        onChange={handleSearchTextChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => handleSearchSubmit(text)}
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {searchQuery && searchQuery != "" && (
                        <Chip
                            label={`Search: ${searchQuery}`}
                            onDelete={clearSearch}
                        />
                    )}
                </div>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    fullWidth
                    label="Filter"
                    margin="normal"
                    value={cardsFilter}
                    onChange={event => setCardsFilter(event.target.value)}
                >
                    <MenuItem value="all-cards">All Cards</MenuItem>
                    <MenuItem
                        value="my-uploads"
                        disabled={plan && (plan == "basic" || plan == "plus")}
                    >
                        My Uploads
                    </MenuItem>
                    {filter(decks, function(deck) {
                        return Boolean(deck.climer) == true;
                    }).map(deck => (
                        <MenuItem value={deck.id} key={deck.id}>
                            {deck.name}
                        </MenuItem>
                    ))}
                    {filter(decks, function(deck) {
                        return Boolean(deck.climer) == false;
                    }).map(deck => (
                        <MenuItem value={deck.id} key={deck.id}>
                            {deck.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            {plan && (plan == "basic" || plan == "plus") && (
                <Grid container>
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            If you'd like to add your own custom cards{" "}
                            <Link component="a" href="/manage-plan">
                                {" "}
                                Upgrade your plan now
                            </Link>
                            !
                        </Alert>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} className={classes.listRoot}>
                {filteredCards != null ? (
                    <>
                        {filteredCards.length == 0 ? (
                            <>
                                <Typography gutterBottom align="center">
                                    No cards found
                                </Typography>
                            </>
                        ) : (
                            <>
                                {filteredCards.map(card => (
                                    <Card card={card} key={card.id} />
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {cards.length == 0 ? (
                            <>
                                <Typography gutterBottom align="center">
                                    No cards found
                                </Typography>
                            </>
                        ) : (
                            <>
                                {cards.map(card => (
                                    <Card card={card} key={card.id} />
                                ))}
                            </>
                        )}
                    </>
                )}
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
        </Grid>
    );
};

export default CardsTable;
