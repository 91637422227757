import React from "react";
import StatsCard from "@global/StatsCard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Tooltip } from "@material-ui/core";

const Revenue = () => {
    const { revenue } = reactProps;

    return (
        <StatsCard
            icon={
                <AttachMoneyIcon
                    style={{ backgroundColor: "#fcefad", color: "#f7d00f" }}
                />
            }
            title="Revenue"
            number={Boolean(revenue) ? `$${revenue.toFixed(2)}` : "$0.00"}
            link="https://dashboard.stripe.com/dashboard"
            infoIcon={
                <Tooltip title="Month-to-Date">
                    <ErrorOutlineIcon style={{ transform: "rotate(180deg)" }} />
                </Tooltip>
            }
            newTab
        />
    );
};

export default React.memo(Revenue);
