import React, { Component } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ContextualComponent from "@assets/ContextualComponent";

export default class Register extends Component {
    render() {
        return (
            <ContextualComponent>
                <Paper>
                    <Typography component="h1" variant="h5" className="p-4">
                        Sign Up
                    </Typography>
                    <form className="p-3" method="post">
                        <input
                            name="_token"
                            className="d-none"
                            defaultValue={csrf_token}
                        />

                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">
                                Email Address
                            </InputLabel>
                            <Input
                                id="email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                name="password"
                                type="password"
                                id="password"
                            />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">
                                Password Confirmation
                            </InputLabel>
                            <Input
                                name="password_confirmation"
                                type="password"
                                id="password_confirmation"
                            />
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                        />
                        <div className="row">
                            <div className="col">
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    Sign Up
                                </Button>
                            </div>
                        </div>
                    </form>
                </Paper>
            </ContextualComponent>
        );
    }
}

if (document.getElementById("register")) {
    ReactDOM.render(<Register />, document.getElementById("register"));
}
