import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import General from "@layouts/General";
import {
    Paper,
    Typography,
    TextField,
    Button,
    Link,
    IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import MessageSnackbar from "@global/MessageSnackbar";
import LoadingBackdrop from "@global/LoadingBackdrop";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 450,
        padding: theme.spacing(5),
        textAlign: "center"
    },
    inputContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    button: {
        marginLeft: 18,
        marginTop: 12
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        margin: `${theme.spacing(2)}px auto`,
        "& svg": {
            color: "#05597B",
            height: 45,
            margin: `0 ${theme.spacing(2)}px`,
            width: 45
        }
    }
}));

const ThankYou = () => {
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = event => {
        setCode(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true);
        axios
            .get(`/api/v1/sessions/validate/${code}`)
            .then(response => {
                console.log(response);
                if (response.data.session_exists) {
                    window.location = `/${response.data.slug}`;
                } else {
                    setLoading(false);
                    setError(response.data.message);
                }
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };

    return (
        <General
            style={{
                alignItems: "center",
                background: "url(/img/cards-background.png) center",
                display: "flex",
                justifyContent: "center"
            }}
            maxWidth={false}
        >
            <Paper elevation={10} className={classes.root}>
                <Typography variant="h3" color="primary" gutterBottom>
                    Thanks for participating!
                </Typography>
                <Typography variant="h5" component="p" color="secondary">
                    Like what you saw?
                </Typography>
                <Typography
                    variant="h4"
                    component="p"
                    color="primary"
                    gutterBottom
                >
                    Share us on Social Media!
                </Typography>
                <div className={classes.iconContainer}>
                    <IconButton
                        href="https://www.facebook.com/climerconsulting"
                        target="_blank"
                    >
                        <FacebookIcon />
                    </IconButton>
                    <IconButton
                        href="https://twitter.com/amyclimer"
                        target="_blank"
                    >
                        <TwitterIcon />
                    </IconButton>
                    <IconButton
                        href="https://www.instagram.com/ClimerCards/"
                        target="_blank"
                    >
                        <InstagramIcon />
                    </IconButton>
                </div>
                <Typography variant="body1" gutterBottom>
                    If you are interested in Climer Cards,{" "}
                    <Link href="https://climercards.com/" target="_blank">
                        click here
                    </Link>{" "}
                    to find out more about the virtual and physical decks.
                </Typography>
                <Typography
                    variant="h5"
                    component="p"
                    color="primary"
                    style={{ marginTop: "2rem" }}
                >
                    Join another session
                </Typography>
                <div className={classes.inputContainer}>
                    <TextField
                        value={code}
                        onChange={handleChange}
                        label="Access Code"
                    />
                    <Button
                        onClick={handleSubmit}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                    >
                        Join
                    </Button>
                </div>
            </Paper>
            {error && <MessageSnackbar message={error} variant="error" />}
            {loading && <LoadingBackdrop open={loading} />}
        </General>
    );
};

if (document.getElementById("session-ended-thank-you")) {
    ReactDOM.render(
        <ErrorBoundary>
            <ThankYou />
        </ErrorBoundary>,
        document.getElementById("session-ended-thank-you")
    );
}
