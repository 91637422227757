import React from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Button
} from "@material-ui/core";
import { last, filter } from "lodash";

const CardsSelectTable = props => {
    const { auth } = reactProps;
    const {
        cards,
        selected,
        toggleSelectAll,
        handleSelect,
        handleAddSingle,
        classes
    } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            indeterminate={
                                selected.length > 0 &&
                                selected.length < cards.length
                            }
                            checked={
                                cards.length > 0 &&
                                selected.length === cards.length
                            }
                            onChange={toggleSelectAll}
                            inputProps={{
                                "aria-label": "select all cards"
                            }}
                        />
                    </TableCell>
                    <TableCell>image</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Alt Text</TableCell>
                    <TableCell>Decks</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {cards.map(card => (
                    <TableRow key={card.id}>
                        <TableCell>
                            <Checkbox
                                checked={selected.indexOf(card.uuid) !== -1}
                                inputProps={{
                                    "aria-labelledby": `table-row-${card.uuid}`
                                }}
                                onClick={event =>
                                    handleSelect(event, card.uuid)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <img
                                src={card.frontImage}
                                alt={card.alt_text}
                                className={classes.imageThumb}
                            />
                        </TableCell>
                        <TableCell>{card.name}</TableCell>
                        <TableCell>{card.alt_text}</TableCell>
                        <TableCell>
                            {card.decks.length > 0 ? (
                                <>
                                    {filter(card.decks, function(deck) {
                                        return (
                                            deck.climer == true ||
                                            deck.user_id == auth.id
                                        );
                                    }).map(deck => (
                                        <span key={deck.id}>
                                            {deck.id == last(card.decks).id
                                                ? deck.name
                                                : `${deck.name}, `}
                                        </span>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <span>None</span>
                                </>
                            )}
                        </TableCell>
                        <TableCell>
                            {card.organization
                                ? card.organization.name
                                : auth.id === card.user_id
                                ? auth.name
                                : "Climer Cards"}
                        </TableCell>
                        <TableCell>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleAddSingle(card.uuid)}
                            >
                                Add
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default React.memo(CardsSelectTable);
