import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
require("../integrations/_integrations");
import General from "@layouts/General";
import { makeStyles } from "@material-ui/core/styles";
import ErrorBoundary from "@global/ErrorBoundary";
import {
    Grid,
    Paper,
    Typography,
    Container,
    Card,
    TextField,
    CardContent,
    Tooltip,
    Button
} from "@material-ui/core";
import { loadCSS } from "fg-loadcss";
import { upperCaseFirst } from "@utils/stringUtils";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardForm from "@global/CardForm";
import CreditCardLogo from "./facilitators/components/CreditCardLogo";
import { useIsTyping } from "use-is-typing";
import { X } from "react-feather";
import { filter, find } from "lodash";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(12),
        padding: theme.spacing(2)
    },
    paperRoot: {
        borderTop: "4px solid #05597B",
        padding: theme.spacing(2)
    },
    activationLogo: {
        height: 64,
        width: "auto",
        position: "relative",
        bottom: 85,
        zIndex: 9999
    },
    cardRoot: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    floatRight: {
        float: "right"
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    padded: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    pointer: {
        cursor: "pointer"
    },
    strikeThrough: {
        textDecoration: "line-through"
    }
}));

const ReActivate = () => {
    const classes = useStyles();
    const {
        auth,
        type,
        plan,
        stripeKey,
        plans,
        prices,
        paymentMethods,
        nameOfOrganization,
        defaultPaymentMethodId,
        userToken
    } = reactProps;

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [paymentMethod, setPaymentMethod] = useState(
        defaultPaymentMethodId
            ? find(paymentMethods, function(method) {
                  return method.id == defaultPaymentMethodId;
              })
            : null
    );
    const [addingCard, setAddingCard] = useState(!Boolean(paymentMethod));
    const [organizationName, setOrganizationName] = useState(
        nameOfOrganization
    );
    const [couponCode, setCouponCode] = useState("");
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [searchingCoupons, setSearchingCoupons] = useState(false);
    const [isTyping, couponInput] = useIsTyping();
    const [customCouponError, setCustomCouponError] = useState(null);
    const userPlanName = auth.subscriptions[0]
        ? _.lowerCase(auth.subscriptions[0].name)
        : "none";

    const planTeam =
        plan == "enterprise" || plan == "team" || plan == "education";

    const planTeamUser =
        userPlanName == "enterprise" ||
        userPlanName == "team" ||
        userPlanName == "education";

    const clearSelectedCode = () => {
        setSelectedCoupon(null);
        setCouponCode("");
    };

    const handleChange = event => {
        setCouponCode(event.target.value);
    };

    const getIntervalString = () => {
        if (reactProps.interval === "monthly") {
            return "month";
        }
        return "year";
    };

    const interval = getIntervalString();

    const selectedPlan = find(plans, function(p) {
        return p.name == upperCaseFirst(plan);
    });

    const selectedPrice = find(prices, function(p) {
        return (
            p.product == selectedPlan.id &&
            p.active === true &&
            p.recurring &&
            p.recurring.interval == interval
        );
    });

    let stripePromise = false;

    if (stripeKey) {
        stripePromise = loadStripe(stripeKey);
    }

    const handleNewCard = () => {
        setPaymentMethod(null);
        setAddingCard(true);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("planName", selectedPlan.name);
        params.append("planId", selectedPrice.id);
        params.append("paymentMethod", paymentMethod.id);

        if (Boolean(selectedCoupon)) {
            params.append("couponCode", selectedCoupon.promotion_code);
        }

        if (plan == "team" || plan == "enterprise" || plan == "education") {
            params.append("organization_name", organizationName);
        }

        axios
            .post(`/api/v1/re-activate-plan`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Account Re-Activated");
                window.location = `/dashboard?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const fetchCouponDetails = () => {
        setSearchingCoupons(true);

        let params = new URLSearchParams();
        params.append("promotion_code", couponCode);
        params.append("reactivation", 1);

        axios
            .get(`/api/v1/coupons`, {
                params: params
            })
            .then(response => {
                console.log("response", response);
                setSelectedCoupon(response.data.coupon);
                setSearchingCoupons(false);
            })
            .catch(error => {
                console.log("error", error);
                if (error.response.data.unauthorized) {
                    setCustomCouponError(
                        "The coupon code that you entered is only available for first time subscribers"
                    );
                }
                setSearchingCoupons(false);
            });
    };

    const getCouponDescription = () => {
        let description = "";
        if (Boolean(selectedCoupon.details.amount_off)) {
            description = `$${parseFloat(
                selectedCoupon.details.amount_off / 100
            ).toFixed(2)} off `;
        }

        if (Boolean(selectedCoupon.details.percent_off)) {
            description = `${selectedCoupon.details.percent_off}% off `;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "once"
        ) {
            description = `${description} your first ${
                type == "monthly" ? "month" : "year"
            }`;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "forever"
        ) {
            description = `${description} as long as your subscription is active`;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "repeating" &&
            Boolean(selectedCoupon.details.duration_in_months)
        ) {
            description = `${description} for the first ${selectedCoupon.details.duration_in_months} months of your subscription`;
        }

        return description;
    };

    const getCostDecription = () => {
        let discounted_amount;

        if (Boolean(selectedCoupon.details.percent_off)) {
            discounted_amount =
                (selectedPrice.unit_amount / 100) *
                (selectedCoupon.details.percent_off / 100);
        }

        if (Boolean(selectedCoupon.details.amount_off)) {
            discounted_amount =
                selectedPrice.unit_amount / 100 -
                selectedCoupon.details.amount_off / 100;
        }

        return (
            <span>
                Pay{" "}
                <span className={classes.strikeThrough}>
                    ${parseFloat(selectedPrice.unit_amount / 100).toFixed(2)}
                </span>{" "}
                ${parseFloat(discounted_amount).toFixed(2)} now.
            </span>
        );
    };

    useEffect(() => {
        let node = loadCSS(
            "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
            document.querySelector("#font-awesome-css")
        );

        return () => {
            node.parentNode.removeChild(node);
        };
    }, []);

    useEffect(() => {
        if (
            (!Boolean(selectedCoupon) &&
                !searchingCoupons &&
                couponCode.length >= 3 &&
                !isTyping) ||
            (Boolean(selectedCoupon) &&
                selectedCoupon.promotion_code != couponCode)
        ) {
            fetchCouponDetails();
        }
    }, [isTyping]);

    return (
        <General
            activation
            maxWidth={false}
            style={{
                background: "url(/img/cards-background.png) center"
            }}
        >
            <Container maxWidth="lg">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} md={6} lg={4}>
                        <Paper className={classes.paperRoot}>
                            <Typography gutterBottom align="center">
                                Re-Register for {upperCaseFirst(plan)} - $
                                {selectedPrice
                                    ? parseInt(
                                          selectedPrice.unit_amount / 100
                                      ).toFixed(2)
                                    : "--"}
                                /{interval}
                            </Typography>
                            <Card>
                                <CardContent>
                                    <>
                                        {(plan == "enterprise" ||
                                            plan == "team" ||
                                            plan == "education") && (
                                            <>
                                                {nameOfOrganization === "" && (
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        margin="normal"
                                                        value={organizationName}
                                                        label="Organization Name"
                                                        className={
                                                            classes.padded
                                                        }
                                                        onChange={event =>
                                                            setOrganizationName(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        helperText="This will be used when inviting users to join your plan."
                                                    />
                                                )}
                                            </>
                                        )}
                                        <>
                                            {!planTeam && planTeamUser && (
                                                <Alert severity="warning">
                                                    <Typography
                                                        gutterBottom
                                                        align="justify"
                                                    >
                                                        <strong>
                                                            Downgrading to this
                                                            plan will remove
                                                            your current
                                                            organization. All
                                                            users in your
                                                            organization will be
                                                            moved to a two week
                                                            trial of the "Basic"
                                                            plan.
                                                        </strong>
                                                    </Typography>
                                                    <Typography
                                                        gutterBottom
                                                        align="justify"
                                                    >
                                                        <strong>
                                                            Downgrading plans
                                                            will archive all
                                                            active and draft
                                                            sessions. You will
                                                            be able to
                                                            un-archive and
                                                            activate sessions
                                                            based on the
                                                            permissions of your
                                                            new plan.
                                                        </strong>
                                                    </Typography>
                                                </Alert>
                                            )}
                                        </>
                                        {Boolean(paymentMethod) ? (
                                            <>
                                                <Typography
                                                    gutterBottom
                                                    className={
                                                        classes.marginTop
                                                    }
                                                >
                                                    Would you like to pay with
                                                    this card?
                                                </Typography>
                                                {paymentMethod.card &&
                                                paymentMethod.card.last_four ? (
                                                    <CreditCardLogo
                                                        paymentMethod={
                                                            paymentMethod
                                                        }
                                                    />
                                                ) : (
                                                    <Typography gutterBottom>
                                                        New{" "}
                                                        {
                                                            paymentMethod.card
                                                                .brand
                                                        }{" "}
                                                        ending in{" "}
                                                        {
                                                            paymentMethod.card
                                                                .last4
                                                        }
                                                    </Typography>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {stripeKey && stripePromise && (
                                                    <Elements
                                                        stripe={stripePromise}
                                                    >
                                                        <CardForm
                                                            setLoading={
                                                                setLoading
                                                            }
                                                            setPaymentMethod={
                                                                setPaymentMethod
                                                            }
                                                            setErrorMessage={
                                                                setErrorMessage
                                                            }
                                                            setAdding={
                                                                setAddingCard
                                                            }
                                                        />
                                                    </Elements>
                                                )}
                                            </>
                                        )}
                                        <Grid container>
                                            {Boolean(selectedCoupon) ? (
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                gutterBottom
                                                                variant="caption"
                                                            >
                                                                Coupon Applied
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={8}
                                                            md={3}
                                                        >
                                                            <Typography
                                                                gutterBottom
                                                            >
                                                                {
                                                                    selectedCoupon.promotion_code
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            md={1}
                                                        >
                                                            <Tooltip title="Clear selected coupon code">
                                                                <div
                                                                    className={
                                                                        classes.pointer
                                                                    }
                                                                >
                                                                    <X
                                                                        onClick={
                                                                            clearSelectedCode
                                                                        }
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography
                                                        gutterBottom
                                                        variant="caption"
                                                    >
                                                        {getCouponDescription()}
                                                    </Typography>
                                                    <br />
                                                    <Typography
                                                        gutterBottom
                                                        variant="caption"
                                                    >
                                                        {getCostDecription()}
                                                    </Typography>
                                                </Grid>
                                            ) : (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        margin="normal"
                                                        ref={couponInput}
                                                        value={couponCode}
                                                        onChange={handleChange}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        label="Have a coupon code?"
                                                    />
                                                    {!searchingCoupons &&
                                                        couponCode.length > 3 &&
                                                        !isTyping && (
                                                            <Typography
                                                                gutterBottom
                                                                variant="caption"
                                                            >
                                                                {customCouponError
                                                                    ? customCouponError
                                                                    : "That coupon code doesn't match any active promotions"}
                                                            </Typography>
                                                        )}
                                                </Grid>
                                            )}
                                        </Grid>
                                        <div
                                            className={clsx(
                                                classes.floatRight,
                                                classes.marginTop
                                            )}
                                        >
                                            <Button
                                                disabled={addingCard}
                                                variant="contained"
                                                onClick={handleNewCard}
                                            >
                                                {Boolean(paymentMethod)
                                                    ? "Enter a new Card"
                                                    : "Add a Card"}
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={handleSubmit}
                                                disabled={
                                                    !Boolean(paymentMethod)
                                                }
                                            >
                                                Pay Now
                                            </Button>
                                        </div>
                                    </>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </General>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("re-activate")) {
    ReactDOM.render(
        <ErrorBoundary>
            <ReActivate />
        </ErrorBoundary>,
        document.getElementById("re-activate")
    );
}
