import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';

export default class AdminCTA extends Component {
    constructor(props) {
        super(props);

        this.navigate = this.navigate.bind(this);
    }
    navigate(route) {
        window.location = route;
    }
    render() {
        const StyledFab = withStyles({
            label: {
                color: 'white',
            },
        })(Fab);
        return (
            <Tooltip className="float-right" title={this.props.title}>
                <StyledFab
                    onClick={() => this.navigate(this.props.url)}
                    size={this.props.small ? 'small' : 'large'}
                    color="primary"
                >
                    {this.props.icon ? this.props.icon : <AddIcon />}
                </StyledFab>
            </Tooltip>
        );
    }
}
