import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeckCard from "@global/DeckCard";
import DeckUploadArea from "@global/DeckUploadArea";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {},
    decksContainer: {
        margin: 0,
        marginTop: theme.spacing(4)
    }
}));

const DecksDisplay = ({ decks }) => {
    const classes = useStyles();
    const { plan } = reactProps;

    return (
        <Grid
            item
            xs={12}
            container
            spacing={3}
            className={classes.decksContainer}
        >
            {decks.map(deck => (
                <Grid item xs={12} sm={6} lg={3} key={deck.id}>
                    <DeckCard deck={deck} />
                </Grid>
            ))}
            {!plan ||
                (plan != "basic" && plan != "plus" && (
                    <Grid item xs={12} sm={6} lg={3}>
                        <DeckUploadArea />
                    </Grid>
                ))}
        </Grid>
    );
};

export default DecksDisplay;
