import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    Link,
    Button,
    IconButton,
    Hidden,
    Drawer
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import MobileMenu from "./MobileMenu";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        padding: "0 10%",
        [theme.breakpoints.down("sm")]: {
            padding: 0
        }
    },
    space: {
        justifyContent: "space-between",
        "& > *": {
            flex: "1 0 33%",
            [theme.breakpoints.down("sm")]: {
                flex: "1 0 50%"
            }
        }
    },
    alignRight: {
        textAlign: "right"
    },
    menuButton: {
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            flex: "unset"
        }
    },
    logo: {
        maxHeight: 28,
        [theme.breakpoints.down("xs")]: {
            maxHeight: "unset",
            maxWidth: 264
        }
    },
    links: {
        marginLeft: theme.spacing(4)
    },
    center: {
        display: "flex",
        justifyContent: "space-evenly",
        "& a": {
            color: theme.palette.text.primary,
            fontSize: 17,
            fontWeight: "600"
        }
    }
}));

const GeneralHeader = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const classes = useStyles();

    const toggleDrawer = () => setDrawerOpen(prevState => !prevState);

    return (
        <AppBar
            position="sticky"
            elevation={1}
            className={classes.root}
            color="inherit"
        >
            <Toolbar className={classes.space}>
                <div>
                    <a href="/">
                        <img
                            src="/img/Climer-Cards-Logo-Revised.png"
                            alt="Climer Cards Logo"
                            className={classes.logo}
                        />
                    </a>
                </div>
                <Hidden smDown>
                    <div className={classes.center}>
                        <a href="/">Home</a>
                        {/*<a href="https://climercards.com/virtual#signup">How to Use</a>*/}
                        <a href="https://climercards.com/virtual#signup">
                            Pricing
                        </a>
                        <a
                            href="https://climercards.com/support-for-virtual-climer-cards/"
                            target="_blank"
                        >
                            Support
                        </a>
                    </div>
                </Hidden>
                <Hidden smDown>
                    <div className={classes.alignRight}>
                        <Button
                            color="primary"
                            variant="outlined"
                            href="https://climercards.com/virtual#signup"
                            className={classes.links}
                        >
                            Sign Up
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            href="/login"
                            className={classes.links}
                        >
                            Login
                        </Button>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <div className={classes.menuButton}>
                        <IconButton onClick={toggleDrawer}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <Drawer
                        open={drawerOpen}
                        onClose={toggleDrawer}
                        anchor="right"
                    >
                        <MobileMenu toggleDrawer={toggleDrawer} />
                    </Drawer>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

export default GeneralHeader;
