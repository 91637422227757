import React, { useState } from "react";
import {
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Typography,
    Checkbox,
    Button
} from "@material-ui/core";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import axios from "axios";

const ReActivateButton = () => {
    const [open, setOpen] = useState(false);
    const { user, userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [immediate, setImmediate] = useState(false);

    const toggleOpen = () => {
        setOpen(prevState => !prevState);
    };

    const toggleImmediate = () => {
        setImmediate(prevState => !prevState);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("immediate", immediate ? 1 : 0);
        axios
            .post(`/api/v1/admin/users/${user.uuid}/reactivate-plan`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("User plan reactivated");
                window.location = `/admin/users/${user.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <>
            <Link
                component="div"
                onClick={toggleOpen}
                style={{ cursor: "pointer" }}
            >
                Reactivate Subscription
            </Link>
            <Dialog
                fullWidth
                open={open}
                maxWidth="sm"
                onClose={toggleOpen}
                aria-labelledby="reactivate-plan-dialog"
            >
                <DialogTitle id="reactivate-plan-dialog">
                    Reactivate Plan
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        Reactivating the subscription will continue to charge
                        the user for the subscription
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={toggleOpen}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Reactivate Subscription
                    </Button>
                </DialogActions>
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404) {
            if (response.data && response.data.message) {
                setErrorMessage(response.data.message);
            } else {
                setErrorMessage(
                    "Something went wrong. Please try again later."
                );
            }
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default ReActivateButton;
