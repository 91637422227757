import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import General from "@layouts/General";
import { Paper, Typography, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import MessageSnackbar from "@global/MessageSnackbar";
import LoadingBackdrop from "@global/LoadingBackdrop";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 450,
        padding: theme.spacing(5),
        textAlign: "center"
    },
    inputContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    button: {
        marginLeft: 18,
        marginTop: 12
    }
}));

const NotFound = () => {
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = event => {
        setCode(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true);
        axios
            .get(`/api/v1/sessions/validate/${code}`)
            .then(response => {
                console.log(response);
                if (response.data.session_exists) {
                    window.location = `/${response.data.slug}`;
                } else {
                    setLoading(false);
                    setError(response.data.message);
                }
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };

    return (
        <General
            style={{
                alignItems: "center",
                background: "url(/img/cards-background.png) center",
                display: "flex",
                justifyContent: "center"
            }}
            maxWidth={false}
        >
            <Paper elevation={10} className={classes.root}>
                <Typography
                    variant="h4"
                    component="p"
                    gutterBottom
                    color="secondary"
                >
                    Looks like you're lost
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Are you trying to find a session? Enter your access code
                    below to get started
                </Typography>
                <Typography
                    variant="h5"
                    component="p"
                    color="primary"
                    style={{ marginTop: "2rem" }}
                >
                    Join a session
                </Typography>
                <div className={classes.inputContainer}>
                    <TextField
                        value={code}
                        onChange={handleChange}
                        label="Access Code"
                    />
                    <Button
                        onClick={handleSubmit}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                    >
                        Join
                    </Button>
                </div>
            </Paper>
            {error && <MessageSnackbar message={error} variant="error" />}
            {loading && <LoadingBackdrop open={loading} />}
        </General>
    );
};

if (document.getElementById("session-ended-not-found")) {
    ReactDOM.render(
        <ErrorBoundary>
            <NotFound />
        </ErrorBoundary>,
        document.getElementById("session-ended-not-found")
    );
}
