import React from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import {
    Container,
    Breadcrumbs,
    Link,
    Divider,
    Typography,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: 40
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    center: {
        alignItems: "center",
        display: "flex",
        height: "60vh",
        justifyContent: "center"
    }
}));

const Activites = () => {
    const classes = useStyles();

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Container className={classes.root}>
                <Typography variant="h3" gutterBottom display="inline">
                    Activities
                </Typography>
                <Breadcrumbs
                    aria-label="activities"
                    className={classes.breadcrumbs}
                >
                    <Link component="a" href="/dashboard">
                        Dashboard
                    </Link>
                    <Typography>Activites</Typography>
                </Breadcrumbs>
                <Divider />
                <div className={classes.center}>
                    <div style={{ textAlign: "center" }}>
                        <Typography
                            variant="h3"
                            component="p"
                            gutterBottom
                            align="center"
                        >
                            Activity ideas coming soon!
                        </Typography>
                        <Typography
                            align="center"
                            gutterBottom
                            style={{ marginBottom: "2rem" }}
                        >
                            Create a new session OR activate a draft
                        </Typography>
                        <Button
                            href="/sessions"
                            variant="contained"
                            color="primary"
                        >
                            Create New Session
                        </Button>
                    </div>
                </div>
            </Container>
        </DashboardLayout>
    );
};

if (document.getElementById("activities")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Activites />
        </ErrorBoundary>,
        document.getElementById("activities")
    );
}
