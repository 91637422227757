import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import Auth from "../layouts/Auth";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Typography,
    Paper,
    TextField,
    Link,
    Grid,
    Container
} from "@material-ui/core";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import clsx from "clsx";
import PasswordField from "@global/PasswordField";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    padded: {
        padding: theme.spacing(4)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    }
}));

const ResetPassword = () => {
    const classes = useStyles();
    const { uuid, nonce } = reactProps;
    const [values, setValues] = useState({
        password: "",
        password_confirmation: ""
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("password", values.password);
        params.append("password_confirmation", values.password_confirmation);
        params.append("uuid", uuid);
        params.append("nonce", nonce);

        axios
            .post(`/api/v1/password/reset/submit`, params)
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    "Password reset. Please login using your new password"
                );
                window.location = `/login?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Auth>
            <Container maxWidth="sm" className={classes.root}>
                <Paper elevation={10} className={classes.padded}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" align="center">
                                Reset Your Password
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <TextField
                                required
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                type="password"
                                value={values.password}
                                label="New Password"
                                onChange={handleChange("password")}
                            />
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                type="password"
                                value={values.password_confirmation}
                                label="New Password Confirmation"
                                onChange={handleChange(
                                    "password_confirmation"
                                )}
                            /> */}
                            <PasswordField
                                value={values.password}
                                handleChange={handleChange("password")}
                            />
                            <PasswordField
                                value={values.password_confirmation}
                                handleChange={handleChange(
                                    "password_confirmation"
                                )}
                                confirm
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.marginTop}>
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Change Password
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.marginTop}>
                            <Typography
                                gutterBottom
                                align="center"
                                variant="caption"
                                component="div"
                            >
                                Remember your password?
                            </Typography>
                            <Typography align="center">
                                <Link component="a" href="/login">
                                    Back to Login
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Auth>
    );

    function getErrorMessage(response) {
        console.log("response", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage(
                "Form request expired. Please refresh the page and try again."
            );
        } else if (response.status == 401) {
            setErrorMessage(
                "Cannot verify your permission to perform this action. Please try again."
            );
        } else if (response.status == 404) {
            if (response.data.message) {
                setErrorMessage(response.data.message);
            } else {
                setErrorMessage(
                    "An unknown error has occured. Please try again later."
                );
            }
        } else if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        } else {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("reset-password")) {
    ReactDOM.render(
        <ErrorBoundary>
            <ResetPassword />
        </ErrorBoundary>,
        document.getElementById("reset-password")
    );
}
