import React from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ContextualComponent from "@assets/ContextualComponent";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
        marginRight: theme.spacing(2)
    }
}));

const Nav = () => {
    const classes = useStyles();
    return (
        <ContextualComponent>
            <AppBar position="static" color="primary">
                {console.log(reactProps)}
                <Toolbar>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                    >
                        Nomadic
                    </Typography>
                    {!reactProps.auth ? (
                        <Button color="inherit" href="/login">
                            Login
                        </Button>
                    ) : (
                        <Button color="inherit" href="/logout">
                            Logout
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
        </ContextualComponent>
    );
};

export default Nav;

if (document.getElementById("main-navigation")) {
    ReactDOM.render(<Nav />, document.getElementById("main-navigation"));
}
