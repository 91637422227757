import React from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../components/AdminNav";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";
import DecksTable from "./components/DecksTable";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Tooltip,
    Fab
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const Index = () => {
    const classes = useStyles();

    return (
        <DashboardLayout sideNav={<AdminNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Decks
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.floatRight}>
                        <Tooltip title="Add a new Deck">
                            <Fab
                                size="small"
                                component="a"
                                variant="round"
                                color="primary"
                                href={`/admin/decks/create`}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Decks"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/admin/dashboard">
                            Dashboard
                        </Link>
                        <Typography>Decks</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <DecksTable />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("decks-index")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>,
        document.getElementById("decks-index")
    );
}
