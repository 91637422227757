import React, { useState, useEffect, useRef } from "react";
import {
    Typography,
    Button,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    Chip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "react-feather";
import { find, head, filter, forEach } from "lodash";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import axios from "axios";
import CardsSelectTable from "./CardsSelectTable";
import CloseIcon from "@material-ui/icons/Close";
import MultipleCardForm from "./MultipleCardForm";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {},
    spaceBetween: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    header: {
        backgroundColor: "#fff",
        borderBottom: "1px solid #00000029",
        maxHeight: "95vh",
        maxWidth: 960,
        overflow: "scroll",
        position: "fixed",
        width: "100%",
        zIndex: 50
    },
    padded: {
        padding: theme.spacing(3)
    },
    imageThumb: {
        borderRadius: 4,
        maxWidth: 85,
        height: "auto"
    },
    rightMargin: {
        marginRight: theme.spacing(3)
    }
}));

const CardSelect = ({ values, setValues, setOpen, fullScreen, deck }) => {
    const { userToken, auth } = reactProps;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState(reactProps.cards);
    const [cardsCount, setCardsCount] = useState(reactProps.cards.length);
    const [selected, setSelected] = useState(
        values.cards.length > 0 ? values.cards.map(card => card.uuid) : []
    );
    const [searchQuery, setSearchQuery] = useState("");
    const [text, setText] = useState("");
    const [cardsFilter, setCardsFilter] = useState("all-cards");
    const [filteredCards, setFilteredCards] = useState(null);
    const [reFilter, setReFilter] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [showMultiForm, setShowMultiForm] = useState(false);
    const headerRef = useRef(null);
    const tableRef = useRef(null);

    const toggleSelectAll = event => {
        if (filteredCards != null) {
            if (event.target.checked) {
                const newSelected = filteredCards.map(card => card.uuid);
                setSelected(newSelected);
            } else {
                setSelected([]);
            }
        } else {
            if (event.target.checked) {
                const newSelected = cards.map(card => card.uuid);
                setSelected(newSelected);
            } else {
                setSelected([]);
            }
        }
    };

    const handleSelect = (event, uuid) => {
        const selectedIndex = selected.indexOf(uuid);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, uuid);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleSelectMultiple = cards => {
        let currentSelected = selected;

        forEach(cards, function(card) {
            currentSelected.push(card.uuid);
        });
        setSelected(currentSelected);
    };

    const handleAddSelected = () => {
        const cardsToAdd = [];
        if (selected) {
            selected.map(cardID => {
                cardsToAdd.push(find(cards, { uuid: cardID }));
            });
        }
        if (cardsToAdd.length) {
            setValues({
                ...values,
                featured_image: head(cardsToAdd).frontImage,
                featured_alt: head(cardsToAdd).alt_text,
                cards: cardsToAdd
            });
            setMessage("Cards Added");
            setOpen(false);
        } else {
            setError("No selected images found");
        }
    };

    const handleAddSingle = cardID => {
        const allCards = values.cards;
        if (find(allCards, { uuid: cardID })) {
            setError("Cannot add more than one of the same card");
            return;
        }
        allCards.push(find(cards, { uuid: cardID }));
        if (allCards.length === 1) {
            setValues({
                ...values,
                featured_image: head(allCards).frontImage,
                featured_alt: head(allCards).alt_text,
                cards: allCards
            });
        } else {
            setValues({ ...values, cards: allCards });
        }
        setMessage("Card Added");
        setOpen(false);
    };

    const handleSearchTextChange = event => {
        setText(event.target.value);
        event.persist();
    };

    const handleSearchKeyUp = event => {
        if (event.keyCode === 13 && event.target.value.length > 0) {
            handleSearchSubmit(event.target.value);
        }
        event.persist();
    };

    const clearSearch = () => {
        setSearchQuery("");
        setText("");
        setCards(reactProps.cards);

        if (cardsFilter !== null) {
            setTimeout(
                function() {
                    setReFilter(true);
                }.bind(this),
                500
            );
        }
    };

    const handleSearchSubmit = searchText => {
        console.log("text", text);
        setLoading(true);
        let params = new URLSearchParams();
        params.append("searchQuery", text);

        axios
            .get(`/api/v1/cards/search`, {
                params: params,
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                setCards(response.data.cards);
                setSearchQuery(text);
                setText("");
                if (cardsFilter != null) {
                    setTimeout(
                        function() {
                            setReFilter(true);
                            setLoading(false);
                        }.bind(this),
                        500
                    );
                }
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleReFilter = () => {
        setReFilter(false);
        console.log("should re-filter");
        let newFilteredCards;

        if (cardsFilter == "all-cards") {
            newFilteredCards = null;
        } else if (cardsFilter == "my-uploads") {
            newFilteredCards = filter(cards, function(card) {
                return card.user_id == auth.id;
            });
        } else if (cardsFilter == "climer") {
            newFilteredCards = filter(cards, function(card) {
                return card.climer == true;
            });
        }
        setFilteredCards(newFilteredCards);
    };

    const toggleMultiForm = () => {
        setShowMultiForm(show => !show);
        if (headerRef.current && tableRef.current) {
            tableRef.current.style.marginTop =
                headerRef.current.clientHeight + "px";
        }
    };

    useEffect(() => {
        handleReFilter();
    }, [cardsFilter]);

    useEffect(() => {
        if (reFilter == true) {
            handleReFilter();
        }
    });

    useEffect(() => {
        if (headerRef.current && tableRef.current) {
            setInterval(() => {
                if (headerRef.current && tableRef.current) {
                    tableRef.current.style.marginTop =
                        headerRef.current.clientHeight + "px";
                }
            }, 10);
        }
    }, [headerRef.current, tableRef.current]);

    return (
        <div>
            <div
                ref={headerRef}
                className={clsx(classes.padded, classes.header)}
            >
                {!showMultiForm ? (
                    <div className={classes.spaceBetween}>
                        <Typography variant="h4">Cards</Typography>
                        <div style={{ textAlign: "right" }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={toggleMultiForm}
                                className={classes.rightMargin}
                            >
                                Add New Cards
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleAddSelected}
                            >
                                Add Selected
                            </Button>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                ) : (
                    <div className={classes.spaceBetween}>
                        <Typography variant="h4">Cards</Typography>
                        <div style={{ textAlign: "right" }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={toggleMultiForm}
                                className={classes.rightMargin}
                            >
                                Search Cards
                            </Button>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                )}
                {!showMultiForm ? (
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label="Search"
                                margin="normal"
                                value={text}
                                onKeyUp={handleSearchKeyUp}
                                onChange={handleSearchTextChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    handleSearchSubmit(text)
                                                }
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {searchQuery && searchQuery != "" && (
                                <Chip
                                    label={`Search: ${searchQuery}`}
                                    onDelete={clearSearch}
                                />
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                select
                                SelectProps={{ native: true }}
                                margin="normal"
                                label="Filter"
                                value={cardsFilter}
                                onChange={event =>
                                    setCardsFilter(event.target.value)
                                }
                            >
                                <option value="all-cards">All Cards</option>
                                <option value="climer">Climer Cards</option>
                                <option value="my-uploads">
                                    My Uploaded Cards
                                </option>
                            </TextField>
                        </Grid>
                    </Grid>
                ) : (
                    <MultipleCardForm
                        handleSelectMultiple={handleSelectMultiple}
                        setAvailableCards={setCards}
                        setShowMultiForm={setShowMultiForm}
                    />
                )}
            </div>
            <div ref={tableRef}>
                {filteredCards != null ? (
                    <>
                        {filteredCards.length > 0 ? (
                            <CardsSelectTable
                                cards={filteredCards}
                                selected={selected}
                                toggleSelectAll={toggleSelectAll}
                                handleSelect={handleSelect}
                                handleAddSingle={handleAddSingle}
                                classes={classes}
                            />
                        ) : (
                            <Typography variant="h5" align="center">
                                No Cards To Show
                            </Typography>
                        )}
                    </>
                ) : (
                    <>
                        {cards.length > 0 ? (
                            <CardsSelectTable
                                cards={cards}
                                selected={selected}
                                toggleSelectAll={toggleSelectAll}
                                handleSelect={handleSelect}
                                handleAddSingle={handleAddSingle}
                                classes={classes}
                            />
                        ) : (
                            <Typography variant="h5" align="center">
                                No Cards To Show
                            </Typography>
                        )}
                    </>
                )}
            </div>
            {loading && <LoadingBackdrop open={loading} />}
            {message && (
                <MessageSnackbar
                    message={message}
                    variant="success"
                    onClose={() => setMessage("")}
                />
            )}
            {error && (
                <MessageSnackbar
                    message={error}
                    variant="error"
                    onClose={() => setError("")}
                />
            )}
        </div>
    );
};

export default React.memo(CardSelect);
