import React, { useState, useEffect } from "react";
import {
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography
} from "@material-ui/core";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";

const useStyles = makeStyles(() => ({
    floatRight: {
        float: "right"
    },
    cardContainer: {
        borderBottom: "1px solid #666",
        marginBottom: 15,
        marginTop: 15,
        paddingBottom: 5
    }
}));

const AddPaymentMethodDialog = props => {
    const { handleClose } = props;
    const { userToken } = reactProps;
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const cardOptions = {
        iconStyle: "solid",
        style: {
            base: {
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "18px"
            }
        }
    };

    const attemptHandleSubmit = async () => {
        setLoading(true);
        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (payload.error) {
            setErrorMessage(payload.error.message);
            setLoading(false);
        } else {
            console.log("paymentMethod", payload.paymentMethod.id);
            setPaymentMethod(payload.paymentMethod.id);
        }
    };

    const handleSubmit = () => {
        setSubmitted(true);
        let params = new URLSearchParams();
        params.append("paymentMethod", paymentMethod);

        axios
            .post(`/api/v1/user/add-payment-method`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    "Default Payment Method Updated"
                );
                window.location = `/manage-plan?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (paymentMethod && !submitted) {
            handleSubmit();
        }
    });

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            component="h2"
                            align="center"
                            gutterBottom
                        >
                            Enter payment information
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardElement
                            options={cardOptions}
                            className={classes.cardContainer}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={attemptHandleSubmit}
                >
                    Save
                </Button>
            </DialogActions>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default AddPaymentMethodDialog;
