import React from "react";
import { Paper, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import getInitials from "@utils/getInitials";
import { last } from "lodash";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    userRow: {
        alignItems: "center",
        borderBottom: "1px solid #ccc",
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2),
        "&:last-of-type": {
            borderBottom: "none"
        }
    }
}));

const NewUsers = () => {
    const classes = useStyles();
    const { newUsers } = reactProps;

    console.log("newUsers: ", newUsers);

    return (
        <Paper className={classes.root} elevation={1}>
            <Typography variant="h4" gutterBottom>
                New Users
            </Typography>
            {Boolean(newUsers) && newUsers.length > 0 ? (
                <>
                    {newUsers.map(user => (
                        <a
                            href={`/admin/users/${user.uuid}/edit`}
                            key={user.id}
                            className={classes.userRow}
                        >
                            <Avatar
                                style={
                                    Boolean(user.profileImage)
                                        ? {}
                                        : {
                                              backgroundColor: user.avatarColor
                                                  ? user.avatarColor
                                                  : "#115a78"
                                          }
                                }
                                src={user.profileImage}
                                alt={user.name}
                            >
                                {getInitials(user.name)}
                            </Avatar>
                            <Typography>{user.name}</Typography>
                            <Typography variant="caption">
                                {moment(user.created_at).format("L")}
                            </Typography>
                        </a>
                    ))}
                </>
            ) : (
                <div className={classes.root}>
                    <Typography gutterBottom align="center">
                        No new users found.
                    </Typography>
                </div>
            )}
        </Paper>
    );
};

export default NewUsers;
