import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { find } from "lodash";

const PromptDisplay = props => {
    const { prompts, currentPrompt } = props;
    const presentPrompt = find(prompts, function(prompt) {
        return prompt.order === parseInt(currentPrompt);
    });

    const isPresentPrompt = presentPrompt ? presentPrompt.prefix : null;

    return (
        <>
            {currentPrompt !== null && (
                <div>
                    {isPresentPrompt ? (
                        <Typography
                            variant="h4"
                            component="h3"
                            align="center"
                            color="primary"
                        >
                            Select a card that represents...
                        </Typography>
                    ) : null}
                    <Typography
                        variant="h2"
                        component="p"
                        gutterBottom
                        align="center"
                        color="inherit"
                        style={{ color: "581e47" }}
                    >
                        {presentPrompt ? presentPrompt.description : "--"}
                    </Typography>
                </div>
            )}
        </>
    );
};

export default PromptDisplay;
