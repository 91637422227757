import React, { useState } from "react";
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    TextField
} from "@material-ui/core";
import LoadingBackdrop from "@global/LoadingBackdrop";

const QuickStartButton = props => {
    const { title, variant, marginRight } = props;
    const { auth, userToken, decks } = reactProps;
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState({
        quick_start_name: auth.quick_start_name
            ? auth.quick_start_name
            : auth.name,
        quick_start_slug: auth.quick_start_slug ? auth.quick_start_slug : "",
        deck_id: auth && auth.deck_id ? auth.deck_id : ""
    });

    const toggleOpen = () => {
        setOpen(prevState => !prevState);
    };

    const handleClick = () => {
        setLoading(true);
        axios
            .get(`/api/v1/user/current-quick-session`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                if (response.data.current_session) {
                    window.location = `/user/quick-start`;
                }
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
                toggleOpen();
            });
    };

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let session_name = encodeURIComponent(values.quick_start_name);
        window.location = `/user/quick-start?session_name=${session_name}&slug=${values.quick_start_slug}&deck_id=${values.deck_id}`;
    };

    return (
        <>
            <Button
                color="primary"
                onClick={handleClick}
                variant={variant ? variant : "contained"}
                style={marginRight ? { marginRight: 20 } : {}}
            >
                {title ? title : "Quick Start"}
            </Button>
            <Dialog
                fullWidth
                open={open}
                maxWidth="md"
                onClose={toggleOpen}
                aria-labelledby="quick-start-confirmation-dialog"
            >
                <DialogTitle id="quick-start-confirmation-dialog">
                    Start a Quick Session
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography gutterBottom align="center">
                                Confirm your Session Information
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Session Name"
                                value={values.quick_start_name}
                                onChange={handleChange("quick_start_name")}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Access Code"
                                value={values.quick_start_slug}
                                onChange={handleChange("quick_start_slug")}
                            />
                            <TextField
                                select
                                fullWidth
                                size="small"
                                label="Deck"
                                margin="normal"
                                SelectProps={{
                                    native: true
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={values.deck_id}
                                onChange={handleChange("deck_id")}
                            >
                                <option value="">None Selected</option>
                                {decks.map(deck => (
                                    <option value={deck.id} key={deck.id}>
                                        {deck.name}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={toggleOpen}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Confirm Session
                    </Button>
                </DialogActions>
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
        </>
    );
};

export default QuickStartButton;
