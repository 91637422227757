import React from "react";
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { upperCaseFirst } from "@utils/stringUtils";
import { Check, Plus } from "react-feather";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {},
    pricingRow: {
        border: "none"
    },
    borderSides: {
        borderLeft: "1px solid #999",
        borderRight: "1px solid #999"
    },
    borderLeft: {
        borderLeft: "1px solid #999"
    },
    borderRight: {
        borderRight: "1px solid #999"
    },
    trialBox: {
        backgroundColor: "#05597B",
        margin: 0,
        padding: theme.spacing(2)
    },
    trialText: {
        color: "white"
    },
    disabled: {
        color: "#999"
    }
}));

const PlanComparison = props => {
    const classes = useStyles();
    const { currentPlan, selectedPlan } = props;

    const getUsersValue = planName => {
        switch (planName) {
            case "Team":
                return "Up to 10";
                break;
            case "Education":
                return "Up to 75";
            case "Enterprise":
                return "Up to 300";
                break;
            default:
                return "1";
        }
    };

    const getActiveSessions = planName => {
        switch (planName) {
            case "Professional":
                return "10";
                break;
            case "Team":
                return "10 per user";
                break;
            case "Education":
                return "5 per user";
            case "Enterprise":
                return "Unlimited";
                break;
            default:
                return "3";
        }
    };

    const getPlannedSessions = planName => {
        switch (planName) {
            case "Basic":
                return "Up to 3";
                break;
            case "Plus":
                return "Up to 10";
                break;
            default:
                return "Unlimited";
        }
    };

    return (
        <Table>
            <TableBody>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        <Typography gutterBottom variant="h4" align="center">
                            Features
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderSides
                        )}
                    >
                        <Typography
                            gutterBottom
                            variant="h4"
                            align="center"
                            className={classes.disabled}
                        >
                            {upperCaseFirst(currentPlan)} (Current Plan)
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(classes.pricingRow, classes.borderLeft)}
                    >
                        <Typography gutterBottom variant="h4" align="center">
                            {selectedPlan.name}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        <Typography gutterBottom align="center">
                            Users
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderSides
                        )}
                    >
                        <Typography
                            gutterBottom
                            align="center"
                            className={classes.disabled}
                        >
                            {getUsersValue(upperCaseFirst(currentPlan))}
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(classes.pricingRow, classes.borderLeft)}
                    >
                        <Typography gutterBottom align="center">
                            {getUsersValue(selectedPlan.name)}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        <Typography gutterBottom align="center">
                            Active Sessions
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderSides
                        )}
                    >
                        <Typography
                            gutterBottom
                            align="center"
                            className={classes.disabled}
                        >
                            {getActiveSessions(upperCaseFirst(currentPlan))}
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(classes.pricingRow, classes.borderLeft)}
                    >
                        <Typography gutterBottom align="center">
                            {getActiveSessions(selectedPlan.name)}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        <Typography gutterBottom align="center">
                            Orginal Climer Cards
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderSides
                        )}
                    >
                        <Typography
                            gutterBottom
                            align="center"
                            className={classes.disabled}
                        >
                            <Check />
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(classes.pricingRow, classes.borderLeft)}
                    >
                        <Typography gutterBottom align="center">
                            <Check />
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        <Typography gutterBottom align="center">
                            <Plus /> Additional Decks
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderSides
                        )}
                    >
                        <Typography
                            gutterBottom
                            align="center"
                            className={classes.disabled}
                        >
                            {currentPlan == "Basic" ? " " : <Check />}
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(classes.pricingRow, classes.borderLeft)}
                    >
                        <Typography gutterBottom align="center">
                            {selectedPlan.name == "Basic" ? " " : <Check />}
                        </Typography>
                    </TableCell>
                </TableRow>
                {/* <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        <Typography gutterBottom align="center">
                            Planned Sessions
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderSides
                        )}
                    >
                        <Typography
                            gutterBottom
                            align="center"
                            className={classes.disabled}
                        >
                            {getPlannedSessions(currentPlan)}
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(classes.pricingRow, classes.borderLeft)}
                    >
                        <Typography gutterBottom align="center">
                            {getPlannedSessions(selectedPlan.name)}
                        </Typography>
                    </TableCell>
                </TableRow> */}
            </TableBody>
        </Table>
    );
};

export default PlanComparison;
