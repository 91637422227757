import React, { useState } from "react";
import {
    Paper,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    deleteButton: {
        color: "#6e7518",
        marginRight: theme.spacing(3)
    },
    dialogActions: {
        justifyContent: "flex-start",
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3)
    }
}));

const DeleteUser = () => {
    const classes = useStyles();
    const { user, userToken } = reactProps;
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const toggleOpen = () => setOpen(!open);

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("reason", reason);
        params.append("email", user.email);
        params.append("token", userToken);

        axios
            .post("/api/v1/user/delete-request", params, {
                headers: { Authorization: `Bearing: ${userToken}` }
            })
            .then(response => {
                if (response.data.request_sent) {
                    setMessage(
                        "Your request to have your account deleted has been sent."
                    );
                    setOpen(false);
                    setReason("");
                } else {
                    setErrorMessage("Something went wrong. Please try again.");
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                getErrorMessage(error);
                setLoading(false);
            });
    };

    return (
        <Paper className={classes.root}>
            <Typography gutterBottom variant="body1">
                Settings
            </Typography>
            <Typography>Delete User</Typography>
            <Typography gutterBottom>
                Submit a request to delete your user and permanently purge all
                data associated with your account.
            </Typography>
            <Button
                onClick={toggleOpen}
                variant="outlined"
                color="inherit"
                className={classes.deleteButton}
            >
                Delete User
            </Button>
            <Dialog
                open={open}
                onClose={toggleOpen}
                aria-labelledby="delete-user-dialog"
                aria-describedby="delete-user-request-form"
            >
                <DialogTitle id="delete-user-dialog">
                    Delete Account
                </DialogTitle>
                <DialogContent id="delete-user-request-form">
                    <Typography gutterBottom>
                        Are you sure you want to permanently delete your user
                        and all associated data? We will notify you once
                        complete.
                    </Typography>
                    <TextField
                        fullWidth
                        required
                        name="reason"
                        type="text"
                        margin="normal"
                        label="Reason for leaving?"
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        disabled={reason === ""}
                        onClick={handleSubmit}
                        variant="outlined"
                        color="inherit"
                        className={classes.deleteButton}
                    >
                        Delete User
                    </Button>
                    <Button onClick={toggleOpen}>Cancel</Button>
                </DialogActions>
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            {message && message != "" && (
                <MessageSnackbar variant="success" message={message} />
            )}
        </Paper>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default DeleteUser;
