import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    Link,
    Button,
    IconButton,
    Avatar,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Divider
} from "@material-ui/core";
import { Menu } from "react-feather";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import MenuIcon from "@material-ui/icons/Menu";
import { getInitial, buildLink } from "@utils/stringUtils";
import clsx from "clsx";
import MobileMenu from "./MobileMenu";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import LoadingBackdrop from "@global/LoadingBackdrop";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MessageSnackbar from "@global/MessageSnackbar";
import QRCode from "react-qr-code";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        padding: "0 5%",
        [theme.breakpoints.down("md")]: {
            padding: 0
        }
    },
    space: {
        justifyContent: "space-between"
    },
    floatRight: {
        float: "right"
    },
    even: {
        justifyContent: "space-evenly"
    },
    leftHeader: {
        "& .MuiButton-label": {
            textAlign: "center"
        },
        [theme.breakpoints.up("md")]: {
            minWidth: 190
        },
        [theme.breakpoints.down("sm")]: {
            "& .MuiIconButton-root": {
                padding: 0,
                width: "32%"
            }
        }
    },
    logo: {
        maxHeight: 64,
        padding: 10,
        [theme.breakpoints.down("xs")]: {
            maxHeight: "unset",
            maxWidth: "100%"
        }
    },
    links: {
        marginLeft: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    profileDialog: {
        padding: 36,
        textAlign: "center",
        overflow: "hidden"
    },
    profileDivider: {
        margin: 15,
        transform: "translateX(-15%)",
        width: "150%"
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        "& img": {
            width: "auto"
        }
    },
    profileAvatar: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: 36,
        margin: "8px auto",
        height: 100,
        width: 100
    },
    flexBetween: {
        display: "flex",
        justifyContent: "space-between"
    },
    rightSection: {
        alignItems: "center",
        display: "flex",
        "& > *:nth-child(n)": {
            marginRight: theme.spacing(2)
        },
        [theme.breakpoints.down("sm")]: {
            "& > *:nth-child(n)": {
                marginRight: 0
            }
        }
    }
}));

const SessionHeader = props => {
    const { auth, facilitator, organization, userToken, rootUrl } = reactProps;
    const {
        session,
        handleNext,
        nextDisabled,
        prevDisabled,
        handlePrevious,
        toggleDropdown
    } = props;
    const [open, setOpen] = useState(false);
    // const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();

    // const toggleDrawer = () => setDrawerOpen(prevState => !prevState);

    const orgHref =
        session && session.user.organization_id
            ? buildLink(session.user.organization.url)
            : null;

    const handleExitAndEnd = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", [session.uuid]);

        axios
            .post(`/api/v1/sessions/archive`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session ended");
                window.location = `/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const showCopied = () => {
        setCopied(true);

        navigator.clipboard.writeText(`${rootUrl}/${session.slug}`);

        setTimeout(
            function() {
                setCopied(false);
            }.bind(this),
            3000
        );
    };

    const setFacilitatorActivity = value => {
        let params = new URLSearchParams();
        params.append("facilitator_is_active", value);

        axios
            .post(
                `/api/v1/sessions/${session.uuid}/update-active-facilitator`,
                params
            )
            .then(response => {
                console.log("response-active-facilitator", response);
                // setSession(response.data.session);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    return (
        <AppBar
            position="sticky"
            elevation={1}
            className={classes.root}
            color="inherit"
        >
            <Toolbar className={classes.even}>
                <div className={classes.leftHeader}>
                    {session &&
                        auth &&
                        (auth.id === session.user_id ||
                            (auth.organization_id &&
                                auth.organization_id ==
                                    session.organization_id)) &&
                        window.location.href.includes("presentation") && (
                            <Button
                                size="small"
                                component="a"
                                color="primary"
                                onClick={() => setOpen("confirm")}
                            >
                                Exit Session
                            </Button>
                        )}
                    <Tooltip title="Previous Prompt">
                        <IconButton
                            component="div"
                            onClick={
                                handlePrevious ? handlePrevious : undefined
                            }
                            disabled={prevDisabled}
                        >
                            <SkipPreviousIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Manage Prompts">
                        <IconButton
                            onClick={
                                toggleDropdown ? toggleDropdown : undefined
                            }
                        >
                            <Menu />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Next Prompt">
                        <IconButton
                            component="div"
                            onClick={handleNext ? handleNext : undefined}
                            disabled={nextDisabled}
                        >
                            <SkipNextIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.center}>
                    {Boolean(facilitator) &&
                    Boolean(facilitator.organization) &&
                    Boolean(facilitator.organization.whitelabel) &&
                    !Boolean(session.deck.climer) ? (
                        <img
                            src={facilitator.organization.logoImage}
                            alt={`${facilitator.organization.name} logo`}
                            className={classes.logo}
                        />
                    ) : (
                        <img
                            src="/img/Climer-Cards-Logo-Revised.png"
                            alt="Climer Cards Logo"
                            className={classes.logo}
                        />
                    )}
                </div>
                <div className={classes.rightSection}>
                    {/* <CopyToClipboard
                        text={`${rootUrl}/${session.slug}`}
                        onCopy={showCopied}
                    > */}
                    {session &&
                        auth &&
                        (auth.id === session.user_id ||
                            (auth.organization_id &&
                                auth.organization_id ===
                                    session.organization_id)) &&
                        window.location.href.includes("presentation") && (
                            <Button
                                size="small"
                                color="primary"
                                onClick={() => {
                                    !openModal
                                        ? setOpenModal(true)
                                        : setOpenModal(false);
                                }}
                            >
                                {openModal && <>Hide QR</>}
                                {!openModal && <>Show QR</>}
                            </Button>
                        )}
                    <Button size="small" color="primary" onClick={showCopied}>
                        Copy Link
                    </Button>
                    {/* </CopyToClipboard> */}
                    {session.user && session.user.public == 1 && (
                        <Avatar
                            onClick={() => setOpen("profile")}
                            component="button"
                            style={{
                                border: "none",
                                backgroundColor: session.user.profileImage
                                    ? ""
                                    : session.user.avatarColor
                                    ? session.user.avatarColor
                                    : "#115a78"
                            }}
                            src={session.user.profileImage}
                            alt={session.user.name}
                            className={classes.avatar}
                        >
                            {getInitial(session.user.name)}
                        </Avatar>
                    )}
                </div>
            </Toolbar>
            <Dialog
                fullWidth
                maxWidth={open == "confirm" ? "sm" : "xs"}
                open={Boolean(open)}
                onClose={() => setOpen(false)}
            >
                {open == "confirm" && <DialogTitle>Exit Session</DialogTitle>}
                {open == "profile" && (
                    <DialogContent className={classes.profileDialog}>
                        <Avatar
                            src={session.user.profileImage}
                            alt={session.user.name}
                            style={{
                                backgroundColor: session.user.avatarColor
                                    ? session.user.avatarColor
                                    : "inherit"
                            }}
                            className={classes.profileAvatar}
                        >
                            {getInitial(session.user.name)}
                        </Avatar>
                        <Typography variant="h4">
                            {session.user.name}
                        </Typography>
                        {session.user.title && (
                            <Typography variant="body1" gutterBottom>
                                {session.user.title}
                            </Typography>
                        )}
                        {session.user.organization_id && (
                            <>
                                <Typography variant="h5">
                                    {session.user.organization.name}
                                </Typography>
                                {session.user.organization.url && (
                                    <Link
                                        href={orgHref}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {session.user.organization.url}
                                    </Link>
                                )}
                            </>
                        )}
                        <Divider className={classes.profileDivider} />
                        <Typography variant="h4" color="secondary" gutterBottom>
                            Like what you saw?
                        </Typography>
                        <Typography
                            variant="h3"
                            color="primary"
                            component="a"
                            href="https://climercards.com/virtual#signup"
                            style={{
                                marginBottom: 28,
                                display: "inline-block"
                            }}
                        >
                            GET Climer Cards TODAY!
                        </Typography>
                        <Typography
                            style={{ fontWeight: "bold", fontSize: 12 }}
                            align="center"
                        >
                            Already have an account?{" "}
                            <a href="/login" style={{ fontWeight: "normal" }}>
                                Login
                            </a>
                        </Typography>
                    </DialogContent>
                )}
                {open == "confirm" && (
                    <>
                        <DialogContent>
                            <Typography gutterBottom>
                                <strong>
                                    Archiving this session will remove all
                                    active participants.
                                </strong>
                            </Typography>
                            <Typography gutterBottom>
                                If you need to leave temporarily just exit the
                                session only and you will be able to return
                                through your dashboard or with the shared link
                                and resume the session.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                href={`/sessions/${reactProps.session.uuid}/edit`}
                                variant="contained"
                                color="primary"
                                onClick={() => setFacilitatorActivity(0)}
                            >
                                Exit Session
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleExitAndEnd}
                            >
                                Exit and Archive Session
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {copied && (
                <MessageSnackbar variant="success" message="Link Copied" />
            )}
            <Snackbar
                open={Boolean(openModal)}
                className={"session-qr-code"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Slide direction="up">
                    <QRCode
                        style={{
                            backgroundColor: "white",
                            borderRadius: "10%",
                            padding: 15,
                            margin: "0 20px 20px",
                            border: "2px solid black",
                            width: 170,
                            height: 170
                        }}
                        value={`${rootUrl}/${session.slug}`}
                    />
                </Slide>
            </Snackbar>
        </AppBar>
    );
};

export default SessionHeader;
