import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Paper,
    IconButton,
    Button,
    Typography,
    Menu,
    MenuItem
} from "@material-ui/core";
import { MoreVertical, ChevronRight } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {},
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2)
    },
    linkSection: {
        textAlign: "right",
        textTransform: "uppercase",
        padding: theme.spacing(2)
    }
}));

const DashboardCard = props => {
    const { heading, options, children, link, disabled, ...rest } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h3">{heading}</Typography>
                {options && (
                    <IconButton
                        onClick={handleClick}
                        aria-haspopup="true"
                        aria-controls="options-menu"
                    >
                        <MoreVertical />
                    </IconButton>
                )}
            </div>
            <div {...rest}>{children}</div>
            <div className={classes.linkSection}>
                <Button href={link.href} endIcon={<ChevronRight />}>
                    {link.text}
                </Button>
            </div>
            {options && (
                <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    keepMounted
                    id="options-menu"
                >
                    {options.map(option => (
                        <MenuItem
                            key={option.action}
                            component={
                                typeof option.action == "string" ? "a" : "li"
                            }
                            href={
                                typeof option.action == "string"
                                    ? option.action
                                    : ""
                            }
                            onClick={
                                typeof option.action == "function"
                                    ? option.action
                                    : undefined
                            }
                            disabled={
                                option.disabled && option.disabled == true
                            }
                        >
                            {option.text}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </Paper>
    );
};

DashboardCard.propTypes = {
    heading: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.exact({
            text: PropTypes.string,
            action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            disabled: PropTypes.bool
        })
    ),
    children: PropTypes.any.isRequired,
    link: PropTypes.exact({
        text: PropTypes.string,
        href: PropTypes.string
    }).isRequired
};

export default DashboardCard;
