import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../components/AdminNav";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Button,
    LinearProgress,
    TextField,
    Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";
import { CSVLink } from "react-csv";
import axios from "axios";
import MessageSnackbar from "@global/MessageSnackbar";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    },
    main: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        textAlign: "center"
    }
}));

const Export = () => {
    const classes = useStyles();
    const { userToken, roles } = reactProps;
    const [selectedRole, setSelectedRole] = useState("");
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const getExport = role => {
        setLoading(true);
        axios
            .get(`/api/v1/admin/users/export?role=${role ? role : ""}`, {
                headers: { Authorization: `Bearer ${userToken}` }
            })
            .then(response => {
                setData(response.data.export_data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
                getErrorMessage(error);
            });
    };

    React.useEffect(() => {
        getExport();
    }, []);

    const handleChangeRole = event => {
        const role = event.target.value;
        setSelectedRole(role);
        getExport(role);
    };

    return (
        <DashboardLayout sideNav={<AdminNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Users
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Users"
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/admin/dashboard">
                            Dashboard
                        </Link>
                        <Link href="/admin/users">Users</Link>
                        <Typography>Export</Typography>
                    </Breadcrumbs>
                    <Divider />
                </Grid>
                <Grid
                    container
                    spacing={3}
                    component={Paper}
                    className={classes.main}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            component="h2"
                            gutterBottom
                            align="left"
                        >
                            Export User Information
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            SelectProps={{ native: true }}
                            label="Filter by role"
                            onChange={handleChangeRole}
                            value={selectedRole}
                            InputLabelProps={{ shrink: true }}
                        >
                            <option value="">No Filter</option>
                            {roles.map(role => (
                                <option value={role.name} key={role.id}>
                                    {role.label}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        {loading === true && (
                            <>
                                <Typography gutterBottom>
                                    Generating Report...
                                </Typography>
                                <LinearProgress />
                            </>
                        )}
                        {data && !loading && (
                            <Button
                                data={data}
                                target="_blank"
                                color="primary"
                                variant="contained"
                                component={CSVLink}
                                filename={`users-info-${new Date().toString()}.csv`}
                            >
                                Download Export
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("errorMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("admin-user-export")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Export />
        </ErrorBoundary>,
        document.getElementById("admin-user-export")
    );
}
