import React, { useState } from "react";
import { Grid, Paper, Typography, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    },
    buttons: {
        textAlign: "right",
        "& > button": {
            color: "#f00",
            display: "block",
            marginLeft: "auto"
        }
    }
}));

const ActiveSessionCard = props => {
    const { rootUrl, userToken } = reactProps;
    const { session, setLoading, setCopied, setErrorMessage } = props;
    const classes = useStyles();
    const [edit, setEdit] = useState(false);
    const [text, setText] = useState(session.slug);

    const showCopied = () => {
        setCopied(true);

        navigator.clipboard.writeText(`${rootUrl}/${session.slug}`);

        setTimeout(
            function() {
                setCopied(false);
            }.bind(this),
            3000
        );
    };

    const handleChange = event => {
        setText(event.target.value);
    };

    const toggleEdit = () => {
        setEdit(prevState => !prevState);
    };

    const handleSaveChanges = () => {
        console.log(text);
        setLoading(true);
        let params = new FormData();
        params.append("name", session.name);
        params.append("slug", text);
        params.append("description", session.description);
        params.append("deck_id", session.deck_id);

        axios
            .post(`/api/v1/sessions/${session.uuid}/edit`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session URL updated");
                window.location = `/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleEnd = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", [session.uuid]);

        axios
            .post(`/api/v1/sessions/archive`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session status updated");
                window.location = `/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Paper className={classes.padded}>
            <Grid container>
                <Grid item container xs={8}>
                    <Grid item xs={12}>
                        <Typography variant="caption">Session Name</Typography>
                        <Typography
                            gutterBottom
                            variant="h3"
                            component="a"
                            href={`/sessions/${session.uuid}/edit`}
                            color="primary"
                            style={{
                                textDecoration: "underline",
                                display: "block"
                            }}
                        >
                            {session.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption">Access Code</Typography>
                        <Typography gutterBottom color="primary">
                            {session.slug}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption">Deck</Typography>
                        <Typography gutterBottom color="primary">
                            {session.deck.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.buttons}>
                    <Button
                        component="a"
                        color="primary"
                        variant="contained"
                        href={`/${session.slug}/presentation`}
                    >
                        Enter
                    </Button>
                    <Button onClick={handleEnd} color="inherit">
                        Archive Session
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {!edit ? (
                        <>
                            <Typography variant="caption">
                                Shared URL
                            </Typography>
                            <Typography gutterBottom color="primary">
                                {`${rootUrl}/${session.slug}`}
                            </Typography>
                        </>
                    ) : (
                        <TextField
                            size="small"
                            label="Shared URL"
                            value={text}
                            onChange={handleChange}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    {!edit ? (
                        <Button
                            size="small"
                            onClick={toggleEdit}
                            color="primary"
                        >
                            Edit Access Code / Link
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            onClick={handleSaveChanges}
                            color="primary"
                        >
                            Save Changes
                        </Button>
                    )}
                    {/* <CopyToClipboard
                        text={`${rootUrl}/${session.slug}`}
                        onCopy={showCopied}
                    > */}
                    <Button size="small" color="primary" onClick={showCopied}>
                        Copy URL
                    </Button>
                    {/* </CopyToClipboard> */}
                    <Button
                        size="small"
                        component="a"
                        href={`/sessions/${session.uuid}/edit`}
                        color="primary"
                    >
                        Manage Prompts
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default ActiveSessionCard;
