import React, { useState } from "react";
import { AppBar, Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2)
    },
    content: {
        alignItems: "center",
        display: "flex",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#FFF"
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: "#FFF"
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: "#FFF",
            color: "#FFF"
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#FFF"
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):after": {
            borderBottomColor: "#FFF"
        },
        "& .MuiInput-underline": {
            color: "#FFF"
        },
        "& a": {
            backgroundColor: "#FFF"
        },
        "& .MuiButton-outlinedPrimary:hover": {
            backgroundColor: "#FFF"
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column"
        }
    },
    label: {
        fontSize: 24,
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 8,
            textAlign: "center"
        }
    },
    inputRoot: {
        margin: "0 1rem"
    }
}));

const EnterCode = () => {
    const classes = useStyles();
    const [code, setCode] = useState("");

    return (
        <AppBar
            position="sticky"
            elevation={1}
            className={classes.root}
            color="primary"
        >
            <div className={classes.content}>
                <Typography
                    htmlFor="access-code-input"
                    className={classes.label}
                    color="secondary"
                >
                    Enter Session Access Code Here.
                </Typography>
                <div>
                    <TextField
                        className={classes.inputRoot}
                        id="access-code-input"
                        value={code}
                        onChange={event => setCode(event.target.value)}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        href={`/${code}`}
                    >
                        Enter
                    </Button>
                </div>
            </div>
        </AppBar>
    );
};

export default EnterCode;
