import React from "react";
import PropTypes from "prop-types";
import { Icon, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: 137,
        padding: theme.spacing(2),
        position: "relative"
    },
    flexBetween: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    icon: {
        "& > svg": {
            borderRadius: "50%",
            height: 40,
            padding: 7,
            width: 40
        }
    },
    clickable: {
        cursor: "pointer",
        "&:focus": {
            outline: `1px solid ${theme.palette.secondary.main}`
        }
    },
    infoIcon: {
        position: "absolute",
        top: 15,
        right: 15
    }
}));

const StatsCard = props => {
    const classes = useStyles();
    const { icon, title, number, link, infoIcon, newTab } = props;
    return (
        <Paper
            className={classes.root}
            elevation={1}
            target={newTab ? "_blank" : "_self"}
            href={link ? link : undefined}
            component={link ? "a" : "div"}
        >
            {Boolean(infoIcon) == true && (
                <div className={classes.infoIcon}>{infoIcon}</div>
            )}
            <div className={classes.icon}>{icon}</div>
            <div className={classes.flexBetween}>
                <Typography variant="h4" component="p">
                    {title}
                </Typography>
                <Typography
                    variant="h3"
                    component="p"
                    align="right"
                    color="primary"
                >
                    {number}
                </Typography>
            </div>
        </Paper>
    );
};

StatsCard.PropTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    number: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    link: PropTypes.string,
    newTab: PropTypes.bool
};

export default React.memo(StatsCard);
