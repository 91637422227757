import * as Sentry from "@sentry/react";

if (reactProps.sentry_dsn) {
    Sentry.init({
        dsn: reactProps.sentry_dsn,
        integrations: [Sentry.replayIntegration()],
        // Session Replay
        replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
