import React from "react";
import PropTypes from "prop-types";
import {
    Paper,
    Typography,
    Switch,
    Button,
    Link,
    useMediaQuery
} from "@material-ui/core";
import { Check } from "react-feather";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { isString } from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        borderTop: "10px solid #05597B",
        padding: theme.spacing(2),
        textAlign: "center",
        width: 350
    },
    span: {
        fontSize: 18,
        color: "#00000080"
    },
    compareLink: {
        display: "block",
        margin: "1rem auto"
    },
    featureContainer: {
        position: "relative",
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
        "& > p": {
            left: "50%",
            position: "relative",
            textAlign: "left",
            transform: "translateX(-30%)",
            [theme.breakpoints.down("xs")]: {
                transform: "translateX(-36%)"
            },
            "& > svg": {
                color: theme.palette.primary.main,
                marginRight: theme.spacing(2)
            }
        }
    }
}));

const PricingCard = ({
    plan,
    toggleShowTable,
    handleOpenModal,
    handleCloseModal,
    openModal,
    setPickedPlan,
    pickedPlan,
    setPeriodPlan,
    periodPlan,
    userPlanType
}) => {
    const classes = useStyles();
    // const [type, setType] = React.useState("monthly");

    const theme = useTheme();
    const featuredSpacing = useMediaQuery(theme.breakpoints.down("sm"));

    const handleSwitch = event => {
        if (event.target.checked) {
            // setType("annual");
            setPeriodPlan("annual");
        } else {
            // setType("monthly");
            setPeriodPlan("monthly");
        }
    };

    console.log("isUserPlanTypeTeam>>>", userPlanType === "team");
    console.log("CurrentPlanTeam?>>>", plan.team);

    return (
        <div>
            <Paper className={classes.root} elevation={10}>
                <Typography
                    variant="h3"
                    gutterBottom
                    style={
                        plan.featured && !featuredSpacing
                            ? { marginTop: 30 }
                            : {}
                    }
                >
                    {plan.name}
                </Typography>
                <Typography variant="h2" component="p" gutterBottom>
                    {periodPlan == "annual" ? (
                        <>
                            {isString(plan.price.yearly) ? (
                                plan.price.yearly
                            ) : (
                                <>
                                    {`$${plan.price.yearly}`}
                                    <span className={classes.span}>/yr</span>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {isString(plan.price.monthly) ? (
                                plan.price.monthly
                            ) : (
                                <>
                                    {`$${plan.price.monthly}`}
                                    <span className={classes.span}>/mo</span>
                                </>
                            )}
                        </>
                    )}
                </Typography>
                <Typography variant="caption">Monthly</Typography>
                <Switch
                    color="primary"
                    checked={periodPlan == "annual"}
                    onChange={handleSwitch}
                />
                <Typography variant="caption">Yearly</Typography>
                <div className={classes.featureContainer}>
                    <Typography variant="body1" gutterBottom>
                        <Check />
                        {plan.line_one}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Check />
                        {plan.line_two}
                    </Typography>
                    {plan.line_three && (
                        <Typography variant="body1" gutterBottom>
                            <Check />
                            {plan.line_three}
                        </Typography>
                    )}
                </div>
                {plan?.link?.includes("https://") ||
                plan?.link?.includes("http://") ? (
                    <Button
                        style={
                            plan.featured && !featuredSpacing
                                ? { marginTop: 30 }
                                : {}
                        }
                        color="primary"
                        variant={plan.featured ? "contained" : "outlined"}
                        href={plan?.link}
                    >
                        {plan.action_text}
                    </Button>
                ) : (
                    <>
                        {!plan.team && userPlanType === "team" ? (
                            <Button
                                style={
                                    plan.featured && !featuredSpacing
                                        ? { marginTop: 30 }
                                        : {}
                                }
                                color="primary"
                                variant={
                                    plan.featured ? "contained" : "outlined"
                                }
                                onClick={() => {
                                    handleOpenModal();
                                    setPickedPlan(plan.link);
                                }}
                            >
                                {plan.action_text}
                            </Button>
                        ) : (
                            <Button
                                style={
                                    plan.featured && !featuredSpacing
                                        ? { marginTop: 30 }
                                        : {}
                                }
                                color="primary"
                                variant={
                                    plan.featured ? "contained" : "outlined"
                                }
                                href={
                                    reactProps.activation
                                        ? `/re-activate/${plan.link}/${periodPlan}`
                                        : `/register/${plan.link}/${periodPlan}`
                                }
                            >
                                {plan.action_text}
                            </Button>
                        )}
                    </>
                )}
                <Link
                    className={classes.compareLink}
                    component="button"
                    onClick={toggleShowTable}
                >
                    Compare Plans
                </Link>
            </Paper>
            <Dialog
                open={Boolean(openModal)}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    <Alert severity="warning">
                        <Typography gutterBottom align="justify">
                            <strong>
                                Downgrading to this plan will remove your
                                current organization. All users in your
                                organization will be moved to a two week trial
                                of the "Basic" plan.
                            </strong>
                        </Typography>
                        <Typography gutterBottom align="justify">
                            <strong>
                                Downgrading plans will archive all active and
                                draft sessions. You will be able to un-archive
                                and activate sessions based on the permissions
                                of your new plan.
                            </strong>
                        </Typography>
                    </Alert>
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button
                        href={`/re-activate/${pickedPlan}/${periodPlan}`}
                        variant="contained"
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

PricingCard.propTypes = {
    plan: PropTypes.shape({
        name: PropTypes.string,
        price: PropTypes.shape({
            monthly: PropTypes.number,
            yearly: PropTypes.number
        }),
        line_one: PropTypes.string,
        line_two: PropTypes.string,
        line_three: PropTypes.string,
        link: PropTypes.string,
        action_text: PropTypes.string,
        team: PropTypes.bool,
        featured: PropTypes.bool
    }).isRequired,
    toggleShowTable: PropTypes.func.isRequired
};

export default PricingCard;
