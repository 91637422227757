import React from "react";
import {
    Grid,
    TextField,
    FormControlLabel,
    Button,
    Checkbox,
    Chip,
    Switch,
    Paper,
    MenuItem
} from "@material-ui/core";
import FormImages from "./FormImages";
import { find, indexOf } from "lodash";

const SingleCardForm = props => {
    const { user_decks } = reactProps;
    const {
        card,
        values,
        handleChange,
        handleSubmit,
        handleChangeDirectly,
        classes
    } = props;
    const disabled =
        values.name == "" ||
        values.alt_text == "" ||
        values.verify_copyright == false ||
        values.upload_link == "";

    const toggleOrientation = () => {
        let currentOrientation = values.orientation;

        if (currentOrientation == "tall") {
            handleChangeDirectly("orientation", "wide");
        } else {
            handleChangeDirectly("orientation", "tall");
        }
    };

    const toggleHasBack = () => {
        let current = values.has_back;
        handleChangeDirectly("has_back", !current);
    };

    const toggleVerifyCopyright = () => {
        let current = values.verify_copyright;
        handleChangeDirectly("verify_copyright", !current);
    };

    const handleCancel = () => {
        window.history.back();
    };

    const deckLabel = deck_id => {
        let deck = find(user_decks, function(deck) {
            return deck.id == deck_id;
        });
        return deck.name;
    };
    return (
        <Grid container spacing={2} className={classes.padded}>
            <Grid item xs={12} md={6}>
                <FormImages
                    values={values}
                    has_back={values.has_back}
                    orientation={values.orientation}
                    card={Boolean(card) ? card : false}
                    handleChangeDirectly={handleChangeDirectly}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={classes.alignRight}>
                    <Button
                        onClick={handleCancel}
                        style={{ marginRight: 16, color: "#f00" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={disabled}
                        onClick={handleSubmit}
                    >
                        Save Card
                    </Button>
                </div>
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    value={values.name}
                    label="Primary Image Title"
                    onChange={handleChange("name")}
                />
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    value={values.alt_text}
                    label="Primary Image Alt Text"
                    onChange={handleChange("alt_text")}
                />
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    onChange={toggleOrientation}
                                    checked={values.orientation == "wide"}
                                />
                            }
                            label={
                                values.orientation == "tall"
                                    ? "Portrait View"
                                    : "Landscape View"
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    onChange={toggleHasBack}
                                    checked={values.has_back}
                                />
                            }
                            label="Add Back of Card"
                        />
                    </Grid>
                </Grid>
                <TextField
                    fullWidth
                    margin="normal"
                    value={values.back_name}
                    disabled={!values.has_back}
                    required={values.has_back}
                    label="Back of Card Image Title"
                    onChange={handleChange("back_name")}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    required={values.has_back}
                    disabled={!values.has_back}
                    value={values.back_alt_text}
                    label="Back of Card Alt Text"
                    onChange={handleChange("back_alt_text")}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    onChange={toggleVerifyCopyright}
                                    checked={values.verify_copyright}
                                />
                            }
                            label="I verify that I own the rights to use all images being uploaded"
                        />
                    </Grid>
                </Grid>
                {user_decks && user_decks.length > 0 ? (
                    <TextField
                        select
                        fullWidth
                        margin="normal"
                        value={values.decks}
                        onChange={handleChange("decks")}
                        label="Associated Decks"
                        SelectProps={{
                            multiple: true,
                            renderValue: selected => (
                                <>
                                    {selected.map(deck => (
                                        <Chip
                                            key={deck}
                                            label={deckLabel(deck)}
                                        />
                                    ))}
                                </>
                            )
                        }}
                        helperText="You can only add new card to custom decks"
                    >
                        {user_decks.map(deck => (
                            <MenuItem key={deck.id} value={deck.id}>
                                <Checkbox
                                    checked={Boolean(
                                        indexOf(values.decks, deck.id) > -1
                                    )}
                                />
                                {deck.name}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <TextField
                        disabled
                        fullWidth
                        margin="normal"
                        label="Associated Decks"
                        defaultValue=""
                        helperText="You have not added any custom decks. Cards can only be added to custom decks."
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default SingleCardForm;
