import React, { useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Button,
    CardHeader,
    Collapse
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChangeBillingInterval from "../facilitators/components/ChangeBillingInterval";
import CancelPlan from "../facilitators/components/CancelPlan";
import ReActivatePlan from "../facilitators/components/ReActivatePlan";

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    center: {
        marginTop: theme.spacing(3),
        textAlign: "center",
        alignItems: "center"
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        color: "#fff"
    },
    tableBody: {
        "& tr": {
            borderBottom: "1px solid #ccc"
        },
        "& tr:nth-of-type(even)": {
            backgroundColor: "#f5f9ff"
        },
        "& td": {
            paddingRight: 36,
            textAlign: "right"
        },
        "& th": {
            paddingLeft: 36
        }
    },
    tableBodySecond: {
        "& tr": {
            borderBottom: "1px solid #ccc"
        },
        "& tr:nth-of-type(odd)": {
            backgroundColor: "#f5f9ff"
        },
        "& td": {
            paddingRight: 36,
            textAlign: "right"
        },
        "& th": {
            paddingLeft: 36
        }
    },
    activeTag: {
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
        fontSize: 12,
        marginLeft: 20,
        padding: "4px 6px",
        position: "relative",
        textTransform: "uppercase",
        top: -3
    },
    convertButton: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 5,
        textTransform: "capitalize"
    }
}));

const PlanCard = props => {
    const { subscriptionStatus, stripeSub } = reactProps;
    const {
        plan,
        handleOpen,
        currentPlan,
        expanded,
        toggleShowMore,
        showMore,
        setLoading,
        getErrorMessage
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const active =
        plan && currentPlan && plan.name.toLowerCase() === currentPlan;

    const getCTALabel = () => {
        if (currentPlan === "enterprise") {
            return "Downgrade Plan";
        }

        if (currentPlan === "education") {
            if (plan.name !== "Enterprise") {
                return "Downgrade Plan";
            }
        }

        if (currentPlan === "team") {
            if (plan.name !== "Enterprise" && plan.name !== "Education") {
                return "Downgrade Plan";
            }
        }

        if (currentPlan === "professional") {
            if (
                plan.name !== "Team" &&
                plan.name !== "Enterprise" &&
                plan.name !== "Education"
            ) {
                return "Downgrade Plan";
            }
        }

        if (currentPlan === "plus") {
            if (plan.name === "Basic") {
                return "Downgrade Plan";
            }
        }

        return "Upgrade Plan";
    };

    const getPriceMonthly = () => {
        switch (plan.name) {
            case "Basic":
                return "$12/month";
            case "Plus":
                return "$10/month";
            case "Professional":
                return "$35/month";
            case "Team":
                return "$97/month";
            case "Education":
                return "$50/month";
            case "Enterprise":
                return "Let's Talk!";
            default:
                return "--";
        }
    };

    const getPriceYearly = () => {
        switch (plan.name) {
            case "Basic":
                return "$97/year";
            case "Plus":
                return "$100/year";
            case "Professional":
                return "$297/year";
            case "Team":
                return "$997/year";
            case "Education":
                return "$500/year";
            case "Enterprise":
                return "Let's Talk!";
            default:
                return "--";
        }
    };

    const getUserNumber = () => {
        switch (plan.name) {
            case "Basic":
                return 1;
            case "Plus":
                return 1;
            case "Professional":
                return 1;
            case "Team":
                return "Up to 10";
            case "Education":
                return "Up to 75";
            case "Enterprise":
                return "10+";
            default:
                return "--";
        }
    };

    const getActiveSessionNumber = () => {
        switch (plan.name) {
            case "Basic":
                return 3;
            case "Plus":
                return 3;
            case "Professional":
                return 10;
            case "Team":
                return "10 Per User";
            case "Education":
                return "5 Per User";
            case "Enterprise":
                return "Unlimited";
            default:
                return "--";
        }
    };

    const getDraftSessionNumber = () => {
        switch (plan.name) {
            case "Basic":
                return "3";
            case "Plus":
                return "5";
            case "Professional":
                return "10";
            case "Team":
                return "10 Per user";
            case "Education":
                return "10 Per User";
            case "Enterprise":
                return "Unlimited";
            default:
                return "--";
        }
    };

    const getAdditionalDecks = () => {
        switch (plan.name) {
            case "Basic":
                return "No";
            case "Plus":
                return "Yes";
            case "Professional":
                return "Yes";
            case "Team":
                return "Yes";
            case "Enterprise":
                return "Yes";
            case "Education":
                return "Yes";
            default:
                return "--";
        }
    };

    const getCustomDecks = () => {
        switch (plan.name) {
            case "Basic":
                return "No";
            case "Plus":
                return "No";
            case "Professional":
                return "Yes";
            case "Team":
                return "Yes";
            case "Education":
                return "Yes";
            case "Enterprise":
                return "Yes";
            default:
                return "--";
        }
    };

    const toggleOpen = () => setOpen(oldState => !oldState);

    return (
        <Card className={classes.root}>
            <CardHeader
                className={active ? classes.header : ""}
                disableTypography
                title={
                    <Typography align="center" variant="h3" color="inherit">
                        {plan.name}
                        {active && (
                            <span className={classes.activeTag}>
                                {subscriptionStatus}
                            </span>
                        )}
                    </Typography>
                }
            />
            <CardContent>
                <table style={{ width: "100%" }}>
                    <tbody className={classes.tableBody}>
                        <tr>
                            <th scope="row">Monthly Rate</th>
                            <td>{getPriceMonthly()}</td>
                        </tr>
                        <tr>
                            <th scope="row">Yearly Rate</th>
                            <td>{getPriceYearly()}</td>
                        </tr>
                        <tr>
                            <th scope="row">Users</th>
                            <td>{getUserNumber()}</td>
                        </tr>
                    </tbody>
                </table>
                <Collapse in={showMore}>
                    <table style={{ width: "100%" }}>
                        <tbody className={classes.tableBodySecond}>
                            <tr>
                                <th scope="row">Active Sessions</th>
                                <td>{getActiveSessionNumber()}</td>
                            </tr>
                            <tr>
                                <th scope="row">Draft Sessions</th>
                                <td>{getDraftSessionNumber()}</td>
                            </tr>
                            <tr>
                                <th scope="row">Original Climer Cards</th>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <th scope="row">Additional Decks</th>
                                <td>{getAdditionalDecks()}</td>
                            </tr>
                            <tr>
                                <th scope="row">Custom Decks</th>
                                <td>{getCustomDecks()}</td>
                            </tr>
                        </tbody>
                    </table>
                </Collapse>
                <div className={classes.center}>
                    {!expanded && (
                        <a
                            style={{ display: "block", marginBottom: 16 }}
                            href="#"
                            onClick={toggleShowMore}
                        >
                            Show {showMore ? "Less" : "More"}
                        </a>
                    )}
                    {plan.name == "Enterprise" ? (
                        <Button
                            color="primary"
                            variant="outlined"
                            href={`https://climercards.com/contact-us/`}
                        >
                            Contact Us
                        </Button>
                    ) : (
                        <>
                            {active ? (
                                <Button
                                    component="div"
                                    variant="contained"
                                    color="primary"
                                >
                                    Active Plan
                                </Button>
                            ) : (
                                <>
                                    {Boolean(handleOpen) ? (
                                        <Button
                                            color="primary"
                                            component="div"
                                            variant="outlined"
                                            onClick={() => handleOpen(plan)}
                                        >
                                            {getCTALabel()}
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            href={`/register/${plan.link}/monthly`}
                                        >
                                            Get Started
                                        </Button>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {Boolean(active) == true && stripeSub && (
                        <>
                            {stripeSub.plan.interval == "month" &&
                                !Boolean(stripeSub.canceled_at) && (
                                    <>
                                        <Button
                                            size="small"
                                            color="primary"
                                            className={classes.convertButton}
                                            onClick={toggleOpen}
                                        >
                                            Convert to{" "}
                                            {stripeSub.plan.interval == "month"
                                                ? "Yearly"
                                                : "Monthly"}{" "}
                                            subscription
                                        </Button>
                                        <ChangeBillingInterval
                                            getPriceMonthly={getPriceMonthly}
                                            getPriceYearly={getPriceYearly}
                                            open={open}
                                            handleClose={() => setOpen(false)}
                                        />
                                    </>
                                )}
                            {stripeSub && !Boolean(stripeSub.canceled_at) && (
                                <CancelPlan
                                    setLoading={setLoading}
                                    getErrorMessage={getErrorMessage}
                                    align="center"
                                />
                            )}
                            {stripeSub && Boolean(stripeSub.canceled_at) && (
                                <ReActivatePlan
                                    setLoading={setLoading}
                                    getErrorMessage={getErrorMessage}
                                    align="center"
                                />
                            )}
                        </>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default PlanCard;
