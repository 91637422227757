import React from "react";
import {
    Grid,
    Chip,
    Dialog,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { filter, forEach } from "lodash";

const useStyles = makeStyles(theme => ({
    root: {},
    padded: {
        padding: theme.spacing(2)
    },
    paddedMore: {
        padding: theme.spacing(4)
    },
    fullImage: {
        width: "100%",
        height: "auto"
    },
    spaceBetween: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    }
}));

const CardDetailsDialog = props => {
    const classes = useStyles();
    const { decks } = reactProps;
    const { handleClose, card, open } = props;
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="card-dialog"
            open={Boolean(open) && open === card.id}
        >
            <DialogTitle
                id="card-dialog"
                className={classes.spaceBetween}
                disableTypography
            >
                <Typography variant="h4">View {card.name}</Typography>
                {reactProps.auth && reactProps.auth.id == card.user_id && (
                    <Button
                        href={`/cards/${card.uuid}/edit`}
                        color="primary"
                        variant="outlined"
                    >
                        Edit Card
                    </Button>
                )}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={4}>
                        <div className={classes.padded}>
                            <img
                                src={card.frontImage}
                                className={classes.fullImage}
                                alt={card.alt_text}
                            />
                            <Typography gutterBottom align="center">
                                Front
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={8}>
                        <div className={classes.paddedMore}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        Image Title
                                    </Typography>
                                    <Typography gutterBottom>
                                        {card.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        Alt Text
                                    </Typography>
                                    <Typography gutterBottom>
                                        {card.alt_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {Boolean(card.has_back) && (
                        <>
                            <Grid item xs={6} md={4}>
                                <div className={classes.padded}>
                                    <img
                                        src={card.backImage}
                                        className={classes.fullImage}
                                        alt={card.back_alt_text}
                                    />
                                    <Typography gutterBottom align="center">
                                        Back
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <div className={classes.paddedMore}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                Image Title
                                            </Typography>
                                            <Typography gutterBottom>
                                                {card.back_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                Alt Text
                                            </Typography>
                                            <Typography gutterBottom>
                                                {card.back_alt_text}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Typography gutterBottom>Associated Decks</Typography>
                        {filter(card.decks, function(deck) {
                            let hasAccess = false;

                            forEach(decks, function(d) {
                                if (deck.id == d.id) {
                                    hasAccess = true;
                                }
                            });
                            return hasAccess;
                        }).map(deck => (
                            <Chip
                                component="a"
                                key={deck.id}
                                label={deck.name}
                                href={`/decks/${deck.uuid}/edit`}
                            />
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CardDetailsDialog;
