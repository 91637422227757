import React from "react";
import { Drawer, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SidebarFooter from "./SidebarFooter";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        border: "none",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: 260
    },
    logoContainer: {
        backgroundColor: "#fff",
        display: "block",
        height: 64,
        padding: 15
    },
    logo: {
        maxWidth: "100%"
    }
}));

const Sidebar = props => {
    const classes = useStyles();
    const { admin } = reactProps;
    const { open, toggleDrawer, children } = props;

    return (
        <>
            <Hidden smDown>
                <Drawer
                    variant="permanent"
                    PaperProps={{ className: classes.root }}
                >
                    <div>
                        <a
                            className={classes.logoContainer}
                            href={admin ? "/admin/dashboard" : "/dashboard"}
                        >
                            <img
                                src="/img/Climer-Cards-Logo-Revised.png"
                                className={classes.logo}
                            />
                        </a>
                        {children}
                    </div>
                    <SidebarFooter />
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    open={open}
                    onClose={toggleDrawer}
                    PaperProps={{ className: classes.root }}
                >
                    <div>
                        <div className={classes.logoContainer}>
                            <img
                                src="/img/Climer-Cards-Logo-Revised.png"
                                className={classes.logo}
                            />
                        </div>
                        {children}
                    </div>
                    <SidebarFooter />
                </Drawer>
            </Hidden>
        </>
    );
};

export default Sidebar;
