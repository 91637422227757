import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        backgroundColor: "#4786AA1A",
        border: "2px dashed #05597B",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        height: 318,
        justifyContent: "center",
        margin: "0 auto",
        padding: theme.spacing(3),
        width: 230
    }
}));

const DeckUploadArea = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h3" align="center" color="primary" paragraph>
                Upload your own deck!
            </Typography>
            <Button variant="outlined" color="primary" href="/decks/create">
                Get Started
            </Button>
        </div>
    );
};

export default DeckUploadArea;
