import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useIsTyping } from "use-is-typing";
import useWindowDimensions from "@utils/windowDimensions";
import { filter, find } from "lodash";

const SuggestedPromptInput = props => {
    const { auth, session } = reactProps;
    const {
        value,
        handleChange,
        handleDeckChange,
        size,
        label,
        autoFocus,
        handleSubmit
    } = props;
    const [inputValue, setInputValue] = useState("");
    const [isTyping, input] = useIsTyping({ timeout: 200 });
    const { width } = useWindowDimensions();
    const { availableDecks } = auth;

    const defaultTitle =
        width > 900 ? "Enter OR select a suggested prompt" : "Add a prompt";

    const [selectedDeck, setSelectedDeck] = useState(
        find(availableDecks, { id: value.deck_id })
    );

    const handleInputChange = (event, newValue) => {
        setInputValue(newValue);
    };

    const handleSelect = newValue => {
        setInputValue(newValue);
        handleChange(newValue);
    };

    const handleDeckSelect = newValue => {
        handleDeckChange(newValue);
    };

    const [options, setOptions] = useState([
        "one goal you have before our next session.",
        "one hope you have for our team.",
        "how you are feeling today.",
        "something about you.",
        "something about your day."
    ]);

    const keyUpSelect = () => {
        if (inputValue.length > 1) {
            handleSelect(inputValue);
            if (Boolean(handleSubmit)) {
                handleSubmit(inputValue);
            }
        }
    };

    const handleKeyUp = event => {
        if (event.keyCode === 13 && inputValue.length > 0) {
            setTimeout(
                function() {
                    keyUpSelect();
                }.bind(this),
                225
            );
        }
        event.persist();
    };

    useEffect(() => {
        if (inputValue.length > 2 && inputValue != value && !isTyping) {
            handleChange(inputValue);
        }
    });

    useEffect(() => {
        setInputValue("");
    }, []);

    useEffect(() => {
        if (inputValue.length >= 6) {
            setOptions([]);
        }

        if (inputValue.length < 6 && options.length == 0) {
            setOptions([
                "one goal you have before our next session.",
                "one hope you have for our team.",
                "how you are feeling today.",
                "something about you.",
                "something about your day."
            ]);

            if (inputValue == "" && value != "") {
                handleChange("");
            }
        }
    }, [inputValue]);

    return (
        <>
            <Autocomplete
                freeSolo
                value={value}
                disableClearable
                options={options}
                includeInputInList
                autoFocus={Boolean(autoFocus)}
                onChange={(event, newValue) => {
                    handleSelect(newValue);
                }}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onKeyUp={handleKeyUp}
                id="suggested-prompts"
                renderInput={params => (
                    <TextField
                        {...params}
                        fullWidth
                        ref={input}
                        margin="normal"
                        size={size ? size : "medium"}
                        label={label ? label : defaultTitle}
                    />
                )}
            />
            {session.multiple_decks === 1 && (
                <TextField
                    fullWidth
                    select
                    label="Deck"
                    SelectProps={{
                        native: true
                    }}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={value.deck_id}
                    onChange={event => {
                        handleDeckSelect(event.target.value);
                    }}
                >
                    <option value="">None Selected</option>
                    {filter(availableDecks, function(deck) {
                        return Boolean(deck.climer);
                    }).map(deck => (
                        <option value={deck.id} key={deck.id}>
                            {deck.name}
                        </option>
                    ))}
                    {filter(availableDecks, function(deck) {
                        return !Boolean(deck.climer);
                    }).map(deck => (
                        <option value={deck.id} key={deck.id}>
                            {deck.name}
                        </option>
                    ))}
                </TextField>
            )}
        </>
    );
};

export default SuggestedPromptInput;
