import React, { useState } from "react";
import {
    Paper,
    Grid,
    IconButton,
    Typography,
    Button,
    ClickAwayListener,
    TextField,
    Select,
    MenuItem,
    Tooltip,
    Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "@utils/windowDimensions";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import SuggestedPromptInput from "@global/SuggestedPromptInput";
import { X } from "react-feather";
import { find, filter, head, last, take, reverse } from "lodash";
import clsx from "clsx";
import { truncateString } from "@utils/stringUtils";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        position: "absolute",
        left: 0,
        top: 64,
        backgroundColor: "rgba(245, 249, 255, .95)",
        height: 457,
        zIndex: 999
    },
    floatRight: {
        float: "right"
    },
    center: {
        textAlign: "center"
    },
    padded: {
        padding: theme.spacing(3)
    },
    card: {
        height: 300,
        "& > p": {
            backgroundColor: "#581e47",
            borderRadius: "4px 4px 0 0",
            color: "#fff",
            padding: 12,
            textAlign: "center",
            textTransform: "uppercase"
        },
        "& > div": {
            padding: theme.spacing(2)
        }
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    marginBottom: {
        marginBottom: theme.spacing(2)
    }
}));

const PromptManager = props => {
    const classes = useStyles();
    const { auth, session, userToken, plan } = reactProps;
    const {
        prompts,
        setPrompts,
        dropdown,
        currentPrompt,
        toggleDropdown,
        handleNext,
        handlePrevious
    } = props;
    const { width } = useWindowDimensions();
    const { availableDecks } = auth;
    const [adding, setAdding] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [description, setDescription] = useState("");
    const [deck_id, setDeckId] = useState("");

    const [headChange, setHeadChange] = useState(1);

    const currentPromptDescription = find(prompts, function(prompt) {
        return prompt.order == parseInt(currentPrompt);
    })
        ? find(prompts, function(prompt) {
              return prompt.order == parseInt(currentPrompt);
          }).description
        : "--";
    const previousPrompts = filter(prompts, function(prompt) {
        return prompt.order < parseInt(currentPrompt);
    });
    const nextPrompts = filter(prompts, function(prompt) {
        return prompt.order > parseInt(currentPrompt);
    });
    const immediatePrevious =
        previousPrompts.length > 0 ? last(previousPrompts) : null;
    const immediateNext = nextPrompts.length > 0 ? head(nextPrompts) : null;

    // Setup permissions for user plan
    const userLowPlan = plan === "basic" || plan === "plus";
    const toggleAdding = () => {
        let currentAdding = adding;
        setAdding(!currentAdding);
    };

    const handleChange = value => {
        setDescription(value);
    };

    const handleSubmit = () => {
        setLoading(true);

        let params = new URLSearchParams();
        params.append("description", description);
        params.append("prefix", headChange);
        params.append("order", parseInt(currentPrompt) + 1);
        params.append("deck_id", deck_id);

        axios
            .post(
                `/api/v1/sessions/${session.uuid}/add-prompt-in-session`,
                params,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then(response => {
                setLoading(false);
                setTimeout(
                    function() {
                        setPrompts(response.data.prompts);
                        setDescription("");
                        setAdding(false);
                    }.bind(this),
                    600
                );
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };
    const getDeckName = promptIndex => {
        if (!promptIndex) {
            promptIndex = parseInt(currentPrompt);
        }
        let name = "none";
        let prompt = find(prompts, function(prompt) {
            return prompt.order === parseInt(promptIndex);
        });
        if (prompt) {
            let deck = find(availableDecks, function(deck) {
                return deck.id === prompt.deck_id;
            });
            if (deck) {
                name = deck.name;
            }
        }
        return name;
    };

    const handleResponsesXlsx = () => {
        setLoading(true);

        axios
            .get(`/api/v1/sessions/${session.uuid}/report/xlsx`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                setLoading(false);
                if (response.data && response.data.status) {
                    if (response.data.status === "success") {
                        window.location = `/download-report/${
                            response.data.report_id
                        }?report_name=${encodeURIComponent(
                            response.data.report_name
                        )}`;
                    } else {
                        alert(response.data.message || "Unknown error");
                    }
                } else {
                    alert("Unknown error");
                }
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    alert(error.response.data.message);
                } else {
                    alert("Unknown error");
                }
                setLoading(false);
            });
    };

    const handleResponsesPdf = () => {
        setLoading(true);

        axios
            .get(`/api/v1/sessions/${session.uuid}/report/pdf`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                if (response.data && response.data.status) {
                    if (response.data.status === "success") {
                        if (response.data.html) {
                            alert("Unknown error.");
                        } else {
                            window.location = `/download-report/${
                                response.data.report_id
                            }?report_name=${encodeURIComponent(
                                response.data.report_name
                            )}`;
                            setLoading(false);
                        }
                    } else {
                        alert(response.data.message || "Unknown error !");
                        setLoading(false);
                    }
                } else {
                    alert("Unknown error !!");
                }
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    alert(error.response.data.message);
                } else {
                    console.log(error);
                    alert("Unknown error !!!");
                }
                setLoading(false);
            });
    };

    return (
        <>
            {dropdown && (
                <ClickAwayListener onClickAway={toggleDropdown}>
                    <Paper
                        className={classes.root}
                        style={{ width: width }}
                        elevation={6}
                    >
                        <Grid
                            container
                            justify="flex-end"
                            style={{ position: "relative" }}
                        >
                            <Grid
                                item
                                xs={3}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0
                                }}
                            >
                                <div className={classes.floatRight}>
                                    <IconButton onClick={toggleDropdown}>
                                        <X />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            {!userLowPlan && (
                                <Grid item xs={12}>
                                    <div
                                        className="report-download-icons"
                                        style={{
                                            paddingTop: "5px",
                                            paddingBottom: "10px"
                                        }}
                                        align="center"
                                    >
                                        <Typography
                                            display="inline"
                                            style={{
                                                marginRight: "10px",
                                                position: "relative",
                                                bottom: "-3px"
                                            }}
                                        >
                                            <strong>
                                                Download all Responses:
                                            </strong>
                                        </Typography>
                                        <Tooltip title="Download XLSX report">
                                            <Link
                                                style={{
                                                    marginRight: "10px",
                                                    cursor: "pointer",
                                                    display: "inline-block"
                                                }}
                                                onClick={handleResponsesXlsx}
                                            >
                                                <img
                                                    height="24px"
                                                    src="/img/icons/xls-128.png"
                                                    alt="Download XLSX report"
                                                />
                                            </Link>
                                        </Tooltip>
                                        <Tooltip title="Download PDF report">
                                            <Link
                                                style={{
                                                    cursor: "pointer",
                                                    display: "inline-block"
                                                }}
                                                onClick={handleResponsesPdf}
                                            >
                                                <img
                                                    height="24px"
                                                    src="/img/icons/pdf-128.png"
                                                    alt="Download PDF report"
                                                />
                                            </Link>
                                        </Tooltip>
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    component="h3"
                                    align="center"
                                >
                                    Enter prompts or questions for
                                    participants to answer.
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={3} className={classes.padded}>
                                <Paper className={classes.card}>
                                    <Typography color="inherit" gutterBottom>
                                        Previous Prompts
                                    </Typography>
                                    <div>
                                        {previousPrompts.length > 0 ? (
                                            <>
                                                {take(
                                                    reverse(previousPrompts),
                                                    5
                                                ).map(prompt => (
                                                    <Typography
                                                        gutterBottom
                                                        key={prompt.id}
                                                        color="primary"
                                                        variant={
                                                            immediatePrevious &&
                                                            prompt.id ==
                                                                immediatePrevious.id
                                                                ? "h4"
                                                                : "body1"
                                                        }
                                                        className={
                                                            immediatePrevious &&
                                                            prompt.id ==
                                                                immediatePrevious.id
                                                                ? classes.marginBottom
                                                                : ""
                                                        }
                                                        noWrap
                                                    >
                                                        {truncateString(
                                                            prompt.description,
                                                            200
                                                        )}
                                                        {session.multiple_decks &&
                                                        session.multiple_decks >
                                                            0 &&
                                                        prompt.id ==
                                                            immediatePrevious.id ? (
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                Deck:{" "}
                                                                {getDeckName(
                                                                    prompt.order
                                                                )}
                                                            </Typography>
                                                        ) : null}
                                                    </Typography>
                                                ))}
                                            </>
                                        ) : (
                                            <Typography gutterBottom>
                                                --
                                            </Typography>
                                        )}
                                    </div>
                                </Paper>
                                <div
                                    className={clsx(
                                        classes.floatRight,
                                        classes.marginTop
                                    )}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={previousPrompts.length < 1}
                                        onClick={handlePrevious}
                                    >
                                        Previous Prompt
                                    </Button>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                md={3}
                                className={classes.padded}
                                sx={{ height: "fit-content" }}
                            >
                                <Paper className={classes.card}>
                                    <Typography
                                        color="inherit"
                                        gutterBottom
                                        style={{ marginBottom: "0" }}
                                    >
                                        Current Prompt
                                    </Typography>
                                    <div>
                                        {/* <Typography
                                            gutterBottom
                                            variant="body2"
                                        >
                                        </Typography> */}
                                        {adding ? (
                                            <TextField
                                                select
                                                label={"Prefix"}
                                                value={headChange}
                                                onChange={e =>
                                                    setHeadChange(
                                                        e.target.value
                                                    )
                                                }
                                                SelectProps={{
                                                    MenuProps: {
                                                        disablePortal: true
                                                    }
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem value={1}>
                                                    Select a card that
                                                    represents...
                                                </MenuItem>
                                                <MenuItem value={0}>
                                                    -- None --
                                                </MenuItem>
                                            </TextField>
                                        ) : null}
                                        {adding ? (
                                            <>
                                                <SuggestedPromptInput
                                                    autoFocus
                                                    size="small"
                                                    value={description}
                                                    handleChange={handleChange}
                                                    handleDeckChange={value => {
                                                        setDeckId(value);
                                                    }}
                                                    handleSubmit={handleSubmit}
                                                />
                                                <div
                                                    className={clsx(
                                                        classes.floatRight,
                                                        classes.marginTop
                                                    )}
                                                >
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleSubmit}
                                                        disabled={
                                                            description.length ==
                                                                0 ||
                                                            (session.multiple_decks ===
                                                                1 &&
                                                                (deck_id ===
                                                                    "" ||
                                                                    deck_id ===
                                                                        0))
                                                        }
                                                    >
                                                        Create Prompt
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Typography
                                                    gutterBottom
                                                    variant="h4"
                                                    color="primary"
                                                    className={
                                                        classes.marginBottom
                                                    }
                                                    style={{
                                                        wordBreak: "break-all"
                                                    }}
                                                >
                                                    {currentPrompt !== null ? (
                                                        <>
                                                            {truncateString(
                                                                currentPromptDescription,
                                                                195
                                                            )}
                                                            {session.multiple_decks &&
                                                            session.multiple_decks >
                                                                0 ? (
                                                                <Typography
                                                                    variant="caption"
                                                                    display="block"
                                                                    gutterBottom
                                                                >
                                                                    Deck:{" "}
                                                                    {getDeckName()}
                                                                </Typography>
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        "--"
                                                    )}
                                                </Typography>
                                            </>
                                        )}
                                    </div>
                                </Paper>
                                <div
                                    className={clsx(
                                        classes.center,
                                        classes.marginTop
                                    )}
                                >
                                    <Button
                                        component="div"
                                        variant="contained"
                                        onClick={toggleAdding}
                                        color={adding ? "default" : "primary"}
                                    >
                                        {adding
                                            ? "Cancel"
                                            : "Create Next Prompt"}
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={4} md={3} className={classes.padded}>
                                <Paper className={classes.card}>
                                    <Typography color="inherit" gutterBottom>
                                        Next Prompts
                                    </Typography>
                                    <div>
                                        {nextPrompts.length > 0 ? (
                                            <>
                                                {take(nextPrompts, 5).map(
                                                    prompt => (
                                                        <Typography
                                                            gutterBottom
                                                            key={prompt.id}
                                                            color="primary"
                                                            variant={
                                                                immediateNext &&
                                                                prompt.id ==
                                                                    immediateNext.id
                                                                    ? "h4"
                                                                    : "body1"
                                                            }
                                                            className={
                                                                immediateNext &&
                                                                prompt.id ==
                                                                    immediateNext.id
                                                                    ? classes.marginBottom
                                                                    : ""
                                                            }
                                                            noWrap
                                                        >
                                                            {truncateString(
                                                                prompt.description,
                                                                200
                                                            )}
                                                            {session.multiple_decks &&
                                                            session.multiple_decks >
                                                                0 &&
                                                            prompt.id ==
                                                                immediateNext.id ? (
                                                                <Typography
                                                                    variant="caption"
                                                                    display="block"
                                                                    gutterBottom
                                                                >
                                                                    Deck:{" "}
                                                                    {getDeckName(
                                                                        prompt.order
                                                                    )}
                                                                </Typography>
                                                            ) : null}
                                                        </Typography>
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <Typography gutterBottom>
                                                --
                                            </Typography>
                                        )}
                                    </div>
                                </Paper>
                                <div className={classes.marginTop}>
                                    <Button
                                        component="div"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleNext}
                                        disabled={
                                            description.length < 1 ||
                                            (session.multiple_decks === 1 &&
                                                (deck_id === "" ||
                                                    deck_id === 0))
                                        }
                                    >
                                        Next Prompt
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </ClickAwayListener>
            )}
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default PromptManager;
