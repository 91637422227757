import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import ActiveSessionCard from "./ActiveSessionCard";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import SessionsTable from "./SessionsTable";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop: theme.spacing(2)
    },
    noActiveSessions: {
        margin: `${theme.spacing(7)}px auto`,
        textAlign: "center",
        width: "100%"
    },
    marginBottom: {
        marginBottom: theme.spacing(4)
    }
}));

const ActiveSessions = () => {
    const { activeSessions, userToken } = reactProps;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState(reactProps.activeSessions);
    const [errorMessage, setErrorMessage] = useState("");
    const [copied, setCopied] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("created_at");
    const [searchQuery, setSearchQuery] = useState("");

    const handleSort = property => event => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        setLoading(true);
        let isAsc = orderBy == property && order == "asc";

        axios
            .get(
                `/api/v1/sessions/search/active?sortBy=${property}&sortDirection=${
                    !isAsc ? "asc" : "desc"
                }&take=10&skip=0&searchQuery=&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setSessions(response.data.sessions);
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const settings = {
        dots: activeSessions.length > 1 ? true : false,
        arrows: activeSessions.length > 1 ? true : false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Grid container item xs={12} className={classes.marginBottom}>
            {activeSessions.length == 0 ? (
                <div className={classes.noActiveSessions}>
                    <Typography variant="h3" gutterBottom>
                        You have no Active Sessions
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Create a new session OR activate a draft
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        href="/sessions/create"
                        className={classes.marginTop}
                    >
                        Create New Session
                    </Button>
                </div>
            ) : (
                <>
                    <Grid item xs={12} className={classes.marginTop}>
                        <Typography variant="h4" gutterBottom>
                            Active Sessions
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SessionsTable
                            sessions={sessions}
                            order={order}
                            orderBy={orderBy}
                            handleSort={handleSort}
                        />
                    </Grid>
                </>
            )}
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            {copied && (
                <MessageSnackbar
                    variant="success"
                    message="Shared URL Copied"
                />
            )}
        </Grid>
    );
};

export default ActiveSessions;
