import { createMuiTheme } from '@material-ui/core/styles';

const breakpoints = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1310,
            xl: 1500,
        },
    },
});

export default breakpoints;
