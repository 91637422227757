import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Button,
    Tabs,
    Tab
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import DecksDisplay from "./components/DecksDisplay";
import { filter } from "lodash";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        textAlign: "right"
    },
    decksContainer: {
        margin: 0,
        marginTop: theme.spacing(4)
    },
    alertItem: {
        marginTop: theme.spacing(2)
    }
}));

const Index = () => {
    const { plan } = reactProps;
    const classes = useStyles();
    const [decks, setDecks] = useState(reactProps.decks);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                setDecks(reactProps.decks);
                break;
            case 1:
                setDecks(getClimerDecks());
                break;
            case 2:
                setDecks(getUserDecks());
                break;
            default:
                setDecks(reactProps.decks);
                break;
        }
    };

    const getClimerDecks = () => {
        return filter(reactProps.decks, { climer: 1 });
    };

    const getUserDecks = () => {
        return filter(reactProps.decks, { climer: 0 });
    };

    const canCreate =
        !plan ||
        (plan == "professional" ||
            plan == "team" ||
            plan == "enterprise" ||
            plan == "education");

    React.useEffect(() => {
        console.log("plan", plan);
        console.log("canCreate", canCreate);
    }, []);

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Decks
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.floatRight}>
                    <Button
                        color="primary"
                        variant="contained"
                        href="/decks/create"
                        disabled={!canCreate}
                    >
                        Create New Deck
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Decks"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Typography>Decks</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                </Grid>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                >
                    <Tab
                        label="All Decks"
                        disabled={plan && (plan == "basic" || plan == "plus")}
                    />
                    <Tab
                        label="Climer Cards"
                        disabled={plan && (plan == "basic" || plan == "plus")}
                    />
                    <Tab
                        label="My Decks"
                        disabled={plan && (plan == "basic" || plan == "plus")}
                    />
                </Tabs>
                {plan && (plan == "basic" || plan == "plus") && (
                    <Grid container>
                        <Grid item xs={12} className={classes.alertItem}>
                            <Alert severity="warning">
                                If you'd like to add your own custom decks{" "}
                                {plan == "basic" &&
                                    "or for access to additional climer decks"}
                                ,{" "}
                                <Link component="a" href="/manage-plan">
                                    {" "}
                                    Upgrade your plan now
                                </Link>
                                !
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                <DecksDisplay decks={decks} />
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("facilitators-decks-index")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>,
        document.getElementById("facilitators-decks-index")
    );
}
