import React from "react";
import PropTypes from "prop-types";
import Page from "@global/Page";
import GeneralHeader from "@global/GeneralHeader";
import DashboardHeader from "@global/DashboardHeader";
import GeneralFooter from "@global/GeneralFooter";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UserNav from "@global/UserNav";
import SessionHeader from "../global/SessionHeader";
import EnterCode from "../global/EnterCode";

const useStyles = makeStyles(theme => ({
    root: {
        flex: "1",
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1)
        }
    },
    marginTop: {
        marginTop: 60
    }
}));

const General = props => {
    const {
        children,
        maxWidth,
        activation,
        toggleDropdown,
        handleNext,
        nextDisabled,
        handlePrevious,
        prevDisabled,
        accessInput,
        ...rest
    } = props;
    const { session, auth } = reactProps;
    const classes = useStyles();

    return (
        <Page>
            {Boolean(activation) && Boolean(auth) ? (
                <DashboardHeader activation={Boolean(activation)} />
            ) : (
                <>
                    {session ? (
                        <SessionHeader
                            session={session}
                            nextDisabled={nextDisabled}
                            prevDisabled={prevDisabled}
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            toggleDropdown={
                                toggleDropdown ? toggleDropdown : false
                            }
                        />
                    ) : (
                        <GeneralHeader
                            session={session}
                            nextDisabled={nextDisabled}
                            prevDisabled={prevDisabled}
                            handleNext={handleNext ? handleNext : false}
                            handlePrevious={
                                handlePrevious ? handlePrevious : false
                            }
                            toggleDropdown={
                                toggleDropdown ? toggleDropdown : false
                            }
                        />
                    )}
                </>
            )}
            {accessInput && <EnterCode />}
            <Container maxWidth={maxWidth} className={classes.root} {...rest}>
                {Boolean(activation) && Boolean(auth) ? (
                    <div className={classes.marginTop}>{children}</div>
                ) : (
                    <>{children}</>
                )}
            </Container>
            <GeneralFooter />
        </Page>
    );
};

General.propTypes = {
    children: PropTypes.any.isRequired,
    maxwidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

General.defaultProps = {
    maxWidth: "lg"
};

export default General;
