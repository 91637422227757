import React, { useEffect, useState } from "react";
import {
    Paper,
    Typography,
    Link,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { upperCaseFirst } from "@utils/stringUtils";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { ExternalLink } from "react-feather";
import CancelButton from "./CancelButton";
import ReActivateButton from "./ReActivateButton";
import { find } from "lodash";
import AffiliatlyInfo from "./AffiliatlyInfo";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    idLink: {}
}));

const PlanDetails = ({ user, setLoading, getErrorMessage }) => {
    const classes = useStyles();
    const { app_env, userToken, coupons, subscription } = reactProps;
    const [stripeUser, setStripeUser] = useState(null);
    const [cancelAt, setCancelAt] = useState(null);
    const [canceledAt, setCanceledAt] = useState(null);
    const [upcomingInvoice, setUpcomingInvoice] = useState(null);
    const [selectedCoupon, setSelectedCoupon] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [open, setOpen] = useState(false);

    const getSelectedInterval = interval => {
        if (interval == "month") {
            return "Monthly";
        }

        if (interval == "year") {
            return "Yearly";
        }

        return "";
    };

    const toggleOpen = e => {
        e.preventDefault();
        setOpen(!open);
    };

    const getCouponDescription = () => {
        let description = "";
        let chosenCoupon = find(coupons, function(coupon) {
            return coupon.stripe_coupon_id == selectedCoupon;
        });

        console.log(chosenCoupon);

        if (Boolean(chosenCoupon.details.amount_off)) {
            description = `$${parseFloat(
                chosenCoupon.details.amount_off / 100
            ).toFixed(2)} off `;
        }

        if (Boolean(chosenCoupon.details.percent_off)) {
            description = `${chosenCoupon.details.percent_off}% off `;
        }

        if (
            Boolean(chosenCoupon.details.duration) &&
            chosenCoupon.details.duration == "once"
        ) {
            description = `${description} the user's first month`;
        }

        if (
            Boolean(chosenCoupon.details.duration) &&
            chosenCoupon.details.duration == "forever"
        ) {
            description = `${description} as long as the user's subscription is active`;
        }

        if (
            Boolean(chosenCoupon.details.duration) &&
            chosenCoupon.details.duration == "repeating" &&
            Boolean(chosenCoupon.details.duration_in_months)
        ) {
            description = `${description} for the user's first ${chosenCoupon.details.duration_in_months} months`;
        }

        return description;
    };

    const handleCouponChange = event => {
        console.log(event.target.value);
        setSelectedCoupon(event.target.value);
    };

    useEffect(() => {
        console.log(app_env);
        setFetching(true);
        axios
            .get(
                `/api/v1/admin/users/get-stripe-user?stripe_id=${user.stripe_id}&user_id=${user.uuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then(response => {
                console.log("response", response);
                setStripeUser(response.data.stripeUser);
                if (Boolean(response.data.cancel_at_period_end)) {
                    setCancelAt(response.data.cancel_at);
                    setCanceledAt(response.data.canceled_at);
                } else {
                    setUpcomingInvoice(response.data.upcoming);
                    setFetching(false);
                }
            })
            .catch(error => {
                console.log("error", error);
                setFetching(false);
            });
    }, []);

    const handleAddCoupon = () => {
        setLoading(true);
        const chosenCoupon = find(coupons, function(coupon) {
            return coupon.stripe_coupon_id == selectedCoupon;
        });

        let params = new URLSearchParams();
        params.append("user_id", user.uuid);
        params.append("coupon_id", chosenCoupon.id);

        axios
            .post(`/api/v1/admin/users/update-coupon`, params, {
                headers: { Authorization: `Bearer ${userToken}` }
            })
            .then(response => {
                console.log(response);
                if (response.data.updated) {
                    const message = encodeURI("Coupon successfully updated.");
                    window.location = window.location + `?message=${message}`;
                } else {
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
                getErrorMessage(error);
            });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper className={classes.root}>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Stripe Customer ID
                    </Typography>
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        gutterBottom
                        href={`https://dashboard.stripe.com/${
                            app_env == "local" ? "test/" : ""
                        }customers/${user.stripe_id}`}
                        className={classes.idLink}
                    >
                        {user.stripe_id}
                        <ExternalLink />
                    </Link>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Paper className={classes.root}>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Plan
                    </Typography>
                    <Grid container>
                        {Boolean(stripeUser) && !fetching && (
                            <>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>
                                        {`Plan - ${stripeUser.plan_name !=
                                            null && stripeUser.plan_name} ${
                                            Boolean(stripeUser.price) !== false
                                                ? `(${getSelectedInterval(
                                                      stripeUser.price.recurring
                                                          .interval
                                                  )})`
                                                : ""
                                        }`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom align="right">
                                        {stripeUser &&
                                            stripeUser.price &&
                                            `$${parseFloat(
                                                stripeUser.price.unit_amount /
                                                    100
                                            ).toFixed(2)}`}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {!Boolean(stripeUser) && fetching && (
                            <>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>
                                        <Skeleton
                                            animation="wave"
                                            variant="text"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom align="right">
                                        <Skeleton
                                            animation="wave"
                                            variant="text"
                                        />
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {Boolean(stripeUser) &&
                        Boolean(upcomingInvoice) &&
                        !fetching && (
                            <>
                                {upcomingInvoice.discount ? (
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>
                                                Coupon -{" "}
                                                {
                                                    upcomingInvoice.discount
                                                        .coupon.name
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                gutterBottom
                                                align="right"
                                            >
                                                {Boolean(
                                                    upcomingInvoice.discount
                                                        .coupon.percent_off
                                                ) &&
                                                    `${upcomingInvoice.discount.coupon.percent_off}% off`}
                                                {Boolean(
                                                    upcomingInvoice.discount
                                                        .coupon.amount_off
                                                ) &&
                                                    `$${parseFloat(
                                                        upcomingInvoice.discount
                                                            .coupon.amount_off /
                                                            100
                                                    ).toFixed(2)} off`}
                                                {Boolean(
                                                    upcomingInvoice.discount
                                                        .coupon.duration ==
                                                        "forever"
                                                ) && ` forever`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography gutterBottom>
                                            Coupon - N/A
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography gutterBottom>
                                            Next Charge
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography gutterBottom align="right">
                                            {`$${parseFloat(
                                                upcomingInvoice.total / 100
                                            ).toFixed(2)} ${moment
                                                .unix(
                                                    upcomingInvoice.next_payment_attempt
                                                )
                                                .fromNow()}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    {Boolean(cancelAt) && Boolean(canceledAt) && (
                        <>
                            <Grid item xs={12}>
                                <Typography gutterBottom>Active</Typography>
                                <Typography gutterBottom>
                                    This plan is active for now but it was
                                    canceled at{" "}
                                    {moment
                                        .utc(canceledAt)
                                        .local()
                                        .format("LLL")}
                                    .
                                </Typography>
                                <Typography gutterBottom>
                                    {user.name} will have access to the plan
                                    until{" "}
                                    {moment
                                        .utc(cancelAt)
                                        .local()
                                        .format("LLL")}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {!Boolean(upcomingInvoice) &&
                        fetching &&
                        !Boolean(canceledAt) &&
                        !Boolean(cancelAt) && (
                            <>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        <Skeleton
                                            animation="wave"
                                            variant="text"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography gutterBottom>
                                            <Skeleton
                                                animation="wave"
                                                variant="text"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography gutterBottom align="right">
                                            <Skeleton
                                                animation="wave"
                                                variant="text"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    {!Boolean(stripeUser) &&
                        !Boolean(upcomingInvoice) &&
                        !fetching && (
                            <>
                                {Boolean(subscription) ? (
                                    <Typography gutterBottom>
                                        {upperCaseFirst(
                                            subscription.stripe_status.replace(
                                                "_",
                                                " "
                                            )
                                        )}
                                    </Typography>
                                ) : (
                                    <Typography gutterBottom>
                                        No Active Plan
                                    </Typography>
                                )}
                            </>
                        )}
                    {Boolean(stripeUser) &&
                        Boolean(upcomingInvoice) &&
                        !fetching && (
                            <Link href="#" onClick={toggleOpen}>
                                Add / Update Coupon
                            </Link>
                        )}
                    {Boolean(stripeUser) &&
                        Boolean(upcomingInvoice) &&
                        !fetching && <CancelButton />}
                    {Boolean(cancelAt) && Boolean(canceledAt) && (
                        <>
                            <ReActivateButton />
                        </>
                    )}
                </Paper>
            </Grid>
            {user && user.affiliatly_blob && user.affiliatly_blob != null && (
                <Grid item xs={12} md={6} lg={4}>
                    <Paper className={classes.root}>
                        <AffiliatlyInfo />
                    </Paper>
                </Grid>
            )}
            <Dialog
                open={open}
                onClose={toggleOpen}
                aria-labelledby="add-coupon-title"
                aria-describedby="add-coupon-body"
            >
                <DialogTitle id="add-coupon-title">
                    Add / Update Coupon
                </DialogTitle>
                <DialogContent id="add-coupon-body">
                    <Typography>
                        Adding a new discount coupon will replace any existing
                        discounts and will by applied in the next billing cycle.
                    </Typography>
                    <TextField
                        select
                        SelectProps={{ native: true }}
                        fullWidth
                        margin="normal"
                        label="Coupon Code"
                        value={selectedCoupon}
                        onChange={handleCouponChange}
                        helperText={
                            selectedCoupon == "" ? "" : getCouponDescription()
                        }
                        InputLabelProps={{ shrink: true }}
                    >
                        <option value="">None</option>
                        {coupons.map(coupon => (
                            <option
                                value={coupon.stripe_coupon_id}
                                key={coupon.id}
                            >
                                {coupon.name} - {coupon.promotion_code}
                            </option>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!Boolean(selectedCoupon)}
                        onClick={handleAddCoupon}
                        color="primary"
                        variant="contained"
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default PlanDetails;
