import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    tallImageClass: {
        height: "100%",
        maxHeight: 200,
        width: "auto"
    },
    wideImageClass: {
        height: "auto",
        width: "100%"
    }
}));

const CardImage = props => {
    const { alt, src, orientation } = props;
    const classes = useStyles();

    return (
        <>
            <img
                src={src}
                alt={alt}
                className={
                    orientation == "tall"
                        ? classes.tallImageClass
                        : classes.wideImageClass
                }
            />
        </>
    );
};

export default CardImage;
