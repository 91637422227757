import React from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Button,
    Hidden
} from "@material-ui/core";
import ActiveSessions from "./components/ActiveSessions";
import { makeStyles } from "@material-ui/core/styles";
import PendingSessions from "./components/PendingSessions";
import QuickStartButton from "@global/QuickStartButton";
import MobileSessionsMenu from "./components/MobileSessionsMenu";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        textAlign: "right"
    },
    sessionsContainer: {
        [theme.breakpoints.down("sm")]: {
            margin: 0
        }
    }
}));

const Index = () => {
    const classes = useStyles();
    const { pendingSessions } = reactProps;

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        My Sessions
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.floatRight}>
                        <Hidden mdDown>
                            {/* <QuickStartButton
                                marginRight
                                variant="outlined"
                                title="Quick Start Session"
                            /> */}
                            <Button
                                component="a"
                                color="primary"
                                variant="contained"
                                href="/sessions/create"
                            >
                                Create New Session
                            </Button>
                        </Hidden>
                        <Hidden lgUp>
                            <MobileSessionsMenu />
                        </Hidden>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Users"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Typography>Sessions</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    className={classes.sessionsContainer}
                >
                    <ActiveSessions />
                    <PendingSessions sessions={pendingSessions} />
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("facilitators-sessions-index")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>,
        document.getElementById("facilitators-sessions-index")
    );
}
