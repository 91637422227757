import React, { useState, useRef, useEffect } from "react";
import plans from "@utils/plans";
import PricingCard from "./PricingCard";
import { filter } from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Link,
    Hidden,
    Tabs,
    Tab,
    useMediaQuery
} from "@material-ui/core";
import PaperWithPopover from "./PaperWithPopover";

const useStyles = makeStyles(theme => ({
    root: {},
    cardContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        "& > div": {
            margin: "12px 25px",
            [theme.breakpoints.down("xs")]: {
                margin: "12px 0"
            }
        }
    },
    switchPaper: {
        margin: "2rem auto",
        padding: theme.spacing(2),
        textAlign: "center",
        width: 290
    },
    tabContainer: {},
    mobileContainer: {
        display: "flex",
        margin: "auto",
        maxWidth: 400,
        transition: "300ms",
        "& > div": {
            marginRight: 275,
            maxWidth: "100%"
        }
    }
}));

const PricingCardsView = ({ toggleShowTable }) => {
    const { userPlanName, subscriptionsCount } = reactProps;
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);
    const [pickedPlan, setPickedPlan] = useState(null);
    const [periodPlan, setPeriodPlan] = useState("monthly");
    const [isSubscriptions, setIsSubscriptions] = useState(
        Boolean(subscriptionsCount)
    );

    console.log(">>subscriptionsCount>>>", subscriptionsCount);
    console.log("isSubscriptions", isSubscriptions);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    function setUserPlanType() {
        const hasPlanTeam = filter(plans, { name: userPlanName });
        const result = hasPlanTeam[0]?.team || false;
        if (result) {
            return "team";
        }
        return "individual";
    }

    const userPlanType = setUserPlanType();

    const [planType, setPlanType] = useState(setUserPlanType());
    const theme = useTheme();
    const transform = useMediaQuery(theme.breakpoints.down("xs")) ? 602 : 660;

    const transformContainer = useRef(null);

    const individualPlans = filter(plans, { team: false });
    const teamPlans = filter(plans, { team: true });

    const togglePlanType = () => {
        if (planType == "individual") setPlanType("team");
        else setPlanType("individual");
    };

    const changePlanTab = value => {
        setPlanType(value);
        if (value == "team")
            transformContainer.current.style.transform = `translateX(-${transform}px)`;
        else transformContainer.current.style.transform = "translateX(0)";
    };

    return (
        <div className={classes.root}>
            <Hidden mdUp>
                <div className={classes.tabContainer}>
                    <Typography variant="h4" align="center" color="secondary">
                        Plan Pricing
                    </Typography>
                    <Tabs
                        centered
                        value={planType}
                        onChange={(event, value) => changePlanTab(value)}
                        indicatorColor="primary"
                    >
                        <Tab value="individual" label="Individual Plans" />
                        <Tab value="team" label="Group Plans" />
                    </Tabs>
                </div>
            </Hidden>
            <Hidden smDown>
                {planType == "individual" ? (
                    <div className={classes.cardContainer}>
                        {individualPlans.map(plan => (
                            <PricingCard
                                key={plan.name}
                                plan={plan}
                                toggleShowTable={toggleShowTable}
                                handleOpenModal={handleOpenModal}
                                handleCloseModal={handleCloseModal}
                                openModal={openModal}
                                setPickedPlan={setPickedPlan}
                                pickedPlan={pickedPlan}
                                setPeriodPlan={setPeriodPlan}
                                periodPlan={periodPlan}
                                userPlanType={userPlanType}
                            />
                        ))}
                    </div>
                ) : (
                    <div className={classes.cardContainer}>
                        {teamPlans.map(plan => (
                            <PricingCard
                                key={plan.name}
                                plan={plan}
                                toggleShowTable={toggleShowTable}
                                handleOpenModal={handleOpenModal}
                                handleCloseModal={handleCloseModal}
                                openModal={openModal}
                                setPickedPlan={setPickedPlan}
                                pickedPlan={pickedPlan}
                                setPeriodPlan={setPeriodPlan}
                                periodPlan={periodPlan}
                                userPlanType={userPlanType}
                            />
                        ))}
                    </div>
                )}
            </Hidden>
            <Hidden mdUp>
                <div
                    ref={transformContainer}
                    className={classes.mobileContainer}
                >
                    <div className={classes.cardContainer}>
                        {individualPlans.map(plan => (
                            <PricingCard
                                key={plan.name}
                                plan={plan}
                                toggleShowTable={toggleShowTable}
                                handleOpenModal={handleOpenModal}
                                handleCloseModal={handleCloseModal}
                                openModal={openModal}
                                setPickedPlan={setPickedPlan}
                                pickedPlan={pickedPlan}
                                setPeriodPlan={setPeriodPlan}
                                periodPlan={periodPlan}
                                userPlanType={userPlanType}
                            />
                        ))}
                    </div>
                    <div className={classes.cardContainer}>
                        {teamPlans.map(plan => (
                            <PricingCard
                                key={plan.name}
                                plan={plan}
                                toggleShowTable={toggleShowTable}
                                handleOpenModal={handleOpenModal}
                                handleCloseModal={handleCloseModal}
                                openModal={openModal}
                                setPickedPlan={setPickedPlan}
                                pickedPlan={pickedPlan}
                                setPeriodPlan={setPeriodPlan}
                                periodPlan={periodPlan}
                                userPlanType={userPlanType}
                            />
                        ))}
                    </div>
                </div>
            </Hidden>
            <Hidden smDown>
                <div>
                    <Paper className={classes.switchPaper} elevation={10}>
                        <Typography variant="h4" color="primary">
                            {planType == "individual"
                                ? "Have a team?"
                                : "Individual?"}
                        </Typography>
                        <Link component="button" onClick={togglePlanType}>
                            {planType == "individual"
                                ? "Check out our team plans"
                                : "Check out our individual plans"}
                        </Link>
                    </Paper>
                </div>
                <PaperWithPopover
                    isSubscriptions={isSubscriptions}
                    setIsSubscriptions={setIsSubscriptions}
                />
            </Hidden>
        </div>
    );
};

export default PricingCardsView;
