import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: 26,
        marginTop: theme.spacing(4),
        textTransform: "uppercase"
    },
    first: {
        marginTop: theme.spacing(1)
    }
}));

const NavHeader = ({ title, first }) => {
    const classes = useStyles();
    return (
        <li className={clsx(classes.root, first ? classes.first : "")}>
            {title}
        </li>
    );
};

export default NavHeader;
