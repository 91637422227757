import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import General from "@layouts/General";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PromptController from "./components/PromptController";
import CardList from "@global/CardList";
import { filter } from "lodash";
import Pusher from "pusher-js";
import PromptResponses from "./components/PromptResponses";
import MessageSnackbar from "@global/MessageSnackbar";
import mobileWakeLock from "@utils/mobileWakeLock";
import axios from "axios";
import useWindowFocus from "use-window-focus";

const useStyles = makeStyles(() => ({
    root: {
        background: "#FFF"
    }
}));

const Presentation = () => {
    const {
        userToken,
        auth,
        pusher_app_key,
        pusher_app_cluster,
        pusher_app_host,
        pusher_app_scheme
    } = reactProps;
    const classes = useStyles();
    const [deck, setDeck] = useState(reactProps.deck);
    const [decks, setDecks] = useState([reactProps.deck]);
    const [updateChannel, setUpdateChannel] = useState(null);
    const [addedChannel, setAddedChannel] = useState(null);
    const [message, setMessage] = useState("");
    const [subscribed, setSubscribed] = useState(false);
    const [open, setOpen] = useState(false);
    const [flipped, setFlipped] = useState(null);
    const [prompts, setPrompts] = useState(reactProps.session.prompts);
    const [showResponses, setShowResponses] = useState(false);
    const [currentPrompt, setCurrentPrompt] = useState(
        reactProps.currentPrompt ? reactProps.currentPrompt : null
    );

    const [session, setSession] = useState(reactProps.session);

    const token = auth.uuid;
    const nextDisabled =
        filter(prompts, function(prompt) {
            return prompt.order > currentPrompt;
        }).length < 1;
    const decksRef = useRef();

    let enabled = prompts.length;
    mobileWakeLock(Boolean(enabled));

    const prevDisabled = currentPrompt === 0 || !currentPrompt;

    const handleNext = () => {
        setCurrentPrompt(prevState => prevState + 1);

        if (open === true) {
            setOpen(false);
        }

        setTimeout(
            function() {
                setShowResponses(false);
            }.bind(this),
            400
        );
    };

    const handlePrevious = () => {
        setCurrentPrompt(prevState => prevState - 1);

        if (open == true) {
            setOpen(false);
        }

        setTimeout(
            function() {
                setShowResponses(false);
            }.bind(this),
            400
        );
    };

    const toggleDropdown = () => {
        let currentOpen = open;
        setOpen(!currentOpen);
    };

    const subscriptionChange = () => {
        if (subscribed) {
            let params = new URLSearchParams();
            params.append("currentPrompt", currentPrompt);

            axios
                .post(
                    `/api/v1/sessions/${session.uuid}/update-active-prompt`,
                    params,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    }
                )
                .then(response => {
                    setSession(response.data.session);
                })
                .catch(error => {});
        }
    };

    const checkForActivePrompt = async () => {
        await axios
            .get(`/api/v1/sessions/${session.uuid}/get-active-prompt`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                },
                validateStatus: () => true
            })
            .then(response => {
                if (response.data.session) {
                    setSession(response.data.session);
                }
                setCurrentPrompt(parseInt(response.data.currentPrompt));
                setTimeout(
                    function() {
                        setSubscribed(true);
                    }.bind(this),
                    400
                );
            })
            .catch(error => {
                setSubscribed(true);
            });
    };

    const toggleShowResponses = () => {
        setShowResponses(prevState => !prevState);
    };

    const handlePromptChange = () => {
        setShowResponses(false);
    };

    const toggleFlipped = () => {
        axios
            .get(`/api/v1/sessions/${session.uuid}/flip-status`)
            .then(response => {
                setFlipped(response.data.flipped);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const queryDeck = deck_id => {
        axios
            .get(`/api/v1/sessions/${reactProps.session.uuid}/deck/${deck_id}`)
            .then(response => {
                applyNewDeck(response.data.deck);
                setDeck(response.data.deck);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const applyNewDeck = newDeck => {
        let deck = false;
        if (decksRef.current) {
            deck = decksRef.current.find(obj => {
                return obj.id === newDeck.id;
            });
        }

        if (!deck) {
            if (!decks) {
                setDecks([newDeck]);
            } else {
                setDecks([...decks, newDeck]);
            }
        }
    };

    const setDeckById = deck_id => {
        let targetDeck = false;
        if (decksRef.current) {
            targetDeck = decksRef.current.find(d => d.id == deck_id);
        }
        if (!targetDeck) {
            queryDeck(deck_id);
        } else {
            setDeck(targetDeck);
        }
    };

    const setFacilitatorActivity = value => {
        let params = new URLSearchParams();
        params.append("facilitator_is_active", value);

        axios
            .post(
                `/api/v1/sessions/${session.uuid}/update-active-facilitator`,
                params
            )
            .then(response => {
                // setSession(response.data.session);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        subscriptionChange();
    }, [currentPrompt]);

    useEffect(() => {
        checkForActivePrompt();
    }, []);

    useEffect(() => {
        if (prompts.length === 0) {
            setMessage("Enter a prompt to begin");
        }
    }, []);

    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(`${reactProps.session.uuid}-flip`);
        channel.bind_global(function() {
            toggleFlipped();
        });
    }, []);

    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(
            `prompt-updated-${reactProps.session.uuid}`
        );

        channel.bind_global(function(event, data) {
            if (data && data.hasOwnProperty("deck_id")) {
                setDeckById(data.deck_id);
            }
        });
        setUpdateChannel(channel);
    }, []);

    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(
            `prompt-added-in-session-${reactProps.session.uuid}`
        );
        channel.bind_global(function(event, data) {
            if (data && data.hasOwnProperty("deck_id")) {
                if (parseInt(data.prompt_order) === 0) {
                    setDeckById(data.deck_id);
                }
            }
        });
        setAddedChannel(channel);
    }, []);

    //tracking facilitator window
    // useEffect(() => {
    //     if (session.asynchronous !== 1){
    //         if (!windowFocused){
    //             console.log('This will run after 30 second!')
    //             const timer = setTimeout(() => {
    //                 setFacilitatorActivity( 0)
    //             }, 30000);
    //             return () => clearTimeout(timer);
    //         } else {
    //             setFacilitatorActivity(1);
    //         }
    //     }
    // }, [windowFocused]);

    // set active facilitator when he enter the session in new window
    useEffect(() => {
        session.asynchronous !== 1 ? setFacilitatorActivity(1) : "";
    }, []);

    useEffect(() => {
        decksRef.current = decks;
    }, [decks]);

    return (
        <General
            maxWidth="xl"
            handleNext={handleNext}
            nextDisabled={nextDisabled}
            handlePrevious={handlePrevious}
            prevDisabled={prevDisabled}
            toggleDropdown={toggleDropdown}
        >
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <PromptController
                            dropdown={open}
                            prompts={prompts}
                            flipped={flipped}
                            setPrompts={setPrompts}
                            handleNext={handleNext}
                            currentPrompt={currentPrompt}
                            toggleDropdown={toggleDropdown}
                            handlePrevious={handlePrevious}
                            setCurrentPrompt={setCurrentPrompt}
                        />
                    </Grid>
                </Grid>
                {showResponses ? (
                    <PromptResponses
                        token={token}
                        prompts={prompts}
                        session={session}
                        deck={deck}
                        flipped={flipped}
                        setFlipped={setFlipped}
                        toggleFlipped={toggleFlipped}
                        handlePromptChange={handlePromptChange}
                        currentPrompt={parseInt(currentPrompt)}
                        toggleShowResponses={toggleShowResponses}
                        setShowResponses={setShowResponses}
                        isFacilitator={true}
                    />
                ) : (
                    <CardList
                        token={token}
                        prompts={prompts}
                        deck={deck}
                        flipped={flipped}
                        toggleShowResponses={toggleShowResponses}
                        session={session}
                        currentPromptNumber={currentPrompt}
                    />
                )}
            </div>
            {message && message != "" && (
                <MessageSnackbar variant="success" message={message} />
            )}
        </General>
    );
};

if (document.getElementById("facilitators-session-presentation")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Presentation />
        </ErrorBoundary>,
        document.getElementById("facilitators-session-presentation")
    );
}
