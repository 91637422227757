import React, { useState } from "react";
import {
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem
} from "@material-ui/core";
import SuggestedPromptInput from "@global/SuggestedPromptInput";

const AddPromptModal = props => {
    const {
        session,
        handleCancel,
        setSession,
        setLoading,
        getErrorMessage,
        setSubscribed
    } = props;
    const { userToken } = reactProps;
    const [description, setDescription] = useState("");
    const [headChange, setHeadChange] = useState(1);
    const [deck_id, setDeckId] = useState("");

    const handleChange = value => {
        setDescription(value);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("description", description);
        params.append("session_id", session.id);
        params.append("order", session.prompts.length);
        params.append("prefix", headChange);
        params.append("deck_id", deck_id);

        axios
            .post(`/api/v1/prompts/create`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                setSession(response.data.session);
                handleCancel();
                setLoading(false);
                setTimeout(
                    function() {
                        setSubscribed(false);
                    }.bind(this),
                    500
                );
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <>
            <DialogContent>
                <TextField
                    select
                    label={"Prefix"}
                    value={headChange}
                    onChange={e => setHeadChange(e.target.value)}
                    SelectProps={{
                        MenuProps: { disablePortal: true }
                    }}
                    fullWidth
                >
                    <MenuItem value={1}>
                        Select a card that represents...
                    </MenuItem>
                    <MenuItem value={0}>-- None --</MenuItem>
                </TextField>
                <SuggestedPromptInput
                    autoFocus
                    value={description}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleDeckChange={value => setDeckId(value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                        description.length == 0 ||
                        (session.multiple_decks === 1 &&
                            (deck_id === "" || deck_id === 0))
                    }
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    );
};

export default AddPromptModal;
