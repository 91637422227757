import React, { useState, useEffect } from 'react';
import {
    Paper,
    Grid,
    TextField,
    Select,
    FormControl,
    InputLabel,
    Input,
    MenuItem,
    Button,
} from '@material-ui/core';

export default function Form(props) {
    return (
        <div className="create-role-container">
            <Paper className="p-3">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth required id="name" label="Name" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            id="rolelabel"
                            label="Label"
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" spacing={4}>
                    <Grid item xs={12} md={6}>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="roles">Role</InputLabel>
                            <Select
                                value=""
                                inputProps={{
                                    name: 'roles',
                                    id: 'roles',
                                }}
                            >
                                <MenuItem value={null}>
                                    <em>Please select a role</em>
                                </MenuItem>
                                <MenuItem value={null}>
                                    <em>Admin</em>
                                </MenuItem>
                                <MenuItem value={null}>
                                    <em>Regular</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="float-right"
                            size="large"
                            type="submit"
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
