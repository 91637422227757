import React from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import LoginForm from "./components/LoginForm";
import Auth from "@layouts/Auth";
import { Container } from "@material-ui/core";

const Login = () => {
    return (
        <Auth>
            <Container maxWidth="sm">
                <LoginForm />
            </Container>
        </Auth>
    );
};

if (document.getElementById("login")) {
    ReactDOM.render(<Login />, document.getElementById("login"));
}
