import React, { useState } from "react";
import {
    Paper,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import QuickStartButton from "@global/QuickStartButton";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2.5)
    },
    buttonContainer: {
        "& > button": {
            display: "block",
            marginLeft: "auto"
        },
        "& > button:first-of-type": {
            marginBottom: theme.spacing(2)
        }
    },
    floatRight: {
        float: "right"
    }
}));

const QuickStart = () => {
    const { auth, userToken, decks } = reactProps;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        quick_start_name:
            auth && auth.quick_start_name ? auth.quick_start_name : auth.name,
        quick_start_slug:
            auth && auth.quick_start_slug ? auth.quick_start_slug : "",
        deck_id: auth && auth.deck_id ? auth.deck_id : ""
    });

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const toggleOpen = () => {
        let currentOpen = open;
        setOpen(!currentOpen);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("quick_start_name", values.quick_start_name);
        params.append("slug", values.quick_start_slug);
        params.append("deck_id", values.deck_id);

        axios
            .post("/api/v1/user/profile/quick-session", params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    "Quick Session Defaults Updated"
                );
                window.location = `/dashboard?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Paper className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom>
                        Quick Start Session
                    </Typography>
                    <Typography variant="overline">
                        Quick Start Session Name
                    </Typography>
                    <Typography color="primary" gutterBottom>
                        {auth.quick_start_name
                            ? auth.quick_start_name
                            : auth.name}
                    </Typography>
                    <Typography variant="overline">Access Code</Typography>
                    <Typography color="primary" gutterBottom>
                        {auth.quick_start_slug
                            ? auth.quick_start_slug
                            : "None Entered"}
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.buttonContainer}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.floatRight}>
                                <QuickStartButton />
                                {/* <Button
                                    component="a"
                                    color="primary"
                                    variant="contained"
                                    href="/user/quick-start"
                                    onClick={() => setLoading(true)}
                                >
                                    Quick Start
                                </Button> */}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.floatRight}>
                                <Button color="primary" onClick={toggleOpen}>
                                    Settings
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="overline">Deck</Typography>
                    <Typography color="primary" gutterBottom>
                        {auth.deck ? auth.deck.name : "None Selected"}
                    </Typography>
                    <Typography variant="overline">Share Link</Typography>
                    <Typography color="primary" gutterBottom>
                        {auth.quick_start_slug
                            ? `${reactProps.app_url}/${auth.quick_start_slug}`
                            : "--"}
                    </Typography>
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                open={open}
                maxWidth="md"
                onClose={toggleOpen}
                aria-labelledby="quick-sessions-title"
            >
                <DialogTitle id="quick-sessions-title">
                    Quick Start Session
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        size="small"
                        margin="normal"
                        label="Session Name"
                        value={values.quick_start_name}
                        onChange={handleChange("quick_start_name")}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        margin="normal"
                        label="Link/Access Code"
                        value={values.quick_start_slug}
                        onChange={handleChange("quick_start_slug")}
                    />
                    <TextField
                        select
                        fullWidth
                        size="small"
                        label="Deck"
                        margin="normal"
                        SelectProps={{
                            native: true
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={values.deck_id}
                        onChange={handleChange("deck_id")}
                    >
                        <option value="">None Selected</option>
                        {decks.map(deck => (
                            <option value={deck.id} key={deck.id}>
                                {deck.name}
                            </option>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleOpen}>Cancel</Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Save Settings
                    </Button>
                </DialogActions>
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Paper>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default QuickStart;
