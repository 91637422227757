import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        "& > img": {
            height: "auto",
            maxWidth: "100%",
            margin: `${theme.spacing(3)}px auto`
        }
    },
    heading: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(5),
        textDecoration: "underline",
        textTransform: "uppercase"
    }
}));

const TermsOfUseContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                style={{ fontWeight: "bold" }}
                className={classes.heading}
                align="center"
            >
                Terms Of Use
            </Typography>
            <Typography gutterBottom>
                Welcome to the Climer Cards Website and related web application
                (https://www.climercards.com/) (collectively “
                <strong>Website</strong>”) of Climer Consulting, LLC. (“
                <strong>Climer Consulting</strong>”), a firm located in
                Ashsville, NC. These Terms of Use (“
                <strong>Terms of Use</strong>”) apply to users visiting or
                otherwise utilizing the Website, as visitors, licensees of
                Climer Consulting software, or sublicensees. Users may be
                referred to in these Terms of Use as “<strong>User</strong>”, “
                <strong>You</strong>”, “<strong>Your</strong>”, or “
                <strong>Yourself</strong>.” By accessing the Website and any
                pages thereof, You hereby consent to and accept these Terms of
                Use. If You do not agree to these Terms of Use, You may not use
                the Website.
            </Typography>
            <Typography className={classes.heading} align="center">
                Introduction
            </Typography>
            <Typography gutterBottom>
                Climer Consulting will from time-to-time make changes to its
                Terms of Use, and when changes are made, Climer Consulting shall
                post such changes to its Website and request that all Users
                review the same. Then, Your continued use of the Website, or any
                services accessible through it after such posting or
                notification, shall serve as Your explicit consent to the
                modifications.
            </Typography>
            <Typography gutterBottom>
                <strong>
                    If You violate any of these Terms of Use, in Climer
                    Consulting’s sole discretion, your right to use the Website
                    will be terminated.
                </strong>
            </Typography>
            <Typography align="center" className={classes.heading}>
                SERVICES &amp; AUTHORIZED USE
            </Typography>
            <Typography gutterBottom>
                Services offered by Climer Consulting on the Website are subject
                to applicable underlying agreements and licenses (collectively “
                <strong>Underlying Agreements</strong>”) for such services that
                You executed with Climer Consulting (including, but not limited
                to, the Master License Agreement and End User License
                Agreement). Additionally, these Terms of Use shall apply to any
                of Your sublicensees who are utilizing the Website pursuant to
                the Master Services Agreement. These Terms of Use are intended
                to supplement the underlying agreements and licenses and such
                underlying agreements and licenses shall control in the case of
                a conflict with these Terms of Use.
            </Typography>
            <Typography gutterBottom>
                You are authorized to use the Website for Your own personal use
                (or the use of Your sublicensees, if any) and in accordance with
                these Terms of Use and the Underlying Agreements.
            </Typography>
            <Typography align="center" className={classes.heading}>
                UNAUTHORIZED USE
            </Typography>
            <Typography gutterBottom>
                You shall not use the Website in any illegal activity nor to
                post or transmit any unlawful, fraudulent, libelous, defamatory,
                obscene, pornographic, profane, threatening, abusive, hateful,
                offensive, or otherwise objectionable material of any kind,
                including without limitation any transmissions constituting or
                encouraging conduct that would constitute a criminal offense,
                give rise to civil liability, or otherwise violate any local,
                state, national or foreign law, including without limitation the
                U.S. export control laws and regulations.
            </Typography>
            <Typography gutterBottom>
                You shall provide true, accurate, current and complete
                information about Yourself during sign up and as prompted by the
                registration form, if applicable. If any information provided by
                You is untrue, inaccurate, not current or incomplete, Climer
                Consulting has the right to terminate Your account and refuse
                any and all current or future use of the Website.
            </Typography>
            <Typography gutterBottom>You shall not:</Typography>
            <ol>
                <li>
                    <Typography gutterBottom>
                        Monitor, gather or copy any content on this Website by
                        using any robot, “bot,” spider, crawler, spyware,
                        engine, device, software, extraction tool or any other
                        automatic device, utility or manual process of any kind;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Copy, modify or distribute any portion of the Website;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Use the Website to process data on behalf of any third
                        party that is not subject to these Terms of Use or the
                        underlying agreements and licenses;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Frame or utilize framing techniques to enclose any
                        trademark or other proprietary information (including,
                        without limitation, any images, text or page layout of
                        the Website);
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Engage in any activities through or in connection with
                        this Website that seek to attempt to harm others or are
                        unlawful, offensive, obscene, threatening, harassing,
                        abusive or that violate any right of any third party;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Attempt to circumvent the security systems of the
                        Website;{" "}
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Attempt to gain unauthorized access to services,
                        materials, other accounts, computer systems or networks
                        connected to any server controlled or utilized by Climer
                        Consulting;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        License, sublicense, sell, resell, rent, lease,
                        transfer, assign, distribute, time share or otherwise
                        commercially exploit or make the Website available to
                        any third party;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Upload or submit any data or information that contains
                        viruses or any other computer code, corrupt files or
                        programs designed to interrupt, destroy or limit the
                        functionality or disrupt any software, hardware,
                        telecommunications, networks, servers or other
                        equipment;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Engage in any activity that interferes with a user’s
                        access to this Website or the proper operation of this
                        Website;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Impersonate any person or entity;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        Resell or transfer the Website, or use of, or access to
                        the Website to any third-party.
                    </Typography>
                </li>
            </ol>
            <Typography gutterBottom>
                <strong>
                    IF CLIMER CONSULTING BECOMES AWARE THAT YOU ARE ENGAGING IN
                    ANY OF THE PROHIBITED ACTIVITIES LISTED ABOVE, OR OTHER
                    CONDUCT, WHICH IN CLIMER CONSULTING’S SOLE DISCRETION IS
                    HARMFUL TO CLIMER CONSULTING OR THE WEBSITE, CLIMER
                    CONSULTING RESERVES THE RIGHT TO REVOKE YOUR RIGHT TO THE
                    WEBSITE AND ANY PERMITTED USES THEREOF.
                </strong>
            </Typography>
            <Typography align="center" className={classes.heading}>
                SITE MONITORING &amp; COOKIES
            </Typography>
            <Typography gutterBottom>
                Climer Consulting uses electronic means to track and monitor
                Your use of the Website. You acknowledge and agree that Climer
                Consulting has the right to electronically monitor Your use of
                the Website using various methods, and to disclose any of the
                collected information as necessary or appropriate to satisfy any
                law, regulation or other governmental request, to operate the
                Website properly, or to protect itself or its subscribers.
                Climer Consulting reserves the right to refuse to post or to
                remove any information or materials, in whole or in part, that,
                in its sole discretion, are unacceptable, undesirable,
                inappropriate or in violation of these Terms of Service.
            </Typography>
            <Typography gutterBottom>
                Climer Consulting may use a cookie or similar text files on some
                pages of the Website. A cookie is an element of data that a
                website can send to your browser, which may then store it on
                your computer. A cookie does not retrieve any other data from
                Your hard drive, does not carry computer viruses and does not
                reveal anything about You that You have not already revealed.
                The Website makes extensive use of cookies to speed up certain
                functions by utilizing the processing capability of your browser
                and provide other benefits. You agree to turn on the use of
                cookies on your browser and acknowledge that the Website may not
                function properly without cookies being enabled.
            </Typography>
            <Typography gutterBottom>
                <strong>
                    Your interactions with Climer Consulting are further
                    governed by the Climer Consulting Privacy Policy with we
                    encourage You to read at the link{" "}
                    <a href="https://climercards.com/privacy-policy/">
                        {reactProps.rootUrl}/privacy-policy
                    </a>
                    .
                </strong>
            </Typography>
            <Typography align="center" className={classes.heading}>
                LINKS TO OTHER WEBSITES
            </Typography>
            <Typography gutterBottom>
                The Website may contain web links to third-party websites. These
                links are provided as a convenience to You and such third-party
                websites are not under Climer Consulting’s control. Climer
                Consulting makes no representations or warranties as to the
                accuracy and information on or from other websites. Please be
                advised that the privacy policy and security policy of
                third-party linked websites are not that of Climer Consulting.{" "}
            </Typography>
            <Typography align="center" className={classes.heading}>
                ELECTRONIC MAIL SECURITY
            </Typography>
            <Typography gutterBottom>
                Electronic mail over the Internet is not secure or confidential.
                Climer Consulting is not responsible for any damages in
                connection with electronic mail You send to Climer Consulting or
                electronic mail sent to You, at your request.{" "}
            </Typography>
            <Typography align="center" className={classes.heading}>
                SOFTWARE AND HARDWARE REQUIREMENTS
            </Typography>
            <Typography gutterBottom>
                To access the Website, You must obtain at your own expense
                access to the Internet which involves computer hardware (e.g.,
                computers, cable modems, wi-fi routers, printers) and software
                (e.g., web browser) requirements. You are solely responsible for
                any fees and charges related to your access to the Internet,
                including but not limited to any Internet Service Provider (ISP)
                fees, telephone charges and cellular provider charges. Certain
                segments of the Website may require additional hardware and/or
                software to effectively use the Website. A .pdf file viewer
                software product may also be needed to view certain online
                documents. Climer Consulting requires You to use a web browser
                that supports adequate security measures which includes the use
                of Secure Socket Layer (SSL) encryption technology with minimum
                128 bit encryption, and such standards may change from
                time-to-time as determined by Climer Consulting.
            </Typography>
            <Typography gutterBottom>
                Climer Consulting recommends You use adequate anti-virus and
                anti-spyware software and that You keep such software current to
                help You avoid data compromise and related losses. You are
                responsible for selecting all such systems, hardware and your
                ISP. You are also responsible for any defect, malfunction or
                interruption in service or security due to hardware failure,
                your choice of ISP and systems and computer services. Climer
                Consulting is not responsible for any computer virus or related
                computer hardware and software problems that may arise with your
                use of the Website services over the Internet.
            </Typography>
            <Typography align="center" className={classes.heading}>
                COPYRIGHTS, TRADEMARKS AND/OR RESTRICTIONS ON USE
            </Typography>
            <Typography gutterBottom>
                You acknowledge and agree that Climer Consulting owns all right,
                title, and interest to the Website and all content and works of
                authorship displayed on the Website, except where expressly
                noted; including without limitation photos, text, icons, source
                code, video, software, graphics and sound recordings (including
                how such information and materials are arranged on the Site).
            </Typography>
            <Typography gutterBottom>
                Such items are protected by copyright and/or other laws. You are
                authorized to view such information and materials on the Website
                only for Your own purposes. You may not copy, distribute,
                disseminate, sell, reproduce, display, link to, license, create
                derivative works of or republish the Website or any portion of
                the Website for any commercial or public purpose without Climer
                Consulting’s prior express written consent.
            </Typography>
            <Typography gutterBottom>
                In addition, you acknowledge and agree, that Climer Consulting
                (or others, as indicated) owns the trademarks, service marks,
                registered service marks or registered trademarks and logos
                (each referred to as “<strong>Marks</strong>”) which are
                displayed on the Website. You shall not use such Marks without
                the prior express written consent of Climer Consulting or the
                indicated third-party that may own the Marks. You agree that
                except as expressly provided herein, Climer Consulting has
                granted You no license, rights in or rights to use any of the
                Marks; Climer Consulting and/or applicable third parties and
                licensors retain all rights to the Marks.
            </Typography>
            <Typography align="center" className={classes.heading}>
                YOUR ACCOUNT, YOUR INFORMATION, AND HACKING
            </Typography>
            <Typography gutterBottom>
                You are responsible for maintaining the confidentiality of Your
                account, usernames and passwords, and for restricting access to
                Your computer. You agree to accept responsibility for all
                activities that occur under your account or password and shall
                immediately notify Climer Consulting of any unauthorized use of
                Your account or credentials.
            </Typography>
            <Typography gutterBottom>
                If you need help accessing or removing any of Your information,
                please contact Climer Consulting at help@climercards.com
            </Typography>
            <Typography gutterBottom>
                <strong>
                    Climer Consulting tries to ensure that the Website is secure
                    and that the information shared with us is safe. However, no
                    security measure is perfect, and unauthorized intrusions
                    into the Website are possible. You acknowledge and agree
                    that Climer Consulting shall not be responsible for any
                    damages stemming from such unauthorized intrusions or
                    disruption of the Website.
                </strong>
            </Typography>
            <Typography align="center" className={classes.heading}>
                WARRANTY DISCLAIMER
            </Typography>
            <Typography gutterBottom style={{ textTransform: "uppercase" }}>
                THE INFORMATION, MATERIALS AND SERVICES CONTAINED IN THE SITE,
                INCLUDING TEXT, LINKS, GRAPHICS OR OTHER ITEMS ARE PROVIDED "
                <strong>AS AVAILABLE</strong>" and "<strong>AS IS</strong>. "
                CLIMER CONSULTING MAKES NO WARRANTIES OF ANY KIND, EXPRESS OR
                IMPLIED, WITH RESPECT TO THE WEBSITE AND SERVICES HEREUNDER, OR
                ANY RELATED SERVICE, OR THE FITNESS OF THE WEBSITE AND/OR
                SERVICES FOR YOUR USE.
            </Typography>
            <Typography gutterBottom style={{ textTransform: "uppercase" }}>
                CLIMER CONSULTING HEREBY EXPRESSLY DISCLAIMS ANY IMPLIED
                WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                IMPLIED WARRANTIES ARISING FROM A COURSE OF DEALING OR COURSE OF
                PERFORMANCE. NO ORAL OR WRITTEN INFORMATION GIVEN BY CLIMER
                CONSULTING, ITS EMPLOYEES, LICENSORS OR THE LIKE WILL CREATE A
                WARRANTY HEREUNDER. ALSO, CLIMER CONSULTING DISCLAIMS ANY AND
                ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, WITH
                RESPECT TO THE WEBSITE OR SERVICES PERTAINING TO
                NON-INFRINGEMENT, SECURITY, ACCURACY, NON-INTERRUPTION AND
                FREEDOM FROM COMPUTER VIRUS(ES). CLIMER CONSULTING DOES NOT
                WARRANT THE ACCURACY, COMPLETENESS OR ADEQUACY OF THE
                INFORMATION AND MATERIALS ON THE SITE.
            </Typography>
            <Typography gutterBottom style={{ textTransform: "uppercase" }}>
                CLIMER CONSULTING MAKES NO REPRESENTATION THAT MATERIALS ON THIS
                WEBSITE ARE APPROPRIATE OR AVAILABLE FOR USE IN LOCATIONS
                OUTSIDE THE UNITED STATES. ACCESS TO THIS WEBSITE FROM COUNTRIES
                OR TERRITORIES WHERE SUCH ACCESS IS ILLEGAL IS PROHIBITED. IF
                YOU ACCESS THIS WEBSITE OUTSIDE THE UNITED STATES, YOU DO SO ON
                YOUR OWN INITIATIVE AND ARE RESPONSIBLE FOR COMPLIANCE WITH
                LOCAL LAWS.
            </Typography>
            <Typography align="center" className={classes.heading}>
                LIMITATION OF LIABILITY
            </Typography>
            <Typography gutterBottom style={{ textTransform: "uppercase" }}>
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO
                CIRCUMSTANCES SHALL EITHER YOU OR CLIMER CONSULTING (AND THEIR
                RELATED PARTIES) (collectively, “<strong>THE PARTIES</strong>
                ”) BE LIABLE FOR ANY OF THE FOLLOWING: (I) DIRECT DAMAGES IN
                EXCESS OF THE ACTUAL FEE(S) PAID BY YOU TO CLIMER CONSULTING FOR
                ACCESS TO THE WEBSITE (IF ANY) OR, (II) ANY INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, EXEMPLARY, PUNITIVE, OR OTHER INDIRECT DAMAGES
                WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL,
                USE, DATA, OR OTHER INTANGIBLES) ARISING OUT OF OR IN ANY WAY
                CONNECTED WITH THE WEBSITE OR ITS RELATED SERVICES WHETHER BASED
                ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
                EVEN IF THE PARTY OR ITS RELATED PARTIES HAVE BEEN ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES.
            </Typography>
            <Typography gutterBottom style={{ textTransform: "uppercase" }}>
                THE PARTIES INTEND THAT THE LIMITATIONS AND DISCLAIMERS
                CONTAINED IN THIS SECTION SHALL BE VALID AND ENFORCED EVEN IF
                THEY FAIL OF THEIR ESSENTIAL PURPOSE.
            </Typography>
            <Typography align="center" className={classes.heading}>
                INDEMNIFICATION
            </Typography>
            <Typography gutterBottom>
                You agree to indemnify, defend and hold harmless Climer
                Consulting and its respective directors, officers, employees,
                and agents from and against all claims and expenses (including
                reasonable attorneys’ fees) arising out of Your violation of
                these Terms of Use or misuse of the Website.
            </Typography>
            <Typography align="center" className={classes.heading}>
                GOVERNING LAW
            </Typography>
            <Typography gutterBottom>
                These Terms of Use are governed by and shall be interpreted
                under the internal laws of the State of North Carolina,
                excluding its conflict of law principles.
                <strong>
                    VENUE AND JURISDICTION FOR ANY FEDERAL OR STATE COURT
                    LITIGATION OR ANY ALTERNATIVE DISPUTE RESOLUTION INCLUDING
                    MEDIATION AND ARBITRATION SHALL BE BUNCOMBE COUNTY, STATE OF
                    NORTH CAROLINA.
                </strong>
            </Typography>
        </div>
    );
};

export default TermsOfUseContent;
