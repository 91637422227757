import React from "react";
import { Grid, TextField, Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    floatRight: {
        float: "right"
    },
    cardContainer: {
        borderBottom: "1px solid #666",
        marginBottom: 15,
        marginTop: 15,
        paddingBottom: 5
    }
}));

const OrganizationStep = props => {
    const classes = useStyles();
    const {
        values,
        handleChange,
        setLoading,
        handleNextStep,
        getErrorMessage,
        handlePreviousStep
    } = props;

    const attemptHandleNext = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("organization_name", values.organization_name);
        params.append(
            "organization_description",
            values.organization_description
        );
        params.append("organization_url", values.organization_url);

        axios
            .post(`/api/v1/registration/steps/organization-info`, params)
            .then(response => {
                console.log("response", response);
                handleNextStep();
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Team/Organization Name"
                    value={values.organization_name}
                    onChange={handleChange("organization_name")}
                    helperText="The name of your workplace, group, organization, etc. This will be included in the email that is sent when you invite other users."
                />
                <TextField
                    rows={3}
                    fullWidth
                    multiline
                    margin="normal"
                    label="Description (optional)"
                    value={values.organization_description}
                    onChange={handleChange("organization_description")}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    value={values.organization_url}
                    onChange={handleChange("organization_url")}
                    label="Team/Organization Website (optional)"
                />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.floatRight}>
                    <ButtonGroup>
                        <Button
                            variant="contained"
                            onClick={handlePreviousStep}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={attemptHandleNext}
                        >
                            Next
                        </Button>
                    </ButtonGroup>
                </div>
            </Grid>
        </Grid>
    );
};

export default OrganizationStep;
