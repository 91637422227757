import { Fab, Menu, MenuItem, Tooltip } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import ContextualComponent from '@assets/ContextualComponent';
import ConfirmationModal from './ConfirmationModal';

export default function MoreButton(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [requestedPatient, setRequestedPatient] = useState(null);
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(
        false,
    );
    const [activeOption, setActiveOption] = useState(false);

    let textInput;

    function setTextInputRef(element) {
        textInput = element;
    }

    const menuStyles = {
        paper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0 -5% 0 0',
            backgroundColor: 'rgba(51, 51, 51, 0.8)',
            color: 'white',
        },
        list: {
            padding: 0,
        },
    };

    const StyledMenu = withStyles(menuStyles)(Menu);

    const menuItemStyles = {
        root: {
            borderBottom: '1px solid white',
            '&:hover': {
                backgroundColor: '#9c9c9c',
            },
        },
    };

    const StyledMenuItem = withStyles(menuItemStyles)(MenuItem);

    function handleShowOptions() {
        setMenuOpen(true);
        setTooltipOpen(false);
        setAnchorEl(textInput);
    }

    function handleClose() {
        setMenuOpen(false);
        setAnchorEl(null);
    }

    function handleMouseEnter() {
        setAnchorEl(textInput);
        setTooltipOpen(true);
        setTimeout(function() {
            setTooltipOpen(false);
        }, 600);
    }

    function handleMouseLeave() {
        setTooltipOpen(false);
    }

    function handleOptionClick(option) {
        console.log('option: ', option);
        if (option.rxValet) {
            handleRxValetRequest(option);
        } else if (!option.modal && !option.confirm) {
            linkAction(option);
        } else if (option.confirm) {
            handleConfirmationOption(option);
        }
    }

    function handleConfirmationOption(option) {
        setMenuOpen(false);
        setActiveOption(option);
        setShowConfirmationMessage(true);
    }

    function navigate(route) {
        window.location = route;
    }

    function handleRxValetRequest(option) {
        setMenuOpen(false);
        window.open(option.link, '_blank');
    }

    function linkAction(option) {
        console.log('option', option);
        setMenuOpen(false);
        setLoading(true);
        if (option.link && !option.confirm && !option.hidden && !option.modal) {
            navigate(option.link);
        }
        if (
            !option.link &&
            !option.confirm &&
            !option.hidden &&
            !option.modal
        ) {
            option.submitFunction();
        }
    }

    const StyledFab = withStyles({
        label: {
            color: 'white',
        },
    })(Fab);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-menu' : undefined;

    return (
        <div>
            <ContextualComponent>
                <div ref={setTextInputRef}>
                    <Tooltip
                        title="Options"
                        disableFocusListener
                        open={tooltipOpen}
                    >
                        <StyledFab
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            id={'dropdown-button' + props.row.id}
                            size={'small'}
                            color="primary"
                            className={
                                props.floatRight
                                    ? 'btn btn-more-button float-right'
                                    : 'btn more-button'
                            }
                            onClick={handleShowOptions}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <MoreVertIcon />
                        </StyledFab>
                    </Tooltip>
                </div>
                <div className="options-text">
                    <StyledMenu
                        id={id}
                        keepMounted
                        open={menuOpen}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {props.menuOptions.map(option => {
                            if (option.hidden != true) {
                                return (
                                    <StyledMenuItem
                                        disabled={
                                            option.disabled &&
                                            option.disabled == true
                                        }
                                        key={option.key}
                                        onClick={e => handleOptionClick(option)}
                                        className="download-option"
                                    >
                                        {option.label}
                                    </StyledMenuItem>
                                );
                            }
                        })}
                    </StyledMenu>
                </div>
                {showConfirmationMessage && (
                    <ConfirmationModal
                        confirmationMessage={activeOption.confirmationMessage}
                        explanation={activeOption.explanation}
                        label={activeOption.label}
                        submitFunction={activeOption.submitFunction}
                    />
                )}
            </ContextualComponent>
        </div>
    );
}
