import { useWakeLock } from "react-screen-wake-lock";
import { useEffect, useState } from "react";

export const mobileWakeLock = (enabled = true) => {
    const { isSupported, released, request, release } = useWakeLock({
        // onRequest: () => console.log('Screen Wake Lock: requested!'),
        // onError: () => console.log('An error happened 💥'),
        // onRelease: () => console.log('Screen Wake Lock: released!'),
    });
    const [alreadyEnabled, setAlreadyEnabled] = useState(false);

    useEffect(() => {
        try {
            if (!isSupported) {
                //console.log('WakeLock: not supported')
                return;
            }
            //console.log('WakeLock: enabled')

            if (setAlreadyEnabled === enabled) {
                //console.log('WakeLock: already enabled', released, enabled)
                return;
            }

            setAlreadyEnabled(enabled);

            if (enabled) {
                //console.log('WakeLock: enable')
                request();
            } else {
                //console.log('WakeLock: disable')
                release();
            }
        } catch (error) {
            console.log("error", error);
        }
    });
};

export default mobileWakeLock;
