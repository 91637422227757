import React from "react";
import StatsCard from "@global/StatsCard";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const TotalDecks = () => {
    const { decksCount } = reactProps;

    return (
        <StatsCard
            icon={
                <CheckBoxOutlineBlankIcon
                    style={{ backgroundColor: "#e4eb8d", color: "#687518" }}
                />
            }
            title="Number of decks"
            number={decksCount}
            link="/admin/decks"
        />
    );
};

export default React.memo(TotalDecks);
