import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        "& > img": {
            height: "auto",
            maxWidth: "100%",
            margin: `${theme.spacing(3)}px auto`
        }
    },
    heading: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(5),
        textDecoration: "underline",
        textTransform: "uppercase"
    },
    privacyPolicyImage: {
        width: "100%",
        height: "auto",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    bold: {
        fontWeight: "bold"
    },
    image: {
        height: "auto",
        margin: `${theme.spacing(3)}px auto 0`,
        maxWidth: 500,
        width: "100%"
    }
}));

const PrivacyPolicyContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                Introduction and Privacy Principles
            </Typography>
            <Typography gutterBottom align="left">
                Thank you for visiting! Climer Consulting, LLC values the
                privacy of the facilitators, students, and other users of its
                website and Climer Cards cloud application. Please take a moment
                to read this document in its entirety and make sure you
                understand it. If you have any questions about this Privacy
                Policy, please feel free to reach out to Climer Consulting here:{" "}
                <a href="mailto:help@climercards.com">help@climercards.com.</a>
            </Typography>
            <img
                src="/img/privacy-policy-image.png"
                alt="privacy policy"
                className={classes.privacyPolicyImage}
            />
            <Typography
                className={`${classes.heading} ${classes.bold}`}
                align="center"
            >
                PRIVACY POLICY
            </Typography>
            <Typography gutterBottom align="left">
                This Privacy Policy describes the current policies and practices
                of Climer Consulting, LLC. (“<strong>Climer Consulting</strong>
                ”) with regard to Personal Data (defined below) collected from
                users (“<strong>Users</strong>”) accessing its website:{" "}
                <a href="https://climercards.com">https://climercards.com</a> (“
                <strong>Website</strong>”) and related cloud based application
                (“<strong>Climer Cards</strong>”).
            </Typography>
            <Typography gutterBottom align="left">
                <span
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                >
                    Updates:{" "}
                </span>{" "}
                This Privacy Policy is periodically updated. Please check back
                on a regular basis for any changes. If there are changes to this
                Privacy Policy, Climer Consulting will post an update on its
                website alerting users to the changes.{" "}
                <span
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                >
                    Please note:
                </span>{" "}
                continued use of the Website or Climer Cards application after
                an update to the Privacy Policy serves as a User’s explicit
                consent to the updated terms.
            </Typography>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                The Data Climer Consulting Collects
            </Typography>
            <Typography className={classes.heading}>
                Information Collected From All Visitors to the Website:
            </Typography>
            <Typography gutterBottom align="left">
                Climer Consulting{" "}
                <strong>
                    <em>does not</em>
                </strong>{" "}
                utilize tracking technology on its Website and does not track
                Users who visit the Website unless they specifically log into
                the Climer Cards application using their unique credentials as
                detailed below. Once logged into Climer Cards, Climer Consulting
                collects information about the User’s use of Climer Cards,
                including the information they input into the application, page
                visits and the like as described in the “Use of Non-Personal
                Data Section.”
            </Typography>
            <Typography gutterBottom align="left">
                Data collected by the Website from all Users, is considered “
                <strong>Non-Personal Data</strong>” of a User.
            </Typography>
            <Typography className={classes.heading}>
                Information Collected from Users Licensing Climer Cards
            </Typography>
            <Typography gutterBottom align="left">
                Climer Consulting collects the following information from Users
                who license directly with Climer Consulting for access to Climer
                Cards (collectively “<strong>Facilitators</strong>”):
            </Typography>
            <ul>
                <li>
                    <Typography gutterBottom align="left">
                        First Name
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        Last Name
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        E-Mail Address
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        Billing Information
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        The Licensee also has the option to provide{" "}
                        <em>additional</em> information after sign-up.
                    </Typography>
                </li>
            </ul>
            <Typography gutterBottom align="left">
                The above information is considered “
                <strong>Personal Data</strong>” of the Facilitators and will
                only be provided to Climer Consulting when submitted by the
                Facilitator during the sign-up process.{" "}
                <strong>
                    Climer Consulting uses Stripe to process credit card
                    information, and will not have direct access to that
                    information after sign up.
                </strong>
            </Typography>
            <Typography className={classes.heading}>
                Information Collected from Users Utilizing Climer Cards
            </Typography>
            <Typography gutterBottom align="left">
                For those Facilitators who are utilizing Climer Cards with
                third-parties (“<strong>Students</strong>”) under a Master
                License Agreement, Climer Consulting will endeavor to only
                collect information from those Students which is necessary to
                grant them access to Climer Cards. However, by its very nature,
                Students may share personal information during sessions using
                the Climer Cards application.
            </Typography>
            <Typography gutterBottom align="left">
                <strong>Students Over the Age of 13:</strong> Students over the
                age of 13 hereby grant Climer Consulting the explicit consent to
                collect the information, including any Personal Data, they input
                into the Climer Cards application.
            </Typography>
            <Typography gutterBottom align="left">
                <strong>Students Under the Age of 13:</strong> Facilitators have
                the following options to protect the privacy of Students under
                the age of 13.{" "}
            </Typography>
            <Typography gutterBottom align="left">
                <span style={{ textDecoration: "underline" }}>Option 1:</span>{" "}
                The Facilitator may obtain the explicit consent of the Student’s
                parent or guardian to allow Climer Consulting to collect the
                Student’s Personal Data.
            </Typography>
            <Typography gutterBottom align="left">
                <span style={{ textDecoration: "underline" }}>Option 2:</span>{" "}
                Option 2: The Facilitator may encourage Students{" "}
                <strong>
                    <em>not to use</em>
                </strong>{" "}
                their names or other Personal Data during a Climer Cards session
                and may toggle the reminder in the Facilitator dashboard.
            </Typography>
            <Typography gutterBottom align="left">
                <strong>
                    <em>
                        Any information entered into the Climer Cards
                        application during a session will be stored in the
                        software’s database. Therefore, it is the Facilitator’s
                        responsibility to make sure they strictly abide by
                        either Option 1 or Option 2 above.
                    </em>
                </strong>
            </Typography>
            <Typography gutterBottom align="left">
                To the extent above information could be used to identify an
                individual Student, it is considered “
                <strong>Personal Data</strong>” of the Student.
            </Typography>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                Why Climer Consulting Collects Personal Data and How We Use It
            </Typography>
            <Typography className={classes.heading}>
                General Use of Personal Data
            </Typography>
            <Typography gutterBottom align="left">
                Climer Consulting uses the Personal Data collected on the
                Website and in Climer Cards to allow Climer Consulting to
                provide Users with:{" "}
            </Typography>
            <ol>
                <li>
                    <Typography gutterBottom align="left">
                        access to the Website and;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        to ensure a User’s usage of the Website and Climer Cards
                        is in compliance with the Terms of Use and any
                        underlying agreements a User has signed.{" "}
                    </Typography>
                </li>
            </ol>
            <Typography gutterBottom align="left">
                Climer Consulting may also use the Personal Data to contact a
                User for a variety of reasons, such as but not limited to:
            </Typography>
            <ol>
                <li>
                    <Typography gutterBottom align="left">
                        notifying a User about use of the Website or;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        requesting feedback on the Website, Climer Cards, or its
                        related services.
                    </Typography>
                </li>
            </ol>
            <Typography className={classes.heading}>
                Marketing and E-Mails
            </Typography>
            <Typography gutterBottom align="left">
                The above paragraph notwithstanding, Climer Consulting{" "}
                <strong>
                    <em>will not</em>
                </strong>{" "}
                contact any Students with any marketing e-mails, only
                Facilitators. Climer Consulting may contact Students (to the
                extent possible) if Climer Consulting discovers a Student is
                having technical problems related to the Website or Climer Cards
                and cannot reach the Facilitator first.
            </Typography>
            <Typography gutterBottom align="left">
                To maintain its mailing list, Climer Consulting uses a software
                which keeps the list current and tracks if a User opts-out of
                receiving future communications. Climer Consulting complies with
                CAN-SPAM and related regulations that govern the use and
                maintenance of mailing lists.{" "}
            </Typography>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                Storage, Sharing and Disclosure of Personal Data
            </Typography>
            <Typography className={classes.heading}>
                Storage of Personal Data
            </Typography>
            <Typography gutterBottom align="left">
                Climer Consulting is a “cloud based” application, meaning all of
                its data, including Personal and Non-Personal data of Users, is
                stored on servers and other computer systems which are
                accessible via the Internet and are not under the direct
                physical control of Climer Consulting. Climer Consulting is
                providing this information in the spirit of full disclosure to
                our Users and will store all Personal and Non-Personal Data of
                our Users in accordance with this Privacy Policy.
            </Typography>
            <Typography className={classes.heading}>
                Facilitator Access to Student Personal Data
            </Typography>
            <Typography gutterBottom align="left">
                Facilitators will have access to their particular Student
                information contained in the Climer Cards database. This is a
                feature of Climer Cards and allows each Facilitator to manage
                their own sessions and Students. Whether or not a particular
                Student’s information is Personal Data is dependent on the
                options elected by the Facilitator and the information submitted
                by the Student during a Climer Cards session.
            </Typography>
            <Typography className={classes.heading}>
                Disclosure to Third-Parties
            </Typography>
            <Typography gutterBottom align="left">
                Climer Consulting may disclose Personal Data to third parties
                under certain circumstances. Climer Consulting may disclose
                Personal Data to third parties if: 1) a User requested that
                Climer Consulting do so; 2) to comply with an applicable law,
                regulation, valid subpoena, or request of the US Government or;
                3) Climer Consulting is acquired by or merged into another
                entity, or all or a portion of its assets are sold or otherwise
                transferred to another entity, Climer Consulting may disclose
                Personal Data to that entity. However, if the Personal Data
                includes information obtained from Students, Climer Consulting
                will ensure the acquiring entity abides by the privacy
                protections in this policy, or will provide Facilitators notice
                and an option to delete their Personal data prior to the sale
                occurring.{" "}
            </Typography>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                Access to And Control Over Information Held By Climer Consulting
            </Typography>
            <Typography gutterBottom align="left">
                <span style={{ textDecoration: "underline" }}>
                    Facilitator Dashboard:{" "}
                </span>{" "}
                Facilitators can update or correct their own information on file
                with Climer Consulting by using the Facilitator dashboard
            </Typography>
            <Typography gutterBottom align="left">
                <span style={{ textDecoration: "underline" }}>E-Mail: </span>{" "}
                Additionally, via e-mail Users can specifically request
                information about:
            </Typography>
            <ul>
                <li>
                    <Typography gutterBottom align="left">
                        What data Climer Consulting has about them, if any;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        Changing/correcting any data Climer Consulting has about
                        them;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        Deleting any data Climer Consulting has about them and;
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom align="left">
                        Expressing any concern that the User has about use of
                        their data.
                    </Typography>
                </li>
            </ul>
            <Typography gutterBottom align="left">
                For help with further questions or access to Personal Data a
                User can use the contact form on the{" "}
                <a href="https://www.climercards.com/">Website</a> (
                <a href="https://climercards.com/contact/">
                    https://climercards.com/contact/
                </a>
                ) or contact:{" "}
                <a href="mailto:help@climercards.com">help@climercards.com</a>
            </Typography>
            <Typography gutterBottom align="left">
                Please Note: Climer Consulting will direct any request from a
                Student for access to their information to the Facilitator they
                are receiving access from. The Facilitator will have the ability
                to update or correct Student information, not the Student
                him\herself.
            </Typography>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                How Climer Consulting Uses Non-Personal Data
            </Typography>
            <Typography gutterBottom align="left">
                Non-Personal Data may also be automatically collected about a
                User utilizing the Website. For example, Climer Consulting uses
                technology to automatically collect Non-Personal Data about the
                use of Website. This technology is used to track how many Users
                access Website, the date and time of their visits, the length of
                their stays, and which pages they view. Climer Consulting may
                also use technology to determine which web browsers Users use
                and the address from which they accessed the Website. The
                information collected using this technology does not identify
                any User personally and is thus all Non-Personal Data that
                simply enables Climer Consulting to compile statistics about
                Users and their use of the Website and related services.
            </Typography>
            <Typography gutterBottom align="left">
                Climer Consulting may store certain information from a User’s
                web browser using “cookies” or the browser-resident
                database\cache. A cookie is a piece of data stored on the User’s
                computer tied to information about the User and their use of the
                Website. Climer Consulting may use session-ID cookies to confirm
                that registered Users are logged in. These cookies would
                terminate once the User closes the web browser. Climer
                Consulting may also use persistent cookies that store certain
                Personal Data already provided to Climer Consulting to make it
                easier for a User to log back into the Website. A User can
                remove or block cookies using the settings in the User’s web
                browser if a user wants to disable such functions, but such
                action may impair the full functionality of the Website.
            </Typography>
            <Typography gutterBottom align="left">
                <strong>
                    Climer Consulting does not utilize third-party tracking
                    cookies or engage in cross-website tracking in any way. A
                    User’s utilization of the Website or Climer Cards will not
                    be shared with advertisers.{" "}
                </strong>
            </Typography>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                The Security Practices Of Climer Consulting
            </Typography>
            <Typography gutterBottom align="left">
                Climer Consulting uses industry standard security measures to
                protect Users’ Personal Data. While such security measures
                provide reasonable protections against unauthorized access, they{" "}
                <strong>
                    <em>do not</em>
                </strong>{" "}
                provide a guarantee that Personal Data will not be compromised
                in the future. By its nature, a cloud based service like Climer
                Cards resides on servers and computer systems accessible via the
                Internet which may be compromised or misused by third-party bad
                actors that Climer Consulting has no control over.
            </Typography>
            <Typography gutterBottom align="left">
                Whenever a website collects sensitive information a User should
                confirm that the information is encrypted and transmitted in a
                secure way. One way that a User may do so is by looking for a
                closed lock icon at the bottom of the web browser or looking for
                "https" at the beginning of the address of the web page.
            </Typography>
            <div style={{ textAlign: "center" }}>
                <img
                    src="/img/https-example-image.png"
                    alt="browser address bar with HTTPS lock icon"
                    className={classes.image}
                />
            </div>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                LINKS TO OTHER WEBSITES
            </Typography>
            <Typography gutterBottom align="left">
                The Website may contain web links to third-party websites that
                Climer Consulting believes Users may find helpful. These
                websites are not under Climer Consulting’s control. Climer
                Consulting makes no representations or warranties as to the
                accuracy and information on or from other websites. Please be
                advised that the privacy policy and security policy of
                third-party linked websites are not that of Climer Consulting.
            </Typography>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                Questions About Our Privacy Policy?
            </Typography>
            <Typography gutterBottom align="left">
                If a User has any questions about this Privacy Policy or
                concerns about the way Climer Consulting processes Personal
                Data, please contact Climer Consulting at:{" "}
                <a href="mailto:help@climercards.com">help@climercards.com</a>{" "}
                Or utilize the contact form at the link{" "}
                <a href="/feedback">{reactProps.rootUrl}/feedback</a>.
            </Typography>
            <Typography
                align="center"
                className={`${classes.heading} ${classes.bold}`}
            >
                COPPA Statement
            </Typography>
            <Typography gutterBottom align="left">
                Climer Consulting does not intend to collect Personal Data from
                Users (or Students, as the case may be) that are under the age
                of thirteen. If a User allows access to the Website to a third
                party, that User is responsible for ensuring that the
                information shared by the third party does not contain their
                first\last name, address, or any other information that may
                allow Climer Consulting (or any third party) to identify them
                individually.
            </Typography>
            <Typography gutterBottom align="left">
                To the extent that Personal Data of anyone under the age of
                thirteen is shared with Climer Consulting by a User, such
                sharing of the Personal Data serves as the explicit consent for
                Climer Consulting to collect such information.
            </Typography>
        </div>
    );
};

export default PrivacyPolicyContent;
