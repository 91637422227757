import React from "react";
import ReactDOM from "react-dom";
require("../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import General from "@layouts/General";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0 auto",
        padding: theme.spacing(3),
        width: "75%"
    }
}));

const About = () => {
    const classes = useStyles();

    return (
        <General
            maxWidth={false}
            style={{
                background: "url(/img/cards-background.png) center"
            }}
        >
            <Paper elevation={10} className={classes.root}>
                <Typography variant="h1" color="primary" align="center">
                    About Climer Cards
                </Typography>
                <Typography variant="body1">
                    Deserunt in ad commodo duis aute aliqua tempor. Anim aliquip
                    enim veniam adipisicing cupidatat ipsum elit quis cillum.
                    Eiusmod culpa sint duis laboris laboris nulla ullamco.
                    Mollit cillum occaecat amet pariatur labore occaecat
                    reprehenderit voluptate reprehenderit qui exercitation
                    ullamco non pariatur. Ipsum aute ea sint dolore et. Deserunt
                    magna dolore reprehenderit ullamco do. Ut id exercitation et
                    consequat consectetur dolore exercitation.
                </Typography>
            </Paper>
        </General>
    );
};

if (document.getElementById("about")) {
    ReactDOM.render(
        <ErrorBoundary>
            <About />
        </ErrorBoundary>,
        document.getElementById("about")
    );
}
