import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {},
    icon: {
        padding: 12,
        "& svg": {
            height: 24,
            width: 24
        }
    }
}));

const PasswordField = React.forwardRef((props, ref) => {
    const { value, handleChange, confirm, label, ...rest } = props;
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);

    return (
        <TextField
            {...rest}
            inputRef={ref}
            label={label ? label : confirm ? "Confirm Password" : "Password"}
            fullWidth
            required
            margin="normal"
            name={confirm ? "password-confirm" : "password"}
            type={showPassword ? "text" : "password"}
            onChange={handleChange ? handleChange : undefined}
            value={value || value == "" ? value : undefined}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleShowPassword}
                            className={classes.icon}
                            size="small"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
});

PasswordField.propTypes = {
    value: PropTypes.string,
    handleChange: PropTypes.func,
    confirm: PropTypes.bool,
    label: PropTypes.string
};

export default PasswordField;
