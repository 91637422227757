import React, { useState } from "react";
import {
    Grid,
    TextField,
    Switch,
    FormControlLabel,
    Typography,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(6)
    }
}));

const QuickSessionSettings = () => {
    const classes = useStyles();
    const { auth, decks, userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isPublic, setIsPublic] = useState(
        Boolean(auth.public) ? true : false
    );
    const [values, setValues] = useState({
        quick_start_name:
            auth && auth.quick_start_name ? auth.quick_start_name : auth.name,
        quick_start_slug:
            auth && auth.quick_start_slug ? auth.quick_start_slug : "",
        deck_id: auth && auth.deck_id ? auth.deck_id : ""
    });

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const togglePublic = () => {
        let currentValue = isPublic;
        setIsPublic(!currentValue);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("quick_start_name", values.quick_start_name);
        params.append("quick_start_slug", values.quick_start_slug);
        params.append("deck_id", values.deck_id);

        axios
            .post("/api/v1/user/profile/quick-session", params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    "Quick Session Defaults Updated"
                );
                window.location = `/user/settings?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
                <div className={classes.marginBottom}>
                    <Typography gutterBottom>Settings</Typography>
                </div>
                <div className={classes.marginBottom}>
                    <Typography gutterBottom variant="body2">
                        Public Profile
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={isPublic}
                                onChange={togglePublic}
                            />
                        }
                        label="Allow participants to view your profile during sessions"
                    />
                </div>
                <Typography gutterBottom variant="body2">
                    Quick Session Defaults
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    margin="normal"
                    label="Session Name"
                    value={values.quick_start_name}
                    onChange={handleChange("quick_start_name")}
                />
                <TextField
                    fullWidth
                    size="small"
                    margin="normal"
                    label="Link/Access Code"
                    value={values.quick_start_slug}
                    onChange={handleChange("quick_start_slug")}
                />
                <TextField
                    select
                    fullWidth
                    size="small"
                    label="Deck"
                    margin="normal"
                    SelectProps={{
                        native: true
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={values.deck_id}
                    onChange={handleChange("deck_id")}
                >
                    <option value="">None Selected</option>
                    {decks.map(deck => (
                        <option value={deck.id} key={deck.id}>
                            {deck.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default QuickSessionSettings;
