import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "./layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import AdminNav from "./admin/components/AdminNav";
import { Button, Container, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FeedbackForm from "@global/FeedbackForm";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center"
    },
    main: {
        padding: theme.spacing(3),
        textAlign: "center",
        "& > button": {
            marginTop: theme.spacing(2)
        }
    }
}));

const Feedback = () => {
    const { admin } = reactProps;
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <DashboardLayout sideNav={admin ? <AdminNav /> : <UserNav />}>
            <Container maxWidth="md" className={classes.root}>
                <main className={classes.main}>
                    <Typography variant="h3" gutterBottom>
                        We Would Love Your Feedback!
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        We are excited to introduce the Virtual Climer Cards web
                        app, and we need your help to make this experience as
                        amazing as possible.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        As you are using Virtual Climer Cards, if you find
                        anything that doesn’t work or could be improved, please
                        let us know. The button below will give you access to a
                        form to use to submit bugs, typos, feature requests,
                        general feedback, or questions. Thank you! We appreciate
                        you!
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Amy Climer and the Virtual Climer Cards Developers
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setOpen(true)}
                    >
                        Submit Feedback
                    </Button>
                </main>
            </Container>
            <FeedbackForm open={open} setOpen={setOpen} />
        </DashboardLayout>
    );
};

if (document.getElementById("feedback")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Feedback />
        </ErrorBoundary>,
        document.getElementById("feedback")
    );
}
