import React, { useState } from "react";
import {
    Grid,
    Typography,
    Paper,
    Avatar,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getInitial } from "@utils/stringUtils";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    floatRight: {
        float: "right"
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        minWidth: 200,
        minHeight: 200
    },
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlgin: "center"
    },
    padded: {
        padding: theme.spacing(4)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    }
}));

const UserProfile = props => {
    const { setLoading } = props;
    const { user, userToken, auth, organization } = reactProps;
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(prevState => !prevState);
    };

    const handleRemove = () => {
        setLoading(true);
        axios
            .post(`/api/v1/users/${user.uuid}/remove`, null, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("User removed");
                window.location = `/users?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    return (
        <Grid
            container
            jusify="center"
            spacing={2}
            className={classes.marginTop}
        >
            <Grid item xs={12} sm={6} md={8}>
                <Paper className={classes.padded}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="overline">Name</Typography>
                            <Typography gutterBottom>{user.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">
                                Email Address
                            </Typography>
                            <Typography gutterBottom>{user.email}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Joined</Typography>
                            <Typography gutterBottom>
                                {moment.utc(user.created_at).fromNow()}
                            </Typography>
                        </Grid>
                        {auth.id == organization.user_id && (
                            <Grid item xs={12}>
                                <div className={classes.floatRight}>
                                    <Typography variant="caption">
                                        <a
                                            href="#"
                                            onClick={toggleOpen}
                                            style={{
                                                fontSize: 12,
                                                cursor: "pointer",
                                                color: "#e53935"
                                            }}
                                        >
                                            Remove User
                                        </a>
                                    </Typography>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper className={classes.padded}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.content}>
                                <Avatar
                                    src={user.profileImage}
                                    alt={user.name}
                                    className={classes.avatar}
                                    style={{
                                        backgroundColor: user.avatarColor
                                            ? user.avatarColor
                                            : "inherit"
                                    }}
                                >
                                    {getInitial(user.name)}
                                </Avatar>
                                {user.id == user.organization.user_id ? (
                                    <Typography
                                        gutterBottom
                                        align="center"
                                        variant="caption"
                                        className={classes.marginTop}
                                    >
                                        Organization Manager
                                    </Typography>
                                ) : (
                                    <Typography
                                        gutterBottom
                                        align="center"
                                        variant="caption"
                                        className={classes.marginTop}
                                    >
                                        Organization Facilitator
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Dialog
                fullWidth
                open={open}
                maxWidth="md"
                onClose={toggleOpen}
                aria-labelledby="remove-user"
            >
                <DialogTitle id="remove-user">Remove {user.name}</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom align="center">
                        Are you sure you would like to remove {user.name} from{" "}
                        {organization.name}? If you confirm, {user.name} will no
                        longer have access to the {organization.name}{" "}
                        Organization.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={toggleOpen}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleRemove}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default UserProfile;
