import React, { useState, useEffect } from "react";
import { find } from "lodash";
import Pusher from "pusher-js";
import { Grid, Typography, Button } from "@material-ui/core";
import ResponseCard from "@global/ResponseCard";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    center: {
        textAlign: "center"
    }
}));

const PromptResponses = props => {
    const classes = useStyles();
    const {
        deck,
        prompts,
        token,
        toggleShowResponses,
        hasResponded,
        setHasResponded,
        session
    } = props;
    const {
        pusher_app_key,
        pusher_app_cluster,
        pusher_app_host,
        pusher_app_scheme
    } = reactProps;
    const [currentPromptInfo, setCurrentPromptInfo] = useState(
        find(prompts, function(prompt) {
            return prompt.order === parseInt(props.currentPrompt);
        })
    );
    const [subscribed, setSubscribed] = useState(false);
    const [responses, setResponses] = useState(null);
    const [flipped, setFlipped] = useState(false);

    const fetchResponses = () => {
        if (Boolean(currentPromptInfo)) {
            axios
                .get(`/api/v1/prompts/${currentPromptInfo.uuid}`)
                .then(response => {
                    setResponses(response.data.responses);
                })
                .catch(error => {
                    console.log("error", error);
                });
        }
    };

    const applyNewResponse = data => {
        if (Boolean(currentPromptInfo)) {
            if (currentPromptInfo.uuid !== data.prompt.uuid) {
                return;
            }
            return setResponses(responses => {
                let found = false;
                let BreakException = {};
                let responsesCopy = { ...responses };

                if (Object.keys(responsesCopy).length) {
                    try {
                        Object.values(responsesCopy).forEach(response => {
                            if (response.id === data.id) {
                                found = true;
                                throw BreakException;
                            }
                        });
                    } catch (e) {
                        if (e !== BreakException) throw e;
                    }
                }

                responsesCopy = Object.values(responsesCopy);

                if (found === false) {
                    if (!responsesCopy) {
                        responsesCopy = [];
                    }
                    responsesCopy.push(data);
                }

                return setResponses(responsesCopy);
            });
        } else {
            return;
        }
    };

    const toggleFlipped = () => {
        axios
            .get(`/api/v1/sessions/${reactProps.session.uuid}/flip-status`)
            .then(response => {
                setFlipped(response.data.flipped);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        if (
            !currentPromptInfo ||
            currentPromptInfo.order !== parseInt(props.currentPrompt)
        ) {
            setCurrentPromptInfo(
                find(prompts, function(prompt) {
                    return prompt.order === parseInt(props.currentPrompt);
                })
            );
        }
    }, []);

    useEffect(() => {
        if (!subscribed) {
            setSubscribed(true);
            let params = {
                cluster: pusher_app_cluster,
                forceTLS: false
            };
            if (pusher_app_scheme === "https") {
                params.forceTLS = true;
                params.enabledTransports = ["ws", "wss"];
            }
            if (!!pusher_app_host && pusher_app_host !== "") {
                params.wsHost = pusher_app_host;
            }
            let pusher = new Pusher(pusher_app_key, params);
            let channel = pusher.subscribe(
                `response-added-${reactProps.session.uuid}`
            );
            channel.bind_global(function(event, data) {
                if (data && data.hasOwnProperty("id")) {
                    applyNewResponse(data);
                } else {
                    fetchResponses();
                }
            });
        }
        setHasResponded(hasResponded);
    }, []);

    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(`${reactProps.session.uuid}-flip`);
        channel.bind_global(function() {
            toggleFlipped();
        });
    }, []);

    return (
        <Grid container spacing={2}>
            {responses && responses.length > 0 ? (
                <>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            Current Responses:
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        spacing={3}
                        xs={12}
                        style={{ marginBottom: 25 }}
                    >
                        {responses.map(response => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                                key={response.id}
                            >
                                {deck && (
                                    <ResponseCard
                                        token={token}
                                        key={flipped}
                                        session={session}
                                        response={response}
                                        deck={deck}
                                        allFlipped={flipped}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <Typography gutterBottom align="center">
                        No responses to show
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <div className={classes.center}>
                    <Button
                        component="p"
                        color="primary"
                        variant="outlined"
                        onClick={toggleShowResponses}
                    >
                        Add New Response
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};

export default PromptResponses;
