import React, { useState, useEffect } from "react";
import { Grid, Paper, Button, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ResponseModal from "./ResponseModal";
import CardImage from "./CardImage";
import AdvancedBackdrop from "./AdvancedBackdrop";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import { find, shuffle } from "lodash";
import axios from "axios";
import SingleCard from "./SingleCard";
import * as Sentry from "@sentry/react";

const useStyles = makeStyles(theme => ({
    root: {},
    cardContainer: {
        padding: theme.spacing(1),
        cursor: "pointer"
    },
    cardImage: {
        borderRadius: 4,
        height: "auto",
        width: "100%"
    },
    dialogContent: {
        padding: theme.spacing(3)
    },
    masonryContainer: {
        margin: "0 auto"
    },
    masonryGrid: {
        display: "flex",
        marginLeft: "-30px"
    },
    masonryGridColumn: {
        paddingLeft: 30,
        backgroundClip: "padding-box"
    },
    center: {
        textAlign: "center"
    },
    srDisplay: {
        position: "absolute",
        top: -99999
    },
    alignCenter: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    listRoot: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center"
        }
    }
}));

const CardList = props => {
    const {
        deck,
        prompts,
        token,
        flipped,
        currentPromptNumber,
        toggleShowResponses,
        subscribed,
        session,
        promptData,
        participant
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [currentCard, setCurrentCard] = useState(null);
    const [loading, setLoading] = useState(true);
    const [valuesLoading, setValuesLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [responses, setResponses] = useState([]);
    const [currentPrompt, setCurrentPrompt] = useState(
        find(prompts, function(prompt) {
            return prompt.order === parseInt(currentPromptNumber);
            // return prompt.order === parseInt(reactProps.currentPrompt);
        })
    );
    const [backSelected, setBackSelected] = useState(false);
    const [promptDataCache, setPromptDataCache] = useState(null);
    const [currentPromptCache, setCurrentPromptCache] = useState(null);

    const [cards, setCards] = useState(deck.cards);

    const allowSortCards = cardsArray => {
        if (deck.allow_random_sort) {
            setCards(shuffle(cardsArray));
        } else {
            setCards(cardsArray);
        }
    };

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeDirectly = (key, value) => {
        setValues({
            ...values,
            [key]: value
        });
    };

    const handleSubmit = () => {
        if (!valuesLoading) {
            setValuesLoading(true);
        }

        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("description", values.response);
        params.append("card_id", currentCard.id);
        params.append("token", token);
        if (backSelected === true) params.append("back_of_card", true);

        if (!participant) {
            axios
                .post(`/api/v1/session/${session.uuid}/response/create`, params)
                .then(response => {
                    setValuesLoading(false);
                    setTimeout(
                        function() {
                            setOpen(false);
                            setCurrentCard(null);
                            setBackSelected(false);
                            setPromptDataCache(null);
                            setCurrentPromptCache(null);
                            setValues({
                                ...values,
                                response: ""
                            });
                            toggleShowResponses();
                        }.bind(this),
                        500
                    );
                })
                .catch(error => {
                    console.log("error", error);
                    getErrorMessage(error.response);
                    setValuesLoading(false);
                });
        } else {
            axios
                .post(
                    `/api/v1/prompts/${promptData[0].uuid}/response/asinchronous-create`,
                    params
                )
                .then(response => {
                    setValuesLoading(false);
                    setTimeout(
                        function() {
                            setOpen(false);
                            setCurrentCard(null);
                            setBackSelected(false);
                            setPromptDataCache(null);
                            setCurrentPromptCache(null);
                            setValues({
                                ...values,
                                response: ""
                            });
                            toggleShowResponses();
                        }.bind(this),
                        500
                    );
                })
                .catch(error => {
                    console.log("error", error);
                    getErrorMessage(error.response);
                    setValuesLoading(false);
                });
        }
    };

    const fetchResponses = () => {
        if (currentPrompt !== null && currentPrompt !== undefined) {
            axios
                .get(`/api/v1/prompts/${currentPrompt.uuid}`)
                .then(response => {
                    setResponses(response.data.responses);
                })
                .catch(error => {
                    console.log("error", error);
                });
        }
    };

    const handleClick = (card, back = false) => {
        setBackSelected(back);
        setCurrentCard(card);
        setPromptDataCache(promptData);
        setCurrentPromptCache(currentPrompt);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentCard(null);
        setPromptDataCache(null);
        setCurrentPromptCache(null);
    };

    const handleLoaded = () => {
        setLoading(false);
    };

    // const flipCards = () => {
    //     console.log("flip all cards");
    //     axios
    //         .post(`/api/v1/flip-session-cards/${session.uuid}`, null, {
    //             headers: {
    //                 Authorization: `Bearer ${userToken}`
    //             }
    //         })
    //         .then(response => {
    //             console.log("response", response);
    //         })
    //         .catch(error => {
    //             console.log("error", error);
    //         });
    // };

    useEffect(() => {
        fetchResponses();
        setTimeout(handleLoaded, 1000);
    }, []);

    useEffect(() => {
        setCurrentPrompt(
            find(prompts, function(prompt) {
                return prompt.order === parseInt(currentPromptNumber);
            })
        );
    }, [currentPromptNumber]);

    useEffect(() => {
        allowSortCards(deck.cards);
    }, [deck]);

    return (
        <Grid container spacing={1} justify="center">
            <Grid item xs={12}>
                <div className={classes.center}>
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={toggleShowResponses}
                    >
                        Show Responses
                    </Button>
                </div>
            </Grid>
            {/* {Boolean(auth) &&
                (auth.id == session.user_id ||
                    (auth.organization_id &&
                        auth.organization_id == session.organization_id)) &&
                Boolean(session.allow_facilitator_flip) &&
                window.location.href.includes("presentation") && (
                    <Grid item xs={12}>
                        <div className={classes.center}>
                            <Link href="#" color="primary" onClick={flipCards}>
                                {flipped == "front"
                                    ? "Flip all to Back"
                                    : "Flip all to Front"}
                            </Link>
                        </div>
                    </Grid>
                )} */}
            <Grid item xs={12} className={classes.listRoot}>
                {deck.cards.map(card => (
                    <SingleCard
                        card={card}
                        key={card.id}
                        allFlipped={flipped}
                        handleClick={handleClick}
                        session={session}
                    />
                ))}
            </Grid>
            {open && (
                <ResponseModal
                    {...props}
                    // prompts={prompts}
                    classes={classes}
                    card={currentCard}
                    // values={values}
                    back={backSelected}
                    // handleClose={handleClose}
                    // handleChange={handleChange}
                    // handleSubmit={handleSubmit}
                    // handleChangeDirectly={handleChangeDirectly}
                    setOpen={setOpen}
                    currentPrompt={currentPromptCache}
                    valuesLoading={valuesLoading}
                    setValuesLoading={setValuesLoading}
                    participant={participant}
                    backSelected={backSelected}
                    setCurrentCard={setCurrentCard}
                    setBackSelected={setBackSelected}
                    promptData={promptDataCache}
                    getErrorMessage={getErrorMessage}
                    handleClose={handleClose}
                />
            )}
            {loading && !subscribed && <AdvancedBackdrop open={loading} />}
            {valuesLoading && <LoadingBackdrop open={valuesLoading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Grid>
    );

    function getErrorMessage(response) {
        if (!response || !response.hasOwnProperty("status")) {
            setErrorMessage("Unknown error!");
            if (!!response) {
                Sentry.captureMessage(
                    "Unknown error: " + JSON.stringify(response)
                );
            }
        } else {
            if (response.status == 400 || response.status == 419) {
                setErrorMessage("Form request expired.");
            }

            if (response.status == 401) {
                setErrorMessage("Cannot verify permissions.");
            }

            if (response.status == 422) {
                var err = response.data;
                let message = _.head(err[Object.keys(err)[0]]);
                setErrorMessage(message);
            }

            if (
                response.status == 404 &&
                response.data &&
                response.data.message
            ) {
                setErrorMessage(response.data.message);
            }

            if (response.status == 500) {
                setErrorMessage(
                    "Something went wrong. Please try again later."
                );
            }
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default CardList;
