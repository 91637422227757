import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import General from "@layouts/General";
import {
    Paper,
    Typography,
    TextField,
    Button,
    Container,
    useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import AdminNav from "./admin/components/AdminNav";

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0 auto",
        padding: theme.spacing(3),
        textAlign: "right",
        "& > *": {
            marginBottom: theme.spacing(2)
        }
    },
    dashboardContent: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        "& > *": {
            marginTop: -32
        }
    }
}));

const Contact = () => {
    const classes = useStyles();
    const { captchaKey, auth, admin } = reactProps;
    const [captchaConfirmed, setCaptchaConfirmed] = useState(Boolean(auth));
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [values, setValues] = useState({
        name: auth && auth.name ? auth.name : "",
        email: auth && auth.email ? auth.email : "",
        message: ""
    });
    const theme = useTheme();
    const compactCaptcha = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSubmit = () => {
        let params = new FormData();
        params.append("name", values.name);
        params.append("email", values.email);
        params.append("message", values.message);
        setLoading(true);

        axios
            .post("/api/v1/contact/submit", params)
            .then(response => {
                if (response.data.submitted) {
                    setLoading(false);
                    setSubmitted(true);
                }
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleCaptcha = value => {
        if (Boolean(value)) setCaptchaConfirmed(true);
        else setErrorMessage("ReCAPTCHA failed. Please try again.");
    };

    const renderContactForm = () => {
        return (
            <Container maxWidth="sm">
                <Paper elevation={10} className={classes.root}>
                    <Typography variant="h1" color="primary" align="center">
                        Get in touch
                    </Typography>
                    {submitted ? (
                        <Typography
                            variant="h5"
                            component="p"
                            align="center"
                            style={{ margin: "2rem auto" }}
                        >
                            Thank you! Your message has been sent.
                        </Typography>
                    ) : (
                        <>
                            <TextField
                                onChange={handleChange("name")}
                                label="Name"
                                value={values.name}
                                fullWidth
                            />
                            <TextField
                                onChange={handleChange("email")}
                                label="Email"
                                value={values.email}
                                fullWidth
                            />
                            <TextField
                                onChange={handleChange("message")}
                                label="Message"
                                value={values.message}
                                fullWidth
                                multiline
                                rows={5}
                            />
                            {!auth && (
                                <ReCAPTCHA
                                    sitekey={captchaKey}
                                    onChange={handleCaptcha}
                                    size={compactCaptcha ? "compact" : "normal"}
                                />
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!captchaConfirmed}
                            >
                                Submit
                            </Button>
                        </>
                    )}
                </Paper>
                {loading && <LoadingBackdrop open={loading} />}
                {errorMessage && errorMessage != "" && (
                    <MessageSnackbar variant="error" message={errorMessage} />
                )}
            </Container>
        );
    };

    return (
        <>
            {auth ? (
                <DashboardLayout
                    sideNav={admin ? <AdminNav /> : <UserNav />}
                    contentStyles={classes.dashboardContent}
                >
                    {renderContactForm()}
                </DashboardLayout>
            ) : (
                <General
                    maxWidth={false}
                    style={{
                        background: "url(/img/cards-background.png) center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    {renderContactForm()}
                </General>
            )}
        </>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("contact")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Contact />
        </ErrorBoundary>,
        document.getElementById("contact")
    );
}
