import React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InvoicesTable from "./InvoicesTable";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        overflow: "scroll"
    }
}));

const Invoices = () => {
    const classes = useStyles();
    const { invoices } = reactProps;

    React.useEffect(() => {
        console.log("invoices", invoices);
    }, []);
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h4">
                    Invoice History
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    {invoices.length > 0 ? (
                        <InvoicesTable invoices={invoices} />
                    ) : (
                        <Typography gutterBottom align="center">
                            No invoices to show
                        </Typography>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Invoices;
