import React from "react";
import StatsCard from "@global/StatsCard";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

const TotalUsers = () => {
    const { usersCount } = reactProps;

    return (
        <StatsCard
            icon={
                <PersonOutlineIcon
                    style={{ backgroundColor: "#4786AA50", color: "#4786AA" }}
                />
            }
            title="Number of users"
            number={usersCount}
            link="/admin/users"
        />
    );
};

export default React.memo(TotalUsers);
