import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import { Grid, Typography } from "@material-ui/core";
import CreateRoleForm from "./partials/CreateRoleForm";
import ContextualComponent from "@assets/ContextualComponent";

export default function Create() {
    return (
        <div>
            <ContextualComponent>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography>Create a Role</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CreateRoleForm />
                    </Grid>
                </Grid>
            </ContextualComponent>
        </div>
    );
}

if (document.getElementById("admin-roles-create")) {
    ReactDOM.render(<Create />, document.getElementById("admin-roles-create"));
}
