import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../components/AdminNav";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Fab,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    Button
} from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import UsersTable from "./components/UsersTable";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const Index = () => {
    const classes = useStyles();
    const { users } = reactProps;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <DashboardLayout sideNav={<AdminNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Users
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.floatRight}>
                        <Button
                            href="/admin/users/invite"
                            color="primary"
                            variant="contained"
                        >
                            Invite User
                        </Button>
                        <IconButton
                            color="primary"
                            aria-controls="user-options-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="user-options-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem component="a" href="/admin/users/export">
                                Export User Info
                            </MenuItem>
                        </Menu>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Users"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/admin/dashboard">
                            Dashboard
                        </Link>
                        <Typography>Users</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            {users.length > 0 ? (
                                <UsersTable />
                            ) : (
                                <Typography
                                    variant="h4"
                                    component="p"
                                    align="center"
                                >
                                    No users to show
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("users-index")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>,
        document.getElementById("users-index")
    );
}
