import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Link, Paper, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import React from "react";
import General from "../../layouts/General";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 470,
        padding: theme.spacing(5),
        textAlign: "center"
    },
    inputContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    button: {
        marginLeft: 18,
        marginTop: 12
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        margin: `${theme.spacing(2)}px auto`,
        "& svg": {
            color: "#05597B",
            height: 45,
            margin: `0 ${theme.spacing(2)}px`,
            width: 45
        }
    }
}));

export const WaitFacilitator = () => {
    const classes = useStyles();

    return (
        <General
            style={{
                alignItems: "center",
                background: "url(/img/cards-background.png) center",
                display: "flex",
                justifyContent: "center"
            }}
            maxWidth={false}
        >
            <Paper elevation={10} className={classes.root}>
                <Typography variant="h3" color="primary" gutterBottom>
                    Please wait for the facilitator
                </Typography>
                <Typography variant="h5" component="p" color="secondary">
                    Like what you saw?
                </Typography>
                <Typography
                    variant="h4"
                    component="p"
                    color="primary"
                    gutterBottom
                >
                    Share us on Social Media!
                </Typography>
                <div className={classes.iconContainer}>
                    <IconButton
                        href="https://www.facebook.com/climerconsulting"
                        target="_blank"
                    >
                        <FacebookIcon />
                    </IconButton>
                    <IconButton
                        href="https://twitter.com/amyclimer"
                        target="_blank"
                    >
                        <TwitterIcon />
                    </IconButton>
                    <IconButton
                        href="https://www.instagram.com/ClimerCards/"
                        target="_blank"
                    >
                        <InstagramIcon />
                    </IconButton>
                </div>
                <Typography variant="body1" gutterBottom>
                    If you are interested in Climer Cards,{" "}
                    <Link href="https://climercards.com/" target="_blank">
                        click here
                    </Link>{" "}
                    to find out more about the virtual and physical decks.
                </Typography>
            </Paper>
        </General>
    );
};
