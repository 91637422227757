import { colors } from "@material-ui/core";

const white = "#FFFFFF";

const palette = {
    primary: {
        contrastText: white,
        dark: "#00314c",
        main: "#115a78",
        light: "#8abcc6"
    },
    secondary: {
        contrastText: white,
        dark: "#c09f00",
        main: "#f7d00f",
        light: "#fcefad"
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600]
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
        default: "#F4F6F8",
        paper: white
    },
    divider: colors.grey[200]
};

export default palette;
