import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Paper,
    Dialog,
    DialogTitle,
    Tooltip,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Checkbox,
    InputAdornment,
    TextField
} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import ReactDragListView from "react-drag-listview";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import AddPromptModal from "./AddPromptModal";
import useWindowDimensions from "@utils/windowDimensions";
import EditAndDelete from "./EditAndDelete";
import { find, head } from "lodash";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1)
        }
    },
    floatRight: {
        float: "right"
    }
}));

const Prompts = () => {
    const classes = useStyles();
    const { width } = useWindowDimensions();
    const { userToken } = reactProps;
    const [decks, setDecks] = useState(null);
    const [session, setSession] = useState(reactProps.session);
    const [prompts, setPrompts] = useState([]);
    const [subscribed, setSubscribed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [edit, setEdit] = useState(null);
    const [values, setValues] = useState({
        description: ""
    });

    const handleSubmitChange = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("description", values.description);

        axios
            .post(`/api/v1/admin/prompts/${head(selected)}/edit`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                //console.log("response", response);
                setPrompts(response.data.prompts);
                setEdit(null);
                setValues({
                    description: ""
                });
                setLoading(false);
            })
            .catch(error => {
                //console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleEdit = uuid => {
        //console.log("uuid", uuid);
        setValues({
            ...values,
            description: find(prompts, function(prompt) {
                return prompt.uuid == uuid;
            }).description
        });
        setEdit(uuid);
    };

    const toggleSelectAll = event => {
        if (event.target.checked) {
            const newSelected = prompts.map(prompt => prompt.uuid);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };

    const handleSelect = (event, uuid) => {
        const selectedIndex = selected.indexOf(uuid);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, uuid);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const toggleOpen = () => {
        setOpen(prevState => !prevState);
    };

    const handleDragEnd = (fromIndex, toIndex) => {
        setLoading(true);
        let currentPrompts = prompts;
        let moved = currentPrompts.splice(fromIndex, 1)[0];
        currentPrompts.splice(toIndex, 0, moved);
        setPrompts(currentPrompts);
        setLoading(false);
        updatePromptsOrder(currentPrompts);
    };

    const updatePromptsOrder = newPrompts => {
        let params = new URLSearchParams();
        params.append("prompts", JSON.stringify(newPrompts));

        axios
            .post(`/api/v1/admin/sessions/${session.uuid}/re-order`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                //console.log("response", response);
            })
            .catch(error => {
                //console.log("error", error);
            });
    };

    const queryDecks = () => {
        setLoading(true);
        let params = new URLSearchParams();

        axios
            .get(`/api/v1/admin/decks/all`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                setDecks(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (decks === null) {
            setDecks(false);
            if (Boolean(reactProps.decks)) {
                setDecks(reactProps.decks);
            } else {
                queryDecks();
            }
        }
    });

    useEffect(() => {
        if (!subscribed) {
            setPrompts(session.prompts);
            setSubscribed(true);
        }
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.padded}>
                    <div className={classes.floatRight}>
                        <Tooltip title="Add a new prompt">
                            <Fab
                                size="small"
                                variant="round"
                                color="primary"
                                onClick={toggleOpen}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                    {prompts.length > 0 && !loading && (
                        <TableContainer>
                            <ReactDragListView
                                onDragEnd={handleDragEnd}
                                nodeSelector="section"
                                handleSelector="span"
                            >
                                <div>
                                    <Table
                                        component="div"
                                        style={{
                                            tableLayout: "auto"
                                        }}
                                    >
                                        <TableHead component="div">
                                            {selected.length > 0 && (
                                                <EditAndDelete
                                                    selected={selected}
                                                    setLoading={setLoading}
                                                    handleEdit={handleEdit}
                                                    getErrorMessage={
                                                        getErrorMessage
                                                    }
                                                />
                                            )}
                                            <TableRow component="div">
                                                {session.multiple_decks ===
                                                1 ? (
                                                    <>
                                                        <TableCell
                                                            padding="checkbox"
                                                            component="div"
                                                        >
                                                            <Checkbox
                                                                indeterminate={
                                                                    selected.length >
                                                                        0 &&
                                                                    selected.length <
                                                                        prompts.length
                                                                }
                                                                checked={
                                                                    prompts.length >
                                                                        0 &&
                                                                    selected.length ===
                                                                        prompts.length
                                                                }
                                                                onChange={
                                                                    toggleSelectAll
                                                                }
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "select all prompts"
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                width:
                                                                    "calc(100% - 48px - 200px - 48px)",
                                                                minWidth:
                                                                    "350px"
                                                            }}
                                                            component="article"
                                                            id={`table-row-${prompt.uuid}`}
                                                        >
                                                            <Typography variant="body1">
                                                                Name
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            component="div"
                                                            style={{
                                                                width: 200,
                                                                minWidth: 150
                                                            }}
                                                        >
                                                            <Typography variant="body1">
                                                                Deck
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            component="article"
                                                            style={{
                                                                width: 48
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </TableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableCell
                                                            padding="checkbox"
                                                            component="div"
                                                        >
                                                            <Checkbox
                                                                indeterminate={
                                                                    selected.length >
                                                                        0 &&
                                                                    selected.length <
                                                                        prompts.length
                                                                }
                                                                checked={
                                                                    prompts.length >
                                                                        0 &&
                                                                    selected.length ===
                                                                        prompts.length
                                                                }
                                                                onChange={
                                                                    toggleSelectAll
                                                                }
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "select all prompts"
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                width:
                                                                    "calc(100% - 48px - 48px)",
                                                                minWidth:
                                                                    "350px"
                                                            }}
                                                            component="article"
                                                            id={`table-row-${prompt.uuid}`}
                                                        >
                                                            <Typography variant="body1">
                                                                Name
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            component="article"
                                                            style={{
                                                                width: 48
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody component="div">
                                            {prompts.map(prompt => (
                                                <TableRow
                                                    key={prompt.uuid}
                                                    component="section"
                                                >
                                                    <TableCell
                                                        padding="checkbox"
                                                        component="div"
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selected.indexOf(
                                                                    prompt.uuid
                                                                ) !== -1
                                                            }
                                                            inputProps={{
                                                                "aria-labelledby": `table-row-${prompt.uuid}`
                                                            }}
                                                            onClick={event =>
                                                                handleSelect(
                                                                    event,
                                                                    prompt.uuid
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="article"
                                                        id={`table-row-${prompt.uuid}`}
                                                    >
                                                        {!edit ||
                                                        edit != prompt.uuid ? (
                                                            prompt.description
                                                        ) : (
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                value={
                                                                    values.description
                                                                }
                                                                onChange={handleChange(
                                                                    "description"
                                                                )}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Tooltip title="Save changes">
                                                                                <IconButton
                                                                                    onClick={
                                                                                        handleSubmitChange
                                                                                    }
                                                                                >
                                                                                    <SaveIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    {session.multiple_decks ===
                                                        1 && (
                                                        <TableCell component="div">
                                                            {prompt.deck_id >
                                                                0 &&
                                                            Boolean(decks) &&
                                                            decks.filter(
                                                                d =>
                                                                    d.id ===
                                                                    prompt.deck_id
                                                            ).length ? (
                                                                <Typography variant="caption">
                                                                    {Boolean(
                                                                        decks
                                                                    ) &&
                                                                        decks.filter(
                                                                            d =>
                                                                                d.id ===
                                                                                prompt.deck_id
                                                                        )[0]
                                                                            .name}
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant="caption">
                                                                    —
                                                                </Typography>
                                                            )}
                                                        </TableCell>
                                                    )}
                                                    <TableCell
                                                        align="right"
                                                        component="article"
                                                    >
                                                        <Tooltip title="Move this prompt">
                                                            <IconButton
                                                                component="span"
                                                                onClick={e =>
                                                                    e.preventDefault()
                                                                }
                                                            >
                                                                <DragIndicatorIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </ReactDragListView>
                        </TableContainer>
                    )}
                    {prompts.length == 0 && !loading && (
                        <Typography gutterBottom align="center" variant="body2">
                            No Prompts added
                        </Typography>
                    )}
                </Paper>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            <Dialog
                fullWidth
                open={open}
                maxWidth="md"
                onClose={toggleOpen}
                aria-labelledby="add-prompt"
            >
                <DialogTitle id="add-prompt">Add a prompt</DialogTitle>
                <AddPromptModal
                    session={session}
                    setSession={setSession}
                    setLoading={setLoading}
                    setSubscribed={setSubscribed}
                    getErrorMessage={getErrorMessage}
                    handleCancel={() => setOpen(false)}
                />
            </Dialog>
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default Prompts;
