const plans = [
    {
        name: "Basic",
        price: {
            monthly: 12,
            yearly: 97
        },
        line_one: "Original Climer Cards",
        line_two: "3 Active Sessions",
        line_three: "3 Draft Sessions",
        link: "basic",
        action_text: "Get Started",
        team: false,
        featured: false,
        trial_30_day: true,
        users: 1,
        active_sessions: 3,
        draft_sessions: 1,
        criginal_climer_cards: true,
        additional_decks: false,
        custom_decks: false,
        white_labeling: false
    },
    // {
    //     name: "Plus",
    //     price: {
    //         monthly: 10,
    //         yearly: 100
    //     },
    //     line_one: "All Climer Cards Decks",
    //     line_two: "3 Active Sessions",
    //     line_three: "5 Draft Sessions",
    //     link: "plus",
    //     action_text: "Get Started",
    //     team: false,
    //     featured: true
    //     trial_30_day: true,
    //     users: 1,
    //     active_sessions: 3,
    //     draft_sessions: 'Up to 5',
    //     criginal_climer_cards: true,
    //     additional_decks: true,
    //     custom_decks: false,
    //     white_labeling: false
    // },
    {
        name: "Professional",
        price: {
            monthly: 35,
            yearly: 297
        },
        line_one: "All Climer Card Decks",
        line_two: "10 Active Sessions",
        line_three: "Upload Your Own Decks",
        link: "professional",
        action_text: "Go Pro",
        team: false,
        featured: false,
        trial_30_day: true,
        users: 1,
        active_sessions: 10,
        draft_sessions: "Up to 10",
        criginal_climer_cards: true,
        additional_decks: true,
        custom_decks: true,
        white_labeling: false
    },
    {
        name: "Team",
        price: {
            monthly: 97,
            yearly: 997
        },
        line_one: "Up to 10 Users",
        line_two: "Share Sessions Between Users",
        line_three: "Upload Your Own Decks",
        link: "team",
        action_text: "Get Started",
        team: true,
        featured: false,
        trial_30_day: true,
        users: "Up to 10",
        active_sessions: "10 Per User",
        draft_sessions: "10 Per User",
        criginal_climer_cards: true,
        additional_decks: true,
        custom_decks: true,
        white_labeling: true
    },
    // {
    //     name: "Education",
    //     price: {
    //         monthly: 50,
    //         yearly: 500
    //     },
    //     line_one: "Up to 75 Users",
    //     line_two: "Share Sessions Between Users",
    //     line_three: "White Label Your Decks",
    //     link: "education",
    //     action_text: "Get Started",
    //     team: true,
    //     featured: true,
    //     trial_30_day: true,
    //     users: "Up to 75",
    //     active_sessions: "5 Per User",
    //     draft_sessions: "10 Per User",
    //     criginal_climer_cards: true,
    //     additional_decks: true,
    //     custom_decks: true,
    //     white_labeling: true
    // },
    {
        name: "Enterprise",
        price: {
            monthly: "Let's Talk!",
            yearly: "Let's Talk!"
        },
        line_one: "From 10 to Unlimited",
        line_two: "Unlimited Active Sessions",
        line_three: "Unlimited Draft Sessions",
        // link: "enterprise",
        link: "https://climercards.com/contact-us/",
        action_text: "Contact Us",
        team: true,
        featured: false,
        trial_30_day: true,
        users: "Can Be Unlimited",
        active_sessions: "Unlimited",
        draft_sessions: "Unlimited",
        criginal_climer_cards: true,
        additional_decks: true,
        custom_decks: true,
        white_labeling: true
    }
];

export default plans;
