import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import MessageSnackbar from "@global/MessageSnackbar";
import General from "@layouts/General";
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    useTheme,
    useMediaQuery
} from "@material-ui/core";
import LoginForm from "./auth/components/LoginForm";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "-2rem",
        textAlign: "center",
        "& > h1": {
            fontSize: 46,
            letterSpacing: "0.16px",
            lineHeight: "58px",
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down("sm")]: {
                fontSize: 42,
                marginBottom: theme.spacing(6),
                marginTop: "5rem"
            }
        },
        "& > a": {
            marginBottom: theme.spacing(1)
        }
    },
    codeContainer: {
        padding: theme.spacing(3),
        margin: "0 4rem",
        "& > h2, h3": {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.down("sm")]: {
            margin: 0
        }
    },
    inputContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    input: {},
    button: {
        marginLeft: 18,
        marginTop: 12
    },
    subHead: {
        fontSize: 24,
        letterSpacing: "0.08px",
        lineHeight: "32px",
        marginBottom: theme.spacing(5)
    }
}));

const Index = () => {
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [error, setError] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = event => {
        setCode(event.target.value);
    };

    const handleSubmit = () => {
        axios
            .get(`/api/v1/sessions/validate/${code}`)
            .then(response => {
                console.log(response);
                if (response.data.session_exists) {
                    window.location = `/${response.data.slug}`;
                } else {
                    setError(response.data.message);
                }
            })
            .catch(error => console.log(error));
    };

    return (
        <General
            maxWidth={false}
            style={{
                background:
                    "url(/img/climer-cards-background.jpg) no-repeat center/cover",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
            accessInput
        >
            <Container maxWidth="md" className={classes.root}>
                <Typography variant="h1" color="primary" align="center">
                    Deepen conversations, increase connections, and generate new
                    ideas in your virtual meetings and trainings
                </Typography>
                <Typography className={classes.subHead} align="center">
                    Virtual Climer Cards use visual images to get participants
                    to engage around a question you ask. Whether you want team
                    members to share their perspectives on a project, students
                    to talk about their day, or need a new way to brainstorm
                    ideas, Virtual Climer Cards lead to more conversation and
                    engagement in online meetings, trainings, and webinars.
                </Typography>
                <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    href="https://climercards.com/virtual#signup"
                    fullWidth={isMobile}
                >
                    Sign Up
                </Button>
                <Typography align="center">Free 30 day trial</Typography>
                {/* <Paper elevation={10} className={classes.codeContainer}>
                    <Typography variant="h2" color="secondary" align="center">
                        Have an access code?
                    </Typography>
                    <Typography variant="h3" color="primary" align="center">
                        Enter it here
                    </Typography>
                    <div className={classes.inputContainer}>
                        <TextField
                            value={code}
                            onChange={handleChange}
                            label="Access Code"
                        />
                        <Button
                            onClick={handleSubmit}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                        >
                            Join
                        </Button>
                    </div>
                </Paper>
                <LoginForm style={{ marginTop: 28 }} />
                {error && <MessageSnackbar message={error} variant="error" />} */}
            </Container>
        </General>
    );
};

if (document.getElementById("welcome")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>,
        document.getElementById("welcome")
    );
}
