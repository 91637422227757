import React from "react";
import DashboardCard from "@global/DashboardCard";
import {
    Table,
    Typography,
    TableBody,
    TableRow,
    TableCell,
    Button
} from "@material-ui/core";
import { filter } from "lodash";

const UserDecks = () => {
    const { decks, plan } = reactProps;
    const options = [
        {
            text: "View all decks",
            action: "/decks"
        },
        {
            text: "Add new deck",
            action: "/decks/create",
            disabled: plan == "basic" || plan == "plus"
        }
    ];

    return (
        <DashboardCard
            heading="My Decks"
            link={{ text: "See All", href: "/decks" }}
            options={options}
        >
            {decks.length > 0 ? (
                <Table>
                    <TableBody>
                        {filter(decks, function(deck) {
                            return Boolean(deck.climer);
                        }).map(deck => (
                            <TableRow key={deck.id}>
                                <TableCell>{deck.name}</TableCell>
                                <TableCell align="right">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        href={`/decks/${deck.uuid}/edit`}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        {filter(decks, function(deck) {
                            return !Boolean(deck.climer);
                        }).map(deck => (
                            <TableRow key={deck.id}>
                                <TableCell>{deck.name}</TableCell>
                                <TableCell align="right">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        href={`/decks/${deck.uuid}/edit`}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Typography align="center">No custom decks to show</Typography>
            )}
        </DashboardCard>
    );
};

export default UserDecks;
