import { createMuiTheme } from '@material-ui/core/styles';

const palette = createMuiTheme({
    palette: {
        primary: { main: '#00A8E0', contrastText: '#fff' },
        secondary: { main: '#0F7394' },
    },
});

export default palette;
