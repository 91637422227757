import React from "react";
import { Typography, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    logo: {
        width: 33
    },
    cardDescription: {
        position: "relative",
        bottom: 7
    }
}));

const CreditCardLogo = props => {
    const { paymentMethod } = props;
    const classes = useStyles();

    return (
        <Typography gutterBottom>
            {paymentMethod.card.brand == "visa" && (
                <Icon
                    component="span"
                    className={clsx("fab fa-cc-visa", classes.logo)}
                />
            )}
            {paymentMethod.card.brand == "amex" && (
                <Icon
                    component="span"
                    className={clsx("fab fa-cc-amex", classes.logo)}
                />
            )}
            {paymentMethod.card.brand == "mastercard" && (
                <Icon
                    component="span"
                    className={clsx("fab fa-cc-mastercard", classes.logo)}
                />
            )}
            {paymentMethod.card.brand == "discover" && (
                <Icon
                    component="span"
                    className={clsx("fab fa-cc-discover", classes.logo)}
                />
            )}
            {paymentMethod.card.brand == "diners" && (
                <Icon
                    component="span"
                    className={clsx("fab fa-cc-diners-club", classes.logo)}
                />
            )}
            {paymentMethod.card.brand == "jcb" && (
                <Icon
                    component="span"
                    className={clsx("fab fa-cc-jcb", classes.logo)}
                />
            )}
            {paymentMethod.card.brand == "unionpay" && (
                <Icon
                    component="span"
                    className={clsx("fa fa-credit-card", classes.logo)}
                />
            )}
            <span className={classes.cardDescription}>
                Ending in {paymentMethod.card.last_four}
            </span>
        </Typography>
    );
};

export default CreditCardLogo;
