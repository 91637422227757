import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";
import axios from "axios";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";

const ChangeBillingInterval = ({
    open,
    handleClose,
    getPriceMonthly,
    getPriceYearly
}) => {
    const { stripeSub, subscription, userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("stripe_sub_id", subscription.stripe_id);
        params.append("current_interval", stripeSub.plan.interval);

        axios
            .post(`/api/v1/billing/change-billing-interval`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                const message = encodeURI(
                    `Billing interval updated to ${
                        stripeSub.plan.interval == "month"
                            ? "Yearly"
                            : "Monthly"
                    }.`
                );
                window.location = window.location + `?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogTitle>
                Update Plan To{" "}
                {stripeSub.plan.interval == "month" ? "Yearly" : "Monthly"}
            </DialogTitle>
            <DialogContent>
                {stripeSub.plan.interval == "month" ? (
                    <Typography gutterBottom>
                        Your current pay period will be prorated and your
                        payment method will be charged.
                    </Typography>
                ) : (
                    <p>todo yearly-to-monthly switch content</p>
                )}
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Typography>
                        Plan - {subscription.name}(
                        {stripeSub.plan.interval == "month"
                            ? "Yearly"
                            : "Monthly"}
                        )
                    </Typography>
                    <Typography>
                        $
                        {stripeSub.plan.interval == "month"
                            ? getPriceYearly()
                            : getPriceMonthly()}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions
                style={{ justifyContent: "flex-start", paddingLeft: 24 }}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    style={{ marginRight: 12 }}
                >
                    Update
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </DialogActions>
            <LoadingBackdrop open={loading} />
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Dialog>
    );

    function getErrorMessage(response) {
        console.log("errorMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default ChangeBillingInterval;
