import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import moment from "moment";

const useStyles = makeStyles(() => ({
    floatRight: {
        float: "right"
    }
}));

const UpcomingPricingDetails = () => {
    const classes = useStyles();
    const [upcomingInvoice, setUpcomingInvoice] = useState(null);
    const [loading, setLoading] = useState(true);
    const { userToken } = reactProps;

    useEffect(() => {
        axios
            .get(`/api/v1/billing/upcoming-invoice`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                //console.log("response", response);
                setUpcomingInvoice(response.data.upcoming);
                setLoading(false);
            })
            .catch(error => {
                //console.log("error", error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            {upcomingInvoice && (
                <>
                    {Boolean(upcomingInvoice.discount) &&
                        Boolean(upcomingInvoice.discount.coupon) && (
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>
                                        Coupon -{" "}
                                        {upcomingInvoice.discount.coupon.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.floatRight}>
                                        <Typography gutterBottom>
                                            {Boolean(
                                                upcomingInvoice.discount.coupon
                                                    .percent_off
                                            ) &&
                                                `${upcomingInvoice.discount.coupon.percent_off}% off`}
                                            {Boolean(
                                                upcomingInvoice.discount.coupon
                                                    .amount_off
                                            ) &&
                                                `$${parseFloat(
                                                    upcomingInvoice.discount
                                                        .coupon.amount_off / 100
                                                ).toFixed(2)} off`}
                                            {Boolean(
                                                upcomingInvoice.discount.coupon
                                                    .duration == "forever"
                                            ) && ` forever`}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography gutterBottom>Next Charge</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.floatRight}>
                                <Typography gutterBottom>
                                    {`$${parseFloat(
                                        upcomingInvoice.total / 100
                                    ).toFixed(2)} ${moment
                                        .unix(
                                            upcomingInvoice.next_payment_attempt
                                        )
                                        .fromNow()}`}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
            {loading && <LoadingBackdrop open={loading} />}
        </>
    );
};

export default UpcomingPricingDetails;
