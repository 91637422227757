import React from "react";
import clsx from "clsx";
import { ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: "bold",
        paddingBottom: 10,
        paddingTop: 10,
        "& > svg": {
            marginLeft: 10,
            marginRight: 22
        },
        "&:hover": {
            color: "inherit",
            backgroundColor: theme.palette.primary.light
        }
    },
    current: {
        backgroundColor: theme.palette.secondary.main
    }
}));

const NavItem = ({ children, href, current, ...rest }) => {
    const classes = useStyles();

    return (
        <ListItem
            button
            component="a"
            href={href}
            className={clsx(classes.root, current ? classes.current : "")}
            color="inherit"
            {...rest}
        >
            {children}
        </ListItem>
    );
};

export default NavItem;
