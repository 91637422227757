import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Fab,
    Tooltip,
    Button,
    Tabs,
    Tab,
    Paper,
    TextField,
    FormControlLabel,
    Switch,
    Dialog,
    IconButton,
    Hidden,
    Menu,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import CardSelect from "./components/CardSelect";
import { remove, head } from "lodash";
import FeaturedSelect from "./components/FeaturedSelect";
import axios from "axios";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Card from "./components/Card";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        textAlign: "right"
    },
    paper: {
        padding: theme.spacing(3),
        height: "100%"
    },
    divider: {
        marginBottom: theme.spacing(2)
    },
    featuredContainer: {
        textAlign: "center",
        "& > button": {
            marginTop: theme.spacing(1)
        }
    },
    switchText: {
        color: "#05597B",
        display: "block",
        marginBottom: theme.spacing(1),
        textTransform: "uppercase"
    },
    deleteButton: {
        borderColor: "#FF0000",
        color: "#FF0000",
        marginRight: theme.spacing(3)
    },
    flipButton: {
        backgroundColor: theme.palette.primary.main,
        bottom: 0,
        borderRadius: 4,
        color: "#FFF",
        right: 0,
        position: "absolute"
    },
    imageContainer: {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: 4,
        boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
        height: 318,
        margin: "0 auto",
        marginBottom: theme.spacing(2),
        overflow: "hidden",
        position: "relative",
        width: 230
    }
}));

const Edit = () => {
    const classes = useStyles();
    const { deck, plan, userToken, auth, organization } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [values, setValues] = useState({
        name: deck.name,
        description: deck.description,
        allow_back: Boolean(deck.allow_back),
        allow_random_sort: Boolean(!deck.allow_random_sort),
        share_deck: deck.organization_id ? true : false,
        featured_image: deck.featuredImage,
        featured_alt: "",
        cards: deck.cards
    });
    const [open, setOpen] = useState(false);
    const [cards, setCards] = useState(reactProps.cards);
    const [currentCard, setCurrentCard] = useState(null);
    const [editing, setEditing] = useState(false);
    const [files, setFiles] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deckInSessions, setDeckInSessions] = useState([]);

    const deckCantBeDeleted = !!deckInSessions[0];

    const determineEdit = () => {
        if (Boolean(deck.climer) == true) {
            return false;
        }

        if (deck.user_id == auth.id) {
            return true;
        }

        if (plan == "enterprise" || plan == "team" || plan == "education") {
            if (deck.organization_id === organization.id) {
                return true;
            }
        }

        return false;
    };

    const canEdit = determineEdit();

    const toggleEdit = () => {
        setEditing(!editing);
    };

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSwitch = key => event => {
        setValues({
            ...values,
            [key]: event.target.checked
        });
    };

    const handleDialogClick = key => {
        setOpen(key);
    };

    const handleRemove = cardID => {
        const currentCards = values.cards;
        remove(currentCards, { uuid: cardID });
        setValues({ ...values, currentCards });
    };

    const setFeatured = card => {
        setValues({
            ...values,
            featured_image: card.frontImage,
            featured_alt: card.alt_text
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new FormData();
        const cardIDs = values.cards.map(card => card.id);
        params.append("name", values.name);
        params.append("description", values.description);
        params.append("user_id", reactProps.auth.id);
        params.append("cards", cardIDs);
        params.append("climer", 0);
        params.append("featured_image", values.featured_image);
        params.append("allow_back", values.allow_back == true ? 1 : 0);
        params.append(
            "allow_random_sort",
            values.allow_random_sort == true ? 0 : 1
        );
        params.append("organization_share", values.share_deck == true ? 1 : 0);

        axios
            .post(`/api/v1/decks/${deck.uuid}/edit`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log(response);
                if (response.data.updated) {
                    setSuccessMessage("Deck successfully updated");
                    toggleEdit();
                } else {
                    setErrorMessage("Something went wrong. Please try again.");
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleDelete = () => {
        setLoading(true);
        axios
            .post(
                `/api/v1/decks/${deck.uuid}/delete`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then(response => {
                console.log(response);
                if (response.data.deleted) {
                    const message = encodeURIComponent(
                        "Deck successfully deleted."
                    );
                    window.location = `/decks?message=${message}`;
                } else {
                    setLoading(false);
                    setErrorMessage("Something went wrong. Please try again.");
                }
            })
            .catch(error => {
                console.log(error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const isSessionsHasThisDeck = () => {
        axios
            .get(`/api/v1/decks/${deck.uuid}/sessions`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(res => setDeckInSessions(res.data.data))
            .catch(error => {
                console.log(error);
                getErrorMessage(error.response);
            });
    };

    const handleMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function dragStartHandler(e, card) {
        setCurrentCard(card);
    }

    function dragEndHandler(e) {
        e.target.style.background = "#f5f9ff";
    }

    function dragOverHandler(e) {
        e.preventDefault();
        e.target.style.background = "lightgray";
    }

    function dropHandler(e, card) {
        e.preventDefault();

        //Getting index elements
        const currentIndex = values.cards.indexOf(currentCard);
        const dropIndex = values.cards.indexOf(card);

        // Change array
        values.cards.splice(currentIndex, 1); // replace and delete element
        values.cards.splice(dropIndex, 0, currentCard); // add deleted element

        // setting changes to state for rerender cards list
        setValues({
            ...values,
            cards: values.cards
        });
        e.target.style.background = "#f5f9ff";
    }

    useEffect(() => {
        isSessionsHasThisDeck();
    }, []);

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.root}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Decks
                    </Typography>
                </Grid>
                {canEdit && (
                    <Grid item xs={6} className={classes.floatRight}>
                        <Hidden smDown>
                            <Button
                                variant="outlined"
                                color="inherit"
                                className={classes.deleteButton}
                                onClick={() => setOpen("confirm_delete")}
                            >
                                Delete Deck
                            </Button>
                            {!editing ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={toggleEdit}
                                    >
                                        Edit Deck
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Save Changes
                                </Button>
                            )}
                        </Hidden>
                        <Hidden mdUp>
                            <IconButton
                                aria-controls="deck-options"
                                aria-haspopup="true"
                                onClick={handleMenuOpen}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="deck-options"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem
                                    onClick={() => setOpen("confirm_delete")}
                                >
                                    Delete Deck
                                </MenuItem>
                                {!editing ? (
                                    <MenuItem onClick={toggleEdit}>
                                        Edit Deck
                                    </MenuItem>
                                ) : (
                                    <MenuItem onClick={handleSubmit}>
                                        Save Changes
                                    </MenuItem>
                                )}
                            </Menu>
                        </Hidden>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Decks"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Link href="/decks">Decks</Link>
                        <Typography>{deck.name}</Typography>
                    </Breadcrumbs>
                    <Divider my={6} className={classes.divider} />
                </Grid>
                <Grid item container xs={12} spacing={3}>
                    {(Boolean(editing) ||
                        Boolean(deck.featuredImage) ||
                        Boolean(
                            Boolean(deck.cards.length > 0) &&
                                Boolean(head(deck.cards).frontImage)
                        )) && (
                        <Grid
                            item
                            md={3}
                            sm={4}
                            xs={12}
                            className={classes.featuredContainer}
                        >
                            {editing ? (
                                <div
                                    className={classes.imageContainer}
                                    style={{
                                        backgroundImage: `url(${values.featured_image})`
                                    }}
                                />
                            ) : (
                                <div
                                    className={classes.imageContainer}
                                    style={{
                                        backgroundImage: `url(${
                                            values
                                                ? values.featured_image
                                                : Boolean(deck.featuredImage)
                                                ? deck.featuredImage
                                                : deck.cards &&
                                                  deck.cards.length > 0 &&
                                                  head(deck.cards).frontImage
                                        })`
                                    }}
                                />
                            )}
                            {editing && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        handleDialogClick("image_select")
                                    }
                                    disabled={values.featured_image == ""}
                                >
                                    Update Featured Image
                                </Button>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={12} sm={8} md={6}>
                        <Paper className={classes.paper}>
                            <Typography variant="overline" component="h2">
                                Name
                            </Typography>
                            {editing ? (
                                <TextField
                                    required
                                    fullWidth
                                    label="Name"
                                    margin="normal"
                                    value={values.name}
                                    placeholder="My Custom Deck"
                                    onChange={handleChange("name")}
                                />
                            ) : (
                                <Typography
                                    variant="h3"
                                    component="p"
                                    gutterBottom
                                >
                                    {values.name}
                                </Typography>
                            )}
                            <Typography variant="overline" component="h2">
                                Description
                            </Typography>
                            {editing ? (
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    rows={3}
                                    margin="normal"
                                    label="Description"
                                    value={values.description}
                                    onChange={handleChange("description")}
                                />
                            ) : (
                                <Typography variant="body1" gutterBottom>
                                    {values.description}
                                </Typography>
                            )}
                            {editing ? (
                                <FormControlLabel
                                    label="Allow Back of Card"
                                    control={
                                        <Switch
                                            checked={values.allow_back}
                                            onChange={handleSwitch(
                                                "allow_back"
                                            )}
                                            color="primary"
                                        />
                                    }
                                />
                            ) : values.allow_back ? (
                                <Typography className={classes.switchText}>
                                    Back of Deck Enabled
                                </Typography>
                            ) : (
                                <Typography className={classes.switchText}>
                                    Back of Deck Not Enabled
                                </Typography>
                            )}
                            {editing && <br />}
                            {editing ? (
                                <FormControlLabel
                                    label="Set Sequential Card Order"
                                    control={
                                        <Switch
                                            checked={values.allow_random_sort}
                                            onChange={handleSwitch(
                                                "allow_random_sort"
                                            )}
                                            color="primary"
                                        />
                                    }
                                />
                            ) : values.allow_random_sort ? (
                                <Typography className={classes.switchText}>
                                    Sequential Card Order Enabled
                                </Typography>
                            ) : (
                                <Typography className={classes.switchText}>
                                    Sequential Card Order Not Enabled
                                </Typography>
                            )}
                            {editing && <br />}
                            {editing ? (
                                <FormControlLabel
                                    label="Share deck with my organization"
                                    control={
                                        <Switch
                                            checked={values.share_deck}
                                            onChange={handleSwitch(
                                                "share_deck"
                                            )}
                                            color="primary"
                                        />
                                    }
                                />
                            ) : values.share_deck ? (
                                <Typography className={classes.switchText}>
                                    Shared Deck
                                </Typography>
                            ) : (
                                <>
                                    {Boolean(deck.climer) ? (
                                        <Typography
                                            className={classes.switchText}
                                        >
                                            Climer Deck
                                        </Typography>
                                    ) : (
                                        <Typography
                                            className={classes.switchText}
                                        >
                                            Private Deck
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    spacing={3}
                    style={{ marginTop: 16 }}
                >
                    <Grid item xs={editing ? 6 : 12}>
                        <Typography variant="h3">Cards</Typography>
                    </Grid>
                    {editing && (
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => handleDialogClick("card_select")}
                            >
                                Add Cards
                            </Button>
                        </Grid>
                    )}
                    {values.cards.map(card => (
                        <Card
                            key={card.id}
                            card={card}
                            editing={editing}
                            handleRemove={handleRemove}
                            dragStartHandler={dragStartHandler}
                            dragEndHandler={dragEndHandler}
                            dragOverHandler={dragOverHandler}
                            dropHandler={dropHandler}
                        />
                    ))}
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                maxWidth="md"
                open={Boolean(open)}
                onClose={() => setOpen(false)}
            >
                {open == "card_select" && (
                    <CardSelect
                        cards={cards}
                        values={values}
                        setOpen={setOpen}
                        setCards={setCards}
                        setValues={setValues}
                        handleChange={handleChange}
                        deck={deck}
                    />
                )}
                {open == "image_select" && (
                    <FeaturedSelect
                        setOpen={setOpen}
                        cards={values.cards}
                        setFeatured={setFeatured}
                        setFiles={setFiles}
                        files={files}
                        values={values}
                        setValues={setValues}
                        setLoading={setLoading}
                        getErrorMessage={getErrorMessage}
                    />
                )}
                {open == "confirm_delete" &&
                    (deckCantBeDeleted ? (
                        <>
                            <DialogContent>
                                <Typography variant="body1">
                                    This deck is being used in one or more
                                    sessions and cannot be deleted. In order to
                                    delete the deck please remove it from the
                                    following:
                                    {deckInSessions.map((item, index) => (
                                        <strong key={index}>
                                            &nbsp;{item.name},
                                        </strong>
                                    ))}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpen(false)}
                                >
                                    Ok
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <>
                            <DialogTitle>Delete Deck?</DialogTitle>
                            <DialogContent>
                                <Typography variant="body1">
                                    Are you sure you want to delete this deck?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </>
                    ))}
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            {successMessage && successMessage != "" && (
                <MessageSnackbar variant="success" message={successMessage} />
            )}
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("facilitators-decks-edit")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Edit />
        </ErrorBoundary>,
        document.getElementById("facilitators-decks-edit")
    );
}
