import React, { useState, useEffect } from "react";
import {
    Grid,
    TextField,
    Paper,
    Button,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { head, forEach, filter } from "lodash";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1)
        }
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    },
    image: {
        height: 100,
        width: "auto"
    },
    center: {
        alignContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        width: "20%"
    }
}));

const Form = props => {
    const classes = useStyles();
    const { decks, users, organizations } = reactProps;
    const { handleSubmit, session } = props;
    const [values, setValues] = useState({
        name: session ? session.name : "",
        slug: session ? session.slug : "",
        description: session ? session.description : "",
        deck_id: session ? session.deck_id : head(decks).id,
        status: session ? session.status : "",
        multiple_decks: session ? Boolean(session.multiple_decks) : true,
        owner: null
    });
    const [ownerOptions, setOwnerOptions] = useState(null);

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const buildOwnerOptions = () => {
        let newOptions = [];

        forEach(users, function(user) {
            newOptions.push({ type: "user", id: user.id, name: user.name });
        });

        forEach(organizations, function(organization) {
            newOptions.push({
                type: "organization",
                id: organization.id,
                name: organization.name
            });
        });

        setOwnerOptions(newOptions);
    };

    const getOptionValue = option => {
        let node;
        if (option.type == "organization") {
            node = (
                <option value={JSON.stringify(option)}>
                    {`${option.name} (Organization)`}
                </option>
            );
        } else {
            node = (
                <option value={JSON.stringify(option)}>
                    {`${option.name} (User)`}
                </option>
            );
        }

        return node;
    };

    const getOptionLabel = option => {
        let label;
        if (option.type == "organization") {
            label = `${option.name} (Organization)`;
        } else {
            label = `${option.name} (User)`;
        }

        return label;
    };

    const handleOwnerChange = (event, value) => {
        setValues({
            ...values,
            owner: value
        });
    };

    const handleSwitch = key => event => {
        setValues({
            ...values,
            [key]: event.target.checked
        });
    };

    useEffect(() => {
        let newOwner;

        if (session) {
            newOwner = {
                type: session.organization_id ? "organization" : "user",
                id: session.organization_id
                    ? session.organization_id
                    : session.user_id,
                name: session.organization_id
                    ? session.organization.name
                    : session.user.name
            };

            setValues({
                ...values,
                owner: newOwner
            });
        }

        buildOwnerOptions();
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.padded}>
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Session Name"
                        value={values.name}
                        onChange={handleChange("name")}
                    />
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Slug"
                        value={values.slug}
                        onChange={handleChange("slug")}
                    />
                    <TextField
                        rows={3}
                        multiline
                        fullWidth
                        margin="normal"
                        label="Description"
                        value={values.description}
                        onChange={handleChange("description")}
                    />
                    {decks && decks.length > 0 && (
                        <FormControlLabel
                            label="Use multiple decks in this session"
                            value={values.multiple_decks}
                            onChange={handleSwitch("multiple_decks")}
                            control={
                                <Switch
                                    color="primary"
                                    checked={values.multiple_decks}
                                />
                            }
                            style={{ display: "block", marginTop: "1rem" }}
                            disabled={session ? true : false}
                        />
                    )}
                    {values.multiple_decks !== true && (
                        <TextField
                            select
                            required
                            fullWidth
                            label="Deck"
                            margin="normal"
                            SelectProps={{
                                native: true
                            }}
                            value={values.deck_id}
                            onChange={handleChange("deck_id")}
                        >
                            {filter(decks, function(deck) {
                                return Boolean(deck.climer);
                            }).map(deck => (
                                <option value={deck.id} key={deck.id}>
                                    {deck.name}
                                </option>
                            ))}
                            {filter(decks, function(deck) {
                                return !Boolean(deck.climer);
                            }).map(deck => (
                                <option value={deck.id} key={deck.id}>
                                    {deck.name}
                                </option>
                            ))}
                        </TextField>
                    )}
                    <TextField
                        select
                        required
                        fullWidth
                        label="Status"
                        margin="normal"
                        SelectProps={{
                            native: true
                        }}
                        value={values.status}
                        onChange={handleChange("status")}
                    >
                        <option value="upcoming">Upcoming</option>
                        <option value="active">Active</option>
                    </TextField>
                    {ownerOptions && (
                        <Autocomplete
                            id="owner-autocomplete"
                            options={ownerOptions}
                            getOptionLabel={option => getOptionLabel(option)}
                            renderOption={option => getOptionValue(option)}
                            noOptionsText="No potential owners found"
                            onChange={(event, value) =>
                                handleOwnerChange(event, value)
                            }
                            value={values.owner}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    required
                                    fullWidth
                                    margin="normal"
                                    label="Session Owner"
                                />
                            )}
                        />
                    )}
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleSubmit(values)}
                                className={clsx(
                                    classes.floatRight,
                                    classes.marginTop
                                )}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Form;
