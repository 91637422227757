import React, { useState } from "react";
import {
    Grid,
    Paper,
    Table,
    Chip,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    IconButton,
    InputAdornment,
    Button,
    Typography
} from "@material-ui/core";
import { Search } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { head } from "lodash";
import LoadingBackdrop from "@global/LoadingBackdrop";
import { upperCaseFirst } from "@utils/stringUtils";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    floatRight: {
        textAlign: "right",
        padding: theme.spacing(1),
        paddingRight: theme.spacing(2)
    },
    statusTag: {
        color: "#fff",
        padding: "4px 6px"
    }
}));

const UsersTable = () => {
    const { userToken, auth } = reactProps;
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(reactProps.users);
    const [usersCount, setUsersCount] = useState(reactProps.usersCount);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [searchQuery, setSearchQuery] = useState("");
    const [text, setText] = useState("");
    const classes = useStyles();

    const handleSearchTextChange = event => {
        setText(event.target.value);
        event.persist();
    };

    const handleSearchKeyUp = event => {
        if (event.keyCode === 13 && event.target.value.length > 0) {
            handleSearchSubmit(event.target.value);
        }
        event.persist();
    };

    const clearSearch = () => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/users?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=&token=${userToken}`
            )
            .then(response => {
                setSearchQuery("");
                setUsers(response.data.users);
                setUsersCount(reactProps.usersCount);
                setPage(0);
                setLoading(false);
                setText("");
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSearchSubmit = searchText => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/users?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=${searchText}&token=${userToken}`
            )
            .then(response => {
                setSearchQuery(searchText);
                setUsers(response.data.users);
                if (
                    response.data.usersCount &&
                    response.data.usersCount != usersCount
                ) {
                    setUsersCount(response.data.usersCount);
                }
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/users?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=${rowsPerPage *
                    newPage}&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setUsers(response.data.users);
                setPage(newPage);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangeRowsPerPage = event => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/users?sortBy=${orderBy}&sortDirection=${order}&take=${parseInt(
                    event.target.value,
                    10
                )}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setUsers(response.data.users);
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSort = property => event => {
        console.log("handleSort");
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        setLoading(true);
        let isAsc = orderBy == property && order == "asc";

        axios
            .get(
                `/api/v1/admin/users?sortBy=${property}&sortDirection=${
                    !isAsc ? "asc" : "desc"
                }&take=${rowsPerPage}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setUsers(response.data.users);
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                setPage(0);
                setLoading(false);
                if (Boolean(response.data.usersCount)) {
                    setUsersCount(response.data.usersCount);
                }
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    return (
        <div>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.padded}>
                            <TextField
                                fullWidth
                                label="Search"
                                margin="normal"
                                value={text}
                                onKeyUp={handleSearchKeyUp}
                                onChange={handleSearchTextChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    handleSearchSubmit(text)
                                                }
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {searchQuery && searchQuery != "" && (
                                <Chip
                                    label={`Search: ${searchQuery}`}
                                    onDelete={clearSearch}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        aria-labelledby="users-table"
                        size="medium"
                        aria-label="users table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "name" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "name"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("name")}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "email" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "email"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("email")}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="left" padding="default">
                                    Role
                                </TableCell>
                                <TableCell align="left" padding="default">
                                    <TableSortLabel
                                        active={orderBy == "organization"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("organization")}
                                    >
                                        Organization
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell padding="default">
                                    Subscription
                                </TableCell>
                                <TableCell padding="default">Status</TableCell>
                                <TableCell align="right" padding="default">
                                    {" "}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user => (
                                <TableRow key={user.id} hover>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        {head(user.roles).label}
                                    </TableCell>
                                    <TableCell>
                                        {user.organization
                                            ? user.organization.name
                                            : "n/a"}
                                    </TableCell>
                                    <TableCell>
                                        {user.subscriptions &&
                                        user.subscriptions.length > 0 ? (
                                            <Typography>
                                                {head(user.subscriptions).name}
                                            </Typography>
                                        ) : (
                                            "n/a"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {user.subscriptions &&
                                        user.subscriptions.length > 0 ? (
                                            <span
                                                className={classes.statusTag}
                                                style={{
                                                    backgroundColor:
                                                        head(user.subscriptions)
                                                            .stripe_status ==
                                                        "active"
                                                            ? "#4786AA"
                                                            : head(
                                                                  user.subscriptions
                                                              ).stripe_status ==
                                                              "trialing"
                                                            ? "#f7d00f"
                                                            : "#cd420f"
                                                }}
                                            >
                                                {upperCaseFirst(
                                                    head(user.subscriptions)
                                                        .stripe_status
                                                )}
                                            </span>
                                        ) : (
                                            "n/a"
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            component="a"
                                            href={`/admin/users/${user.uuid}/edit`}
                                            disabled={auth.id == user.id}
                                        >
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={usersCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {loading && <LoadingBackdrop open={loading} />}
        </div>
    );
};

export default UsersTable;
