import React, { useState, useEffect } from "react";
import { Grid, TextField, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { head, find } from "lodash";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    floatRight: {
        textAlign: "right"
    }
}));

const EditForm = props => {
    const { handleSubmit } = props;
    const classes = useStyles();
    const { user, roles, organizations } = reactProps;
    const facilitatorRoleId = find(roles, function(role) {
        return role.name == "facilitator";
    })
        ? find(roles, function(role) {
              return role.name == "facilitator";
          }).id
        : null;
    const [values, setValues] = useState({
        name: user.name,
        email: user.email,
        organization_id: user.organization_id ? user.organization_id : ""
    });

    const organizationDisabled =
        head(user.roles).id != facilitatorRoleId ||
        user.organization_id == null ||
        (user.organization_id && user.organization.user_id == user.id);

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    return (
        <Paper className={classes.padded}>
            <TextField
                required
                fullWidth
                label="Name"
                margin="normal"
                value={values.name}
                onChange={handleChange("name")}
            />
            <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                value={values.email}
                onChange={handleChange("email")}
            />
            <TextField
                select
                fullWidth
                SelectProps={{
                    native: true
                }}
                margin="normal"
                label="Organization"
                value={values.organization_id}
                InputLabelProps={{
                    shrink: true
                }}
                onChange={handleChange("organization_id")}
                required={!organizationDisabled}
                disabled={organizationDisabled}
                helperText={
                    head(user.roles).id == facilitatorRoleId
                        ? "To remove this user from this organization, you will need to enroll them use in a Basic, Plus, or Professional plan."
                        : ""
                }
            >
                <option value="">None Selected</option>
                {organizations.map(organization => (
                    <option key={organization.id} value={organization.id}>
                        {organization.name}
                    </option>
                ))}
            </TextField>
            <div className={classes.floatRight}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(values)}
                >
                    Save
                </Button>
            </div>
        </Paper>
    );
};

export default EditForm;
