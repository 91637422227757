import React from "react";
import { Paper, Typography, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    flexBetween: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    percent: {
        fontSize: 13,
        fontWeight: "bold"
    },
    progress: {
        marginBottom: theme.spacing(2)
    }
}));

const FavoriteDecks = () => {
    const classes = useStyles();
    const { ogClimerCount, peopleAndTechCount, otherCount } = reactProps;
    const total = ogClimerCount + peopleAndTechCount + otherCount;

    const getPercentage = (total, part) => {
        return Math.round((part / total) * 100);
    };

    React.useEffect(() => {
        console.log("total", total);
        console.log("ogClimerCount", ogClimerCount);
        console.log("peopleAndTechCount", peopleAndTechCount);
        console.log("otherCount", otherCount);
    }, []);

    return (
        <Paper className={classes.root} elevation={1}>
            <Typography variant="h4" gutterBottom>
                Favorite Decks
            </Typography>
            <div className={classes.flexBetween}>
                <Typography variant="body1">Climer Cards: Originals</Typography>
                <Typography className={classes.percent}>
                    {total != 0 ? getPercentage(total, ogClimerCount) : 0}%
                </Typography>
            </div>
            <LinearProgress
                className={classes.progress}
                color="primary"
                variant="determinate"
                value={total != 0 ? getPercentage(total, ogClimerCount) : 0}
            />
            <div className={classes.flexBetween}>
                <Typography variant="body1">
                    Climer Cards: People + Tech
                </Typography>
                <Typography className={classes.percent}>
                    {total != 0 ? getPercentage(total, peopleAndTechCount) : 0}%
                </Typography>
            </div>
            <LinearProgress
                className={classes.progress}
                color="secondary"
                variant="determinate"
                value={
                    total != 0 ? getPercentage(total, peopleAndTechCount) : 0
                }
            />
            <div className={classes.flexBetween}>
                <Typography variant="body1">Other Decks</Typography>
                <Typography className={classes.percent}>
                    {total != 0 ? getPercentage(total, otherCount) : 0}%
                </Typography>
            </div>
            <LinearProgress
                className={classes.progress}
                variant="determinate"
                value={total != 0 ? getPercentage(total, otherCount) : 0}
            />
        </Paper>
    );
};

export default React.memo(FavoriteDecks);
