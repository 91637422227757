import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    ButtonGroup,
    Typography,
    TextField,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useIsTyping } from "use-is-typing";
import { X } from "react-feather";
import TermsOfUse from "./TermsOfUseContent";
import PrivacyPolicy from "./PrivacyPolicyContent";

const useStyles = makeStyles(() => ({
    floatRight: {
        float: "right"
    },
    cardContainer: {
        borderBottom: "1px solid #666",
        marginBottom: 15,
        marginTop: 15,
        paddingBottom: 5
    },
    pointer: {
        cursor: "pointer"
    },
    strikeThrough: {
        textDecoration: "line-through"
    },
    flexBetween: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    }
}));

const PaymentStep = props => {
    const {
        values,
        setValues,
        handlePrevious,
        getErrorMessage,
        setErrorMessage,
        selectedPlan,
        selectedPrice,
        setLoading,
        getStripePromise
    } = props;
    const classes = useStyles();
    const { type, plan } = reactProps;
    const stripe = useStripe();
    const elements = useElements();
    const [submitted, setSubmitted] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [searchingCoupons, setSearchingCoupons] = useState(false);
    const [isTyping, couponInput] = useIsTyping();
    const [open, setOpen] = useState(false);

    const clearSelectedCode = () => {
        setSelectedCoupon(null);
        setCouponCode("");
    };

    const handleChange = event => {
        setCouponCode(event.target.value);
    };

    const attemptHandleSubmit = async () => {
        setLoading(true);
        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (payload.error) {
            setErrorMessage(payload.error.message);
            setLoading(false);
        } else {
            // console.log("paymentMethod", payload.paymentMethod.id);
            setValues({
                ...values,
                paymentMethod: payload.paymentMethod.id
            });
        }
    };

    const handleSubmit = () => {
        setSubmitted(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("email", values.email);
        params.append("password", values.password);
        params.append("password_confirmation", values.password_confirmation);
        params.append("type", type);
        params.append("plan", plan);
        params.append("paymentMethod", values.paymentMethod);
        params.append("planName", selectedPlan.name);
        params.append("planId", selectedPrice.id);

        if (Boolean(selectedCoupon)) {
            params.append("couponCode", selectedCoupon.promotion_code);
        }

        if (plan == "team" || plan == "enterprise" || plan == "education") {
            params.append("organization_name", values.organization_name);
            params.append("organization_url", values.organization_url);
            params.append(
                "organization_description",
                values.organization_description
            );
        }
        let affCookie = getAffCookie();
        if (affCookie) {
            params.append("affiliatly_cookie", affCookie);
        }

        axios
            .post(`/api/v1/registration/submit`, params)
            .then(response => {
                // console.log("response", response);
                let message = encodeURIComponent("Account Created");
                window.location = `/?message=${message}&token=${response.data.token}`;
            })
            .catch(error => {
                // console.log("error", error);
                getErrorMessage(error.response);
                setValues({
                    ...values,
                    paymentMethod: null
                });
                getStripePromise();
                setLoading(false);
                setSubmitted(false);
            });
    };

    const cardOptions = {
        iconStyle: "solid",
        style: {
            base: {
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "18px"
            }
        }
    };

    const fetchCouponDetails = () => {
        setSearchingCoupons(true);

        let params = new URLSearchParams();
        params.append("promotion_code", couponCode);

        axios
            .get(`/api/v1/coupons`, {
                params: params
            })
            .then(response => {
                // console.log("response", response);
                setSelectedCoupon(response.data.coupon);
                setSearchingCoupons(false);
            })
            .catch(error => {
                // console.log("error", error);
                setSearchingCoupons(false);
            });
    };

    const getCouponDescription = () => {
        let description = "";
        if (Boolean(selectedCoupon.details.amount_off)) {
            description = `$${parseFloat(
                selectedCoupon.details.amount_off / 100
            ).toFixed(2)} off `;
        }

        if (Boolean(selectedCoupon.details.percent_off)) {
            description = `${selectedCoupon.details.percent_off}% off `;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "once"
        ) {
            description = `${description} your first ${
                type == "monthly" ? "month" : "year"
            }`;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "forever"
        ) {
            description = `${description} as long as your subscription is active`;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "repeating" &&
            Boolean(selectedCoupon.details.duration_in_months)
        ) {
            description = `${description} for your first ${selectedCoupon.details.duration_in_months} months`;
        }

        return description;
    };

    const getCostDecription = () => {
        // console.log("selectedCoupon", selectedCoupon);
        // console.log("selectedPrice", selectedPrice);
        let discounted_amount;

        if (Boolean(selectedCoupon.details.percent_off)) {
            discounted_amount =
                selectedPrice.unit_amount / 100 -
                (selectedPrice.unit_amount / 100) *
                    (selectedCoupon.details.percent_off / 100);
        }

        if (Boolean(selectedCoupon.details.amount_off)) {
            discounted_amount =
                selectedPrice.unit_amount / 100 -
                selectedCoupon.details.amount_off / 100;
        }

        return (
            <span>
                Pay{" "}
                <span className={classes.strikeThrough}>
                    ${parseFloat(selectedPrice.unit_amount / 100).toFixed(2)}
                </span>{" "}
                ${parseFloat(discounted_amount).toFixed(2)} in 30 days.
            </span>
        );
    };

    useEffect(() => {
        if (values.paymentMethod && !submitted) {
            handleSubmit();
        }
    });

    useEffect(() => {
        if (
            (!Boolean(selectedCoupon) &&
                !searchingCoupons &&
                couponCode.length >= 3 &&
                !isTyping) ||
            (Boolean(selectedCoupon) &&
                selectedCoupon.promotion_code != couponCode)
        ) {
            fetchCouponDetails();
        }
    }, [isTyping]);

    const handleOpen = open => event => {
        event.preventDefault();
        setOpen(open);
    };

    const handleClose = () => setOpen(false);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    variant="h4"
                    component="h2"
                    align="center"
                    gutterBottom
                >
                    Enter payment information
                </Typography>
                <Typography
                    variant="body2"
                    component="p"
                    align="center"
                    gutterBottom
                >
                    You won't be charged until your trial is complete and you
                    can cancel at any time.
                </Typography>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <CardElement
                            options={cardOptions}
                            className={classes.cardContainer}
                        />
                    </Grid>
                    {Boolean(selectedCoupon) ? (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="caption">
                                        Coupon Applied
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.flexBetween}
                                >
                                    <Typography>
                                        {selectedCoupon.promotion_code}
                                    </Typography>
                                    <Tooltip title="Clear selected coupon code">
                                        <div className={classes.pointer}>
                                            <X onClick={clearSelectedCode} />
                                        </div>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Typography gutterBottom variant="caption">
                                {getCouponDescription()}
                            </Typography>
                            <br />
                            <Typography gutterBottom variant="caption">
                                {getCostDecription()}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                margin="normal"
                                ref={couponInput}
                                value={couponCode}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                label="Have a coupon code?"
                            />
                            {!searchingCoupons &&
                                couponCode.length > 3 &&
                                !isTyping && (
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        variant="caption"
                                    >
                                        That coupon code doesn't match any
                                        active promotions
                                    </Typography>
                                )}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControlLabel
                            label={
                                <span>
                                    Yes, I've read the{" "}
                                    <a href="#" onClick={handleOpen("terms")}>
                                        terms
                                    </a>{" "}
                                    and{" "}
                                    <a href="#" onClick={handleOpen("privacy")}>
                                        privacy policy
                                    </a>
                                </span>
                            }
                            onChange={event =>
                                setValues({
                                    ...values,
                                    agree_to_terms: event.target.checked
                                })
                            }
                            control={
                                <Checkbox
                                    color="primary"
                                    value={values.agree_to_terms}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                <div className={classes.floatRight}>
                    <ButtonGroup>
                        <Button color="primary" onClick={handlePrevious}>
                            Back
                        </Button>
                        <Button
                            color="primary"
                            disabled={!stripe || !values.agree_to_terms}
                            variant="contained"
                            onClick={attemptHandleSubmit}
                        >
                            Submit
                        </Button>
                    </ButtonGroup>
                </div>
            </Grid>
            <Dialog
                fullWidth
                maxWidth="md"
                open={Boolean(open)}
                onClose={handleClose}
                aria-labelledby={`${
                    open == "terms"
                        ? "terms-of-use-title"
                        : "privacy-policy-title"
                }`}
                aria-describedby={`${
                    open == "terms" ? "terms-of-user" : "privacy-policy"
                }`}
            >
                <DialogTitle
                    id={`${
                        open == "terms"
                            ? "terms-of-use-title"
                            : "privacy-policy-title"
                    }`}
                >
                    {open == "terms" ? "Terms Of Use" : "Privacy Policy"}
                </DialogTitle>
                <DialogContent
                    id={`${
                        open == "terms" ? "terms-of-user" : "privacy-policy"
                    }`}
                >
                    {open == "terms" ? <TermsOfUse /> : <PrivacyPolicy />}
                </DialogContent>
            </Dialog>
        </Grid>
    );

    function getAffCookie() {
        const allCookies = document.cookie.split(";");
        let aff = allCookies.find(cookie => cookie.includes("affiliatly_v3"));
        if (aff) {
            // console.log("aff cookie: ", aff);
            aff = aff.trim();
            aff = aff.substring(aff.indexOf("=") + 1);
            // console.log("aff cookie params: ", aff);
            return aff;
        }
        return false;
    }
};

export default PaymentStep;
