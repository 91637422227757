import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    tallRoot: {
        alignItems: "center",
        backgroundColor: "#4786AA1A",
        border: "2px dashed #05597B",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        height: 333,
        justifyContent: "center",
        margin: "0 auto",
        padding: theme.spacing(3),
        width: "100%"
    },
    wideRoot: {
        alignItems: "center",
        backgroundColor: "#4786AA1A",
        border: "2px dashed #05597B",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        height: 172,
        justifyContent: "center",
        margin: "0 auto",
        padding: theme.spacing(3),
        width: "100%"
    },
    marginTop: {
        marginTop: theme.spacing(2)
    }
}));

const CardUploadArea = props => {
    const classes = useStyles();
    const { orientation, title, handleOpen, side } = props;

    return (
        <div
            className={
                orientation == "tall" ? classes.tallRoot : classes.wideRoot
            }
        >
            <Typography variant="h3" align="center" color="primary">
                {title}
            </Typography>
            <Button
                color="primary"
                variant="outlined"
                className={classes.marginTop}
                onClick={() => handleOpen(side)}
            >
                Add Image
            </Button>
        </div>
    );
};

export default CardUploadArea;
