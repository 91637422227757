import React from "react";
import ReactDOM from "react-dom";
require("../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        minHeight: "80vh"
    }
}));

const NoPayment = () => {
    const classes = useStyles();

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Container maxWidth="md" className={classes.root}>
                <div>
                    <Typography variant="h1" align="center">
                        Contact your organization owner to update your
                        organization's billing information to continue using
                        this service.
                    </Typography>
                </div>
            </Container>
        </DashboardLayout>
    );
};

if (document.getElementById("no-payment-method")) {
    ReactDOM.render(
        <ErrorBoundary>
            <NoPayment />
        </ErrorBoundary>,
        document.getElementById("no-payment-method")
    );
}
