import React, { useRef } from "react";
import ReactDOM from "react-dom";
require("../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import General from "./layouts/General";
import {
    Typography,
    Grid,
    Paper,
    Container,
    Hidden,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import PricingTable from "@global/PricingTable";
import PricingCardsView from "@global/PricingCardsView";
import PlanCard from "@global/PlanCard";
import plans from "@utils/plans";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {},
    heading: {
        fontSize: 46,
        lineHeight: "58px",
        letterSpacing: "0.16px",
        marginBottom: theme.spacing(2),
        marginTop: 32,
        [theme.breakpoints.down("sm")]: {
            marginTop: 26
        }
    },
    subHead: {
        fontSize: 32,
        lineHeight: "45px",
        letterSpacing: "0.11px",
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(2)
        }
    },
    videoContainer: {
        position: "relative",
        paddingTop: 25,
        margin: "0 auto 5rem"
    }
}));

const Pricing = () => {
    const classes = useStyles();
    const { activation } = reactProps;
    const tableRef = useRef(null);

    const toggleShowTable = () => {
        tableRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <General
            maxWidth={false}
            activation={Boolean(activation)}
            style={{
                background: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
            accessInput={
                window.location.href.substring(
                    window.location.href.lastIndexOf("/") + 1
                ) !== "re-activate"
            }
        >
            <Container maxWidth="lg">
                {activation ? (
                    <Paper style={{ padding: "1rem", marginBottom: "1rem" }}>
                        <Typography gutterBottom align="center">
                            Select the plan you would like to re-activate your
                            account with.
                        </Typography>
                    </Paper>
                ) : (
                    <div style={{ padding: "1rem", marginBottom: "1rem" }}>
                        <Typography
                            className={classes.heading}
                            align="center"
                            color="primary"
                            variant="h1"
                        >
                            Select Your Plan
                        </Typography>
                        <Typography
                            className={classes.subHead}
                            align="center"
                            color="primary"
                            variant="h3"
                            component="p"
                        >
                            Select the best plan for your needs.
                            <br /> All plans include a 30-day free trial.
                        </Typography>
                    </div>
                )}
                <PricingCardsView toggleShowTable={toggleShowTable} />
                <Hidden smDown>
                    <Paper
                        elevation={10}
                        ref={tableRef}
                        style={{ margin: "150px auto" }}
                    >
                        <PricingTable />
                    </Paper>
                </Hidden>
                <Hidden mdUp>
                    <Grid container spacing={2} ref={tableRef}>
                        {plans.map(plan => (
                            <Grid item xs={12} sm={6} key={plan.name}>
                                <PlanCard active={false} plan={plan} expanded />
                            </Grid>
                        ))}
                    </Grid>
                </Hidden>
                <Container maxWidth="md">
                    <Typography
                        className={classes.heading}
                        align="center"
                        color="primary"
                        variant="h1"
                        component="h2"
                    >
                        Not Sure Which Plan?
                    </Typography>
                    <Typography
                        align="center"
                        color="primary"
                        variant="h3"
                        component="p"
                    >
                        Check out this video to see the different features and
                        pick the best plan for you.
                    </Typography>
                    <div className={classes.videoContainer} align="center">
                        <iframe
                            width="100%"
                            height={`${isMobile ? 350 : 450}`}
                            src="https://www.youtube.com/embed/MfxdTUHIpuI"
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                </Container>
                <Container maxWidth="md">
                    <Typography
                        className={classes.heading}
                        align="center"
                        color="primary"
                        variant="h1"
                        component="h2"
                    >
                        What is a Session?
                    </Typography>
                    <Typography
                        align="center"
                        color="primary"
                        variant="h3"
                        component="p"
                    >
                        Watch this video to learn the difference between an
                        Active, Draft, and Archived Session.
                    </Typography>
                    <div className={classes.videoContainer} align="center">
                        <iframe
                            width="100%"
                            height={`${isMobile ? 350 : 450}`}
                            src="https://www.youtube.com/embed/sK4KblBXhdo"
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                </Container>
                <Container maxWidth="md" id="how-to-use">
                    <Typography
                        className={classes.heading}
                        align="center"
                        color="primary"
                        variant="h1"
                        component="h2"
                    >
                        Learn How to Use Virtual Climer Cards
                    </Typography>
                    <Typography
                        align="center"
                        color="primary"
                        variant="h3"
                        component="p"
                    >
                        Watch this video to get started with your first session.
                    </Typography>
                    <div className={classes.videoContainer} align="center">
                        <iframe
                            width="100%"
                            height={`${isMobile ? 350 : 450}`}
                            src="https://www.youtube.com/embed/IBAirPfbFg0"
                            frameBorder="0"
                            allowFullScreen
                        />
                    </div>
                </Container>
            </Container>
        </General>
    );
};

if (document.getElementById("pricing")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Pricing />
        </ErrorBoundary>,
        document.getElementById("pricing")
    );
}
