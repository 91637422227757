import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import DashboardLayout from "@layouts/DashboardLayout";
import AdminNav from "./components/AdminNav";
import ErrorBoundary from "@global/ErrorBoundary";
import { Grid, Divider, Typography, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TotalDecks from "./components/TotalDecks";
import TotalUsers from "./components/TotalUsers";
import TotalSessions from "./components/TotalSessions";
import Revenue from "./components/Revenue";
import FavoriteDecks from "./components/FavoriteDecks";
import PopularPlans from "./components/PopularPlans";
import TopFacilitators from "./components/TopFacilitators";
import NewUsers from "./components/NewUsers";

const useStyles = makeStyles(theme => ({
    container: {
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            margin: `${theme.spacing(1)}px auto`
        }
    },
    floatRight: {
        float: "right"
    },
    marginY: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    statsGrid: {
        [theme.breakpoints.down("sm")]: {
            margin: 0
        }
    }
}));

const Dashboard = () => {
    const classes = useStyles();
    const { auth } = reactProps;

    return (
        <DashboardLayout sideNav={<AdminNav />}>
            <Container maxWidth="lg">
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom display="inline">
                            Hello {auth.name}
                        </Typography>
                        <Divider className={classes.marginY} />
                    </Grid>
                    <Grid
                        item
                        container
                        spacing={4}
                        xs={12}
                        className={classes.statsGrid}
                    >
                        <Grid item xs={12} sm={6} lg={3}>
                            <TotalDecks />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TotalUsers />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TotalSessions />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Revenue />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FavoriteDecks />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PopularPlans />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TopFacilitators />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NewUsers />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </DashboardLayout>
    );
};

if (document.getElementById("admin-dashboard")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Dashboard />
        </ErrorBoundary>,
        document.getElementById("admin-dashboard")
    );
}
