import React, { useState } from "react";
import Page from "@global/Page";
import Sidebar from "@global/Sidebar";
import DashboardHeader from "@global/DashboardHeader";
import DashboardFooter from "@global/DashboardFooter";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        flex: "1",
        marginTop: 64,
        marginLeft: 260,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 56
        }
    }
}));

const DashboardLayout = props => {
    const classes = useStyles();
    const { sideNav, children, contentStyles } = props;
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Page>
            <DashboardHeader toggleDrawer={toggleDrawer} />
            <Sidebar open={open} toggleDrawer={toggleDrawer}>
                {sideNav}
            </Sidebar>
            <div className={clsx(classes.content, contentStyles)}>
                {children}
            </div>
            <DashboardFooter />
        </Page>
    );
};

DashboardLayout.propTypes = {
    sideNav: PropTypes.node.isRequired
};

export default DashboardLayout;
