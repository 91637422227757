import React, { useState } from "react";
import {
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem
} from "@material-ui/core";

const EditPromptModal = props => {
    const {
        prompt,
        setPrompts,
        setLoading,
        handleCancel,
        getErrorMessage
    } = props;
    const { userToken } = reactProps;
    const [description, setDescription] = useState(
        prompt && prompt.description ? prompt.description : ""
    );

    console.log(prompt);

    const [headChange, setHeadChange] = useState(
        prompt && prompt.prefix ? prompt.prefix : 0
    );

    const handleChange = event => {
        setDescription(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("description", description);
        params.append("prefix", headChange);

        axios
            .post(`/api/v1/prompts/${prompt.uuid}/edit`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                setPrompts(response.data.prompts);
                setLoading(false);
                handleCancel();
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <>
            <DialogContent>
                <TextField
                    select
                    label={"Prefix"}
                    value={headChange}
                    onChange={e => setHeadChange(e.target.value)}
                    SelectProps={{
                        MenuProps: { disablePortal: true }
                    }}
                    fullWidth
                >
                    <MenuItem value={1}>
                        Select a card that represents...
                    </MenuItem>
                    <MenuItem value={0}>-- None --</MenuItem>
                </TextField>
                <TextField
                    rows={3}
                    required
                    autoFocus
                    multiline
                    fullWidth
                    label="Prompt"
                    margin="normal"
                    value={description}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={description.length == 0}
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    );
};

export default EditPromptModal;
