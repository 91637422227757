import React from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PromptStarter from "./PromptStarter";
import PromptManager from "./PromptManager";
import PromptDisplay from "./PromptDisplay";

const useStyles = makeStyles(theme => ({
    root: {},
    padded: {
        padding: theme.spacing(2)
    },
    promptCard: {
        margin: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    }
}));

const PromptController = props => {
    const { session, userToken } = reactProps;
    const {
        flipped,
        dropdown,
        prompts,
        setPrompts,
        currentPrompt,
        setCurrentPrompt,
        handleNext,
        handlePrevious,
        toggleDropdown
    } = props;
    const classes = useStyles();

    const flipCards = () => {
        axios
            .post(`/api/v1/flip-session-cards/${session.uuid}`, null, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {})
            .catch(error => {
                console.log("error", error);
            });
    };

    return (
        <Grid container spacing={2} className={classes.root}>
            {Boolean(session.allow_facilitator_flip) && (
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: "right", paddingRight: 24 }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={flipCards}
                    >
                        {flipped == "front"
                            ? "Flip all to Back"
                            : "Flip all to Front"}
                    </Button>
                </Grid>
            )}
            {prompts && prompts.length > 0 ? (
                <PromptManager
                    prompts={prompts}
                    dropdown={dropdown}
                    setPrompts={setPrompts}
                    handleNext={handleNext}
                    currentPrompt={currentPrompt}
                    handlePrevious={handlePrevious}
                    toggleDropdown={toggleDropdown}
                    setCurrentPrompt={setCurrentPrompt}
                />
            ) : (
                <PromptStarter
                    dropdown={dropdown}
                    setPrompts={setPrompts}
                    toggleDropdown={toggleDropdown}
                    setCurrentPrompt={setCurrentPrompt}
                />
            )}
            {currentPrompt !== null && (
                <Grid item xs={12}>
                    <PromptDisplay
                        prompts={prompts}
                        currentPrompt={currentPrompt}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default PromptController;
