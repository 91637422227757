import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "15%",
        [theme.breakpoints.down("sm")]: {
            marginTop: "5%"
        }
    },
    heading: {
        fontSize: 40,
        fontWeight: "400"
    },
    button: {
        color: "#0097b2",
        borderColor: "rgba(0,151,178,.5)",
        "&:hover": {
            color: "#0097b2",
            borderColor: "#0097b2"
        }
    },
    center: {
        textAlign: "center"
    },
    container: {
        margin: "0 auto"
    },
    image: {
        height: "auto",
        margin: "0 auto",
        maxWidth: "760px",
        width: "50%"
    }
}));

const Error404 = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid
                item
                container
                xs={12}
                sm={8}
                md={5}
                className={classes.container}
            >
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        align="center"
                        className={classes.heading}
                    >
                        404: This page does not exist
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.center}
                    style={{ padding: "1rem" }}
                >
                    <img
                        src="/img/error404.svg"
                        alt=""
                        className={classes.image}
                    />
                </Grid>
                <Grid item xs={12} className={classes.center}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        href="/"
                    >
                        Back to Home
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Error404;
