import React, { useState } from "react";
import {
    Paper,
    Grid,
    IconButton,
    Typography,
    TextField,
    MenuItem,
    Button,
    ClickAwayListener
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "@utils/windowDimensions";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import SuggestedPromptInput from "@global/SuggestedPromptInput";
import { X } from "react-feather";
import { head } from "lodash";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        position: "absolute",
        left: 0,
        top: 64,
        backgroundColor: "rgba(245, 249, 255, .95)",
        height: 420,
        zIndex: 999
    },
    floatRight: {
        float: "right"
    },
    center: {
        textAlign: "center"
    },
    padded: {
        padding: theme.spacing(3)
    },
    card: {
        height: 300,
        "& > p": {
            backgroundColor: "#581e47",
            borderRadius: "4px 4px 0 0",
            color: "#fff",
            padding: 12,
            textAlign: "center",
            textTransform: "uppercase"
        },
        "& > div": {
            padding: theme.spacing(2)
        }
    },
    marginTop: {
        marginTop: theme.spacing(1)
    }
}));

const PromptStarter = props => {
    const classes = useStyles();
    const { session, userToken } = reactProps;
    const { setPrompts, dropdown, toggleDropdown, setCurrentPrompt } = props;
    const { width } = useWindowDimensions();
    const [adding, setAdding] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [headChange, setHeadChange] = useState(1);
    const [description, setDescription] = useState("");
    const [deck_id, setDeckId] = useState("");

    const toggleAdding = () => {
        let currentAdding = adding;
        setAdding(!currentAdding);
    };

    const handleSubmit = () => {
        setLoading(true);

        let params = new URLSearchParams();
        params.append("description", description);
        params.append("deck_id", deck_id);
        params.append("prefix", headChange);

        axios
            .post(
                `/api/v1/sessions/${session.uuid}/add-prompt-in-session`,
                params,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then(response => {
                //console.log("response", response);
                setLoading(false);
                toggleDropdown();
                setTimeout(
                    function() {
                        setPrompts(response.data.prompts);
                        setCurrentPrompt(head(response.data.prompts).order);
                    }.bind(this),
                    600
                );
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <>
            <>
                {dropdown && (
                    <ClickAwayListener onClickAway={toggleDropdown}>
                        <Paper
                            className={classes.root}
                            style={{ width: width }}
                            elevation={6}
                        >
                            <Grid
                                container
                                justify="flex-end"
                                style={{ position: "relative" }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0
                                    }}
                                >
                                    <div className={classes.floatRight}>
                                        <IconButton onClick={toggleDropdown}>
                                            <X />
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h4"
                                        component="h3"
                                        align="center"
                                    >
                                        Enter prompts or questions for
                                        participants to answer.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    md={3}
                                    className={classes.padded}
                                >
                                    <Paper className={classes.card}>
                                        <Typography gutterBottom>
                                            Previous Prompts
                                        </Typography>
                                        <div>
                                            <Typography gutterBottom>
                                                --
                                            </Typography>
                                        </div>
                                    </Paper>
                                    <div
                                        className={clsx(
                                            classes.floatRight,
                                            classes.marginTop
                                        )}
                                    >
                                        <Button
                                            disabled
                                            color="primary"
                                            variant="contained"
                                        >
                                            Previous Prompt
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    md={3}
                                    className={classes.padded}
                                >
                                    <Paper className={classes.card}>
                                        <Typography
                                            gutterBottom
                                            style={{ marginBottom: "0" }}
                                        >
                                            Current
                                        </Typography>
                                        <div>
                                            {adding ? (
                                                <TextField
                                                    select
                                                    label={"Prefix"}
                                                    value={headChange}
                                                    onChange={e =>
                                                        setHeadChange(
                                                            e.target.value
                                                        )
                                                    }
                                                    SelectProps={{
                                                        MenuProps: {
                                                            disablePortal: true
                                                        }
                                                    }}
                                                    fullWidth
                                                >
                                                    <MenuItem value={1}>
                                                        Select a card that
                                                        represents...
                                                    </MenuItem>
                                                    <MenuItem value={0}>
                                                        -- None --
                                                    </MenuItem>
                                                </TextField>
                                            ) : null}
                                            {adding ? (
                                                <>
                                                    <SuggestedPromptInput
                                                        autoFocus
                                                        size="small"
                                                        value={description}
                                                        handleChange={value =>
                                                            setDescription(
                                                                value
                                                            )
                                                        }
                                                        handleDeckChange={value =>
                                                            setDeckId(value)
                                                        }
                                                        handleSubmit={
                                                            handleSubmit
                                                        }
                                                    />
                                                    <div
                                                        className={clsx(
                                                            classes.floatRight,
                                                            classes.marginTop
                                                        )}
                                                    >
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                            disabled={
                                                                description.length ==
                                                                    0 ||
                                                                (session.multiple_decks ===
                                                                    1 &&
                                                                    (deck_id ===
                                                                        "" ||
                                                                        deck_id ===
                                                                            0))
                                                            }
                                                        >
                                                            Create Prompt
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : (
                                                <Typography gutterBottom>
                                                    --
                                                </Typography>
                                            )}
                                        </div>
                                    </Paper>
                                    <div
                                        className={clsx(
                                            classes.center,
                                            classes.marginTop
                                        )}
                                    >
                                        <Button
                                            component="div"
                                            variant="contained"
                                            onClick={toggleAdding}
                                            color={
                                                adding ? "default" : "primary"
                                            }
                                        >
                                            {adding
                                                ? "Cancel"
                                                : "Add First Prompt"}
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    md={3}
                                    className={classes.padded}
                                >
                                    <Paper className={classes.card}>
                                        <Typography gutterBottom>
                                            Up Next
                                        </Typography>
                                        <div>
                                            <Typography gutterBottom>
                                                --
                                            </Typography>
                                        </div>
                                    </Paper>
                                    <div className={classes.marginTop}>
                                        <Button
                                            disabled
                                            component="div"
                                            color="primary"
                                            variant="contained"
                                        >
                                            Next Prompt
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </ClickAwayListener>
                )}
                {loading && <LoadingBackdrop open={loading} />}
                {errorMessage && errorMessage != "" && (
                    <MessageSnackbar variant="error" message={errorMessage} />
                )}
            </>
        </>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default PromptStarter;
