window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');

    window.axios = require('axios');

    window.axios.defaults.headers.common = {
        'X-CSRF-TOKEN': $('meta[name=csrf-token]').attr('content'),
        'X-Requested-With': 'XMLHttpRequest',
    };

    require('bootstrap');
    require('@coreui/coreui');
    require('./scripts.js');
} catch (e) {}

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error(
        'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token',
    );
}
