import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Dialog,
    DialogTitle
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlansScroll from "./components/PlansScroll";
import PaymentMethod from "./components/PaymentMethod";
import Invoices from "./components/Invoices";
import ChangePlanDialog from "./components/ChangePlanDialog";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const ManagePlan = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(null);

    const handleOpen = newOpen => {
        setOpen(newOpen);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Manage Plan
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Manage Plan"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Typography>Billing</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <PlansScroll
                                handleOpen={handleOpen}
                                handleClose={handleClose}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PaymentMethod />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Invoices />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                maxWidth="md"
                open={Boolean(open)}
                onClose={handleClose}
                aria-labelledby="change-plan-dialog"
            >
                <DialogTitle id="change-plan-dialog">
                    Switch to {Boolean(open) ? open.name : "a New Plan"}
                </DialogTitle>
                {Boolean(open) && (
                    <ChangePlanDialog
                        selectedPlan={open}
                        handleClose={handleClose}
                    />
                )}
            </Dialog>
        </DashboardLayout>
    );
};

if (document.getElementById("manage-plan")) {
    ReactDOM.render(
        <ErrorBoundary>
            <ManagePlan />
        </ErrorBoundary>,
        document.getElementById("manage-plan")
    );
}
