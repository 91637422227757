import React, { useEffect, useState } from "react";
import {
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileDropzone from "./FileDropzone";
import CardImage from "./CardImage";

const useStyles = makeStyles(theme => ({
    root: {},
    uploadedImage: {
        borderRadius: 4,
        boxShadow: "0px 3px 6px #35405280",
        height: "auto",
        marginBottom: theme.spacing(2),
        width: "100%"
    },
    imageContainer: {
        alignItems: "center",
        display: "flex",
        height: 200,
        justifyContent: "center",
        margin: `${theme.spacing(2)}px auto`,
        overflow: "hidden",
        padding: 0,
        position: "relative",
        width: 200,
        "& > div": {
            borderRadius: 4,
            boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            overflow: "hidden"
        },
        "& > span": {
            display: "none"
        }
    }
}));

const CardInputs = props => {
    const {
        card,
        updateSingleValue,
        shouldUpdate,
        setLoading,
        getErrorMessage
    } = props;
    const classes = useStyles();
    const { userToken } = reactProps;
    const [values, setValues] = useState(card);

    const handleChange = key => event => {
        setValues({ ...values, [key]: event.target.value });
    };

    const handleChangeDirectly = (key, value) => {
        setValues({
            ...values,
            [key]: value
        });
    };

    const toggleOrientation = () => {
        handleChangeDirectly(
            "orientation",
            values.orientation == "tall" ? "wide" : "tall"
        );
    };

    const toggleHasBack = () => {
        handleChangeDirectly("has_back", values.has_back == true ? 0 : 1);
    };

    const handleBackImage = file => {
        setLoading(true);
        let params = new FormData();
        params.append("side", "back");
        params.append(file[0].name, file[0]);

        axios
            .post(`/api/v1/user/card-media`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                if (response.data.upload_url) {
                    let name = response.data.upload_url.file_name;
                    let url = response.data.upload_url.upload_url;

                    setValues({
                        ...values,
                        back_name: name,
                        back_alt_text: name,
                        back_upload_link: url
                    });
                }
                setLoading(false);
            })
            .catch(error => {
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (shouldUpdate) {
            updateSingleValue(values.temp_id, values);
        }
    }, [shouldUpdate]);

    useEffect(() => {
        updateSingleValue(values.temp_id, values);
    }, [values]);

    return (
        <Grid
            container
            item
            spacing={1}
            xs={12}
            sm={values.has_back ? 12 : 6}
            md={values.has_back ? 8 : 4}
            xl={values.has_back ? 6 : 3}
            className={classes.root}
            component={Paper}
        >
            <Grid item xs={values.has_back ? 6 : 12}>
                <Paper elevation={2} className={classes.imageContainer}>
                    <CardImage
                        orientation={values.orientation}
                        src={values.upload_link}
                        alt={values.alt_text}
                    />
                </Paper>
                <TextField
                    fullWidth
                    required
                    name="name"
                    label="Primary Image Title 2"
                    variant="outlined"
                    margin="normal"
                    value={values.name}
                    onChange={handleChange("name")}
                />
                <TextField
                    fullWidth
                    required
                    name="alt_text"
                    label="Primary Image Alt Text 1"
                    variant="outlined"
                    margin="normal"
                    value={values.alt_text}
                    onChange={handleChange("alt_text")}
                />
            </Grid>
            {values.has_back == true && (
                <Grid item xs={6}>
                    <Paper elevation={2} className={classes.imageContainer}>
                        {values.back_upload_link ? (
                            <CardImage
                                orientation={values.orientation}
                                src={values.back_upload_link}
                                alt={values.back_alt_text}
                            />
                        ) : (
                            <FileDropzone
                                setFiles={handleBackImage}
                                limit={1}
                            />
                        )}
                    </Paper>
                    <TextField
                        fullWidth
                        required
                        name="back name"
                        label="Back of Card Image Title"
                        variant="outlined"
                        margin="normal"
                        value={values.back_name}
                        onChange={handleChange("back_name")}
                    />
                    <TextField
                        fullWidth
                        required
                        name="back_alt_text"
                        label="Back of Card Alt Text"
                        variant="outlined"
                        margin="normal"
                        value={values.back_alt_text}
                        onChange={handleChange("back_alt_text")}
                    />
                </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            onChange={toggleOrientation}
                            checked={values.orientation == "wide"}
                        />
                    }
                    label={
                        values.orientation == "tall"
                            ? "Portrait View"
                            : "Landscape View"
                    }
                />
                <br />
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            onChange={toggleHasBack}
                            checked={Boolean(values.has_back)}
                        />
                    }
                    label="Add Back of Card"
                />
            </Grid>
        </Grid>
    );
};

export default CardInputs;
