import React from "react";
import { Grid, ButtonGroup, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    floatRight: {
        float: "right"
    },
    cardContainer: {
        borderBottom: "1px solid #666",
        marginBottom: 15,
        marginTop: 15,
        paddingBottom: 5
    }
}));

const CardForm = props => {
    const { setLoading, setAdding, setPaymentMethod, setErrorMessage } = props;
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();

    const cardOptions = {
        iconStyle: "solid",
        style: {
            base: {
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "18px"
            }
        }
    };

    const validateCard = async () => {
        setLoading(true);
        console.log("attempting to validate card");
        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (payload.error) {
            console.log("error", payload.error);
            setErrorMessage(payload.error.message);
            setLoading(false);
        } else {
            console.log("paymentMethod", payload.paymentMethod);
            setPaymentMethod(payload.paymentMethod);
            setAdding(false);
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setPaymentMethod(null);
        setAdding(false);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <div>
                    <CardElement
                        options={cardOptions}
                        className={classes.cardContainer}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.floatRight}>
                    <ButtonGroup>
                        <Button variant="contained" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={!stripe}
                            variant="contained"
                            onClick={validateCard}
                        >
                            Confirm New Card
                        </Button>
                    </ButtonGroup>
                </div>
            </Grid>
        </Grid>
    );
};

export default CardForm;
