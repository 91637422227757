import React from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import MUIDataTable from "mui-datatables";
import ContextualComponent from "@assets/ContextualComponent";
import { Paper, Grid, Typography } from "@material-ui/core";
import MoreButton from "../../global/MoreButton";
import AdminCTA from "./../../global/AdminCTA";

export default function Index() {
    const columns = [
        {
            name: "name",
            label: "Role Name",
            options: {
                hint: "The name of the role",
                filter: false,
                sort: false
            }
        },
        {
            name: "label",
            label: "Label",
            options: {
                hint: "The name of the label",
                filter: false,
                sort: false
            }
        },
        {
            name: "total users",
            label: "Total Users",
            options: {
                hint: "Total number of users in this role",
                filter: false,
                sort: false
            }
        },
        {
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                viewColumns: false
            }
        }
    ];

    const data = reactProps.roles.map(role => [
        role.name,
        role.label,
        role.users.length,
        <MoreButton
            floatRight
            row={role}
            tooltip_title="Edit"
            label="Edit"
            menuOptions={[
                {
                    key: "edit-" + role.id,
                    link: "/admin/roles/" + role.id + "/edit",
                    label: "Edit",
                    confirm: false
                }
            ]}
        />
    ]);

    const options = {
        elevation: 0,
        selectableRows: "none",
        fixedHeader: false,
        rowsPerPage: 100,
        responsive: "scroll",
        filter: false,
        sort: false,
        print: false,
        download: false,
        viewColumns: false
    };

    return (
        <ContextualComponent>
            <Paper className="p-3" style={{ overflowX: "auto" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Role Admin</Typography>
                        <Typography variant="subtitle1">
                            Total Roles: {reactProps.roles.length}
                        </Typography>
                        <AdminCTA
                            small={true}
                            title="Create User"
                            url="/admin/roles/create"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MUIDataTable
                            columns={columns}
                            data={data}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </ContextualComponent>
    );
}

if (document.getElementById("roles-index")) {
    ReactDOM.render(<Index />, document.getElementById("roles-index"));
}
