import React from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import {
    Container,
    Breadcrumbs,
    Link,
    Divider,
    Typography,
    Grid,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: 40
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    center: {
        alignItems: "center",
        display: "flex",
        height: "60vh",
        justifyContent: "center"
    },
    floatRight: {
        float: "right",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    sectionGutter: {
        marginBottom: theme.spacing(2)
    },
    videoContainer: {
        position: "relative",
        paddingTop: 25,
        margin: "0 auto"
    },
    link: {
        textDecoration: "underline"
    }
}));

const Instructions = () => {
    const classes = useStyles();

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Container className={classes.root}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h3" gutterBottom display="inline">
                            Instructions
                        </Typography>
                        <Breadcrumbs
                            aria-label="instructions"
                            className={classes.breadcrumbs}
                        >
                            <Link component="a" href="/dashboard">
                                Dashboard
                            </Link>
                            <Typography>Instructions</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            className={classes.floatRight}
                            variant="contained"
                            component="a"
                            href="/sessions/create"
                        >
                            Create New Session
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} md={8} lg={6}>
                        <Typography
                            style={{ marginTop: 50 }}
                            gutterBottom
                            variant="h3"
                            align="center"
                        >
                            Getting Started
                        </Typography>
                        <Typography>
                            This video will provide you with what you need to
                            get started using Virtual Climer Cards.{" "}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} md={8} lg={6}>
                        <Typography>You will learn:</Typography>
                        <ul>
                            <li>
                                <Typography gutterBottom>
                                    how to create a new session
                                </Typography>
                            </li>
                            <li>
                                <Typography gutterBottom>
                                    how to write, edit, or reorder prompts (the
                                    questions participants will respond to)
                                </Typography>
                            </li>
                            <li>
                                <Typography gutterBottom>
                                    the difference between an active, draft,
                                    archived, and shared session
                                </Typography>
                            </li>
                            <li>
                                <Typography gutterBottom>
                                    how to change the deck for a session (Plus
                                    Accounts and above)
                                </Typography>
                            </li>
                            <li>
                                <Typography gutterBottom>
                                    how to create and find the unique link you
                                    will share with participants
                                </Typography>
                            </li>
                            <li>
                                <Typography gutterBottom>
                                    how to ensure you are following privacy and
                                    GDPR guidelines if you are working with
                                    minors
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} md={8} lg={6}>
                        <div className={classes.videoContainer} align="center">
                            <iframe
                                width="100%"
                                height="349"
                                src="https://www.youtube.com/embed/IBAirPfbFg0"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                        <Typography align="center">
                            Video:{" "}
                            <a
                                href="https://youtu.be/IBAirPfbFg0"
                                target="_blank"
                            >
                                https://youtu.be/IBAirPfbFg0
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            align="center"
                            className={classes.sectionGutter}
                        >
                            Please,{" "}
                            <Link
                                className={classes.link}
                                component="a"
                                href="/feedback"
                                target="_blank"
                            >
                                let me know{" "}
                            </Link>
                            if you have any questions.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            align="center"
                            className={classes.sectionGutter}
                        >
                            Thank you! I appreciate you!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography
                            align="center"
                            className={classes.sectionGutter}
                        >
                            Amy Climer
                            <br />
                            <Link
                                className={classes.link}
                                component="a"
                                href="mailto:amy@climerconsulting.com"
                                target="_blank"
                            >
                                amy@climerconsulting.com
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </DashboardLayout>
    );
};

if (document.getElementById("instructions")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Instructions />
        </ErrorBoundary>,
        document.getElementById("instructions")
    );
}
