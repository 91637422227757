import React from "react";
import StatsCard from "@global/StatsCard";
import ListIcon from "@material-ui/icons/List";

const TotalSessions = () => {
    const { sessionsCount } = reactProps;
    return (
        <StatsCard
            icon={
                <ListIcon
                    style={{ backgroundColor: "#cda2c0", color: "#581e47" }}
                />
            }
            title="Total Sessions"
            number={sessionsCount}
            link="/admin/sessions"
        />
    );
};

export default React.memo(TotalSessions);
