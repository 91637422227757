import React, { useState } from "react";
import { Button, Paper } from "@material-ui/core";
import SingleCardForm from "./SingleCardForm";
import MultipleCardForm from "./MultipleCardForm";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    floatRight: {
        float: "right"
    },
    alignRight: {
        textAlign: "right"
    }
}));

const Form = props => {
    const classes = useStyles();
    const [showMultiForm, setShowMultiForm] = useState(false);

    const toggleForm = () => setShowMultiForm(show => !show);

    return (
        <Paper className={clsx(classes.padded, classes.alignRight)}>
            {!props.editForm && (
                <Button
                    onClick={toggleForm}
                    variant="contained"
                    color="primary"
                >
                    {`Upload ${showMultiForm ? "A Single" : "Multiple"} Card${
                        showMultiForm ? "" : "s"
                    }`}
                </Button>
            )}
            {showMultiForm ? (
                <MultipleCardForm />
            ) : (
                <SingleCardForm classes={classes} {...props} />
            )}
        </Paper>
    );
};

export default Form;
