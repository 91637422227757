import React from "react";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getInitial } from "@utils/stringUtils";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        backgroundColor: theme.palette.primary.dark,
        color: "#fff",
        display: "flex",
        justifyContent: "flex-start",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        "&:hover": {
            color: "#fff"
        }
    },
    title: {
        display: "block",
        textAlign: "left"
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        marginRight: theme.spacing(2)
    }
}));

const SidebarFooter = () => {
    const { auth, admin } = reactProps;
    const classes = useStyles();

    return (
        <a className={classes.root} href="/user/settings">
            <Avatar
                src={auth.profileImage}
                alt={auth.name}
                style={{
                    backgroundColor: auth.avatarColor
                        ? auth.avatarColor
                        : "inherit"
                }}
                className={classes.avatar}
            >
                {getInitial(auth.name)}
            </Avatar>
            <div>
                <Typography
                    variant="body1"
                    className={classes.title}
                    color="inherit"
                >
                    {auth.name}
                </Typography>
                <Typography
                    variant="caption"
                    className={classes.title}
                    color="inherit"
                >
                    {admin ? (
                        "Climer Cards Admin"
                    ) : (
                        <>{auth.title ? auth.title : "Facilitator"}</>
                    )}
                </Typography>
            </div>
        </a>
    );
};

export default SidebarFooter;
