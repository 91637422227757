import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Bar } from "react-chartjs-2";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}));

const PopularPlans = () => {
    const classes = useStyles();
    const { subs } = reactProps;

    const chartData = {
        labels: Object.keys(subs),
        datasets: [
            {
                label: "Popular Plans",
                backgroundColor: "#f7d00f",
                borderColor: "transparent",
                borderWidth: 0,
                data: Object.values(subs)
            }
        ]
    };

    return (
        <Paper className={classes.root} elevation={1}>
            <Typography variant="h4" gutterBottom>
                Popular Plans
            </Typography>
            <Bar
                data={chartData}
                options={{
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: "Subscriptions"
                                },
                                ticks: {
                                    precision: 0,
                                    beginAtZero: true
                                }
                            }
                        ]
                    }
                }}
            />
        </Paper>
    );
};

export default React.memo(PopularPlans);
