import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../components/AdminNav";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    Breadcrumbs,
    Link,
    Divider,
    Tooltip,
    Fab,
    Dialog,
    DialogTitle,
    Button
} from "@material-ui/core";
import { Users } from "react-feather";
import DashboardLayout from "@layouts/DashboardLayout";
import EditForm from "./components/EditForm";
import ExtendTrialDialog from "./components/ExtendTrialDialog";
import ChangePlanDialog from "./components/ChangePlanDialog";
import PlanDetails from "./components/PlanDetails";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const Edit = () => {
    const classes = useStyles();
    const { userToken, user, subscription, onTrial } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const handleOpen = newOpen => {
        setOpen(newOpen);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleSubmit = values => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("email", values.email);

        if (
            values.organization_id != "" &&
            values.organization_id != user.organization_id
        ) {
            params.append("organization_id", values.organization_id);
        }

        axios
            .put(`/api/v1/admin/users/${user.uuid}/edit`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("User updated successfully");
                window.location = `/admin/users/${user.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
                getErrorMessage(error.response);
            });
    };

    const handleDeleteUser = () => {
        setLoading(true);
        axios
            .post(
                `/api/v1/admin/users/${user.uuid}/delete`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then(response => {
                if (response.data.deleted) {
                    const message = encodeURIComponent(
                        `User successfully deleted`
                    );
                    window.location = `/admin/users?message=${message}`;
                }
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
                getErrorMessage(error.response);
            });
    };

    useEffect(() => {
        if (Boolean(user.roles)) {
            let adminRoles = user.roles.find(role => {
                return role.name === "admin";
            });
            if (adminRoles && Object.keys(adminRoles).length >= 1) {
                setIsAdmin(true);
            }
        }
    }, []);

    return (
        <DashboardLayout sideNav={<AdminNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Users
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.floatRight}>
                        <Tooltip title={`Login as ${user.name}`}>
                            <Fab
                                size="small"
                                component="a"
                                variant="round"
                                color="primary"
                                href={`/admin/users/switch/${user.id}`}
                            >
                                <Users />
                            </Fab>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Users"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/admin/dashboard">
                            Dashboard
                        </Link>
                        <Link component="a" href="/admin/users">
                            Users
                        </Link>
                        <Typography>Edit {user.name}</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                        {subscription &&
                            subscription.stripe_status == "trialing" &&
                            Boolean(onTrial) && (
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <Typography gutterBottom align="center">
                                            {user.name} is in a trial of the{" "}
                                            {subscription.name} plan. The trial
                                            is set to expire{" "}
                                            {moment(
                                                subscription.trial_ends_at
                                            ).fromNow()}
                                            . If you would like to extend this
                                            trial, please{" "}
                                            <Link
                                                component="span"
                                                onClick={() =>
                                                    handleOpen("trial")
                                                }
                                            >
                                                click here
                                            </Link>
                                            .
                                        </Typography>
                                    </Alert>
                                </Grid>
                            )}
                        {!subscription && user.organization && (
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    <Typography gutterBottom align="center">
                                        {user.name} belongs to the organization{" "}
                                        {user.organization.name}. If you would
                                        like to remove them from this
                                        organization and to their own plan,
                                        please{" "}
                                        <Link
                                            component="span"
                                            onClick={() => handleOpen("plan")}
                                        >
                                            click here
                                        </Link>
                                        .
                                    </Typography>
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <EditForm user={user} handleSubmit={handleSubmit} />
                        </Grid>
                        {Boolean(user.stripe_id) && (
                            <Grid item xs={12}>
                                <PlanDetails
                                    setLoading={setLoading}
                                    getErrorMessage={getErrorMessage}
                                    user={user}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <div className={classes.floatRight}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        color: "#e3342f",
                                        borderColor: "#e3342f",
                                        marginTop: "-30px"
                                    }}
                                    onClick={handleOpenModal}
                                >
                                    Delete User
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            <Dialog
                fullWidth
                maxWidth="md"
                open={Boolean(open)}
                onClose={handleClose}
                aria-labelledby="management-dialog"
            >
                <DialogTitle id="management-dialog">
                    {open == "trial" && "Trial Extension"}
                    {open == "plan" && "Change Plan"}
                </DialogTitle>
                {open == "trial" && (
                    <ExtendTrialDialog
                        setLoading={setLoading}
                        handleClose={handleClose}
                        getErrorMessage={getErrorMessage}
                    />
                )}
                {open == "plan" && (
                    <ChangePlanDialog
                        setLoading={setLoading}
                        handleClose={handleClose}
                        getErrorMessage={getErrorMessage}
                    />
                )}
            </Dialog>
            <Dialog
                open={Boolean(openModal)}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography
                        gutterBottom
                        style={{ fontSize: 20, lineHeight: "1.8rem" }}
                        class="text-center"
                    >
                        <strong>
                            Are you sure you want to delete {user.name} (
                            {user.email}) permanently?
                        </strong>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{ fontSize: 18, lineHeight: "inherit" }}
                    >
                        {isAdmin ? (
                            <>
                                This user is Admin - to save decks and sessions
                                created by the admin - this user will be marked
                                as deleted without erasing data.
                            </>
                        ) : (
                            <>
                                This action will delete all user data
                                permanently (sessions, custom decks, archive,
                                shared sessions etc.).
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDeleteUser}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("admin-user-edit")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Edit />
        </ErrorBoundary>,
        document.getElementById("admin-user-edit")
    );
}
