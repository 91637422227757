import React, { useState, useEffect } from "react";
import {
    CardActions,
    Button,
    Dialog,
    DialogContent,
    Grid,
    Typography,
    TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "./LoadingBackdrop";
import MessageSnackbar from "./MessageSnackbar";

const useStyles = makeStyles(theme => ({
    cardImage: {
        width: "100%",
        height: "auto"
    },
    dialogContent: {
        padding: theme.spacing(3)
    },
    center: {
        textAlign: "center"
    }
}));

const BackResponse = props => {
    const classes = useStyles();
    const { response, token, handleFlip } = props;
    const { session } = reactProps;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [card, setCard] = useState(response.card ? response.card : null);
    const [values, setValues] = useState({
        name: response.name,
        response: ""
    });

    const toggleOpen = () => {
        setOpen(prevState => !prevState);
    };

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("description", values.response);
        params.append("card_id", card.id);
        params.append("token", token);
        params.append("back_of_card", response.back_of_card === 1 ? 0 : 1);

        axios
            .post(`/api/v1/session/${session.uuid}/response/create`, params)
            .then(response => {
                console.log("response", response);
                setLoading(false);
                setTimeout(
                    function() {
                        setOpen(false);
                        setValues({
                            ...values,
                            response: ""
                        });
                        handleFlip();
                    }.bind(this),
                    500
                );
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const getResponseCard = () => {
        console.log("todo get card");
    };

    useEffect(() => {
        if (!card) {
            getResponseCard();
        }
    }, []);

    return (
        <CardActions>
            <Button
                size="small"
                color="primary"
                onClick={toggleOpen}
                disabled={!Boolean(card)}
            >
                Add Response
            </Button>
            <Dialog open={open} onClose={toggleOpen}>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={4}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.alignCenter}
                        >
                            {response.back_of_card === 1 ? (
                                <img
                                    src={card.alt_text}
                                    src={card.frontImage}
                                    className={classes.cardImage}
                                />
                            ) : (
                                <img
                                    alt={card.back_alt_text}
                                    src={card.backImage}
                                    className={classes.cardImage}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.alignCenter}
                        >
                            <div>
                                <Typography
                                    variant="h4"
                                    component="p"
                                    color="primary"
                                    gutterBottom
                                    style={{ fontWeight: "bold" }}
                                >
                                    Add a Back of Card Response
                                </Typography>
                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Enter Your Name"
                                    size="small"
                                    margin="dense"
                                    value={values.name}
                                    onChange={handleChange("name")}
                                />
                                <TextField
                                    rows={4}
                                    fullWidth
                                    multiline
                                    size="small"
                                    margin="dense"
                                    name="response"
                                    value={values.response}
                                    label="Your Response"
                                    onChange={handleChange("response")}
                                />
                                <div
                                    className={classes.center}
                                    style={{ marginTop: 18 }}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        disabled={
                                            values.name == "" ||
                                            values.response == ""
                                        }
                                    >
                                        Create Response
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </CardActions>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default BackResponse;
