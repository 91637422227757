import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Paper,
    Dialog,
    DialogTitle,
    Tooltip,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Checkbox
} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import ReactDragListView from "react-drag-listview";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import AddPromptModal from "./AddPromptModal";
import EditPromptModal from "./EditPromptModal";
import EditAndDelete from "./EditAndDelete";
import useWindowDimensions from "@utils/windowDimensions";
import { find } from "lodash";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    floatRight: {
        float: "right"
    },
    tableHeader: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2)
    },
    editable: {
        cursor: "pointer"
    },
    noPrompts: {
        margin: `${theme.spacing(5)}px auto ${theme.spacing(7)}px`
    }
}));

const Prompts = props => {
    const classes = useStyles();
    const { width } = useWindowDimensions();
    const { disableActions } = props;
    const { userToken, decks } = reactProps;
    const [session, setSession] = useState(reactProps.session);
    const [prompts, setPrompts] = useState([]);
    const [subscribed, setSubscribed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [edit, setEdit] = useState(null);

    const handleEdit = uuid => {
        setEdit(uuid);
    };

    const toggleSelectAll = event => {
        if (event.target.checked) {
            const newSelected = prompts.map(prompt => prompt.uuid);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };

    const handleSelect = (event, uuid) => {
        const selectedIndex = selected.indexOf(uuid);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, uuid);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const toggleOpen = () => {
        setOpen(prevState => !prevState);
    };

    const handleDragEnd = (fromIndex, toIndex) => {
        setLoading(true);
        let currentPrompts = prompts;
        let moved = currentPrompts.splice(fromIndex, 1)[0];
        currentPrompts.splice(toIndex, 0, moved);
        setPrompts(currentPrompts);
        //setLoading(false);
        updatePromptsOrder(currentPrompts);
    };

    const updatePromptsOrder = newPrompts => {
        let params = new URLSearchParams();
        params.append("prompts", JSON.stringify(newPrompts));

        axios
            .post(`/api/v1/sessions/${session.uuid}/re-order`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!subscribed) {
            setSubscribed(true);
            setPrompts(session.prompts);
        }
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper style={{ height: "100%" }}>
                    <div className={classes.tableHeader}>
                        <Typography variant="h4">Prompts</Typography>
                        <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={toggleOpen}
                            disabled={disableActions}
                        >
                            Add Prompt
                        </Button>
                    </div>
                    {prompts.length > 0 && !loading && (
                        <TableContainer>
                            <ReactDragListView
                                onDragEnd={handleDragEnd}
                                nodeSelector="section"
                                handleSelector="span"
                            >
                                <div>
                                    <Table
                                        component="div"
                                        style={{
                                            tableLayout: "auto"
                                        }}
                                    >
                                        <TableHead component="div">
                                            {selected.length > 0 && (
                                                <EditAndDelete
                                                    selected={selected}
                                                    setLoading={setLoading}
                                                    handleEdit={handleEdit}
                                                    getErrorMessage={
                                                        getErrorMessage
                                                    }
                                                />
                                            )}
                                            <TableRow component="div">
                                                {session.multiple_decks ===
                                                1 ? (
                                                    <>
                                                        <TableCell
                                                            padding="checkbox"
                                                            component="div"
                                                        >
                                                            <Checkbox
                                                                disabled={
                                                                    disableActions
                                                                }
                                                                indeterminate={
                                                                    selected.length >
                                                                        0 &&
                                                                    selected.length <
                                                                        prompts.length
                                                                }
                                                                checked={
                                                                    prompts.length >
                                                                        0 &&
                                                                    selected.length ===
                                                                        prompts.length
                                                                }
                                                                onChange={
                                                                    toggleSelectAll
                                                                }
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "select all prompts"
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                width:
                                                                    "calc(100% - 48px - 200px - 130px - 48px)",
                                                                minWidth:
                                                                    "350px"
                                                            }}
                                                            component="article"
                                                            id={`table-row-${prompt.uuid}`}
                                                        >
                                                            <Typography variant="body1">
                                                                Prompt
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            component="div"
                                                            style={{
                                                                width: 200,
                                                                minWidth: 150
                                                            }}
                                                        >
                                                            <Typography variant="body1">
                                                                Deck
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            component="div"
                                                            style={{
                                                                width: 130
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            component="article"
                                                            style={{
                                                                width: 48
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </TableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableCell
                                                            padding="checkbox"
                                                            component="div"
                                                        >
                                                            <Checkbox
                                                                disabled={
                                                                    disableActions
                                                                }
                                                                indeterminate={
                                                                    selected.length >
                                                                        0 &&
                                                                    selected.length <
                                                                        prompts.length
                                                                }
                                                                checked={
                                                                    prompts.length >
                                                                        0 &&
                                                                    selected.length ===
                                                                        prompts.length
                                                                }
                                                                onChange={
                                                                    toggleSelectAll
                                                                }
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "select all prompts"
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                width:
                                                                    "calc(100% - 48px - 130px - 48px)",
                                                                minWidth:
                                                                    "350px"
                                                            }}
                                                            component="article"
                                                            id={`table-row-${prompt.uuid}`}
                                                        >
                                                            &nbsp;
                                                        </TableCell>
                                                        <TableCell
                                                            component="div"
                                                            style={{
                                                                width: 130
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            component="article"
                                                            style={{
                                                                width: 48
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody component="div">
                                            {prompts.map(prompt => (
                                                <TableRow
                                                    key={prompt.uuid}
                                                    component="section"
                                                >
                                                    <TableCell
                                                        padding="checkbox"
                                                        component="div"
                                                    >
                                                        <Checkbox
                                                            disabled={
                                                                disableActions
                                                            }
                                                            checked={
                                                                selected.indexOf(
                                                                    prompt.uuid
                                                                ) !== -1
                                                            }
                                                            inputProps={{
                                                                "aria-labelledby": `table-row-${prompt.uuid}`
                                                            }}
                                                            onClick={event =>
                                                                handleSelect(
                                                                    event,
                                                                    prompt.uuid
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="article"
                                                        id={`table-row-${prompt.uuid}`}
                                                    >
                                                        {disableActions ? (
                                                            prompt.prefix ? (
                                                                <div>
                                                                    Select a
                                                                    card that
                                                                    represents...
                                                                    <br />
                                                                    {
                                                                        prompt.description
                                                                    }
                                                                </div>
                                                            ) : (
                                                                prompt.description
                                                            )
                                                        ) : (
                                                            <Tooltip title="click to edit">
                                                                <span
                                                                    className={
                                                                        classes.editable
                                                                    }
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            prompt.uuid
                                                                        )
                                                                    }
                                                                >
                                                                    {prompt.prefix ? (
                                                                        <div>
                                                                            Select
                                                                            a
                                                                            card
                                                                            that
                                                                            represents...
                                                                            <br />
                                                                            {
                                                                                prompt.description
                                                                            }
                                                                        </div>
                                                                    ) : (
                                                                        prompt.description
                                                                    )}
                                                                </span>
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                    {session.multiple_decks ===
                                                        1 && (
                                                        <TableCell component="div">
                                                            {prompt.deck_id >
                                                                0 &&
                                                            decks.filter(
                                                                d =>
                                                                    d.id ===
                                                                    prompt.deck_id
                                                            ).length ? (
                                                                <Typography variant="caption">
                                                                    {
                                                                        decks.filter(
                                                                            d =>
                                                                                d.id ===
                                                                                prompt.deck_id
                                                                        )[0]
                                                                            .name
                                                                    }
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant="caption">
                                                                    —
                                                                </Typography>
                                                            )}
                                                        </TableCell>
                                                    )}
                                                    <TableCell component="div">
                                                        {prompt.responses
                                                            .length > 0 ? (
                                                            <span>
                                                                {
                                                                    prompt
                                                                        .responses
                                                                        .length
                                                                }
                                                                &nbsp;response
                                                                {prompt
                                                                    .responses
                                                                    .length >
                                                                    1 && "s"}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                No&nbsp;responses
                                                            </span>
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        component="article"
                                                    >
                                                        <Tooltip title="Move this prompt">
                                                            <IconButton
                                                                disabled={
                                                                    disableActions
                                                                }
                                                                component="span"
                                                                onClick={e =>
                                                                    e.preventDefault()
                                                                }
                                                            >
                                                                <DragIndicatorIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </ReactDragListView>
                        </TableContainer>
                    )}
                    {prompts.length == 0 && !loading && (
                        <div className={classes.noPrompts}>
                            <Typography
                                variant="h3"
                                align="center"
                                gutterBottom
                            >
                                You have no prompts added to this session
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                align="center"
                            >
                                You can add a prompt here or in the menu inside
                                the session
                            </Typography>
                        </div>
                    )}
                </Paper>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            <Dialog
                fullWidth
                open={open}
                maxWidth="md"
                onClose={toggleOpen}
                aria-labelledby="add-prompt"
            >
                <DialogTitle id="add-prompt">Add a prompt</DialogTitle>
                <AddPromptModal
                    session={session}
                    setSession={setSession}
                    setLoading={setLoading}
                    setSubscribed={setSubscribed}
                    getErrorMessage={getErrorMessage}
                    handleCancel={() => setOpen(false)}
                />
            </Dialog>
            <Dialog
                fullWidth
                open={Boolean(edit)}
                maxWidth="md"
                onClose={() => setEdit(null)}
                aria-labelledby="edit-prompt"
            >
                <DialogTitle id="edit-prompt">Edit a Prompt</DialogTitle>
                <EditPromptModal
                    setPrompts={setPrompts}
                    setLoading={setLoading}
                    getErrorMessage={getErrorMessage}
                    handleCancel={() => setEdit(null)}
                    prompt={find(prompts, function(prompt) {
                        return prompt.uuid == edit;
                    })}
                />
            </Dialog>
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default Prompts;
