import React from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Fab,
    Tooltip
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import UsersTable from "./components/UsersTable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const Index = () => {
    const classes = useStyles();
    const { users, organization, auth, maxFacilitators } = reactProps;
    const canEdit =
        organization.user_id == auth.id ||
        (auth.organization_id == organization.id &&
            Boolean(auth.can_edit_organization));

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={9}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Users
                    </Typography>
                    <Typography
                        variant="h4"
                        gutterBottom
                        display="inline"
                        style={{ marginLeft: 16 }}
                    >
                        {" Maximum users in your Plan - "}
                        {maxFacilitators > 999999
                            ? "Unlimited"
                            : maxFacilitators}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <div className={classes.floatRight}>
                        <Tooltip title="Invite a new User">
                            <Fab
                                size="small"
                                component="a"
                                color="primary"
                                variant="round"
                                href="/users/invite"
                                disabled={!canEdit}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Users"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Typography>Users</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            {users.length > 0 ? (
                                <UsersTable />
                            ) : (
                                <Typography
                                    variant="h4"
                                    component="p"
                                    align="center"
                                >
                                    No users to show
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("facilitators-users-index")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>,
        document.getElementById("facilitators-users-index")
    );
}
