import React, { useState } from "react";
import {
    Grid,
    Paper,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    TablePagination,
    Chip
} from "@material-ui/core";
import SessionsTable from "./SessionsTable";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "react-feather";
import axios from "axios";
import LoadingBackdrop from "@global/LoadingBackdrop";

const useStyles = makeStyles(theme => ({
    root: {},
    searchArea: {
        padding: theme.spacing(3)
    }
}));

const PendingSessions = ({ search }) => {
    const { userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState(reactProps.pendingSessions);
    const [sessionsCount, setSessionsCount] = useState(
        reactProps.pendingSessionsCount
    );
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [searchQuery, setSearchQuery] = useState("");
    const [text, setText] = useState("");
    const classes = useStyles();

    const handleSearchTextChange = event => {
        setText(event.target.value);
        event.persist();
    };

    const handleSearchKeyUp = event => {
        if (event.keyCode === 13 && event.target.value.length > 0) {
            handleSearchSubmit(event.target.value);
        }
        event.persist();
    };

    const clearSearch = () => {
        setLoading(true);
        axios
            .get(
                `/api/v1/sessions/search/pending?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=&token=${userToken}`
            )
            .then(response => {
                setSearchQuery("");
                setSessions(response.data.sessions);
                setSessionsCount(reactProps.pendingSessionsCount);
                setPage(0);
                setLoading(false);
                setText("");
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSearchSubmit = searchText => {
        setLoading(true);
        axios
            .get(
                `/api/v1/sessions/search/pending?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=${searchText}&token=${userToken}`
            )
            .then(response => {
                setSearchQuery(searchText);
                setSessions(response.data.sessions);
                if (
                    response.data.sessionsCount &&
                    response.data.sessionsCount != sessionsCount
                ) {
                    setSessionsCount(response.data.sessionsCount);
                }
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        axios
            .get(
                `/api/v1/sessions/search/pending?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=${rowsPerPage *
                    newPage}&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setSessions(response.data.sessions);
                setPage(newPage);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangeRowsPerPage = event => {
        setLoading(true);
        axios
            .get(
                `/api/v1/sessions/search/pending?sortBy=${orderBy}&sortDirection=${order}&take=${parseInt(
                    event.target.value,
                    10
                )}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setSessions(response.data.sessions);
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSort = property => event => {
        console.log("handleSort");
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        setLoading(true);
        let isAsc = orderBy == property && order == "asc";

        axios
            .get(
                `/api/v1/sessions/search/pending?sortBy=${property}&sortDirection=${
                    !isAsc ? "asc" : "desc"
                }&take=${rowsPerPage}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setSessions(response.data.sessions);
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    return (
        <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
                Draft Sessions
            </Typography>
            {sessions.length > 0 ? (
                <Paper>
                    {search && (
                        <div className={classes.searchArea}>
                            <TextField
                                fullWidth
                                label="Search"
                                margin="normal"
                                value={text}
                                onKeyUp={handleSearchKeyUp}
                                onChange={handleSearchTextChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    handleSearchSubmit(text)
                                                }
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {searchQuery && searchQuery != "" && (
                                <Chip
                                    label={`Search: ${searchQuery}`}
                                    onDelete={clearSearch}
                                />
                            )}
                        </div>
                    )}
                    {sessions.length > 0 && (
                        <SessionsTable
                            archive
                            sessions={sessions}
                            order={order}
                            orderBy={orderBy}
                            handleSort={handleSort}
                        />
                    )}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={sessionsCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            ) : (
                <Typography
                    variant="h3"
                    align="center"
                    style={{ marginTop: 42 }}
                >
                    You have no Draft Sessions
                </Typography>
            )}
            {loading && <LoadingBackdrop open={loading} />}
        </Grid>
    );
};

export default PendingSessions;
