import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import PlanCard from "@global/PlanCard";
import { find } from "lodash";
import moment from "moment";
import MessageSnackbar from "@global/MessageSnackbar";
import LoadingBackdrop from "@global/LoadingBackdrop";

const PlansScroll = props => {
    const { plans, plan, subscription } = reactProps;
    const { handleOpen } = props;
    const [showAll, setShowAll] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const toggleShowMore = () => setShowMore(prevState => !prevState);

    const teamPlan =
        plan == "team" || plan == "enterprise" || plan == "education";

    React.useEffect(() => {
        console.log("plans", plans);
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography style={{ margin: "1rem auto" }} variant="h4">
                    Plans{" "}
                    {moment().isBefore(subscription.trial_ends_at, "day") &&
                        `- Free Trial Ends In ${moment().to(
                            subscription.trial_ends_at,
                            true
                        )}`}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {(showAll || !teamPlan) && (
                    <Grid
                        item
                        container
                        spacing={3}
                        xs={12}
                        style={{ justifyContent: "center" }}
                    >
                        <Grid item xs={12} sm={6} lg={4}>
                            <PlanCard
                                currentPlan={plan}
                                handleOpen={handleOpen}
                                toggleShowMore={toggleShowMore}
                                showMore={showMore}
                                plan={find(plans, function(p) {
                                    return p.name == "Basic";
                                })}
                                setLoading={setLoading}
                                getErrorMessage={getErrorMessage}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} lg={4}>
                                <PlanCard
                                    currentPlan={plan}
                                    handleOpen={handleOpen}
                                    toggleShowMore={toggleShowMore}
                                    showMore={showMore}
                                    plan={find(plans, function(p) {
                                        return p.name == "Plus";
                                    })}
                                    setLoading={setLoading}
                                    getErrorMessage={getErrorMessage}
                                />
                            </Grid> */}
                        <Grid item xs={12} sm={6} lg={4}>
                            <PlanCard
                                currentPlan={plan}
                                handleOpen={handleOpen}
                                toggleShowMore={toggleShowMore}
                                showMore={showMore}
                                plan={find(plans, function(p) {
                                    return p.name == "Professional";
                                })}
                                setLoading={setLoading}
                                getErrorMessage={getErrorMessage}
                            />
                        </Grid>
                    </Grid>
                )}
                {(showAll || teamPlan) && (
                    <Grid
                        item
                        container
                        spacing={3}
                        xs={12}
                        style={{ justifyContent: "center" }}
                    >
                        <Grid item xs={12} sm={6} lg={4}>
                            <PlanCard
                                currentPlan={plan}
                                handleOpen={handleOpen}
                                toggleShowMore={toggleShowMore}
                                showMore={showMore}
                                plan={find(plans, function(p) {
                                    return p.name == "Team";
                                })}
                                setLoading={setLoading}
                                getErrorMessage={getErrorMessage}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} lg={4}>
                                <PlanCard
                                    currentPlan={plan}
                                    handleOpen={handleOpen}
                                    toggleShowMore={toggleShowMore}
                                    showMore={showMore}
                                    plan={find(plans, function(p) {
                                        return p.name == "Education";
                                    })}
                                    setLoading={setLoading}
                                    getErrorMessage={getErrorMessage}
                                />
                            </Grid> */}
                        <Grid item xs={12} sm={6} lg={4}>
                            <PlanCard
                                currentPlan={plan}
                                handleOpen={handleOpen}
                                toggleShowMore={toggleShowMore}
                                showMore={showMore}
                                plan={find(plans, function(p) {
                                    return p.name == "Enterprise";
                                })}
                                setLoading={setLoading}
                                getErrorMessage={getErrorMessage}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", marginTop: 32 }}>
                <Button onClick={() => setShowAll(!showAll)}>
                    {showAll
                        ? `Hide ${teamPlan ? "Individual" : "Team"} plans`
                        : "See All Plans"}
                </Button>
            </Grid>
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar message={errorMessage} variant="error" />
            )}
            <LoadingBackdrop open={loading} />
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default PlansScroll;
