import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Form from "./components/Form";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import { forEach } from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    divider: {
        marginBottom: theme.spacing(2)
    },
    alignRight: {
        textAlign: "right"
    },
    deleteButton: {
        borderColor: "#ff000047",
        color: "#f00"
    }
}));

const Edit = () => {
    const classes = useStyles();
    const { userToken, card, user_decks } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        name: card.name,
        alt_text: card.alt_text,
        upload_link: card.frontImage,
        orientation: card.orientation,
        has_back: Boolean(card.has_back),
        back_name: card.back_name ? card.back_name : "",
        back_alt_text: card.back_alt_text ? card.back_alt_text : "",
        back_upload_link: card.backImage ? card.backImage : "",
        verify_copyright: true,
        decks: []
    });

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeDirectly = (key, value) => {
        setValues({
            ...values,
            [key]: value
        });
    };

    const handleRemoveDeck = deck_id => {
        let newDecks = [];

        forEach(values.decks, function(deck) {
            if (deck != deck_id) {
                newDecks.push(deck);
            }
        });

        setValues({
            ...values,
            decks: newDecks
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("alt_text", values.alt_text);
        params.append("upload_link", values.upload_link);
        params.append("orientation", values.orientation);
        params.append("has_back", values.has_back ? 1 : 0);

        if (values.has_back) {
            params.append("back_name", values.back_name);
            params.append("back_alt_text", values.back_alt_text);
            params.append("back_upload_link", values.back_upload_link);
        }

        if (values.decks.length > 0) {
            params.append("decks", values.decks);
        }

        axios
            .post(`/api/v1/cards/${card.uuid}/edit`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Card information updated");
                window.location = `/cards?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleDelete = () => {
        setLoading(true);
        axios
            .post(
                `/api/v1/cards/${card.uuid}/delete`,
                {},
                { headers: { Authorization: `Bearer ${userToken}` } }
            )
            .then(response => {
                if (response.data.deleted) {
                    window.location = `/cards?message=${response.data.message}`;
                } else {
                    setLoading(false);
                    setErrorMessage(response.data.message);
                }
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const buildCardDecks = () => {
        let newDecks = [];

        forEach(card.decks, function(deck) {
            newDecks.push(deck.id);
        });

        setValues({
            ...values,
            decks: newDecks
        });
    };

    useEffect(() => {
        buildCardDecks();
    }, []);

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.root}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Edit Card
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.alignRight}>
                    <Button
                        onClick={handleDelete}
                        variant="outlined"
                        className={classes.deleteButton}
                    >
                        Delete
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        mt={2}
                        aria-label="Cards"
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Link component="a" href="/cards">
                            Cards
                        </Link>
                        <Typography>Edit Card</Typography>
                    </Breadcrumbs>
                    <Divider my={6} className={classes.divider} />
                    <Form
                        card={card}
                        values={values}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleRemoveDeck={handleRemoveDeck}
                        handleChangeDirectly={handleChangeDirectly}
                        editForm
                    />
                </Grid>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("facilitators-cards-edit")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Edit />
        </ErrorBoundary>,
        document.getElementById("facilitators-cards-edit")
    );
}
