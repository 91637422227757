import React, { useState } from "react";
import FileDropzone from "@global/FileDropzone";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import {
    Grid,
    Typography,
    Breadcrumbs,
    Link,
    Divider,
    Paper,
    TextField,
    Button,
    Avatar,
    Dialog,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Switch,
    Tooltip
} from "@material-ui/core";
import { forEach } from "lodash";

const OrganizationInfo = props => {
    const { userToken, auth } = reactProps;
    const { organization, classes } = props;
    const [values, setValues] = useState({
        name: organization ? organization.name : "",
        url: organization && organization.url ? organization.url : "",
        description:
            organization && organization.description
                ? organization.description
                : "",
        contact_email:
            organization && organization.contact_email
                ? organization.contact_email
                : "",
        whitelabel: organization && Boolean(organization.whitelabel)
    });
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(false);
    const canEdit =
        organization.user_id == auth.id ||
        (auth.organization_id == organization.id &&
            Boolean(auth.can_edit_organization));

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSwitch = event => {
        setValues({ ...values, whitelabel: event.target.checked });
    };

    const handleSubmit = () => {
        if (canEdit) {
            setLoading(true);
            let params = new URLSearchParams();
            params.append("name", values.name);
            params.append("url", values.url);
            params.append("description", values.description);
            params.append("contact_email", values.contact_email);
            params.append("whitelabel", values.whitelabel === true ? 1 : 0);

            axios
                .post(
                    `/api/v1/organizations/${organization.uuid}/update`,
                    params,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    }
                )
                .then(response => {
                    console.log("response", response);
                    let message = encodeURIComponent(
                        "Organization profile updated"
                    );
                    window.location = `/organization?message=${message}`;
                })
                .catch(error => {
                    console.log("error", error);
                    getErrorMessage(error.response);
                    setLoading(false);
                });
        }
    };

    const handleImageSubmit = () => {
        setLoading(true);
        let params = new FormData();

        forEach(files, function(file) {
            params.append("organization_image", file);
        });

        axios
            .post(
                `/api/v1/organizations/${organization.uuid}/media-upload`,
                params,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    "Organization profile image updated"
                );
                window.location = `/organization?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Grid item xs={12} style={{ marginBottom: 36 }}>
            <Breadcrumbs
                aria-label="Organizations"
                mt={2}
                className={classes.breadcrumbs}
            >
                <Link component="a" href="/admin/dashboard">
                    Dashboard
                </Link>
                <Typography>Organization</Typography>
                <Typography>{organization.name}</Typography>
            </Breadcrumbs>
            <Divider my={6} className={classes.divider} />
            <Paper className={classes.formContainer} component="form">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            label="Organization Name"
                            value={values.name}
                            onChange={handleChange("name")}
                            disabled={!canEdit}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Website URL"
                            value={values.url}
                            onChange={handleChange("url")}
                            disabled={!canEdit}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            label="Description"
                            value={values.description}
                            onChange={handleChange("description")}
                            disabled={!canEdit}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Contact Email"
                            disabled={!canEdit}
                            value={values.contact_email}
                            onChange={handleChange("contact_email")}
                            helperText="This is the team/organizational contact email. Updating the email address here does not update your user email or the email that is used to login."
                        />
                        <Tooltip
                            placement="top"
                            title="You must have an organization logo uploaded to enable white labeling"
                            aria-label="You must have an organization logo uploaded to enable white labeling"
                        >
                            <FormControlLabel
                                label="White label sessions with organization branding"
                                control={<Switch />}
                                onChange={handleSwitch}
                                disabled={
                                    !Boolean(organization.logoImage) || !canEdit
                                }
                                checked={values.whitelabel}
                                value={values.whitelabel}
                                style={{ display: "block" }}
                            />
                        </Tooltip>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!canEdit}
                            onClick={handleSubmit}
                        >
                            Save Changes
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.logoContainer}>
                        {organization.logoImage ? (
                            <img
                                src={organization.logoImage}
                                alt={organization.name}
                                className={classes.image}
                            />
                        ) : (
                            <Avatar className={classes.avatar}>
                                {organization.name[0]}
                            </Avatar>
                        )}
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!canEdit}
                            onClick={() => {
                                if (canEdit) {
                                    setOpen(true);
                                }
                            }}
                        >
                            Upload Image
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogContent>
                    <FileDropzone files={files} setFiles={setFiles} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setFiles([]);
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            if (canEdit) {
                                handleImageSubmit();
                            }
                        }}
                        disabled={!canEdit || files.length == 0}
                    >
                        Upload Image
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default OrganizationInfo;
