import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../components/AdminNav";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    Breadcrumbs,
    Link,
    Divider
} from "@material-ui/core";
import DashboardLayout from "@layouts/DashboardLayout";
import Form from "./components/Form";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import { find } from "lodash";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const Invite = () => {
    const classes = useStyles();
    const { userToken, prices, plans } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (values, facilitatorRoleId) => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("email", values.email);
        params.append("role_id", values.role_id);
        params.append(
            "max_facilitators",
            values.planName == "Enterprise" ? values.max_facilitators : ""
        );

        if (Boolean(values.coupon) && values.coupon != "") {
            params.append("stripe_coupon_id", values.coupon);
        }

        if (values.role_id == facilitatorRoleId) {
            if (
                values.organization_id != "" &&
                values.belongsToOrganization == true
            ) {
                params.append("organization_id", values.organization_id);
            }

            if (
                values.planName != "" &&
                (values.belongsToOrganization == false ||
                    values.organizationOwner == true)
            ) {
                params.append("planName", values.planName);
                params.append(
                    "planId",
                    getPriceId(values.planName, values.subscription_type)
                );
            }

            if (
                values.planName == "Team" ||
                values.planName == "Enterprise" ||
                values.planName == "Education"
            ) {
                if (values.organizationOwner == true) {
                    params.append("organization_owner", 1);
                    params.append("organization_name", values.organizationName);
                }
            }

            params.append("trial_length", values.trial_length);
        }

        axios
            .post(`/api/v1/admin/users/invite`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("User invited successfully");
                window.location = `/admin/users?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
                getErrorMessage(error.response);
            });
    };
    const getPriceId = (planName, type) => {
        let product = find(plans, function(plan) {
            return plan.name == planName;
        });
        let price = {};
        if (planName == "Enterprise") {
            price = find(prices, function(price) {
                if (type == "monthly") {
                    return (
                        product?.default_price == price.id &&
                        price.product == product.id &&
                        price.recurring &&
                        price.recurring.interval == "month"
                    );
                } else {
                    return (
                        product?.default_price == price.id &&
                        price.product == product.id &&
                        price.recurring &&
                        price.recurring.interval != "month"
                    );
                }
            });
        }
        if (!price?.id) {
            price = find(prices, function(price) {
                if (type == "monthly") {
                    return (
                        price.product == product.id &&
                        price.recurring &&
                        price.recurring.interval == "month"
                    );
                } else {
                    return (
                        price.product == product.id &&
                        price.recurring &&
                        price.recurring.interval != "month"
                    );
                }
            });
        }
        return price?.id;
    };
    return (
        <DashboardLayout sideNav={<AdminNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Users
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Users"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/admin/dashboard">
                            Dashboard
                        </Link>
                        <Link component="a" href="/admin/users">
                            Users
                        </Link>
                        <Typography>Invite a User</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Form handleSubmit={handleSubmit} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("admin-user-invite")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Invite />
        </ErrorBoundary>,
        document.getElementById("admin-user-invite")
    );
}
