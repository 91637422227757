import React, { useState } from "react";
import {
    Grid,
    Paper,
    ButtonGroup,
    Button,
    Avatar,
    Typography,
    Dialog,
    DialogContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import getInitials from "@utils/getInitials";
import { DropzoneDialog } from "material-ui-dropzone";
import QuickSessionSettings from "./QuickSessionSettings";
import { forEach } from "lodash";
import Form from "./Form";
import clsx from "clsx";
import FileDropzone from "@global/FileDropzone";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../themes/theme";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(2)
    },
    padded: {
        padding: theme.spacing(4)
    },
    floatRight: {
        float: "right"
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: 50,
        minWidth: 200,
        minHeight: 200
    },
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlgin: "center"
    }
}));

const UserProfile = () => {
    const classes = useStyles();
    const { auth, userToken } = reactProps;
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        name: auth ? auth.name : "",
        email: auth ? auth.email : "",
        title: auth && auth.title ? auth.title : "",
        city: auth && auth.city ? auth.city : "",
        state: auth && auth.state ? auth.state : "",
        country: auth && auth.country ? auth.country : "US"
    });

    const smallButtons = useMediaQuery(theme.breakpoints.down("sm"));

    const toggleOpen = () => {
        setOpen(prevState => !prevState);
    };

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeCountry = event => {
        if (event.target.value !== "US")
            setValues({ ...values, state: "N/A", country: event.target.value });
        else setValues({ ...values, country: event.target.value });
    };

    const handleImageUpdate = () => {
        setLoading(true);
        let params = new FormData();

        forEach(files, function(file) {
            params.append("profile_image", file);
        });

        axios
            .post(`/api/v1/user/profile-image`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Profile Image Updated");
                window.location = `/user/settings?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("email", values.email);

        if (values.title != "") {
            params.append("title", values.title);
        }

        if (values.city != "") {
            params.append("city", values.city);
        }

        if (values.state != "") {
            params.append("state", values.state);
        }

        if (values.country != "") {
            params.append("country", values.country);
        }

        axios
            .post(`/api/v1/user/profile`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Profile Updated");
                window.location = `/user/settings?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
                <Paper className={clsx(classes.marginTop, classes.padded)}>
                    <Grid container spacing={4} justify="center">
                        <Grid item xs={12}>
                            <Typography gutterBottom>Personal</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Form
                                values={values}
                                handleChange={handleChange}
                                handleChangeCountry={handleChangeCountry}
                            />
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={classes.marginTop}>
                                        <ButtonGroup>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={handleSubmit}
                                                size={
                                                    smallButtons
                                                        ? "small"
                                                        : "medium"
                                                }
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                component="a"
                                                variant="contained"
                                                href="/user/password"
                                                size={
                                                    smallButtons
                                                        ? "small"
                                                        : "medium"
                                                }
                                            >
                                                Change Password
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className={classes.content}>
                                <Avatar
                                    src={auth.profileImage}
                                    alt={auth.name}
                                    className={classes.avatar}
                                    style={{
                                        backgroundColor: auth.avatarColor
                                            ? auth.avatarColor
                                            : "inherit"
                                    }}
                                >
                                    {getInitials(auth.name)}
                                </Avatar>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={toggleOpen}
                                    className={classes.marginTop}
                                >
                                    Upload Image
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {/* <Grid item xs={12}>
                <Paper className={clsx(classes.marginTop, classes.padded)}>
                    <QuickSessionSettings />
                </Paper>
            </Grid> */}
            <Dialog open={open} onClose={toggleOpen}>
                <DialogContent>
                    <FileDropzone
                        files={files}
                        setFiles={setFiles}
                        title="Upload Profile Image"
                    />
                    <div style={{ textAlign: "right" }}>
                        <Button
                            onClick={() => {
                                setOpen(false);
                                setFiles([]);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleImageUpdate}
                            variant="contained"
                            color="primary"
                        >
                            Save Image
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            {/* <DropzoneDialog
                open={open}
                onSave={handleImageUpdate}
                onChange={setFiles}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={toggleOpen}
                filesLimit={1}
            /> */}
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default UserProfile;
