import React from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../admin/components/AdminNav";
import UserNav from "@global/UserNav";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography
} from "@material-ui/core";
import SettingsForm from "./components/SettingsForm";
import DeleteUser from "./components/DeleteUser";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const UserProfile = () => {
    const classes = useStyles();
    const { admin } = reactProps;

    return (
        <DashboardLayout sideNav={admin ? <AdminNav /> : <UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Profile
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Profile Settings"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link
                            component="a"
                            href={admin ? "/admin/dashboard" : "/dashboard"}
                        >
                            Dashboard
                        </Link>
                        <Typography>Profile</Typography>
                    </Breadcrumbs>
                    <Divider />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "2rem" }}>
                    <SettingsForm />
                </Grid>
                {/* <Grid item xs={12}>
                    <DeleteUser />
                </Grid> */}
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("user-profile")) {
    ReactDOM.render(
        <ErrorBoundary>
            <UserProfile />
        </ErrorBoundary>,
        document.getElementById("user-profile")
    );
}
