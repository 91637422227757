import React, { useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Typography,
    Checkbox,
    TableSortLabel,
    Tooltip,
    IconButton,
    Menu,
    MenuItem
} from "@material-ui/core";
import ManageSelections from "./ManageSelections";
import moment from "moment";
import HelpIcon from "@material-ui/icons/Help";
import { MoreVertical } from "react-feather";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import CopyToClipboard from "react-copy-to-clipboard";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import axios from "axios";

const SessionsTable = props => {
    const {
        sessions,
        organization,
        order,
        orderBy,
        handleSort,
        copyButton
    } = props;
    const { userToken, auth, rootUrl, plan } = reactProps;
    const [selected, setSelected] = useState([]);
    const [sessionStatus, setSessionStatus] = useState(sessions[0].status);
    const [anchorEl, setAnchorEl] = useState(null);
    const [current, setCurrent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [copied, setCopied] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const mySessions = window.location.pathname === "/sessions";

    // Setup permissions for user plan
    const userLowPlan = plan === "basic" || plan === "plus";
    const toggleSelectAll = event => {
        if (event.target.checked) {
            const newSelected = sessions.map(session => session.uuid);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };

    const handleSelect = (event, uuid) => {
        const selectedIndex = selected.indexOf(uuid);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, uuid);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleOpen = (event, session) => {
        setAnchorEl(event.currentTarget);
        setCurrent(session);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrent(null);
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        setAnchorEl(null);
    };

    const handleArchive = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", current.uuid);

        axios
            .post(`/api/v1/sessions/archive`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session status updated");
                window.location = `${window.location}?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };
    const handleDelete = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", current.uuid);

        axios
            .post(`/api/v1/sessions/deleted`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session has been deleted");
                window.location = `${window.location}?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleResponsesXlsx = () => {
        setLoading(true);

        axios
            .get(`/api/v1/sessions/${current.uuid}/report/xlsx`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                setLoading(false);
                if (response.data && response.data.status) {
                    if (response.data.status === "success") {
                        window.location = `/download-report/${
                            response.data.report_id
                        }?report_name=${encodeURIComponent(
                            response.data.report_name
                        )}`;
                    } else {
                        alert(response.data.message || "Unknown error");
                    }
                } else {
                    alert("Unknown error");
                }
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    alert(error.response.data.message);
                } else {
                    alert("Unknown error");
                }
                setLoading(false);
            });
    };

    const handleResponsesPdf = () => {
        setLoading(true);

        axios
            .get(`/api/v1/sessions/${current.uuid}/report/pdf`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                if (response.data && response.data.status) {
                    if (response.data.status === "success") {
                        if (response.data.html) {
                            alert("Unknown error.");
                        } else {
                            window.location = `/download-report/${
                                response.data.report_id
                            }?report_name=${encodeURIComponent(
                                response.data.report_name
                            )}`;
                            setLoading(false);
                        }
                    } else {
                        alert(response.data.message || "Unknown error !");
                        setLoading(false);
                    }
                } else {
                    alert("Unknown error !!");
                }
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    alert(error.response.data.message);
                } else {
                    console.log(error);
                    alert("Unknown error !!!");
                }
                setLoading(false);
            });
    };

    const showCopied = session => {
        setCopied(true);

        navigator.clipboard.writeText(`${rootUrl}/${session.slug}`);

        setTimeout(
            function() {
                setCopied(false);
            }.bind(this),
            3000
        );
    };

    const setFacilitatorActivity = (value, session) => {
        let params = new URLSearchParams();
        params.append("facilitator_is_active", value);

        axios
            .post(
                `/api/v1/sessions/${session.uuid}/update-active-facilitator`,
                params
            )
            .then(response => {
                console.log("response-active-facilitator", response);
                // setSession(response.data.session);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    return (
        <Paper style={{ width: "100%" }}>
            {sessions.length > 0 ? (
                <TableContainer component="div">
                    {selected.length > 0 && (
                        <ManageSelections
                            sessionStatus={sessionStatus}
                            selected={selected}
                        />
                    )}
                    <Table
                        aria-labelledby="sessions-table"
                        size="medium"
                        component="div"
                        aria-label="sessions table"
                    >
                        <TableHead component="div">
                            <TableRow component="div">
                                <TableCell padding="checkbox" component="div">
                                    <Checkbox
                                        indeterminate={
                                            selected.length > 0 &&
                                            selected.length < sessions.length
                                        }
                                        checked={
                                            sessions.length > 0 &&
                                            selected.length === sessions.length
                                        }
                                        onChange={toggleSelectAll}
                                        inputProps={{
                                            "aria-label": "select all sessions"
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    component="div"
                                    sortDirection={
                                        orderBy == "name" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "name"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("name")}
                                    >
                                        Session Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    component="div"
                                >
                                    Access Code / Link
                                    <Tooltip title="Participants can enter this code into the access code form on the landing page to gain access to the session. You can also copy the full link below and give it directly to the participant.">
                                        <HelpIcon
                                            fontSize="small"
                                            style={{
                                                marginLeft: 6,
                                                marginBottom: 2
                                            }}
                                        />
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    component="div"
                                    sortDirection={
                                        orderBy == "created_at" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "created_at"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("created_at")}
                                    >
                                        Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    component="div"
                                >
                                    Status
                                </TableCell>
                                {organization && (
                                    <TableCell
                                        align="left"
                                        padding="default"
                                        component="div"
                                        sortDirection={
                                            orderBy == "team" ? order : false
                                        }
                                    >
                                        <TableSortLabel
                                            active={orderBy == "team"}
                                            direction={
                                                order == "asc" ? "desc" : "asc"
                                            }
                                            onClick={handleSort("team")}
                                        >
                                            Team
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell
                                    align="right"
                                    padding="default"
                                    component="div"
                                >
                                    {" "}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody component="div">
                            {sessions.map(session => (
                                <TableRow
                                    key={session.id}
                                    hover
                                    component="div"
                                >
                                    <TableCell
                                        padding="checkbox"
                                        component="div"
                                    >
                                        <Checkbox
                                            checked={
                                                selected.indexOf(
                                                    session.uuid
                                                ) !== -1
                                            }
                                            inputProps={{
                                                "aria-labelledby": `table-row-${session.uuid}`
                                            }}
                                            onClick={event =>
                                                handleSelect(
                                                    event,
                                                    session.uuid
                                                )
                                            }
                                        />
                                    </TableCell>
                                    <TableCell component="div">
                                        {session.name}
                                    </TableCell>
                                    <TableCell component="div">
                                        {session.slug}
                                        {Boolean(mySessions) && (
                                            <Tooltip title="This code can be entered on the app’s home page">
                                                <Button
                                                    style={{
                                                        marginLeft: 12
                                                    }}
                                                    size="small"
                                                    color="primary"
                                                    onClick={() =>
                                                        showCopied(session)
                                                    }
                                                >
                                                    Copy Code
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    {Boolean(handleSort) && (
                                        <TableCell component="div">
                                            {moment(session.created_at).format(
                                                "L"
                                            )}
                                        </TableCell>
                                    )}
                                    <TableCell component="div">
                                        {session.status == "active" && (
                                            <span
                                                style={{
                                                    backgroundColor: "#4786AA",
                                                    padding: "4px 6px",
                                                    color: "#fff"
                                                }}
                                            >
                                                Active
                                            </span>
                                        )}
                                        {session.status == "archived" && (
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        "#00000040",
                                                    padding: "4px 6px",
                                                    color: "#fff"
                                                }}
                                            >
                                                Archived
                                            </span>
                                        )}
                                        {session.status != "active" &&
                                            session.status != "archived" && (
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            "#A8D058",
                                                        padding: "4px 6px",
                                                        color: "#fff"
                                                    }}
                                                >
                                                    Draft
                                                </span>
                                            )}
                                    </TableCell>
                                    {organization && session.user && (
                                        <TableCell component="div">
                                            {session.user.name}
                                        </TableCell>
                                    )}
                                    <TableCell align="right" component="div">
                                        {!Boolean(mySessions) ? (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                component="a"
                                                href={`/sessions/${session.uuid}/edit`}
                                            >
                                                Details
                                            </Button>
                                        ) : (
                                            <>
                                                {session.status === "active" ? (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        component="a"
                                                        href={`/${session.slug}/presentation`}
                                                        onClick={() => {
                                                            setFacilitatorActivity(
                                                                1,
                                                                session
                                                            );
                                                        }}
                                                    >
                                                        Enter
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        component="a"
                                                        href={`/sessions/${session.uuid}/edit`}
                                                    >
                                                        Details
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                        {Boolean(mySessions) && (
                                            <IconButton
                                                onClick={e =>
                                                    handleOpen(e, session)
                                                }
                                            >
                                                <MoreVertical />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Menu
                        id="session-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            component="a"
                            href={`/sessions/${current &&
                                current.uuid &&
                                current.uuid}/edit`}
                        >
                            Manage Prompts
                        </MenuItem>
                        <MenuItem
                            component="a"
                            href={`/sessions/${current &&
                                current.uuid &&
                                current.uuid}/edit?details`}
                        >
                            Edit Details
                        </MenuItem>
                        {!userLowPlan && (
                            <MenuItem onClick={handleResponsesXlsx}>
                                Report XLSX
                            </MenuItem>
                        )}
                        {!userLowPlan && (
                            <MenuItem onClick={handleResponsesPdf}>
                                Report PDF
                            </MenuItem>
                        )}
                        <MenuItem onClick={handleArchive}>Archive</MenuItem>
                        <MenuItem onClick={handleOpenModal}>Delete</MenuItem>
                    </Menu>
                    <Dialog
                        open={Boolean(openModal)}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            <Typography
                                gutterBottom
                                style={{ fontSize: 20 }}
                                class="text-center"
                            >
                                <strong>
                                    Are you sure you would like to delete this
                                    session?
                                </strong>
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                style={{ fontSize: 18 }}
                            >
                                This action is permanent and cannot be undone!
                                You can also Archive this session instead.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                            <Button
                                onClick={handleDelete}
                                variant="contained"
                                color="primary"
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </TableContainer>
            ) : (
                <Typography variant="body2" align="center" gutterBottom>
                    No sessions found.
                </Typography>
            )}
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            {copied && (
                <MessageSnackbar
                    variant="success"
                    message="Shared URL Copied"
                />
            )}
        </Paper>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default SessionsTable;
