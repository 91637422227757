import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FeedbackForm from "./FeedbackForm";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        marginLeft: 260,
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0
        }
    },
    links: {
        margin: 0,
        padding: 0,
        "& > li": {
            display: "inline-block",
            marginRight: theme.spacing(2)
        },
        "& a": {
            color: theme.palette.text.primary
        }
    }
}));

const DashboardFooter = () => {
    const classes = useStyles();
    const year = new Date().getFullYear();
    const { auth, captchaKey } = reactProps;

    const [open, setOpen] = useState(false);

    const openForm = event => {
        event.preventDefault();
        setOpen(true);
    };

    return (
        <>
            <div className={classes.root}>
                <ul className={classes.links}>
                    <li>
                        <a href="" onClick={openForm}>
                            Feedback
                        </a>
                    </li>
                    <li>
                        <a href="https://climercards.com/privacy-policy/">
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a href="https://climercards.com/terms-and-conditions/">
                            Terms of Use
                        </a>
                    </li>
                </ul>
                <Typography variant="body1">
                    &copy;
                    {" " + year + " - "}
                    Climer Cards
                </Typography>
            </div>
            <FeedbackForm
                open={open}
                setOpen={setOpen}
                auth={auth}
                captchaKey={captchaKey}
            />
        </>
    );
};

export default DashboardFooter;
