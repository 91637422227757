import React, { useEffect, useState } from "react";
import { Button, Collapse, Typography } from "@material-ui/core";
import { findIndex, forEach } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import FileDropzone from "@global/FileDropzone";
import CardInputs from "@global/CardInputs";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {},
    alignRight: {
        textAlign: "right"
    },
    marginVert: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    flexBetweeen: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    }
}));

const MultipleCardForm = props => {
    const { handleSelectMultiple, setShowMultiForm, setAvailableCards } = props;
    const classes = useStyles();
    const { userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [cards, setCards] = useState([]);
    const [showForm, setShowForm] = useState(true);

    const updateSingleValue = (uuid, newValue) => {
        setCards(oldCards => {
            let newValues = [...oldCards];
            newValues[findIndex(newValues, { temp_id: uuid })] = newValue;
            return newValues;
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("data", JSON.stringify(cards));

        axios
            .post(`/api/v1/cards/create-multiple`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                setAvailableCards(response.data.availableCards);
                handleSelectMultiple(response.data.cards);
                setLoading(false);
                setShowMultiForm(false);
            })
            .catch(error => {
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const buildCards = () => {
        setLoading(true);
        setShowForm(false);
        let params = new FormData();
        params.append("side", "front");

        forEach(files, function(file) {
            params.append(file.name, file);
        });

        axios
            .post(`/api/v1/user/card-media`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                if (
                    response.data.upload_urls &&
                    response.data.upload_urls.length > 0
                ) {
                    const newValues = response.data.upload_urls.map(file => {
                        let orientation = "tall";
                        if (file.dimensions) {
                            let width = parseInt(file.dimensions.width);
                            let height = parseInt(file.dimensions.height);
                            if (width > height) {
                                orientation = "wide";
                            }
                        }
                        return {
                            temp_id: uuidv4(),
                            name: file.file_name,
                            upload_link: file.upload_url,
                            alt_text: file.file_name,
                            orientation: orientation,
                            has_back: 0,
                            back_name: "",
                            back_alt_text: "",
                            back_upload_link: ""
                        };
                    });
                    setCards(oldValues => {
                        return [...oldValues, ...newValues];
                    });
                    setFiles([]);
                } else if (response.data.upload_url) {
                    setCards(oldValues => {
                        let orientation = "tall";
                        if (response.data.upload_url.dimensions) {
                            let width = parseInt(
                                response.data.upload_url.dimensions.width
                            );
                            let height = parseInt(
                                response.data.upload_url.dimensions.height
                            );
                            if (width > height) {
                                orientation = "wide";
                            }
                        }
                        return [
                            ...oldValues,
                            {
                                temp_id: uuidv4(),
                                name: response.data.upload_url.file_name,
                                upload_link:
                                    response.data.upload_url.upload_url,
                                alt_text: response.data.upload_url.file_name,
                                orientation: orientation,
                                has_back: 0,
                                back_name: "",
                                back_alt_text: "",
                                back_upload_link: ""
                            }
                        ];
                    });
                    setFiles([]);
                }
                setLoading(false);
            })
            .catch(error => {
                setShowForm(true);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    useEffect(() => {}, [cards]);

    return (
        <div>
            <Typography align="center">Upload Multiple Cards</Typography>
            <Typography align="center">
                You will be able to update details in the deck edit screen
            </Typography>
            <Collapse
                in={cards.length === 0 || showForm == true}
                className={classes.alignRight}
            >
                {showForm == true && (
                    <>
                        <FileDropzone
                            limit={20}
                            files={files}
                            setFiles={setFiles}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={buildCards}
                            disabled={files.length == 0}
                            className={classes.marginVert}
                        >
                            Confirm Images
                        </Button>
                    </>
                )}
            </Collapse>
            {cards.length > 0 && (
                <>
                    {cards.map(card => (
                        <CardInputs
                            card={card}
                            key={card.temp_id}
                            shouldUpdate={false}
                            setLoading={setLoading}
                            getErrorMessage={getErrorMessage}
                            updateSingleValue={updateSingleValue}
                        />
                    ))}
                    <Button
                        //disabled={!values.verify_copyright}
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </>
            )}
            <div className={classes.flexBetweeen}>
                {/* <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            onChange={handleSwitch("verify_copyright")}
                            checked={values.verify_copyright}
                        />
                    }
                    label="I verify that I own the rights to use all images being uploaded"
                    className={classes.marginVert}
                /> */}
                {/* <Button
                    //disabled={!values.verify_copyright}
                    className={classes.marginVert}
                    //onClick={handleFileUpload}
                    color="primary"
                    variant="contained"
                >
                    Upload Images
                </Button> */}
            </div>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </div>
    );

    function getErrorMessage(response) {
        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 413) {
            setErrorMessage("File too large");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default MultipleCardForm;
