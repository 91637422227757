import React, { useState, useEffect } from 'react';
import {
    Paper,
    Grid,
    TextField,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Button,
} from '@material-ui/core';

export default function Form({ role, roleList }) {
    const [initialSet, setInitialSet] = useState(false);
    const [values, setValues] = useState({
        name: '',
        label: '',
        role: '',
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    useEffect(() => {
        if (role && !initialSet) {
            setValues({
                name: role.name,
                label: role.label,
                role: role.name,
            });

            setInitialSet(true);
        }
    });

    return (
        <div className="create-role-container">
            <Paper className="p-3">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            id="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange('name')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            id="rolelabel"
                            label="Label"
                            value={values.label}
                            onChange={handleChange('label')}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" spacing={4}>
                    <Grid item xs={12} md={6}>
                        <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="roles">Role</InputLabel>
                            <Select
                                value={values.role}
                                inputProps={{
                                    name: 'roles',
                                    id: 'roles',
                                }}
                                onChange={handleChange('role')}
                            >
                                <MenuItem value={null}>
                                    <em>Please select a role</em>
                                </MenuItem>
                                {roleList.map(role => (
                                    <MenuItem key={role.id} value={role.name}>
                                        <span>{role.label}</span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="float-right"
                            size="large"
                            type="submit"
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
