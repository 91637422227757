import React from "react";
import PrimaryTheme from "../themes/PrimaryTheme";
import ErrorBoundary from "./ErrorBoundary";

const Page = props => {
    const { children } = props;

    return (
        <ErrorBoundary>
            <PrimaryTheme>{children}</PrimaryTheme>
        </ErrorBoundary>
    );
};

export default Page;
