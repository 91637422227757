import React from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../admin/components/AdminNav";
import UserNav from "@global/UserNav";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";
import ChangePasswordForm from "./components/ChangePasswordForm";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const ChangePassword = () => {
    const classes = useStyles();
    const { admin } = reactProps;

    return (
        <DashboardLayout sideNav={admin ? <AdminNav /> : <UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom>
                        Change Password
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ChangePasswordForm />
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("change-password")) {
    ReactDOM.render(
        <ErrorBoundary>
            <ChangePassword />
        </ErrorBoundary>,
        document.getElementById("change-password")
    );
}

// import react, { usestate } from 'react';
// import reactdom from 'react-dom';
// import {
//     button,
//     grid,
//     aper,
//     TextField,
//     Typography,
//     MuiThemeProvider,
// } from '@material-ui/core';
// import ContextualComponent from '@assets/ContextualComponent';
// import Snackbar from '../global/MessageSnackbar';

// export default function ChangePassword() {
//     const [message, setMessage] = useState('');
//     const [errorMessage, setErrorMessage] = useState('');
//     const [values, setValues] = useState({
//         old_password: '',
//         password: '',
//         password_confirmation: '',
//     });

//     const handleChange = name => event => {
//         setValues({ ...values, [name]: event.target.value });
//     };

//     return (
//         <ContextualComponent>
//             <Grid container justify="center">
//                 <Grid item xs={12} md={8}>
//                     <Paper className="p-4">
//                         <Grid container spacing={8}>
//                             <Grid item xs={12}>
//                                 <Typography variant="h5" component="h1">
//                                     Change Password
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                         <Grid container justify="center" spacing={8}>
//                             <Grid item xs={12} md={8}>
//                                 <TextField
//                                     fullWidth
//                                     type="password"
//                                     value={values.old_password}
//                                     onChange={handleChange('old_password')}
//                                     label="Old Password"
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container spacing={8} justify="center">
//                             <Grid item xs={12} md={8}>
//                                 <TextField
//                                     fullWidth
//                                     type="password"
//                                     value={values.password}
//                                     onChange={handleChange('password')}
//                                     label="New Password"
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container spacing={8} justify="center">
//                             <Grid item xs={12} md={8}>
//                                 <TextField
//                                     fullWidth
//                                     type="password"
//                                     value={values.password_confirmation}
//                                     onChange={handleChange(
//                                         'password_confirmation',
//                                     )}
//                                     label="Confirm New Password"
//                                 />
//                             </Grid>
//                         </Grid>
//                         <Grid container spacing={8} justify="center">
//                             <Grid item xs={12} md={4}>
//                                 <Button
//                                     onClick={submit}
//                                     fullWidth
//                                     variant="contained"
//                                     color="primary"
//                                     className="my-1"
//                                     disabled={
//                                         values.old_password == '' ||
//                                         values.password == '' ||
//                                         values.password_confirmation == '' ||
//                                         values.password !=
//                                             values.password_confirmation
//                                     }
//                                 >
//                                     Save
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                         {message != '' && (
//                             <Snackbar message={message} variant="success" />
//                         )}
//                         {errorMessage != '' && (
//                             <Snackbar message={errorMessage} variant="error" />
//                         )}
//                     </Paper>
//                 </Grid>
//             </Grid>
//         </ContextualComponent>
//     );

//     function handleClose() {
//         setMessage('');
//     }

//     function submit() {
//         axios
//             .post(
//                 '/api/v1/user/password?token=' +
//                     reactProps.userToken +
//                     '&old_password=' +
//                     values.old_password +
//                     '&password=' +
//                     values.password +
//                     '&password_confirmation=' +
//                     values.password_confirmation,
//             )
//             .then(response => {
//                 console.log(response);
//                 setMessage('Password Updated Successfully');
//             })
//             .catch(error => {
//                 console.log(error);
//                 getErrorMessage(error.response);
//             });
//     }

//     function getErrorMessage(response) {
//         console.log('erroMessage', response);

//         if (response.status == 400 || response.status == 419) {
//             setErrorMessage('Form request expired.');
//         }

//         if (response.status == 401) {
//             setErrorMessage('Cannot verify permissions.');
//         }

//         if (response.status == 422) {
//             var err = response.data;
//             let message = _.head(err[Object.keys(err)[0]]);
//             setErrorMessage(message);
//         }

//         if (response.status == 404 && response.data && response.data.message) {
//             setErrorMessage(response.data.message);
//         }

//         setTimeout(
//             function() {
//                 handleClose();
//             }.bind(this),
//             2000,
//         );
//     }
// }

// if (document.getElementById('change-password')) {
//     ReactDOM.render(
//         <ChangePassword />,
//         document.getElementById('change-password'),
//     );
// }
