import React from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import General from "@layouts/General";
import { Paper, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrivacyPolicyContent from "../plans/components/PrivacyPolicyContent";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}));

const PrivacyPolicy = () => {
    const classes = useStyles();

    return (
        <General
            maxWidth={false}
            style={{
                background: "url(/img/cards-background.png) center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Container maxWidth="md">
                <Paper elevation={10} className={classes.root}>
                    <PrivacyPolicyContent />
                </Paper>
            </Container>
        </General>
    );
};

if (document.getElementById("privacy-policy")) {
    ReactDOM.render(
        <ErrorBoundary>
            <PrivacyPolicy />
        </ErrorBoundary>,
        document.getElementById("privacy-policy")
    );
}
