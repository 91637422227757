import React, { useState } from "react";
import {
    DialogContent,
    DialogActions,
    Button,
    TextField
} from "@material-ui/core";
import { filter, find } from "lodash";

const AddPromptModal = props => {
    const {
        session,
        handleCancel,
        setSession,
        setLoading,
        getErrorMessage,
        setSubscribed
    } = props;
    const { userToken, auth } = reactProps;
    const { availableDecks } = auth;
    const [description, setDescription] = useState("");
    const [deck_id, setDeckId] = useState("");

    const handleChange = event => {
        setDescription(event.target.value);
    };

    const handleDeckSelect = newValue => {
        setDeckId(newValue);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("description", description);
        params.append("deck_id", deck_id);
        params.append("session_id", session.id);
        params.append("order", session.prompts.length);

        axios
            .post(`/api/v1/admin/prompts/create`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                setSession(response.data.session);
                handleCancel();
                setLoading(false);
                setTimeout(
                    function() {
                        setSubscribed(false);
                    }.bind(this),
                    500
                );
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <>
            <DialogContent>
                <TextField
                    rows={3}
                    required
                    multiline
                    fullWidth
                    label="Prompt"
                    margin="normal"
                    value={description}
                    onChange={handleChange}
                />
            </DialogContent>
            {session.multiple_decks === 1 && (
                <DialogContent>
                    <TextField
                        fullWidth
                        select
                        label="Deck"
                        SelectProps={{
                            native: true
                        }}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={deck_id}
                        onChange={event => {
                            handleDeckSelect(event.target.value);
                        }}
                    >
                        <option value="">None Selected</option>
                        {filter(availableDecks, function(deck) {
                            return Boolean(deck.climer);
                        }).map(deck => (
                            <option value={deck.id} key={deck.id}>
                                {deck.name}
                            </option>
                        ))}
                        {filter(availableDecks, function(deck) {
                            return !Boolean(deck.climer);
                        }).map(deck => (
                            <option value={deck.id} key={deck.id}>
                                {deck.name}
                            </option>
                        ))}
                    </TextField>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                        description.length == 0 ||
                        (session.multiple_decks === 1 &&
                            (deck_id === "" || deck_id === 0))
                    }
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    );
};

export default AddPromptModal;
