import React, { useState } from "react";
import { Grid, TextField, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop: theme.spacing(2)
    },
    padded: {
        padding: theme.spacing(4)
    },
    floatRight: {
        textAlign: "right"
    }
}));

const InviteForm = () => {
    const classes = useStyles();
    const { userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        name: "",
        email: ""
    });

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("email", values.email);

        axios
            .post(`/api/v1/users/invite`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("User Invited");
                window.location = `/users?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Grid container spacing={2} className={classes.marginTop}>
            <Grid item xs={12}>
                <Paper className={classes.padded}>
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Name"
                        value={values.name}
                        onChange={handleChange("name")}
                    />
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Email Address"
                        value={values.email}
                        onChange={handleChange("email")}
                    />
                    <div
                        className={clsx(classes.floatRight, classes.marginTop)}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Invite
                        </Button>
                    </div>
                </Paper>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("errorMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404) {
            if (response.data && response.data.message) {
                setErrorMessage(response.data.message);
            } else {
                setErrorMessage(
                    "Something went wrong. Please try again later."
                );
            }
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default InviteForm;
