import React from "react";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { X } from "react-feather";
import FileDropzone from "@global/FileDropzone";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    spaceBetween: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    cardContainer: {
        textAlign: "center",
        "& img": {
            height: "auto",
            margin: `0 auto ${theme.spacing(2)}px`,
            maxWidth: "100%"
        }
    }
}));

const FeaturedSelect = props => {
    const {
        cards,
        setFeatured,
        setOpen,
        setFiles,
        files,
        setValues,
        values,
        setLoading,
        getErrorMessage
    } = props;
    const classes = useStyles();
    const { userToken } = reactProps;

    const handleFileUpload = () => {
        let params = new FormData();
        files.map(file => params.append("image", file));
        axios
            .post(`/api/v1/user/deck-media`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                setValues({
                    ...values,
                    featured_image: response.data.upload_url
                });
                setLoading(false);
                setOpen(null);
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
            });
    };

    return (
        <div className={classes.root}>
            <div className={classes.spaceBetween}>
                <Typography variant="h3">Set Featured Image</Typography>
                <IconButton onClick={() => setOpen(false)}>
                    <X />
                </IconButton>
            </div>
            <FileDropzone title="Upload your own image" setFiles={setFiles} />
            <div style={{ textAlign: "right" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFileUpload}
                >
                    Save
                </Button>
            </div>
            <Typography variant="h5" align="center">
                Or choose a card to be featured
            </Typography>
            <Grid container spacing={3}>
                {cards.length > 0 ? (
                    cards.map(card => (
                        <Grid
                            item
                            xs={6}
                            sm={3}
                            md={2}
                            key={card.id}
                            className={classes.cardContainer}
                        >
                            <img src={card.frontImage} alt={card.alt_text} />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setFeatured(card);
                                    setOpen(false);
                                }}
                            >
                                Select
                            </Button>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography align="center">
                            No cards selected
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default FeaturedSelect;
