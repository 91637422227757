import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { head } from "lodash";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: "center"
    },
    imageContainer: {
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: 4,
        boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
        height: 318,
        margin: "0 auto",
        marginBottom: theme.spacing(2),
        overflow: "hidden",
        position: "relative",
        width: 230
    }
}));

const DeckCard = props => {
    const { deck } = props;
    const classes = useStyles();

    return (
        <a className={classes.root} href={`/decks/${deck.uuid}/edit`}>
            <div
                className={classes.imageContainer}
                style={{
                    backgroundImage: `url(${
                        deck.featuredImage
                            ? deck.featuredImage
                            : deck.cards &&
                              deck.cards.length > 0 &&
                              head(deck.cards).frontImage
                    })`
                }}
            />
            <Typography variant="h4" color="primary">
                {deck.name}
            </Typography>
            <Typography variant="body1" color="primary">
                {deck.climer === 1 ? "Climer Cards" : deck.user.name}
            </Typography>
        </a>
    );
};

export default DeckCard;
