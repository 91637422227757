import React, { useState } from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Link,
    IconButton,
    Button
} from "@material-ui/core";
import { Eye } from "react-feather";
import { take } from "lodash";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    floatRight: {
        float: "right"
    }
}));

const InvoicesTable = props => {
    const { plan } = reactProps;
    const classes = useStyles();
    const [invoices, setInvoices] = useState(take(props.invoices, 5));

    const toggleMore = () => {
        console.log("Should toggle Invoices");
        if (props.invoices.length == invoices.length) {
            setInvoices(take(props.invoices, 5));
        } else {
            setInvoices(props.invoices);
        }
    };

    React.useEffect(() => {
        console.log("invoices", invoices);
        console.log("plan", plan);
        console.log("reactProps", reactProps);
    }, []);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Invoice #</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell align="right"> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoices.map(invoice => (
                        <TableRow key={invoice.id}>
                            <TableCell>{invoice.details.number}</TableCell>
                            <TableCell>
                                {moment(invoice.details.period_end).format("L")}
                            </TableCell>
                            <TableCell>
                                $
                                {parseInt(
                                    invoice.details.amount_paid / 100
                                ).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    href={invoice.details.hosted_invoice_url}
                                    target="_blank"
                                >
                                    <Eye />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {props.invoices.length > 5 && (
                <div className={classes.floatRight}>
                    <Typography variant="caption" gutterBottom>
                        <Link component="div" onClick={toggleMore}>
                            {invoices.length == props.invoices.length
                                ? "Show Less"
                                : "Show More"}
                        </Link>
                    </Typography>
                </div>
            )}
        </>
    );
};

export default InvoicesTable;
