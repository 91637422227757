import React from "react";
import { AppBar, Toolbar, Hidden, IconButton, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { Power, ArrowLeftCircle } from "react-feather";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        boxShadow: "0px 0px 14px #3540521A"
    },
    toolbar: {
        justifyContent: "flex-end",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "space-between"
        }
    },
    logo: {
        maxHeight: 64,
        padding: 10,
        [theme.breakpoints.down("xs")]: {
            maxHeight: "unset",
            maxWidth: 264
        },
        [theme.breakpoints.up("md")]: {
            marginRight: "130px"
        }
    }
}));

const DashboardHeader = props => {
    const { has_original_user } = reactProps;
    const { toggleDrawer, activation } = props;
    const classes = useStyles();

    return (
        <AppBar
            position="fixed"
            elevation={0}
            className={classes.root}
            color="inherit"
        >
            <Toolbar
                className={classes.toolbar}
                style={activation ? { justifyContent: "space-between" } : {}}
            >
                {activation ? (
                    <img
                        src="/img/Climer-Cards-Logo-Revised.png"
                        alt="Climer Cards Logo"
                        className={classes.logo}
                    />
                ) : (
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={toggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                )}
                {has_original_user ? (
                    <Button
                        color="primary"
                        href="/users/switch-back"
                        startIcon={<ArrowLeftCircle width="20" height="20" />}
                    >
                        Switch Back
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        href="/logout"
                        startIcon={<Power width="20" height="20" />}
                    >
                        Logout
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default DashboardHeader;
