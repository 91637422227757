import React, { useState } from "react";
import {
    Button,
    CardContent,
    Grid,
    Link,
    Breadcrumbs,
    Card,
    Divider,
    Typography
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import MessageSnackbar from "./MessageSnackbar";

const useStyles = makeStyles(theme => ({
    root: {
        margin: `${theme.spacing(2)}px auto`
    },
    dropzoneText: {
        padding: theme.spacing(3)
    }
}));

const FileDropzone = props => {
    const classes = useStyles();
    const [message, setMessage] = useState({
        variant: "",
        text: ""
    });
    const { title, setFiles, text, limit, ...rest } = props;

    const [errorMessage, setErrorMessage] = useState(false);

    const handleFiles = files => {
        setFiles(files);
        if (files && files.length > 0) {
            setMessage({
                variant: "success",
                text: `Added ${files.length} Files`
            });
        }
    };

    return (
        <div className={classes.root} {...rest}>
            <Typography variant="h6" gutterBottom>
                <>
                    {title ? title : "File Upload"}
                    <br />
                    <small>
                        You can upload up to{" "}
                        <strong>{limit ? limit : 1}</strong> files at one time.
                    </small>
                </>
            </Typography>
            <DropzoneArea
                showFileNames
                showFileNamesInPreview
                onChange={files => handleFiles(files)}
                filesLimit={limit ? limit : 1}
                maxFileSize={5000000}
                dropzoneText={
                    text ? text : "Drag and drop an image here or click"
                }
                acceptedFiles={["image/*"]}
                dropzoneParagraphClass={classes.dropzoneText}
                // getFileAddedMessage={(fileName) => ''}
                // getDropRejectMessage={(rejectedFile) => `${rejectedFile} REJECTED!`}
                // getFileRemovedMessage={(fileName) => `${fileName} REMOVED!`}
                getFileLimitExceedMessage={filesLimit =>
                    `Maximum exceeded. You can upload up to ${filesLimit} files at one time.`
                }
                showAlerts={["error"]}
            />
            {message.variant !== "" && message.text !== "" && (
                <MessageSnackbar
                    variant={message.variant}
                    message={message.text}
                />
            )}
        </div>
    );
};

export default FileDropzone;
