import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import { Menu, MenuItem, Hidden, Button, withStyles } from "@material-ui/core";
import ContextualComponent from "@assets/ContextualComponent";

export default function Dropdown(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [active, setActive] = useState(null);

    const StyledMenu = withStyles({
        paper: {
            border: "1px solid #d3d4d5",
            width: "250px"
        }
    })(props => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            style={{ width: 200 }}
            MenuListProps={{ style: { padding: 0 } }}
            {...props}
        />
    ));

    const StyledMenuItem = withStyles({
        root: {
            justifyContent: "center"
        }
    })(MenuItem);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
        setActive("primary");
    }

    function handleClose() {
        setAnchorEl(null);
        setActive(null);
    }

    function navigate(route) {
        window.location = route;
    }
    return (
        <ContextualComponent>
            <Hidden only={["xs", "sm"]}>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color={active}
                >
                    {reactProps.auth.email}
                </Button>
            </Hidden>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                onClose={handleClose}
                keepMounted
                open={Boolean(anchorEl)}
                p={0}
                paper={{
                    maxWidth: "200px"
                }}
            >
                <StyledMenuItem
                    className="dropdown-item text-center p-2"
                    style={{ wordWrap: "normal" }}
                    onClick={
                        reactProps.role == "admin"
                            ? () => navigate("/admin/account-settings")
                            : () => navigate("/user/settings")
                    }
                    disabled={false}
                >
                    Account Settings
                </StyledMenuItem>
                <StyledMenuItem
                    className="dropdown-item text-center p-2"
                    style={{ wordWrap: "normal" }}
                    onClick={() => navigate("/logout")}
                >
                    Log Out
                </StyledMenuItem>
            </StyledMenu>
        </ContextualComponent>
    );
}

if (document.getElementById("dropdown-menu")) {
    ReactDOM.render(<Dropdown />, document.getElementById("dropdown-menu"));
}
