import React, { Component } from "react";
import { adminPalette, typography, overrides, breakpoints } from "./Theme";
import MessageSnackbar from "../components/global/MessageSnackbar";
import { ThemeProvider } from "@material-ui/core/styles";

const defaultPalette = adminPalette;

export default class ContextualComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            errors: null,
            palette: defaultPalette
        };
        this.checkForMessage = this.checkForMessage.bind(this);
        this.checkForErrors = this.checkForErrors.bind(this);
    }

    componentDidMount() {
        this.checkForMessage();
        this.checkForErrors();
        console.log(reactProps);
    }

    checkForMessage() {
        if (reactProps) {
            if (reactProps.message) {
                this.setState({
                    message: reactProps.message
                });
            }
        }
    }

    checkForErrors() {
        if (reactProps && reactProps.errors) {
            this.setState({ errors: reactProps.errors });
        }
    }

    render() {
        let palette;
        if (this.props.organization) {
            palette = defaultPalette;
        } else {
            palette = defaultPalette;
        }
        return (
            <ThemeProvider theme={palette}>
                <ThemeProvider theme={palette => ({ typography, ...palette })}>
                    <ThemeProvider
                        theme={typography => ({ overrides, ...typography })}
                    >
                        <ThemeProvider
                            theme={overrides => ({ breakpoints, ...overrides })}
                        >
                            {this.props.children}
                            {this.state.message && this.state.message != "" && (
                                <MessageSnackbar
                                    message={reactProps.message}
                                    variant="success"
                                />
                            )}
                            {this.state.errors && (
                                <MessageSnackbar
                                    message={this.state.errors}
                                    variant="error"
                                />
                            )}
                        </ThemeProvider>
                    </ThemeProvider>
                </ThemeProvider>
            </ThemeProvider>
        );
    }
}
