import React, { useState } from "react";
import { Grid, TextField, Paper, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import FileDropzone from "@global/FileDropzone";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    },
    image: {
        height: 100,
        width: "auto"
    },
    center: {
        alignContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        width: "20%"
    }
}));

const Form = props => {
    const classes = useStyles();
    const { handleSubmit, organization } = props;
    const [files, setFiles] = useState([]);
    const [values, setValues] = useState({
        name: organization ? organization.name : "",
        url: organization ? organization.url : "",
        description: organization ? organization.description : "",
        user_name: "",
        user_email: "",
        planName: organization ? "" : "Team",
        user_id: organization ? organization.user_id : null,
        max_facilitators: organization?.max_facilitators
            ? organization.max_facilitators
            : "",
        trial_length: organization ? "" : "30",
        subscription_type: "annual"
    });

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.padded}>
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Organization Name"
                        value={values.name}
                        onChange={handleChange("name")}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Website URL"
                        value={values.url}
                        onChange={handleChange("url")}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        label="Description"
                        value={values.description}
                        onChange={handleChange("description")}
                    />
                    {!organization ? (
                        <>
                            <TextField
                                fullWidth
                                required
                                margin="normal"
                                label="Primary User Name"
                                value={values.user_name}
                                onChange={handleChange("user_name")}
                            />
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                label="Primary User Email Address"
                                value={values.user_email}
                                onChange={handleChange("user_email")}
                            />
                            <TextField
                                select
                                required
                                fullWidth
                                label="Plan"
                                margin="normal"
                                SelectProps={{
                                    native: true
                                }}
                                value={values.planName}
                                onChange={handleChange("planName")}
                            >
                                <option value="Team">Team</option>
                                {/* <option value="Education">Education</option> */}
                                <option value="Enterprise">Enterprise</option>
                            </TextField>
                            <TextField
                                select
                                required
                                fullWidth
                                label="Payment Rate"
                                margin="normal"
                                SelectProps={{
                                    native: true
                                }}
                                value={values?.subscription_type}
                                onChange={handleChange("subscription_type")}
                            >
                                <option value="annual">Yearly</option>
                                <option value="monthly">Monthly</option>
                            </TextField>
                            {values?.planName == "Enterprise" && (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    value={values.max_facilitators}
                                    placeholder="Unlimited"
                                    label={`Enter max number of Facilitators for ${values.planName} Plan (otherwise it will be unlimited)`}
                                    onChange={handleChange("max_facilitators")}
                                />
                            )}

                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                value={values.trial_length}
                                label="Trial length (in days)"
                                onChange={handleChange("trial_length")}
                            />
                        </>
                    ) : (
                        <TextField
                            select
                            disabled
                            required
                            fullWidth
                            SelectProps={{
                                native: true
                            }}
                            margin="normal"
                            label="Primary User"
                            value={values.user_id}
                            onChange={handleChange("user_id")}
                        >
                            {organization.users.map(user => (
                                <option key={user.id} value={user.id}>
                                    {user.name}
                                </option>
                            ))}
                        </TextField>
                    )}
                    <div className={classes.fileArea}>
                        {organization && organization.logoImage && (
                            <div className={classes.center}>
                                <Typography variant="body2" gutterBottom>
                                    Current Logo
                                </Typography>
                                <img
                                    src={organization.logoImage}
                                    alt="logo image"
                                    className={classes.image}
                                />
                            </div>
                        )}
                        <FileDropzone files={files} setFiles={setFiles} />
                    </div>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleSubmit(values, files)}
                                className={clsx(
                                    classes.floatRight,
                                    classes.marginTop
                                )}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Form;
