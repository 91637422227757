import React from "react";
import { TextField, Grid } from "@material-ui/core";
import states from "@utils/states";
import countries from "@utils/countries";

const Form = props => {
    const { values, handleChange, handleChangeCountry } = props;

    return (
        <>
            <TextField
                required
                fullWidth
                margin="normal"
                label="Name"
                value={values.name}
                onChange={handleChange("name")}
            />
            <TextField
                required
                fullWidth
                margin="normal"
                label="Email Address"
                value={values.email}
                onChange={handleChange("email")}
            />
            <TextField
                fullWidth
                label="Title"
                margin="normal"
                value={values.title}
                onChange={handleChange("title")}
            />
            <TextField
                fullWidth
                label="City"
                margin="normal"
                value={values.city}
                onChange={handleChange("city")}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="State"
                        margin="normal"
                        value={values.state}
                        onChange={handleChange("state")}
                        select
                        SelectProps={{ native: true }}
                        disabled={values.country !== "US"}
                    >
                        <option value=""></option>
                        {states.map(state => (
                            <option
                                value={state.abbreviation}
                                key={state.abbreviation}
                            >
                                {state.name}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Country"
                        margin="normal"
                        value={values.country}
                        onChange={handleChangeCountry}
                        select
                        SelectProps={{ native: true }}
                    >
                        {countries.map(country => (
                            <option
                                value={country.abbreviation}
                                key={country.abbreviation}
                            >
                                {country.name}
                            </option>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </>
    );
};

export default Form;
