import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import {
    Badge,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    withStyles
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import ContextualComponent from "@assets/ContextualComponent";

export default function PatientQueueNotifications() {
    const [subscribed, setSubscribed] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [active, setActive] = useState("default");

    const StyledMenu = withStyles({
        paper: {
            border: "1px solid #d3d4d5",
            width: "250px",
            maxHeight: "50%"
        }
    })(props => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            MenuListProps={{ style: { padding: 0 } }}
            {...props}
        />
    ));

    const StyledMenuItem = withStyles({
        root: {
            justifyContent: "center"
        }
    })(MenuItem);

    useEffect(() => {
        if (!subscribed) {
            setSubscribed(true);
            getPatientQueueNotifications();

            setInterval(
                function() {
                    getPatientQueueNotifications();
                }.bind(this),
                30000
            );
        }
    });

    return (
        <ContextualComponent>
            <Tooltip title="Patient Queue">
                <IconButton
                    aria-controls="patient-queue-menu"
                    aria-haspopup="true"
                    color={active}
                    onClick={handleClick}
                >
                    {notifications.length == 0 ? (
                        <ErrorIcon />
                    ) : (
                        <Badge
                            badgeContent={notifications.length}
                            color="error"
                        >
                            <ErrorIcon />
                        </Badge>
                    )}
                </IconButton>
            </Tooltip>

            <StyledMenu
                id="patient-queue-menu"
                anchorEl={anchorEl}
                keepMounted
                onClose={handleClose}
                open={Boolean(anchorEl)}
                paper={{
                    maxWidth: "200px"
                }}
            >
                {notifications.length == 0 ? (
                    <StyledMenuItem onClick={handleClose}>
                        Patient Queue is empty.
                    </StyledMenuItem>
                ) : (
                    <div className="styled-menu-items-container">
                        {notifications.map(notification => {
                            return (
                                <StyledMenuItem
                                    key={notification.id}
                                    className="dropdown-item text-center p-2"
                                    style={{ wordWrap: "normal" }}
                                    onClick={() =>
                                        navigate(
                                            "/admin/inquiries/" +
                                                notification.id +
                                                "/edit"
                                        )
                                    }
                                >
                                    {uppercase(notification.type)} -{" "}
                                    {notification.patient.first_name}{" "}
                                    {notification.patient.last_name}
                                </StyledMenuItem>
                            );
                        })}
                    </div>
                )}
            </StyledMenu>
        </ContextualComponent>
    );

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
        setActive("primary");
    }

    function handleClose() {
        setAnchorEl(null);
        setActive("default");
    }

    function getPatientQueueNotifications() {
        axios
            .get("/api/v1/admin/patient-queue?token=" + reactProps.userToken)
            .then(response => {
                console.log(response);
                setNotifications(response.data.inquiries);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function navigate(route) {
        window.location = route;
    }

    function uppercase(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

if (document.getElementById("admin-patient-queue-notifications")) {
    ReactDOM.render(
        <PatientQueueNotifications />,
        document.getElementById("admin-patient-queue-notifications")
    );
}
