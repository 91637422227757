/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require("./bootstrap");
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require("./components/Index");
require("./components/Example");
require("./components/Pricing");
require("./components/ReActivate");
require("./components/OrganizationPlanExpired");
require("./components/About");
require("./components/Feedback");
require("./components/Contact");
require("./components/NoPayment");

require("./components/global/PrivacyPolicy");
require("./components/global/TermsOfUse");

require("./components/nav/Nav");

require("./components/users/Index");
require("./components/users/Organization");

require("./components/plans/Registration");

require("./components/facilitators/users/Index");
require("./components/facilitators/users/Show");
require("./components/facilitators/users/Invite");

require("./components/facilitators/ManagePlan");
require("./components/facilitators/QuickStartAdjustments");
require("./components/facilitators/Activities");
require("./components/facilitators/Instructions");

require("./components/facilitators/sessions/Index");
require("./components/facilitators/sessions/OrganizationsIndex");
require("./components/facilitators/sessions/Create");
require("./components/facilitators/sessions/Edit");
require("./components/facilitators/sessions/Presentation");
require("./components/facilitators/sessions/Archive");

require("./components/facilitators/decks/Index");
require("./components/facilitators/decks/Create");
require("./components/facilitators/decks/Edit");

require("./components/facilitators/cards/Index");
require("./components/facilitators/cards/Create");
require("./components/facilitators/cards/Edit");

require("./components/auth/Login");
require("./components/auth/Register");
require("./components/auth/UserProfile");
require("./components/auth/ChangePassword");
require("./components/auth/ForgotPassword");
require("./components/auth/ResetPassword");

require("./components/admin/Dashboard");
require("./components/admin/users/Edit");
require("./components/admin/users/Index");
require("./components/admin/users/Invite");
require("./components/admin/users/Export");

require("./components/admin/organizations/Index");
require("./components/admin/organizations/Create");
require("./components/admin/organizations/Edit");

require("./components/admin/cards/Index");
require("./components/admin/cards/Create");
require("./components/admin/cards/Edit");

require("./components/admin/decks/Index");
require("./components/admin/decks/Create");
require("./components/admin/decks/Edit");

require("./components/admin/sessions/Index");
require("./components/admin/sessions/Edit");
require("./components/admin/sessions/Create");

require("./components/admin/roles/Edit");
require("./components/admin/roles/Index");
require("./components/admin/roles/Create");
require("./components/admin/Notifications");
require("./components/admin/PatitentQueueNotifications");

require("./components/admin/coupons/Index");
require("./components/admin/coupons/Create");

require("./components/global/Dropdown");

require("./components/sessions/Index");
require("./components/sessions/NotFound");
require("./components/sessions/ThankYou");
