import { createMuiTheme } from '@material-ui/core/styles';

const overrides = createMuiTheme({
    overrides: {
        button: {
            raisedPrimary: {
                color: '#fff',
            },
        },
        MUIDataTable: {
            responsiveScroll: {
                overflowX: 'none',
                height: 'auto',
                maxHeight: 'auto',
                overflow: 'visible',
            },
        },
        MuiPaper: {
            elevation4: {
                boxShadow: 'none',
            },
        },
        MuiToolbar: {
            root: {
                borderBottom: '1px solid #c8ced3',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.04)',
            },
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    backgroundColor: '#099492',
                    color: '#fff',
                },
                borderBottom: '1px solid #000',
            },
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: '#099492',
                    color: '#fff',
                    '@media (hover: none)': {
                        color: '#099492',
                        '&:active': {
                            color: '#099492',
                        },
                        '&:focus': {
                            color: '#099492',
                        },
                    },
                },
                zIndex: 3,
            },
        },
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: '#099492',
                    color: '#fff',
                    '@media (hover: none)': {
                        color: '#099492',
                        '&:active': {
                            color: '#099492',
                        },
                        '&:focus': {
                            color: '#099492',
                        },
                    },
                },
            },
        },
    },
});

export default overrides;
