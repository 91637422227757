import React, { useState } from "react";
import {
    DialogContent,
    DialogActions,
    Button,
    MenuItem,
    Typography,
    TextField,
    Link
} from "@material-ui/core";
import { upperCaseFirst } from "@utils/stringUtils";
import { find, filter, sortBy } from "lodash";

const ChangePlanDialog = props => {
    const { user, planName, userToken } = reactProps;
    const { handleClose, setLoading, getErrorMessage } = props;
    const [selectedPlan, setSelectedPlan] = useState("");
    const [organizationName, setOrganizationName] = useState("");

    const handleOrganizationChange = event => {
        setOrganizationName(event.target.value);
    };

    const handleChange = event => {
        setSelectedPlan(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("plan_name", selectedPlan);
        params.append(
            "plan",
            find(plans, function(plan) {
                return plan.name == selectedPlan;
            }).id
        );

        if (
            selectedPlan == "Enterprise" ||
            selectedPlan == "Team" ||
            selectedPlan == "Education" ||
            selectedPlan == "enterprise" ||
            selectedPlan == "team" ||
            selectedPlan == "education"
        ) {
            params.append("organization_name", organizationName);
        }

        axios
            .post(`/api/v1/admin/users/${user.uuid}/change-plan`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Plan Changed");
                window.location = `/admin/users/${user.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const activePlans = filter(reactProps.plans, function(plan) {
        return Boolean(plan.active) == true;
    });

    const plans = [
        find(activePlans, { name: "Basic" }),
        find(activePlans, { name: "Plus" }),
        find(activePlans, { name: "Professional" }),
        find(activePlans, { name: "Team" }),
        find(activePlans, { name: "Education" }),
        find(activePlans, { name: "Enterprise" })
    ];

    return (
        <>
            <DialogContent>
                <Typography gutterBottom>
                    <strong>{user.name}</strong> belongs to the organization,{" "}
                    <strong>{user.organization.name}</strong>. If you would like
                    to move this user to a new plan <strong>{user.name}</strong>{" "}
                    will be removed from{" "}
                    <strong>{user.organization.name}</strong>. If, instead, you
                    would like to change
                    <strong> {user.organization.name}</strong>'s primary plan,
                    change <strong>{user.organization.name}</strong>'s owner,
                    <Link
                        component="a"
                        href={`/admin/users/${user.organization.user.uuid}/edit`}
                    >
                        {` ${user.organization.user.name}'s plan.`}
                    </Link>
                </Typography>
                <TextField
                    select
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true
                    }}
                    label="Select New Plan"
                    onChange={handleChange}
                    value={selectedPlan.name}
                >
                    <MenuItem value="">None Selected</MenuItem>
                    {plans.map(p => (
                        <MenuItem
                            key={p.id}
                            value={p.name}
                            disabled={upperCaseFirst(planName) == p.name}
                        >
                            {upperCaseFirst(planName) == p.name
                                ? `${p.name} - current plan`
                                : p.name}
                        </MenuItem>
                    ))}
                </TextField>
                {(selectedPlan == "Team" ||
                    selectedPlan == "Enterprise" ||
                    selectedPlan == "Education") && (
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        onChange={handleOrganizationChange}
                        value={organizationName}
                        label="Organization Name"
                        helperText="The plan you have selected requires the creation of an organization"
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={selectedPlan == ""}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    );
};

export default ChangePlanDialog;
