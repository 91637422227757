import React, { useState } from "react";
import { Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MessageSnackbar from "@global/MessageSnackbar";
import MultipleCardForm from "./MultipleCardForm";
import SingleCardForm from "./SingleCardForm";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    },
    image: {
        height: 100,
        width: "auto"
    },
    center: {
        alignContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        width: "20%"
    },
    alignRight: {
        textAlign: "right"
    }
}));

const Form = props => {
    const classes = useStyles();

    const [showMultiForm, setShowMultiForm] = useState(false);
    const [message, setMessage] = useState("");

    const toggleForm = () => setShowMultiForm(show => !show);

    return (
        <Paper className={clsx(classes.padded, classes.alignRight)}>
            {!props.editForm && (
                <Button
                    onClick={toggleForm}
                    variant="contained"
                    color="primary"
                >
                    {`Upload ${showMultiForm ? "A Single" : "Multiple"} Card${
                        showMultiForm ? "" : "s"
                    }`}
                </Button>
            )}
            {showMultiForm ? (
                <MultipleCardForm />
            ) : (
                <SingleCardForm {...props} />
            )}
            {message && message != "" && (
                <MessageSnackbar variant="success" message={message} />
            )}
        </Paper>
    );
};

export default Form;
