import React, { useState } from "react";
import plans from "../utils/plans";
import {
    Typography,
    Grid,
    Switch,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Check } from "react-feather";
import clsx from "clsx";
import { isString } from "lodash";

const useStyles = makeStyles(theme => ({
    root: {},
    pricingRow: {
        border: "none"
    },
    borderSides: {
        borderLeft: "1px solid #999",
        borderRight: "1px solid #999"
    },
    borderLeft: {
        borderLeft: "1px solid #999"
    },
    borderRight: {
        borderRight: "1px solid #999"
    },
    trialBox: {
        backgroundColor: "#05597B",
        margin: 0,
        padding: theme.spacing(2)
    },
    trialText: {
        color: "white"
    },
    activationLogo: {
        height: 64,
        width: "auto",
        position: "relative",
        bottom: 85,
        zIndex: 9999
    }
}));

const PricingTable = () => {
    const classes = useStyles();
    const [type, setType] = useState("monthly");
    const { activation, userPlanName } = reactProps;
    const toggleType = () => {
        let currentType = type;

        if (currentType == "monthly") {
            setType("annual");
        } else {
            setType("monthly");
        }
    };

    return (
        // <Table>
        //     <TableBody>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 <Typography gutterBottom variant="h4" align="center">
        //                     Features
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom variant="h4" align="center">
        //                     Basic
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom variant="h4" align="center">
        //                     Plus
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom variant="h4" align="center">
        //                     Professional
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom variant="h4" align="center">
        //                     Team
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom variant="h4" align="center">
        //                     Education
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(classes.pricingRow, classes.borderLeft)}
        //             >
        //                 <Typography gutterBottom variant="h4" align="center">
        //                     Enterprise
        //                 </Typography>
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 align="center"
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 <Typography component="label" align="center">
        //                     <Grid
        //                         component="label"
        //                         container
        //                         alignItems="center"
        //                         spacing={1}
        //                     >
        //                         <Grid item>Monthly</Grid>
        //                         <Grid item>
        //                             <Switch
        //                                 color="primary"
        //                                 onChange={toggleType}
        //                                 checked={type == "annual"}
        //                             />
        //                         </Grid>
        //                         <Grid item>Yearly</Grid>
        //                     </Grid>
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom align="center">
        //                     {type == "monthly" ? (
        //                         <span>
        //                             <strong>$5/</strong>mo
        //                         </span>
        //                     ) : (
        //                         <span>
        //                             <strong>$50/</strong>yr
        //                         </span>
        //                     )}
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom align="center">
        //                     {type == "monthly" ? (
        //                         <span>
        //                             <strong>$10/</strong>mo
        //                         </span>
        //                     ) : (
        //                         <span>
        //                             <strong>$100/</strong>yr
        //                         </span>
        //                     )}
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom align="center">
        //                     {type == "monthly" ? (
        //                         <span>
        //                             <strong>$20/</strong>mo
        //                         </span>
        //                     ) : (
        //                         <span>
        //                             <strong>$200/</strong>yr
        //                         </span>
        //                     )}
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom align="center">
        //                     {type == "monthly" ? (
        //                         <span>
        //                             <strong>$25/</strong>mo
        //                         </span>
        //                     ) : (
        //                         <span>
        //                             <strong>$250/</strong>yr
        //                         </span>
        //                     )}
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography gutterBottom align="center">
        //                     {type == "monthly" ? (
        //                         <span>
        //                             <strong>$50/</strong>mo
        //                         </span>
        //                     ) : (
        //                         <span>
        //                             <strong>$500/</strong>yr
        //                         </span>
        //                     )}
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(classes.pricingRow, classes.borderLeft)}
        //             >
        //                 <Typography gutterBottom align="center">
        //                     {type == "monthly" ? (
        //                         <span>
        //                             <strong>$100/</strong>mo
        //                         </span>
        //                     ) : (
        //                         <span>
        //                             <strong>$997/</strong>yr
        //                         </span>
        //                     )}
        //                 </Typography>
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.trialBox,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 <Typography
        //                     className={classes.trialText}
        //                     align="center"
        //                 >
        //                     30-day free trial
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.trialBox,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography
        //                     className={classes.trialText}
        //                     align="center"
        //                 >
        //                     <Check className={classes.trialText} />
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.trialBox,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography
        //                     className={classes.trialText}
        //                     align="center"
        //                 >
        //                     <Check className={classes.trialText} />
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.trialBox,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography
        //                     className={classes.trialText}
        //                     align="center"
        //                 >
        //                     <Check className={classes.trialText} />
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.trialBox,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography
        //                     className={classes.trialText}
        //                     align="center"
        //                 >
        //                     <Check className={classes.trialText} />
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.trialBox,
        //                     classes.borderSides
        //                 )}
        //             >
        //                 <Typography
        //                     className={classes.trialText}
        //                     align="center"
        //                 >
        //                     <Check className={classes.trialText} />
        //                 </Typography>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.trialBox,
        //                     classes.borderLeft
        //                 )}
        //             >
        //                 <Typography
        //                     className={classes.trialText}
        //                     align="center"
        //                 >
        //                     <Check className={classes.trialText} />
        //                 </Typography>
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 Users
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 1
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 1
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 1
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 Up to 10
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 Up to 75
        //             </TableCell>
        //             <TableCell className={classes.pricingRow} align="center">
        //                 Up to 300
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 Active Sessions
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 3
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 3
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 10
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 5 Per User
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 5 Per User
        //             </TableCell>
        //             <TableCell className={classes.pricingRow} align="center">
        //                 Unlimited
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 Draft Sessions
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 1
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 Up to 5
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 Up to 10
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 10 Per User
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 10 Per User
        //             </TableCell>
        //             <TableCell className={classes.pricingRow} align="center">
        //                 Unlimited
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 Original Climer Cards
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell className={classes.pricingRow} align="center">
        //                 <Check />
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 + Additional Decks
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell className={classes.pricingRow} align="center">
        //                 <Check />
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 Custom Decks
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell className={classes.pricingRow} align="center">
        //                 <Check />
        //             </TableCell>
        //         </TableRow>
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 White Labeling
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell className={classes.pricingRow} align="center">
        //                 <Check />
        //             </TableCell>
        //         </TableRow>
        //         {/* <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             >
        //                 Session Templates
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //                 align="center"
        //             >
        //                 <Check />
        //             </TableCell>
        //         </TableRow> */}
        //         <TableRow className={classes.pricingRow}>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderRight
        //                 )}
        //             ></TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //                 align="center"
        //             >
        //                 <Button
        //                     component="a"
        //                     color="primary"
        //                     variant="outlined"
        //                     href={
        //                         activation
        //                             ? `/re-activate/basic/${type}`
        //                             : `/register/basic/${type}`
        //                     }
        //                 >
        //                     Get Started
        //                 </Button>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //                 align="center"
        //             >
        //                 <Button
        //                     component="a"
        //                     color="primary"
        //                     variant="contained"
        //                     href={
        //                         activation
        //                             ? `/re-activate/plus/${type}`
        //                             : `/register/plus/${type}`
        //                     }
        //                 >
        //                     Get Started
        //                 </Button>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //                 align="center"
        //             >
        //                 <Button
        //                     component="a"
        //                     color="primary"
        //                     variant="outlined"
        //                     href={
        //                         activation
        //                             ? `/re-activate/professional/${type}`
        //                             : `/register/professional/${type}`
        //                     }
        //                 >
        //                     Get Started
        //                 </Button>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //                 align="center"
        //             >
        //                 <Button
        //                     component="a"
        //                     color="primary"
        //                     variant="outlined"
        //                     href={
        //                         activation
        //                             ? `/re-activate/team/${type}`
        //                             : `/register/team/${type}`
        //                     }
        //                 >
        //                     Get Started
        //                 </Button>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(
        //                     classes.pricingRow,
        //                     classes.borderSides
        //                 )}
        //                 align="center"
        //             >
        //                 <Button
        //                     component="a"
        //                     color="primary"
        //                     variant="outlined"
        //                     href={
        //                         activation
        //                             ? `/re-activate/education/${type}`
        //                             : `/register/education/${type}`
        //                     }
        //                 >
        //                     Get Started
        //                 </Button>
        //             </TableCell>
        //             <TableCell
        //                 className={clsx(classes.pricingRow, classes.borderLeft)}
        //                 align="center"
        //             >
        //                 <Button
        //                     component="a"
        //                     color="primary"
        //                     variant="outlined"
        //                     href={
        //                         activation
        //                             ? `/re-activate/enterprise/${type}`
        //                             : `/register/enterprise/${type}`
        //                     }
        //                 >
        //                     Get Started
        //                 </Button>
        //             </TableCell>
        //         </TableRow>
        //     </TableBody>
        // </Table>
        <Table>
            <TableBody>
                <TableRow className={classes.pricingRow}>
                    <TableCell className={clsx(classes.pricingRow)}>
                        <Typography gutterBottom variant="h4" align="center">
                            Features
                        </Typography>
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                        >
                            <Typography
                                gutterBottom
                                variant="h4"
                                align="center"
                            >
                                {plan?.name || ""}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        align="center"
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        <Typography component="label" align="center">
                            <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid item>Monthly</Grid>
                                <Grid item>
                                    <Switch
                                        color="primary"
                                        onChange={toggleType}
                                        checked={type == "annual"}
                                    />
                                </Grid>
                                <Grid item>Yearly</Grid>
                            </Grid>
                        </Typography>
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                        >
                            <Typography gutterBottom align="center">
                                {type == "monthly" ? (
                                    <span>
                                        {isString(plan.price.monthly) ? (
                                            <strong>
                                                {plan?.price?.monthly}
                                            </strong>
                                        ) : (
                                            <>
                                                <strong>
                                                    ${plan?.price?.monthly}/
                                                </strong>
                                                mo
                                            </>
                                        )}
                                    </span>
                                ) : (
                                    <span>
                                        {isString(plan.price.yearly) ? (
                                            <strong>
                                                {plan?.price?.yearly}
                                            </strong>
                                        ) : (
                                            <>
                                                <strong>
                                                    ${plan?.price?.yearly}/
                                                </strong>
                                                yr
                                            </>
                                        )}
                                    </span>
                                )}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.trialBox,
                            classes.borderRight
                        )}
                    >
                        <Typography
                            className={classes.trialText}
                            align="center"
                        >
                            30-day free trial
                        </Typography>
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.trialBox,
                                classes.borderLeft
                            )}
                        >
                            <Typography
                                className={classes.trialText}
                                align="center"
                            >
                                {plan?.trial_30_day && (
                                    <Check className={classes.trialText} />
                                )}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell className={clsx(classes.pricingRow)}>
                        Users
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                            align="center"
                        >
                            {plan?.users || ""}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        Active Sessions
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                            align="center"
                        >
                            {plan?.active_sessions || ""}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        Draft Sessions
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                            align="center"
                        >
                            {plan?.draft_sessions || ""}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        Original Climer Cards
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                            align="center"
                        >
                            {plan?.criginal_climer_cards && <Check />}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        + Additional Decks
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                            align="center"
                        >
                            {plan?.additional_decks && <Check />}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        Custom Decks
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                            align="center"
                        >
                            {plan?.custom_decks && <Check />}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    >
                        White Labeling
                    </TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                            align="center"
                        >
                            {plan?.white_labeling && <Check />}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className={classes.pricingRow}>
                    <TableCell
                        className={clsx(
                            classes.pricingRow,
                            classes.borderRight
                        )}
                    ></TableCell>
                    {plans?.map((plan, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.pricingRow,
                                classes.borderLeft
                            )}
                            align="center"
                        >
                            {plan?.link?.includes("https://") ||
                            plan?.link?.includes("http://") ? (
                                <Button
                                    component="a"
                                    color="primary"
                                    variant={
                                        userPlanName === plan?.name
                                            ? "contained"
                                            : "outlined"
                                    }
                                    href={plan?.link}
                                >
                                    {plan?.action_text || "Get Started"}
                                </Button>
                            ) : (
                                <Button
                                    component="a"
                                    color="primary"
                                    variant={
                                        userPlanName === plan?.name
                                            ? "contained"
                                            : "outlined"
                                    }
                                    href={
                                        activation
                                            ? `/re-activate/${plan?.link}/${type}`
                                            : `/register/${plan?.link}/${type}`
                                    }
                                >
                                    {"Get Started"}
                                </Button>
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default PricingTable;
