import React, { useState } from "react";
import {
    Toolbar,
    Typography,
    ButtonGroup,
    IconButton,
    Tooltip,
    Button
} from "@material-ui/core";
import { Archive, CheckSquare, Trash2 } from "react-feather";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import { lighten, makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import clsx from "clsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark
              },
    title: {
        flex: "1 1 100%"
    },
    floatRight: {
        float: "right"
    },
    noBorder: {
        border: "none"
    }
}));

const ManageSelections = props => {
    const { selected, sessionStatus } = props;
    const { userToken } = reactProps;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const handleActivate = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", selected);

        axios
            .post(`/api/v1/sessions/activate`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session status updated");
                window.location = `/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleDelete = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", selected);

        axios
            .post(`/api/v1/sessions/deleted`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session has been deleted");
                window.location = `${window.location}?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleArchive = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", selected);

        axios
            .post(`/api/v1/sessions/archive`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session status updated");
                window.location = `/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleUnArchive = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", selected);

        axios
            .post(`/api/v1/sessions/unarchive`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session status updated");
                window.location = `/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Toolbar className={clsx(classes.root, classes.highlight)}>
            <Typography
                component="div"
                color="inherit"
                variant="subtitle1"
                className={classes.title}
            >
                {selected.length} selected
            </Typography>
            <div className={classes.floatRight}>
                <ButtonGroup>
                    <Tooltip title="Delete selected sessions">
                        <IconButton
                            aria-label="delete"
                            onClick={handleOpenModal}
                        >
                            <Trash2 />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        disableElevation
                        component={Button}
                        className={classes.noBorder}
                        title="Make selected sessions active"
                    >
                        <IconButton
                            aria-label="activate"
                            onClick={handleActivate}
                        >
                            <CheckSquare />
                        </IconButton>
                    </Tooltip>
                    {sessionStatus === "active" ? (
                        <Tooltip title="Archive selected sessions">
                            <IconButton
                                aria-label="archive"
                                onClick={handleArchive}
                            >
                                <Archive />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Un-Archive selected sessions">
                            <IconButton
                                aria-label="un-archive"
                                onClick={handleUnArchive}
                            >
                                <UnarchiveIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </ButtonGroup>
            </div>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            <Dialog
                open={Boolean(openModal)}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography
                        gutterBottom
                        style={{ fontSize: 20 }}
                        class="text-center"
                    >
                        <strong>
                            Are you sure you would like to delete selected
                            session?
                        </strong>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{ fontSize: 18 }}
                    >
                        This action is permanent and cannot be undone!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Toolbar>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default ManageSelections;
