import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UserProfile from "./components/UserProfile";
import LoadingBackdrop from "@global/LoadingBackdrop";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const Show = () => {
    const { user, auth, organization, userToken } = reactProps;
    const classes = useStyles();
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleOpen = newOpen => {
        setOpen(newOpen);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handlePromote = () => {
        setLoading(true);
        axios
            .post(`/api/v1/users/${user.uuid}/promote`, null, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    `${user.name} is now a manager`
                );
                window.location = `/users/${user.uuid}?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleDemote = () => {
        setLoading(false);
        axios
            .post(`/api/v1/users/${user.uuid}/demote`, null, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    `${user.name} is no longer a manager`
                );
                window.location = `/users/${user.uuid}?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Users
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {auth.id == organization.user_id && (
                        <div className={classes.floatRight}>
                            {user.can_edit_organization ? (
                                <Tooltip
                                    title={`Removing ${user.name}'s manager permissions would mean that ${user.name} would no longer have the ability to invite other users or edit organization information. ${user.name} will still belong to ${organization.name}`}
                                >
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => handleOpen("demote")}
                                    >
                                        Remove {user.name}'s Manager Permissions
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={`Making ${user.name} a manager would give ${user.name} permission to invite other users and edit organization information`}
                                >
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => handleOpen("promote")}
                                    >
                                        Make {user.name} a Manager
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Users"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Link component="a" href="/users">
                            Users
                        </Link>
                        <Typography>{user.name}</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <UserProfile setLoading={setLoading} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                maxWidth="md"
                open={Boolean(open)}
                onClose={handleClose}
                aria-labelledby="manager-dialog"
            >
                <DialogTitle id="manager-dialog">
                    {open == "promote"
                        ? `Make ${user.name} a manager`
                        : `Remove ${user.name}'s Manager Permissions`}
                </DialogTitle>
                <DialogContent>
                    {open == "promote" && (
                        <Typography gutterBottom align="center">
                            Are you sure that you would like to make {user.name}{" "}
                            an organization manager? This will give {user.name}{" "}
                            the ability to update {organization.name}'s details
                            and invite new users.
                        </Typography>
                    )}
                    {open == "demote" && (
                        <Typography gutterBottom align="center">
                            Are you sure that you woudl like to remove{" "}
                            {user.name}'s manager permissions? {user.name} will
                            no longer have the ability to update{" "}
                            {organization.name}'s details or to invite new
                            users. This will <strong>not</strong> remove{" "}
                            {user.name} from the {organization.name}{" "}
                            Organizaiton
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                    {open == "promote" && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handlePromote}
                        >
                            Confirm
                        </Button>
                    )}
                    {open == "demote" && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleDemote}
                        >
                            Confirm
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
        </DashboardLayout>
    );
};

if (document.getElementById("facilitators-user-show")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Show />
        </ErrorBoundary>,
        document.getElementById("facilitators-user-show")
    );
}
