import React, { useState } from "react";
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CardUploadArea from "./CardUploadArea";
import FileDropzone from "@global/FileDropzone";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import { forEach } from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../themes/theme";

const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop: theme.spacing(6)
    },
    uploadedImage: {
        borderRadius: 4,
        boxShadow: "0px 3px 6px #35405280",
        height: "auto",
        marginBottom: theme.spacing(2),
        width: "100%"
    },
    alignCenter: {
        textAlign: "center"
    }
}));

const FormImages = props => {
    const { userToken } = reactProps;
    const classes = useStyles();
    const { card, values, has_back, orientation, handleChangeDirectly } = props;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(null);
    const [uploads, setUploads] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOpen = side => {
        setOpen(side);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new FormData();
        params.append("side", open);
        forEach(uploads, function(upload) {
            params.append("image", upload);
        });

        axios
            .post(`/api/v1/user/card-media`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                if (open == "front") {
                    handleChangeDirectly(
                        "upload_link",
                        response.data.upload_url.upload_url
                    );
                }

                if (open == "back") {
                    handleChangeDirectly(
                        "back_upload_link",
                        response.data.upload_url.upload_url
                    );
                }
                setLoading(false);
                setOpen(null);
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
            });
    };

    return (
        <>
            <div className={classes.marginTop}>
                <Grid container justify="center" spacing={2}>
                    <Grid
                        item
                        xs={12}
                        lg={values.orientation == "tall" ? 5 : 6}
                        className={classes.alignCenter}
                    >
                        {values.upload_link == "" ? (
                            <>
                                <CardUploadArea
                                    side="front"
                                    title="Front of Card"
                                    handleOpen={handleOpen}
                                    orientation={orientation}
                                />
                                <div
                                    style={{
                                        lineHeight: "1.2em",
                                        marginTop: 15
                                    }}
                                >
                                    For best results, images should be under
                                    150kb and no more than 500px per side.
                                </div>
                            </>
                        ) : (
                            <>
                                <img
                                    alt="uploaded image"
                                    src={values.upload_link}
                                    className={classes.uploadedImage}
                                />
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => handleOpen("front")}
                                >
                                    Update Image
                                </Button>
                            </>
                        )}
                    </Grid>
                    {has_back && (
                        <Grid
                            item
                            xs={12}
                            lg={values.orientation == "tall" ? 5 : 6}
                            className={classes.alignCenter}
                        >
                            {values.back_upload_link == "" ? (
                                <CardUploadArea
                                    side="back"
                                    title="Back of Card"
                                    handleOpen={handleOpen}
                                    orientation={orientation}
                                />
                            ) : (
                                <>
                                    <img
                                        alt="uploaded image"
                                        src={values.back_upload_link}
                                        className={classes.uploadedImage}
                                    />
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => handleOpen("back")}
                                    >
                                        Update Image
                                    </Button>
                                </>
                            )}
                        </Grid>
                    )}
                </Grid>
            </div>
            <Dialog
                fullWidth
                fullScreen={fullScreen}
                maxWidth="md"
                open={Boolean(open)}
                aria-labelledby="upload-dialog"
            >
                <DialogTitle id="upload-dialog">
                    Upload{" "}
                    {open == "front"
                        ? "Front of Card Image"
                        : "Back of Card Image"}
                </DialogTitle>
                <DialogContent>
                    <FileDropzone
                        limit={1}
                        files={uploads}
                        setFiles={setUploads}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={uploads.length == 0}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default FormImages;
