import React, { useState, useRef, useEffect } from "react";
import {
    TextField,
    Typography,
    FormControlLabel,
    Switch,
    InputAdornment,
    Collapse,
    FormGroup
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { filter, find } from "lodash";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    floatRight: {
        textAlign: "right"
    },
    image: {
        height: 100,
        width: "auto"
    },
    center: {
        alignContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        width: "20%"
    }
}));

const Form = props => {
    const classes = useStyles();
    const { decks, auth } = reactProps;
    const {
        session,
        values,
        setValues,
        handleChange,
        disableActions,
        handleSwitch,
        handleChangeDirectly,
        userLowPlan
    } = props;

    console.log("userLowPlan", userLowPlan);

    const linkRef = useRef();
    const [selectedDeck, setSelectedDeck] = useState(
        find(decks, { id: values.deck_id })
    );

    const fillLinkInput = () => {
        if (values.slug === "") {
            handleChangeDirectly(
                "slug",
                values.name.replaceAll(" ", "-").toLowerCase()
            );
        }
    };

    useEffect(() => {
        document.getElementById("session-name-input").focus();
    }, []);

    useEffect(() => {
        if (values.allow_facilitator_flip == true) {
            setValues({
                ...values,
                allow_prompt_flip: false,
                allow_response_flip: false
            });
        }
        if (
            values.allow_prompt_flip == true ||
            values.allow_response_flip == true
        ) {
            setValues({
                ...values,
                allow_facilitator_flip: false
            });
        }
    }, [
        values.allow_facilitator_flip,
        values.allow_prompt_flip,
        values.allow_response_flip
    ]);

    return (
        <>
            <TextField
                required
                fullWidth
                margin="normal"
                label="Session Name"
                id="session-name-input"
                value={values.name}
                onChange={handleChange("name")}
                disabled={disableActions}
                onBlur={fillLinkInput}
            />
            <TextField
                required
                fullWidth
                margin="normal"
                label="Unique Link/Access Code"
                value={values.slug}
                onChange={handleChange("slug")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            onClick={() => linkRef.current.focus()}
                        >
                            https://app.climercards.com/
                        </InputAdornment>
                    )
                }}
                inputRef={linkRef}
                helperText="Create a unique link or access code participants will use to view your session. You can select something meaningful to the group or a random mix of letters and numbers."
                disabled={disableActions}
            />
            <Typography variant="caption">
                https://app.climercards.com/{values.slug}
            </Typography>
            <TextField
                fullWidth
                multiline
                rows={4}
                margin="normal"
                label="Description"
                value={values.description}
                onChange={handleChange("description")}
                disabled={disableActions}
            />
            {/*{decks && decks.length > 0 && (*/}
            {/*    <FormControlLabel*/}
            {/*        label="Use multiple decks in this session"*/}
            {/*        value={values.multiple_decks}*/}
            {/*        onChange={handleSwitch("multiple_decks")}*/}
            {/*        control={*/}
            {/*            <Switch*/}
            {/*                color="primary"*/}
            {/*                checked={values.multiple_decks}*/}
            {/*            />*/}
            {/*        }*/}
            {/*        style={{ display: "block", marginTop: "1rem" }}*/}
            {/*        disabled={session ? true : false}*/}
            {/*    />*/}
            {/*)}*/}
            {values.multiple_decks !== true && (
                <TextField
                    required
                    fullWidth
                    select
                    label="Deck"
                    SelectProps={{
                        native: true
                    }}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={values.deck_id}
                    onChange={e => {
                        setValues({
                            ...values,
                            deck_id: e.target.value,
                            allow_facilitator_flip: false
                        });
                        let deck = find(decks, d => {
                            return d.id == e.target.value;
                        });
                        setSelectedDeck(deck);
                    }}
                    disabled={disableActions}
                >
                    <option value="">None Selected</option>
                    {filter(decks, function(deck) {
                        return Boolean(deck.climer);
                    }).map(deck => (
                        <option value={deck.id} key={deck.id}>
                            {deck.name}
                        </option>
                    ))}
                    {filter(decks, function(deck) {
                        return !Boolean(deck.climer);
                    }).map(deck => (
                        <option value={deck.id} key={deck.id}>
                            {deck.name}
                        </option>
                    ))}
                </TextField>
            )}
            <FormControlLabel
                // label="Will minors under the age of 16 be present in your session?"
                label={
                    values.minor_participants
                        ? "Use with minors under the age of 16"
                        : "Minors under the age 16 will not be present"
                }
                value={values.minor_participants}
                onChange={handleSwitch("minor_participants")}
                control={
                    <Switch
                        color="primary"
                        checked={values.minor_participants}
                    />
                }
                style={{ display: "block" }}
            />
            {values.minor_participants == true && (
                <Alert severity="warning" style={{ marginBottom: 8 }}>
                    For privacy, please ask your participants to avoid typing
                    their full real name.
                </Alert>
            )}
            {!userLowPlan && (
                <FormControlLabel
                    // label="Asynchronous"
                    label={
                        values.asynchronous
                            ? "Allow groups to access the session anytime"
                            : "Session to be accessed synchronously only"
                    }
                    value={values.asynchronous}
                    onChange={handleSwitch("asynchronous")}
                    control={
                        <Switch color="primary" checked={values.asynchronous} />
                    }
                    style={{ display: "block" }}
                />
            )}
            {Boolean(auth.organization_id) && (
                <FormControlLabel
                    // label="Share with my organization"
                    label={
                        values.share
                            ? "Share with my organization"
                            : "Do not share with my organization"
                    }
                    value={values.share}
                    onChange={handleSwitch("share")}
                    control={<Switch color="primary" checked={values.share} />}
                    style={{ display: "block" }}
                />
            )}
            {Boolean(selectedDeck) && (
                <Collapse in={selectedDeck.allow_back == true}>
                    <FormGroup>
                        <FormControlLabel
                            // label="Facilitators can flip all cards"
                            label={
                                values.allow_facilitator_flip
                                    ? "Facilitators can flip all cards"
                                    : "Facilitators cannot flip cards"
                            }
                            value={values.allow_facilitator_flip}
                            onChange={event => {
                                if (event.target.checked === true) {
                                    setValues({
                                        ...values,
                                        allow_facilitator_flip: true,
                                        allow_prompt_flip: false,
                                        allow_response_flip: false
                                    });
                                } else {
                                    handleChangeDirectly(
                                        "allow_facilitator_flip",
                                        event.target.checked
                                    );
                                }
                            }}
                            control={
                                <Switch
                                    color="primary"
                                    checked={values.allow_facilitator_flip}
                                />
                            }
                            disabled={selectedDeck.allow_back == false}
                        />
                        <FormControlLabel
                            // label="Allow participants to flip cards on prompt page"
                            label={
                                values.allow_prompt_flip
                                    ? "Allow participants to flip cards on prompt page"
                                    : "Do not allow participants to flip cards on prompt page"
                            }
                            value={values.allow_prompt_flip}
                            onChange={event =>
                                handleChangeDirectly(
                                    "allow_prompt_flip",
                                    event.target.checked
                                )
                            }
                            control={
                                <Switch
                                    color="primary"
                                    checked={values.allow_prompt_flip}
                                />
                            }
                            disabled={selectedDeck.allow_back == false}
                            style={{ display: "block" }}
                        />
                        <FormControlLabel
                            // label="Allow participants to flip cards on response page"
                            label={
                                values.allow_response_flip
                                    ? "Allow participants to flip cards on response page"
                                    : "Do not allow participants to flip cards on response page"
                            }
                            value={values.allow_response_flip}
                            onChange={event =>
                                handleChangeDirectly(
                                    "allow_response_flip",
                                    event.target.checked
                                )
                            }
                            control={
                                <Switch
                                    color="primary"
                                    checked={values.allow_response_flip}
                                />
                            }
                            disabled={selectedDeck.allow_back == false}
                        />
                    </FormGroup>
                </Collapse>
            )}
        </>
    );
};

export default Form;
