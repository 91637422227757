import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { filter, find } from "lodash";
import PaymentMethodCard from "./PaymentMethodCard";

const PaymentMethod = () => {
    const { paymentMethods, defaultPaymentMethodId } = reactProps;

    const defaultPaymentMethod = find(paymentMethods, function(method) {
        return defaultPaymentMethodId == method.id;
    });

    const backupMethods = filter(paymentMethods, function(method) {
        return defaultPaymentMethodId != method.id;
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h4">
                    Payment Method
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <PaymentMethodCard
                    isDefault
                    paymentMethod={defaultPaymentMethod}
                />
                {backupMethods.length > 0 && (
                    <Typography gutterBottom variant="h4">
                        Alternative Payment Methods
                    </Typography>
                )}
                {backupMethods.map(method => (
                    <PaymentMethodCard key={method.id} paymentMethod={method} />
                ))}
            </Grid>
        </Grid>
    );
};

export default PaymentMethod;
