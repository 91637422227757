import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../components/AdminNav";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";
import {
    Grid,
    Typography,
    Breadcrumbs,
    Link,
    Divider,
    Tabs,
    Tab
} from "@material-ui/core";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import Form from "./components/Form";
import Prompts from "./components/Prompts";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tabHead: {
        borderBottom: "1px solid #ccc",
        marginBottom: theme.spacing(2)
    }
}));

const Edit = () => {
    const { userToken, session, rootUrl } = reactProps;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleSubmit = values => {
        setLoading(true);
        console.log("values", values);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("slug", values.slug);
        params.append("description", values.description);
        params.append("deck_id", values.deck_id);
        params.append("status", values.status);

        if (values.owner.type == "organization") {
            params.append("organization_id", values.owner.id);
        }

        if (values.owner.type == "user") {
            params.append("user_id", values.owner.id);
        }

        axios
            .post(`/api/v1/admin/sessions/${session.uuid}/edit`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session updated");
                window.location = `/admin/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <DashboardLayout sideNav={<AdminNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Sessions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Sessions"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/admin/dashboard">
                            Dashboard
                        </Link>
                        <Link component="a" href="/admin/sessions">
                            Sessions
                        </Link>
                        <Typography>Edit {session.name}</Typography>
                    </Breadcrumbs>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="overline">
                                Session URL
                            </Typography>
                            <Link component="div">
                                {`${rootUrl}/${session.slug}`}
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                className={classes.tabHead}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Prompts" />
                                <Tab label="Details" />
                            </Tabs>
                            {tab == 0 && <Prompts />}
                            {tab == 1 && (
                                <Form
                                    session={session}
                                    handleSubmit={handleSubmit}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("session-edit")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Edit />
        </ErrorBoundary>,
        document.getElementById("session-edit")
    );
}
