import React from "react";
import { Toolbar, Typography, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { lighten, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark
              },
    title: {
        flex: "1 1 100%"
    },
    floatRight: {
        float: "right"
    },
    noBorder: {
        border: "none"
    }
}));

const EditAndDelete = props => {
    const { userToken, session } = reactProps;
    const { selected, getErrorMessage, setLoading } = props;
    const classes = useStyles();

    const handleRemove = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selectedPrompts", selected);

        axios
            .post(`/api/v1/prompts/remove`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Prompts removed");
                window.location = `/sessions/${session.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Toolbar className={clsx(classes.root, classes.highlight)}>
            <Typography
                component="div"
                color="inherit"
                variant="subtitle1"
                className={classes.title}
            >
                {selected.length} selected
            </Typography>
            <div className={classes.floatRight}>
                <Tooltip title="Remove selected prompts">
                    <IconButton aria-label="delete" onClick={handleRemove}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

export default EditAndDelete;
