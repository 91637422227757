import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import UserNav from "@global/UserNav";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";
import {
    Grid,
    Typography,
    Breadcrumbs,
    Link,
    Divider,
    Paper,
    Button,
    FormControlLabel,
    Switch,
    Hidden
} from "@material-ui/core";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import Form from "./components/Form";
import { head } from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../themes/theme";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    formActions: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        "& > a:first-of-type": {
            color: "#f00"
        },
        "& button:last-of-type": {
            marginLeft: theme.spacing(2)
        }
    },
    formContainer: {
        padding: theme.spacing(2)
    },
    divider: {
        marginBottom: theme.spacing(2)
    }
}));

const Create = () => {
    const { userToken, session, decks, auth, plan } = reactProps;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        name: session ? session.name : "",
        slug: session ? session.slug : "",
        asynchronous: session ? Boolean(session.asynchronous) : false,
        description: session ? session.description : "",
        multiple_decks: session ? Boolean(session.multiple_decks) : true,
        deck_id: session ? session.deck_id : head(decks).id,
        share: false,
        allow_facilitator_flip: session
            ? Boolean(session.allow_facilitator_flip)
            : false,
        allow_prompt_flip: session ? Boolean(session.allow_prompt_flip) : true,
        allow_response_flip: session
            ? Boolean(session.allow_response_flip)
            : true,
        minor_participants: session
            ? Boolean(session.minor_participants)
            : false
    });

    const smallButtons = useMediaQuery(theme.breakpoints.down("sm"));

    // Setup permissions for user plan
    const userLowPlan = plan === "basic" || plan === "plus";

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeDirectly = (key, value) => {
        setValues({ ...values, [key]: value });
    };

    const handleSwitch = key => event => {
        setValues({
            ...values,
            [key]: event.target.checked
        });
    };

    const handleBack = () => {
        window.history.back();
    };

    const handleSubmitDraft = () => {
        setLoading(true);
        console.log("values", values);
        let params = new FormData();
        params.append("name", values.name);
        params.append("slug", values.slug);
        params.append("asynchronous", values.asynchronous ? 1 : 0);
        params.append("description", values.description);
        params.append("multiple_decks", values.multiple_decks ? 1 : 0);
        params.append("deck_id", values.deck_id);
        params.append("organization_share", values.share ? 1 : 0);
        params.append(
            "allow_facilitator_flip",
            values.allow_facilitator_flip ? 1 : 0
        );
        params.append("allow_prompt_flip", values.allow_prompt_flip ? 1 : 0);
        params.append(
            "allow_response_flip",
            values.allow_response_flip ? 1 : 0
        );
        params.append(
            "minor_participants",
            values.minor_participants == true ? 1 : 0
        );

        axios
            .post(`/api/v1/sessions/create`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session added");
                window.location = `/sessions/${response.data.session.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleSubmitCreate = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("slug", values.slug);
        params.append("asynchronous", values.asynchronous ? 1 : 0);
        params.append("multiple_decks", values.multiple_decks ? 1 : 0);
        params.append("deck_id", values.deck_id);
        params.append("name", values.name);
        params.append("description", values.description);
        params.append("organization_share", values.share ? 1 : 0);
        params.append("status", "active");
        params.append(
            "allow_facilitator_flip",
            values.allow_facilitator_flip ? 1 : 0
        );
        params.append("allow_prompt_flip", values.allow_prompt_flip ? 1 : 0);
        params.append(
            "allow_response_flip",
            values.allow_response_flip ? 1 : 0
        );
        params.append(
            "minor_participants",
            values.minor_participants == true ? 1 : 0
        );

        axios
            .post(`/api/v1/sessions/create-and-activate`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    "New Active Session has beeen started"
                );
                window.location = `/${response.data.session.slug}/presentation?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Sessions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Sessions"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Link component="a" href="/sessions">
                            Sessions
                        </Link>
                        <Typography>Add a Session</Typography>
                    </Breadcrumbs>
                    <Divider my={6} className={classes.divider} />
                    <Hidden mdUp>
                        <Button
                            onClick={handleBack}
                            startIcon={<ArrowBackIcon />}
                        >
                            Go Back
                        </Button>
                    </Hidden>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Paper className={classes.formContainer}>
                                <Typography variant="h4">
                                    Add New Session
                                </Typography>
                                <Form
                                    values={values}
                                    setValues={setValues}
                                    handleChange={handleChange}
                                    handleChangeDirectly={handleChangeDirectly}
                                    handleSwitch={handleSwitch}
                                    userLowPlan={userLowPlan}
                                />
                                <div className={classes.formActions}>
                                    <Hidden smDown>
                                        <Button
                                            color="inherit"
                                            onClick={handleBack}
                                        >
                                            Cancel
                                        </Button>
                                    </Hidden>
                                    <div
                                        className={
                                            smallButtons
                                                ? classes.formActions
                                                : ""
                                        }
                                    >
                                        <Button
                                            color="inherit"
                                            color="primary"
                                            variant="outlined"
                                            size={
                                                smallButtons
                                                    ? "small"
                                                    : "medium"
                                            }
                                            onClick={handleSubmitDraft}
                                        >
                                            Save As Draft
                                        </Button>
                                        <Button
                                            color="inherit"
                                            color="primary"
                                            variant="contained"
                                            size={
                                                smallButtons
                                                    ? "small"
                                                    : "medium"
                                            }
                                            onClick={handleSubmitCreate}
                                        >
                                            Activate Session
                                        </Button>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("facilitators-session-create")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Create />
        </ErrorBoundary>,
        document.getElementById("facilitators-session-create")
    );
}
