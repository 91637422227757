import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import AdminNav from "../components/AdminNav";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";
import {
    Grid,
    Typography,
    Breadcrumbs,
    Link,
    Divider
} from "@material-ui/core";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import Form from "./components/Form";
import { forEach } from "lodash";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

const Create = () => {
    const classes = useStyles();
    const { userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        name: "",
        alt_text: "",
        upload_link: "",
        orientation: "tall",
        has_back: false,
        back_name: "",
        back_alt_text: "",
        back_upload_link: "",
        verify_copyright: false,
        decks: []
    });

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeDirectly = (key, value) => {
        setValues({
            ...values,
            [key]: value
        });
    };

    const handleRemoveDeck = deck_id => {
        let newDecks = [];

        forEach(values.decks, function(deck) {
            if (deck != deck_id) {
                newDecks.push(deck);
            }
        });

        setValues({
            ...values,
            decks: newDecks
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("alt_text", values.alt_text);
        params.append("upload_link", values.upload_link);
        params.append("orientation", values.orientation);
        params.append("has_back", values.has_back ? 1 : 0);

        if (values.has_back) {
            params.append("back_name", values.back_name);
            params.append("back_alt_text", values.back_alt_text);
            params.append("back_upload_link", values.back_upload_link);
        }

        if (values.decks.length > 0) {
            params.append("decks", values.decks);
        }

        axios
            .post(`/api/v1/admin/cards/create`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Custom card created");
                window.location = `/admin/cards?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <DashboardLayout sideNav={<AdminNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Cards
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Cards"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/admin/dashboard">
                            Dashboard
                        </Link>
                        <Link component="a" href="/admin/cards">
                            Cards
                        </Link>
                        <Typography>Add a Card</Typography>
                    </Breadcrumbs>
                    <Divider my={6} style={{ marginBottom: 16 }} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Form
                                values={values}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                                handleRemoveDeck={handleRemoveDeck}
                                handleChangeDirectly={handleChangeDirectly}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("card-create")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Create />
        </ErrorBoundary>,
        document.getElementById("card-create")
    );
}
