import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import DashboardLayout from "@layouts/DashboardLayout";
import UserNav from "@global/UserNav";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    Grid,
    Link,
    Breadcrumbs,
    Divider,
    Typography,
    Fab,
    Tooltip,
    Button,
    Tabs,
    Tab,
    Paper,
    TextField,
    FormControlLabel,
    Switch,
    Dialog,
    IconButton
} from "@material-ui/core";
import CardSelect from "./components/CardSelect";
import { remove } from "lodash";
import FeaturedSelect from "./components/FeaturedSelect";
import axios from "axios";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import theme from "../../themes/theme";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        textAlign: "right"
    },
    divider: {
        marginBottom: theme.spacing(3)
    },
    formPaper: {
        boxShadow: "0px 3px 14px #3540521A",
        padding: theme.spacing(2)
    },
    featuredContainer: {
        borderRadius: 4,
        boxShadow: "0px 3px 6px #35405240",
        height: 318,
        margin: `0 auto ${theme.spacing(2)}px`,
        overflow: "hidden",
        position: "relative",
        width: 230
    },
    featuredPlaceholder: {
        backgroundColor: "#4786AA1A",
        border: "2px dashed #05597B",
        height: "100%",
        width: "100%"
    },
    featuredImage: {
        maxHeight: "100%",
        position: "absolute",
        width: "auto",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    spaceBetween: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between"
    },
    cardsContainer: {
        textAlign: "center",
        cursor: "grab",
        "& img": {
            borderRadius: 4,
            boxShadow: "0px 3px 6px #35405280",
            margin: "0 auto",
            maxWidth: "100%"
        },
        "& button": {
            color: "#f00"
        }
    }
}));

const Create = () => {
    const { userToken, plan } = reactProps;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        name: "",
        description: "",
        allow_back: false,
        allow_random_sort: false,
        share_deck: false,
        featured_image: "",
        featured_alt: "",
        cards: []
    });
    const [open, setOpen] = useState(false);
    const [cards, setCards] = useState(reactProps.cards);
    const [currentCard, setCurrentCard] = useState(null);
    const [files, setFiles] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSwitch = key => event => {
        setValues({
            ...values,
            [key]: event.target.checked
        });
    };

    const handleDialogClick = key => {
        setOpen(key);
    };

    const handleRemove = cardID => {
        const currentCards = values.cards;
        remove(currentCards, { uuid: cardID });
        setValues({ ...values, currentCards });
    };

    const setFeatured = card => {
        const currentCards = values.cards;
        remove(currentCards, { uuid: card.uuid });
        currentCards.unshift(card);
        setValues({
            ...values,
            cards: currentCards,
            featured_image: card.frontImage,
            featured_alt: card.alt_text
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new FormData();
        const cardIDs = values.cards.map(card => card.id);
        params.append("name", values.name);
        params.append("description", values.description);
        params.append("user_id", reactProps.auth.id);
        params.append("cards", cardIDs);
        params.append("climer", 0);
        params.append("featured_image", values.featured_image);
        params.append("allow_back", values.allow_back ? 1 : 0);
        params.append("allow_random_sort", values.allow_random_sort ? 0 : 1);
        params.append("organization_share", values.share_deck ? 1 : 0);

        axios
            .post("/api/v1/decks/create", params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                if (response.data.deck) {
                    const message = encodeURIComponent(
                        "Deck successfully added"
                    );
                    window.location = `/decks/${response.data.deck.uuid}/edit?message=${message}`;
                }
            })
            .catch(error => {
                console.log(error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    function dragStartHandler(e, card) {
        setCurrentCard(card);
    }

    function dragEndHandler(e) {
        e.target.style.background = "#f5f9ff";
    }

    function dragOverHandler(e) {
        e.preventDefault();
        e.target.style.background = "lightgray";
    }

    function dropHandler(e, card) {
        e.preventDefault();

        //Getting index elements
        const currentIndex = values.cards.indexOf(currentCard);
        const dropIndex = values.cards.indexOf(card);

        // Change array
        values.cards.splice(currentIndex, 1); // replace and delete element
        values.cards.splice(dropIndex, 0, currentCard); // add deleted element

        // setting changes to state for rerender cards list
        setValues({
            ...values,
            cards: values.cards
        });
        e.target.style.background = "#f5f9ff";
    }

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        {values.name.length > 0 ? values.name : "Create a Deck"}
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.floatRight}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Save Changes
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Users"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Link href="/decks">Decks</Link>
                        <Typography>
                            {values.name.length > 0
                                ? values.name
                                : "Create a Deck"}
                        </Typography>
                    </Breadcrumbs>
                    <Divider my={6} className={classes.divider} />
                </Grid>
                <Grid item container xs={12} spacing={3}>
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        style={{ textAlign: "center", minWidth: 210 }}
                    >
                        <div className={classes.featuredContainer}>
                            {values.featured_image ? (
                                <img
                                    className={classes.featuredImage}
                                    src={values.featured_image}
                                    alt={values.featured_alt}
                                />
                            ) : (
                                <div className={classes.featuredPlaceholder} />
                            )}
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleDialogClick("image_select")}
                            disabled={values.featured_image == ""}
                        >
                            Update Featured Image
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Paper className={classes.formPaper}>
                            <TextField
                                required
                                fullWidth
                                label="Name"
                                margin="normal"
                                value={values.name}
                                placeholder="My Custom Deck"
                                onChange={handleChange("name")}
                            />
                            <TextField
                                required
                                fullWidth
                                multiline
                                rows={3}
                                margin="normal"
                                label="Description"
                                value={values.description}
                                onChange={handleChange("description")}
                            />
                            <FormControlLabel
                                // label="Allow Back of Card"
                                label={
                                    values.allow_back
                                        ? "Double-sided card"
                                        : "Single-sided card"
                                }
                                control={
                                    <Switch
                                        checked={values.allow_back}
                                        onChange={handleSwitch("allow_back")}
                                        color="primary"
                                    />
                                }
                            />
                            <br />
                            <FormControlLabel
                                // label="Set Sequential Card Order"
                                label={
                                    values.allow_random_sort
                                        ? "Set sequential card order"
                                        : "Cards sort randomly when displayed"
                                }
                                control={
                                    <Switch
                                        checked={values.allow_random_sort}
                                        onChange={handleSwitch(
                                            "allow_random_sort"
                                        )}
                                        color="primary"
                                    />
                                }
                            />
                            <br />
                            {(plan == "team" ||
                                plan == "enterprise" ||
                                plan == "education") && (
                                <FormControlLabel
                                    // label="Share deck with my organization"
                                    label={
                                        values.share_deck
                                            ? "Share deck with my organization"
                                            : "Do not share deck with my organization"
                                    }
                                    control={
                                        <Switch
                                            checked={values.share_deck}
                                            onChange={handleSwitch(
                                                "share_deck"
                                            )}
                                            color="primary"
                                        />
                                    }
                                />
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} className={classes.spaceBetween}>
                        <Typography variant="h4">Cards</Typography>
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleDialogClick("card_select")}
                        >
                            Add Cards
                        </Button>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={3}
                        style={{ marginBottom: 16 }}
                    >
                        {values.cards.length > 0 ? (
                            values.cards.map(card => (
                                <Grid
                                    item
                                    xs={3}
                                    sm={2}
                                    md={1}
                                    key={card.id}
                                    className={classes.cardsContainer}
                                    onDragStart={e => dragStartHandler(e, card)}
                                    onDragLeave={e => dragEndHandler(e)}
                                    onDragEnd={e => dragEndHandler(e)}
                                    onDragOver={e => dragOverHandler(e)}
                                    onDrop={e => dropHandler(e, card)}
                                    draggable={true}
                                >
                                    <img
                                        src={card.frontImage}
                                        alt={card.alt_text}
                                    />
                                    <Button
                                        size="small"
                                        color="inherit"
                                        onClick={() => handleRemove(card.uuid)}
                                    >
                                        Remove
                                    </Button>
                                </Grid>
                            ))
                        ) : (
                            <Typography
                                variant="body1"
                                align="center"
                                style={{ display: "block", margin: "0 auto" }}
                            >
                                No cards added yet
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                fullScreen={fullScreen}
                maxWidth="md"
                open={Boolean(open)}
                onClose={() => setOpen(false)}
            >
                {open == "card_select" && (
                    <CardSelect
                        cards={cards}
                        values={values}
                        setOpen={setOpen}
                        setCards={setCards}
                        setValues={setValues}
                        handleChange={handleChange}
                        fullScreen={fullScreen}
                    />
                )}
                {open == "image_select" && (
                    <FeaturedSelect
                        setOpen={setOpen}
                        cards={values.cards}
                        setFeatured={setFeatured}
                        setFiles={setFiles}
                        files={files}
                        values={values}
                        setValues={setValues}
                        setLoading={setLoading}
                        getErrorMessage={getErrorMessage}
                    />
                )}
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("facilitators-decks-create")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Create />
        </ErrorBoundary>,
        document.getElementById("facilitators-decks-create")
    );
}
