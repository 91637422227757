import React, { useState } from "react";
import {
    Grid,
    Paper,
    Table,
    Chip,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    IconButton,
    InputAdornment,
    Button,
    Avatar,
    Hidden
} from "@material-ui/core";
import { Search } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import getInitials from "@utils/getInitials";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    nameContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        "& > p": {
            marginBottom: 0
        }
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        display: "inline-flex",
        marginRight: theme.spacing(2)
    },
    ownerTag: {
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
        fontWeight: "bold",
        marginRight: 8,
        padding: "4px 6px"
    },
    buttonAndTagContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end"
    }
}));

const UsersTable = () => {
    const { userToken, auth, organization } = reactProps;
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(reactProps.users);
    const [usersCount, setUsersCount] = useState(reactProps.usersCount);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [searchQuery, setSearchQuery] = useState("");
    const [text, setText] = useState("");
    const classes = useStyles();

    const handleSearchTextChange = event => {
        setText(event.target.value);
        event.persist();
    };

    const handleSearchKeyUp = event => {
        if (event.keyCode === 13 && event.target.value.length > 0) {
            handleSearchSubmit(event.target.value);
        }
        event.persist();
    };

    const clearSearch = () => {
        setLoading(true);
        axios
            .get(
                `/api/v1/users?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=&token=${userToken}`
            )
            .then(response => {
                setSearchQuery("");
                setUsers(response.data.users);
                setUsersCount(reactProps.usersCount);
                setPage(0);
                setLoading(false);
                setText("");
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSearchSubmit = searchText => {
        setLoading(true);
        axios
            .get(
                `/api/v1/users?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=${searchText}&token=${userToken}`
            )
            .then(response => {
                setSearchQuery(searchText);
                setUsers(response.data.users);
                if (
                    response.data.usersCount &&
                    response.data.usersCount != usersCount
                ) {
                    setUsersCount(response.data.usersCount);
                }
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        axios
            .get(
                `/api/v1/users?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=${rowsPerPage *
                    newPage}&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setUsers(response.data.users);
                setPage(newPage);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangeRowsPerPage = event => {
        setLoading(true);
        axios
            .get(
                `/api/v1/users?sortBy=${orderBy}&sortDirection=${order}&take=${parseInt(
                    event.target.value,
                    10
                )}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setUsers(response.data.users);
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSort = property => event => {
        console.log("handleSort");
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        setLoading(true);
        let isAsc = orderBy == property && order == "asc";

        axios
            .get(
                `/api/v1/users?sortBy=${property}&sortDirection=${
                    !isAsc ? "asc" : "desc"
                }&take=${rowsPerPage}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setUsers(response.data.users);
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    return (
        <div>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.padded}>
                            <TextField
                                fullWidth
                                label="Search"
                                margin="normal"
                                value={text}
                                onKeyUp={handleSearchKeyUp}
                                onChange={handleSearchTextChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    handleSearchSubmit(text)
                                                }
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {searchQuery && searchQuery != "" && (
                                <Chip
                                    label={`Search: ${searchQuery}`}
                                    onDelete={clearSearch}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        aria-labelledby="users-table"
                        size="medium"
                        aria-label="users table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "name" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "name"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("name")}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <Hidden smDown>
                                    <TableCell
                                        align="left"
                                        padding="default"
                                        sortDirection={
                                            orderBy == "email" ? order : false
                                        }
                                    >
                                        <TableSortLabel
                                            active={orderBy == "email"}
                                            direction={
                                                order == "asc" ? "desc" : "asc"
                                            }
                                            onClick={handleSort("email")}
                                        >
                                            Email
                                        </TableSortLabel>
                                    </TableCell>
                                </Hidden>
                                <TableCell align="right" padding="default">
                                    {" "}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user => (
                                <TableRow key={user.id} hover>
                                    <TableCell>
                                        <div className={classes.nameContainer}>
                                            <Hidden smDown>
                                                <Avatar
                                                    alt={user.name}
                                                    src={user.profileImage}
                                                    className={classes.avatar}
                                                    style={{
                                                        backgroundColor: user.avatarColor
                                                            ? user.avatarColor
                                                            : "inherit"
                                                    }}
                                                >
                                                    {getInitials(user.name)}
                                                </Avatar>
                                            </Hidden>
                                            <p>{user.name}</p>
                                        </div>
                                    </TableCell>
                                    <Hidden smDown>
                                        <TableCell>{user.email}</TableCell>
                                    </Hidden>
                                    <TableCell align="right">
                                        <div
                                            className={
                                                classes.buttonAndTagContainer
                                            }
                                        >
                                            {organization &&
                                            user.id == organization.user_id ? (
                                                <span
                                                    className={classes.ownerTag}
                                                >
                                                    Owner
                                                </span>
                                            ) : (
                                                <>
                                                    {user.can_edit_organization ? (
                                                        <span
                                                            className={
                                                                classes.ownerTag
                                                            }
                                                        >
                                                            Manager
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            )}
                                            {user.id === auth.id ? (
                                                <Button
                                                    size="small"
                                                    component="a"
                                                    color="primary"
                                                    variant="outlined"
                                                    href="/user/settings"
                                                >
                                                    Profile
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="small"
                                                    component="a"
                                                    color="primary"
                                                    variant="outlined"
                                                    href={`/users/${user.uuid}`}
                                                    disabled={
                                                        !organization ||
                                                        organization.user_id !=
                                                            auth.id
                                                    }
                                                >
                                                    View
                                                </Button>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={usersCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {loading && <LoadingBackdrop open={loading} />}
        </div>
    );
};

export default UsersTable;
