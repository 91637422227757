import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import {
    Button,
    Paper,
    Typography,
    TextField,
    Container
} from "@material-ui/core";
import Auth from "../layouts/Auth";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    login: {
        marginTop: theme.spacing(2),
        textAlign: "center"
    }
}));

export default function ForgotPassword() {
    const classes = useStyles();
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = event => {
        setEmail(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("email", email);

        axios
            .post(`/api/v1/password/reset`, params)
            .then(response => {
                console.log("response", response);
                setSuccess(true);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Auth>
            <Container maxWidth="sm">
                <Paper className={classes.root} elevation={10}>
                    <Typography component="h1" variant="h4" align="center">
                        Forgot Password?
                    </Typography>
                    {success ? (
                        <>
                            <Typography align="center">
                                Check your email for a link and further
                                instructions. If there is an account with the
                                email address you provided then the reset email
                                has been submitted.
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="body1" align="center">
                                Enter your email to reset your password
                            </Typography>
                            <TextField
                                required
                                fullWidth
                                value={email}
                                margin="normal"
                                label="Email Address"
                                onChange={handleChange}
                            />
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={email.length == 0}
                            >
                                Reset Password
                            </Button>
                            <Typography className={classes.login}>
                                <a href="/login">Back to Login</a>
                            </Typography>
                        </>
                    )}
                </Paper>
            </Container>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Auth>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
}

if (document.getElementById("forgot-password")) {
    ReactDOM.render(
        <ErrorBoundary>
            <ForgotPassword />
        </ErrorBoundary>,
        document.getElementById("forgot-password")
    );
}
