import React, { useState } from "react";
import theme from "../themes/theme";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import FileDropzone from "./FileDropzone";
import { head } from "lodash";
import axios from "axios";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";

const FeedbackForm = props => {
    const { open, setOpen } = props;
    const { auth, captchaKey, userToken } = reactProps;
    const [values, setValues] = useState({
        feedback_type: "",
        name: auth ? auth.name : "",
        email: auth ? auth.email : "",
        message: ""
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [captchaConfirmed, setCaptchaConfirmed] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleChange = key => event =>
        setValues({ ...values, [key]: event.target.value });

    const handleCaptcha = value => {
        if (Boolean(value)) setCaptchaConfirmed(true);
        else setErrorMessage("ReCAPTCHA failed. Please try again.");
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new FormData();
        params.append("name", values.name);
        params.append("email", values.email);
        switch (values.feedback_type) {
            case "bug":
                params.append("feedback_type", "Bug Report");
                break;
            case "feature":
                params.append("feedback_type", "Feature Request");
                break;
            default:
                params.append("feedback_type", "General Feedback");
                break;
        }
        params.append("message", values.message);
        if (files.length > 0) params.append("image", head(files));

        axios
            .post("/api/v1/feedback/submit", params, {
                headers: { Authorization: `Bearer: ${userToken}` }
            })
            .then(response => {
                if (response.data.submitted) {
                    setLoading(false);
                    setSubmitted(true);
                }
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleClose = () => {
        setOpen(false);
        setValues({
            feedback_type: "",
            name: auth ? auth.name : "",
            email: auth ? auth.email : "",
            message: ""
        });
        setFiles([]);
        setCaptchaConfirmed(false);
        setSubmitted(false);
    };

    return (
        <>
            <Dialog
                fullScreen={isMobile}
                maxWidth={props.maxWidth ? props.maxWidth : "sm"}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>Submit Your Feedback</DialogTitle>
                {submitted == true ? (
                    <DialogContent style={{ padding: "2rem" }}>
                        <Typography variant="h3" gutterBottom align="center">
                            Your feedback has been submitted
                        </Typography>
                        <DialogActions style={{ textAlign: "center" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                ) : (
                    <>
                        <DialogContent>
                            <TextField
                                required
                                fullWidth
                                select
                                SelectProps={{ native: true }}
                                label="Type of Feedback"
                                value={values.feedback_type}
                                onChange={handleChange("feedback_type")}
                                margin="normal"
                            >
                                <option value="general">
                                    General Feedback
                                </option>
                                <option value="bug">Bug Report</option>
                                <option value="feature">Feature Request</option>
                            </TextField>
                            <TextField
                                required
                                fullWidth
                                label="Feedback"
                                multiline
                                rows={5}
                                value={values.message}
                                onChange={handleChange("message")}
                                margin="normal"
                            />
                            <FileDropzone setFiles={setFiles} />
                            {/* <ReCAPTCHA
                                sitekey={captchaKey}
                                onChange={handleCaptcha}
                                size={isMobile ? "compact" : "normal"}
                            /> */}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={values.message === ""}
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Submit Feedback
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default FeedbackForm;
