import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Typography,
    Paper,
    IconButton,
    Collapse,
    Grid,
    Tooltip,
    DialogContent,
    DialogActions,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactCardFlip from "react-card-flip";
import LoopIcon from "@material-ui/icons/Loop";
import BackResponse from "./BackResponse";
import CardImage from "./CardImage";
import { X } from "react-feather";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 5
    },
    cardImage: {
        height: "auto",
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    floatRight: {
        textAlign: "right"
    },
    flipButton: {
        backgound: "transparent",
        borderRadius: 4,
        color: theme.palette.primary.main
    },
    responseText: {
        display: "block",
        marginTop: theme.spacing(2)
    }
}));

const ResponseCard = props => {
    const {
        response,
        token,
        session,
        allFlipped,
        deck,
        isFacilitator,
        fetchResponses,
        setShowResponses
    } = props;
    const { auth } = reactProps;
    const classes = useStyles();
    const [flipped, setFlipped] = useState(Boolean(response.back_of_card));
    const handleFlip = () => setFlipped(!flipped);
    const [open, setOpen] = useState(false);

    const handleDeleteResponse = () => {
        let params = new URLSearchParams();
        params.append("selected", response.uuid);

        axios
            .post(`/api/v1/responses/deleted`, params)
            .then(response => {
                if (response.data) {
                    fetchResponses(true);
                }
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        if (allFlipped == "front") {
            setFlipped(false);
        }

        if (allFlipped == "back") {
            setFlipped(true);
        }
    }, [allFlipped]);

    return (
        <Paper className={classes.root} elevation={6}>
            {isFacilitator && (
                <Grid
                    container
                    justify="flex-end"
                    style={{ position: "relative" }}
                >
                    <Grid
                        item
                        xs={3}
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0
                        }}
                    >
                        <div className={classes.floatRight}>
                            <Tooltip title="Delete response">
                                <IconButton
                                    style={{ padding: 5 }}
                                    onClick={() => setOpen(true)}
                                >
                                    <X />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
            )}
            <Card>
                <CardContent style={{ padding: 16, marginTop: 20 }}>
                    <Typography gutterBottom align="center" component="div">
                        {Boolean(deck.allow_back) &&
                        Boolean(response.card.has_back) &&
                        Boolean(session.allow_flip) ? (
                            <>
                                <ReactCardFlip
                                    isFlipped={flipped}
                                    flipDirection="horizontal"
                                >
                                    <>
                                        <CardImage
                                            alt={response.card.alt_text}
                                            src={response.card.frontImage}
                                            orientation={
                                                response.card.orientation
                                            }
                                        />
                                    </>
                                    <>
                                        <CardImage
                                            alt={response.card.back_alt_text}
                                            src={response.card.backImage}
                                            orientation="tall"
                                        />
                                    </>
                                </ReactCardFlip>
                                <div className={classes.floatRight}>
                                    {Boolean(session.allow_response_flip) && (
                                        <IconButton
                                            size="small"
                                            onClick={handleFlip}
                                            className={classes.flipButton}
                                        >
                                            <LoopIcon />
                                        </IconButton>
                                    )}
                                </div>
                                <strong className={classes.responseText}>
                                    {response.name}
                                </strong>
                            </>
                        ) : (
                            <>
                                {response.back_of_card === 1 ? (
                                    <CardImage
                                        src={response.card.backImage}
                                        alt={response.card.back_alt_text}
                                        orientation="tall"
                                    />
                                ) : (
                                    <CardImage
                                        alt={response.card.alt_text}
                                        src={response.card.frontImage}
                                        orientation={response.card.orientation}
                                    />
                                )}
                                <strong className={classes.responseText}>
                                    {response.name}
                                </strong>
                            </>
                        )}
                    </Typography>
                    <Typography gutterBottom>{response.description}</Typography>
                </CardContent>
                {Boolean(token) && token == response.token && (
                    <Collapse in={flipped != response.back_of_card}>
                        <BackResponse
                            token={token}
                            response={response}
                            handleFlip={handleFlip}
                        />
                    </Collapse>
                )}
            </Card>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            <strong>
                                Are you sure? You really want to delete this
                                response?
                            </strong>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleDeleteResponse}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default ResponseCard;
