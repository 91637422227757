import React, { useState } from "react";
import {
    Grid,
    Paper,
    Table,
    Chip,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    Typography,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    IconButton,
    InputAdornment,
    Button
} from "@material-ui/core";
import { Search } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    deleteButton: {
        color: "#8B0000",
        border: "1px solid #8B0000"
    }
}));

const CouponsTable = props => {
    const { userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [coupons, setCoupons] = useState(reactProps.coupons);
    const [couponsCount, setCouponsCount] = useState(reactProps.couponsCount);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [searchQuery, setSearchQuery] = useState("");
    const [text, setText] = useState("");
    const classes = useStyles();

    const handleSearchTextChange = event => {
        setText(event.target.value);
        event.persist();
    };

    const handleSearchKeyUp = event => {
        if (event.keyCode === 13 && event.target.value.length > 0) {
            handleSearchSubmit(event.target.value);
        }
        event.persist();
    };

    const clearSearch = () => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/coupons?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=&token=${userToken}`
            )
            .then(response => {
                setSearchQuery("");
                setCoupons(response.data.coupons);
                setCouponsCount(reactProps.couponsCount);
                setPage(0);
                setLoading(false);
                setText("");
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSearchSubmit = searchText => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/coupons?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=${searchText}&token=${userToken}`
            )
            .then(response => {
                setSearchQuery(searchText);
                setCoupons(response.data.coupons);
                if (
                    response.data.couponsCount &&
                    response.data.couponsCount != couponsCount
                ) {
                    setCouponsCount(response.data.couponsCount);
                }
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/coupons?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=${rowsPerPage *
                    newPage}&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setCoupons(response.data.coupons);
                setPage(newPage);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangeRowsPerPage = event => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/coupons?sortBy=${orderBy}&sortDirection=${order}&take=${parseInt(
                    event.target.value,
                    10
                )}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setCoupons(response.data.coupons);
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSort = property => event => {
        console.log("handleSort");
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        setLoading(true);
        let isAsc = orderBy == property && order == "asc";

        axios
            .get(
                `/api/v1/admin/coupons?sortBy=${property}&sortDirection=${
                    !isAsc ? "asc" : "desc"
                }&take=${rowsPerPage}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setCoupons(response.data.coupons);
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleDelete = coupon_uuid => {
        setLoading(true);
        axios
            .post(`/api/v1/admin/coupons/${coupon_uuid}/delete`, null, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Coupon Removed");
                window.location = `/admin/coupons?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <div>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.padded}>
                            <TextField
                                fullWidth
                                label="Search"
                                margin="normal"
                                value={text}
                                onKeyUp={handleSearchKeyUp}
                                onChange={handleSearchTextChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    handleSearchSubmit(text)
                                                }
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {searchQuery && searchQuery != "" && (
                                <Chip
                                    label={`Search: ${searchQuery}`}
                                    onDelete={clearSearch}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                {coupons.length > 0 ? (
                    <>
                        <TableContainer>
                            <Table
                                aria-labelledby="coupons-table"
                                size="medium"
                                aria-label="coupons table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            padding="default"
                                            sortDirection={
                                                orderBy == "name"
                                                    ? order
                                                    : false
                                            }
                                        >
                                            <TableSortLabel
                                                active={orderBy == "name"}
                                                direction={
                                                    order == "asc"
                                                        ? "desc"
                                                        : "asc"
                                                }
                                                onClick={handleSort("name")}
                                            >
                                                Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            padding="default"
                                            sortDirection={
                                                orderBy == "expired"
                                                    ? order
                                                    : false
                                            }
                                        >
                                            <TableSortLabel
                                                active={orderBy == "expired"}
                                                direction={
                                                    order == "asc"
                                                        ? "desc"
                                                        : "asc"
                                                }
                                                onClick={handleSort("expired")}
                                            >
                                                Status
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            padding="default"
                                            sortDirection={
                                                orderBy == "promotion_code"
                                                    ? order
                                                    : false
                                            }
                                        >
                                            <TableSortLabel
                                                active={
                                                    orderBy == "promotion_code"
                                                }
                                                direction={
                                                    order == "asc"
                                                        ? "desc"
                                                        : "asc"
                                                }
                                                onClick={handleSort(
                                                    "promotion_code"
                                                )}
                                            >
                                                Promotion Code
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            padding="default"
                                        >
                                            {" "}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {coupons.map(coupon => (
                                        <TableRow key={coupon.id} hover>
                                            <TableCell>{coupon.name}</TableCell>
                                            <TableCell>
                                                {Boolean(coupon.expired)
                                                    ? "Expired"
                                                    : "Active"}
                                            </TableCell>
                                            <TableCell>
                                                {coupon.promotion_code}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    className={
                                                        classes.deleteButton
                                                    }
                                                    onClick={() =>
                                                        handleDelete(
                                                            coupon.uuid
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={couponsCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <>
                        <Typography gutterBottom align="center">
                            No coupons to show
                        </Typography>
                    </>
                )}
            </Paper>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </div>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default CouponsTable;
