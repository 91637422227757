import React, { useState } from "react";
import {
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField
} from "@material-ui/core";
import moment from "moment";

const ExtendTrialDialog = props => {
    const { user, subscription, userToken, onTrial } = reactProps;
    const { handleClose, setLoading, getErrorMessage } = props;
    const [days, setDays] = useState("");

    const handleChange = event => {
        setDays(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("days", days);

        axios
            .post(`/api/v1/admin/users/${user.uuid}/extend-trial`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Trial Extended");
                window.location = `/admin/users/${user.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <>
            <DialogContent>
                {subscription.stripe_status == "trialing" && Boolean(onTrial) && (
                    <Typography gutterBottom>
                        As of now, {user.name}'s trial is set to expire in{" "}
                        {moment(subscription.trial_ends_at).fromNow()}. How many
                        additional days would you like to add to this free
                        trial?
                    </Typography>
                )}
                {subscription.stripe_status == "canceled" ||
                    (subscription.status == "past_due" && (
                        <Typography gutterBottom>
                            As of now, {user.name}'s trial has ended and the
                            plan has been canceled or has expired. How many days
                            would you like to add to this free trial?
                        </Typography>
                    ))}
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    value={days}
                    onChange={handleChange}
                    label="Trial Extension Length (days)"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={days == ""}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    );
};

export default ExtendTrialDialog;
