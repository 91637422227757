import React, { useEffect, useState } from "react";
import {
    Backdrop,
    CircularProgress,
    Typography,
    Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 9999,
        backgroundColor: "rgba(5,89,123,.97)"
    },
    progress: {
        marginLeft: "50%"
    },
    labelContainer: {
        marginLeft: "50%",
        position: "relative",
        bottom: 33,
        left: 10
    },
    explanation: {
        color: "white",
        marginTop: 20,
        marginLeft: "2%"
    }
}));

const AdvancedBackdrop = props => {
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress(oldProgress => {
                if (oldProgress === 100) {
                    setTimeout(
                        function() {
                            //setOpen(false);
                        }.bind(this),
                        1000
                    );
                }
                const diff = Math.random() * 7;
                return Math.min(oldProgress + diff, 100);
            });
        }, 225);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Backdrop open={open} color="primary" className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <CircularProgress
                        value={progress}
                        color="secondary"
                        variant="determinate"
                        className={classes.progress}
                    />
                    <div className={classes.labelContainer}>
                        <Typography
                            component="div"
                            variant="caption"
                            color="secondary"
                        >
                            {`${Math.round(progress)}%`}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ width: "100% " }}>
                        <Typography
                            gutterBottom
                            align="center"
                            className={classes.explanation}
                        >
                            Preparing the cards
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default AdvancedBackdrop;
