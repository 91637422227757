import { createMuiTheme } from '@material-ui/core/styles';
import 'typeface-gothic-a1';

const typography = createMuiTheme({
    typography: {
        fontFamily: [
            'Gothic A1',
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Arial',
            'sans-serif',
        ].join(','),
        useNextVariants: true,
    },
});

export default typography;
