import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Paper } from "@material-ui/core";
import CardImage from "./CardImage";
import ReactCardFlip from "react-card-flip";
import LoopIcon from "@material-ui/icons/Loop";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0)",
        border: "none",
        boxShadow: "none",
        display: "flex",
        cursor: "pointer",
        height: 200,
        justifyContent: "center",
        margin: theme.spacing(2),
        padding: 0,
        position: "relative",
        transitionDuration: "0.25s",
        width: 200,
        "& > div": {
            borderRadius: 4,
            boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            overflow: "hidden"
        },
        "&:hover": {
            transform: "scale(1.15)",
            "& > div": {
                boxShadow:
                    "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)"
            },
            "& > span": {
                display: "inline-block"
            }
        },
        "&:focus-within": {
            transform: "scale(1.15)",
            "& > div": {
                boxShadow:
                    "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)"
            },
            "& > span": {
                display: "inline-block"
            }
        },
        "& > span": {
            display: "none"
        }
    },
    flipButton: {
        backgroundColor: "#115a78cc",
        bottom: 0,
        borderRadius: "50%",
        color: theme.palette.secondary.main,
        position: "absolute",
        right: 0,
        "&:hover": {
            backgroundColor: "#115a78"
        }
    }
}));

const SingleCard = ({ card, handleClick, session, allFlipped }) => {
    const classes = useStyles();
    const [flipped, setFlipped] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const handleFlip = event => {
        event.stopPropagation();
        setFlipped(!flipped);
    };

    const onEnter = () => {
        if (!card.has_back) return;

        setShowButton(true);
    };

    const onLeave = () => {
        if (!card.has_back) return;

        setShowButton(false);
    };

    useEffect(() => {
        if (allFlipped == "front") {
            setFlipped(false);
        }

        if (allFlipped == "back") {
            setFlipped(true);
        }
    }, [allFlipped]);

    return (
        <button
            className={classes.root}
            onClick={() => handleClick(card, flipped)}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            onFocusCapture={onEnter}
            onBlur={onLeave}
        >
            {session.allow_flip == 1 && card.has_back ? (
                <>
                    <ReactCardFlip
                        isFlipped={flipped}
                        flipDirection="horizontal"
                    >
                        <Paper elevation={2}>
                            <CardImage
                                alt={card.alt_text}
                                src={card.frontImage}
                                orientation={card.orientation}
                            />
                        </Paper>
                        <Paper elevation={2}>
                            <CardImage
                                alt={card.back_alt_text}
                                src={card.backImage}
                                orientation="tall"
                            />
                        </Paper>
                    </ReactCardFlip>
                    {session.allow_flip &&
                        session.allow_prompt_flip == true &&
                        session.allow_facilitator_flip == false && (
                            <IconButton
                                className={classes.flipButton}
                                component="span"
                                onClick={handleFlip}
                            >
                                <LoopIcon />
                            </IconButton>
                        )}
                </>
            ) : (
                <Paper elevation={2}>
                    <CardImage
                        alt={card.alt_text}
                        src={card.frontImage}
                        orientation={card.orientation}
                    />
                </Paper>
            )}
        </button>
    );
};

export default SingleCard;
