import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        boxShadow: "0px 0px 14px #3540521A",
        display: "flex",
        justifyContent: "space-between",
        padding: "1.5rem 5%",
        [theme.breakpoints.down("sm")]: {
            padding: "1rem"
        }
    },
    links: {
        margin: 0,
        padding: 0,
        "& > li": {
            display: "inline-block",
            marginRight: theme.spacing(2)
        },
        "& a": {
            color: theme.palette.text.primary
        }
    }
}));

const GeneralFooter = () => {
    const classes = useStyles();
    const year = new Date().getFullYear();

    return (
        <div className={classes.root}>
            <ul className={classes.links}>
                <li>
                    <a href="https://climercards.com/contact-us/">Contact</a>
                </li>
                <li>
                    <a href="https://climercards.com/privacy-policy/">
                        Privacy Policy
                    </a>
                </li>
                <li>
                    <a href="https://climercards.com/terms-and-conditions/">
                        Terms of Use
                    </a>
                </li>
            </ul>
            <Typography variant="body1">
                &copy;
                {" " + year + " - "}
                Climer Cards
            </Typography>
        </div>
    );
};

export default GeneralFooter;
