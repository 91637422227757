import React from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import { Grid, Typography } from "@material-ui/core";
import ContextualComponent from "@assets/ContextualComponent";
import EditRoleForm from "./partials/EditRoleForm";

export default function Edit() {
    return (
        <div>
            <ContextualComponent>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography>Edit a Role</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <EditRoleForm
                            role={reactProps.role}
                            roleList={reactProps.roles}
                        />
                    </Grid>
                </Grid>
            </ContextualComponent>
        </div>
    );
}

if (document.getElementById("admin-roles-edit")) {
    ReactDOM.render(<Edit />, document.getElementById("admin-roles-edit"));
}
