import React, { useState } from "react";
import {
    Grid,
    Paper,
    TextField,
    MenuItem,
    ButtonGroup,
    Button,
    Typography,
    FormControlLabel,
    FormHelperText,
    Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    floatRight: {
        textAlign: "right"
    },
    options: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    center: {
        textAlign: "center"
    }
}));

const Form = () => {
    const classes = useStyles();
    const { userToken } = reactProps;
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        name: "",
        promotion_code: "",
        discount_type: "percentage",
        percent_off: "",
        amount_off: "",
        duration: "forever",
        duration_in_months: "",
        first_time_transaction: false,
        has_expiration_date: false,
        expires_at: new Date(),
        has_max_redemptions: false,
        max_redemptions: ""
    });

    const toggleAdvanced = () => {
        setShowAdvanced(prevState => !prevState);
    };

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeDirectly = (key, value) => {
        setValues({
            ...values,
            [key]: value
        });
    };

    const handleCheck = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.checked
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("promotion_code", values.promotion_code);
        params.append("discount_type", values.discount_type);

        if (values.discount_type == "percentage") {
            params.append("percent_off", values.percent_off);
        } else {
            params.append("amount_off", parseInt(values.amount_off * 100));
        }

        params.append("duration", values.duration);

        if (values.duration == "repeating") {
            params.append("duration_in_months", values.duration_in_months);
        }

        params.append(
            "first_time_transaction",
            values.first_time_transaction ? 1 : 0
        );
        params.append(
            "has_expiration_date",
            values.has_expiration_date ? 1 : 0
        );

        if (values.has_expiration_date) {
            params.append("expires_at", moment(values.expires_at).format("L"));
        }

        params.append(
            "has_max_redemptions",
            values.has_max_redemptions ? 1 : 0
        );

        if (values.has_max_redemptions) {
            params.append("max_redemptions", values.max_redemptions);
        }

        axios
            .post(`/api/v1/admin/coupons/create`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Coupon Created");
                window.location = `/admin/coupons?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
                <Paper className={classes.padded}>
                    <TextField
                        required
                        fullWidth
                        label="Name"
                        margin="normal"
                        value={values.name}
                        onChange={handleChange("name")}
                        helperText="This is to help you to identify the coupon, and how the disount will appear on customer invoices."
                    />
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Promotional Code"
                        value={values.promotion_code}
                        onChange={handleChange("promotion_code")}
                        helperText="The promotional code that the customer will enter to receive the discount"
                    />
                    <TextField
                        select
                        required
                        fullWidth
                        margin="normal"
                        label="Discount Type"
                        value={values.discount_type}
                        onChange={handleChange("discount_type")}
                        helperText="Discounts can be a percentage of the total cost or a fixed disounted amount"
                    >
                        <MenuItem value="percentage">
                            Percentage discount
                        </MenuItem>
                        <MenuItem value="fixed_amount">
                            Fixed amount discount
                        </MenuItem>
                    </TextField>
                    {values.discount_type == "percentage" && (
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            label="Percentage Off"
                            value={values.percent_off}
                            onChange={handleChange("percent_off")}
                        />
                    )}
                    {values.discount_type == "fixed_amount" && (
                        <CurrencyTextField
                            required
                            fullWidth
                            margin="normal"
                            decimalPlaces={2}
                            minimumValue={0.01}
                            decimalCharacter="."
                            digitGroupSeparator=""
                            label="Discount Amount"
                            maximumValue={100000.0}
                            value={values.amount_off}
                            onChange={(event, string) =>
                                handleChangeDirectly("amount_off", string)
                            }
                        />
                    )}
                    <TextField
                        select
                        required
                        fullWidth
                        margin="normal"
                        label="Duration"
                        value={values.duration}
                        onChange={handleChange("duration")}
                        helperText="This determines how long this coupon will apply once it is redeemed."
                    >
                        <MenuItem value="forever">Forever</MenuItem>
                        <MenuItem value="once">
                            Once (The first month for monthly subscriptions or
                            the first year for yearly)
                        </MenuItem>
                        <MenuItem value="repeating">Multiple Months</MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Number of Months"
                        value={values.duration_in_months}
                        onChange={handleChange("duration_in_months")}
                        required={values.duration == "repeating"}
                        disabled={values.duration != "repeating"}
                        helperText={
                            values.duration == "repeating"
                                ? "For yearly subscriptions, the number of months will be prorated"
                                : ""
                        }
                    />
                    <div className={classes.center}>
                        <Button
                            component="p"
                            color="primary"
                            onClick={toggleAdvanced}
                        >
                            {showAdvanced
                                ? "Hide Advanced Options"
                                : "Show Advanced Options"}
                        </Button>
                    </div>
                    {showAdvanced && (
                        <div className={classes.options}>
                            <Typography gutterBottom>Options</Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handleCheck}
                                                name="first_time_transaction"
                                                checked={
                                                    values.first_time_transaction
                                                }
                                            />
                                        }
                                        label="Eligible for first-time order only?"
                                    />
                                    <FormHelperText>
                                        If checked, this code will only be
                                        eligible for customers who have never
                                        subscribed to a plan before.
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handleCheck}
                                                name="has_expiration_date"
                                                checked={
                                                    values.has_expiration_date
                                                }
                                            />
                                        }
                                        label="Add an expiration date"
                                    />
                                    <FormHelperText>
                                        {Boolean(values.has_max_redemptions)
                                            ? "Without an expiration date, the coupon will remain active until it has reached it's redemption limit or it is removed"
                                            : "Without an expiration date, the coupon will remain active until it is removed."}
                                    </FormHelperText>
                                    {values.has_expiration_date ? (
                                        <DatePicker
                                            required
                                            fullWidth
                                            format="L"
                                            disablePast
                                            margin="normal"
                                            label="Expiration Date"
                                            value={values.expires_at}
                                            onChange={date =>
                                                handleChangeDirectly(
                                                    "expires_at",
                                                    date
                                                )
                                            }
                                        />
                                    ) : (
                                        <TextField
                                            disabled
                                            fullWidth
                                            margin="normal"
                                            label="Expiration Date"
                                            defaultValue=""
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handleCheck}
                                                name="has_max_redemptions"
                                                checked={
                                                    values.has_max_redemptions
                                                }
                                            />
                                        }
                                        label="Limit the number of times this code can be redeemed"
                                    />
                                    <FormHelperText>
                                        {Boolean(values.has_expiration_date)
                                            ? "Without a limit, the coupon will remain active until it expires or is removed."
                                            : "Without a limit, the coupon will remain active until it is removed."}
                                    </FormHelperText>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        value={values.max_redemptions}
                                        required={values.has_max_redemptions}
                                        disabled={!values.has_max_redemptions}
                                        onChange={handleChange(
                                            "max_redemptions"
                                        )}
                                        label="How many times can this coupon code be redeemed?"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    <div className={classes.floatRight}>
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                component="a"
                                href="/admin/coupons"
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </div>
                </Paper>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default Form;
