import React from "react";
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Button
} from "@material-ui/core";
import DashboardCard from "@global/DashboardCard";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {},
    active: {
        backgroundColor: "#4786AA",
        color: "#fff",
        padding: "4px 6px"
    },
    draft: {
        backgroundColor: "#A8D058",
        color: "#fff",
        padding: "4px 6px"
    },
    archived: {
        backgroundColor: "#00000040",
        color: "#fff",
        padding: "4px 6px"
    },
    upcoming: {
        backgroundColor: "#A8D058",
        color: "white",
        padding: "4px 6px"
    }
}));

const RecentSessions = ({ sessions }) => {
    const classes = useStyles();
    const options = [
        {
            text: "View all sessions",
            action: "/sessions"
        },
        {
            text: "Add new session",
            action: "/sessions/create"
        }
    ];

    const setFacilitatorActivity = (value, session) => {
        let params = new URLSearchParams();
        params.append("facilitator_is_active", value);

        axios
            .post(
                `/api/v1/sessions/${session.uuid}/update-active-facilitator`,
                params
            )
            .then(response => {
                console.log("response-active-facilitator", response);
                // setSession(response.data.session);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    return (
        <DashboardCard
            heading="Recent Sessions"
            link={{ href: "/sessions", text: "see all" }}
            options={options}
        >
            {sessions.length > 0 ? (
                <TableContainer>
                    <Table>
                        <TableBody>
                            {sessions.map(session => (
                                <TableRow key={session.id}>
                                    <TableCell>
                                        <a
                                            href={`/sessions/${session.uuid}/edit`}
                                        >
                                            {session.name}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        {session.status == "upcoming" && (
                                            <span className={classes.upcoming}>
                                                Draft
                                            </span>
                                        )}
                                        {session.status == "archived" && (
                                            <span className={classes.archived}>
                                                Archived
                                            </span>
                                        )}
                                        {session.status == "active" && (
                                            <span className={classes.active}>
                                                Active
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {moment(session.created_at).format("L")}
                                    </TableCell>
                                    <TableCell align="right">
                                        {session.status == "active" ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                href={`/${session.slug}/presentation`}
                                                onClick={() => {
                                                    setFacilitatorActivity(
                                                        1,
                                                        session
                                                    );
                                                }}
                                            >
                                                Enter
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                href={`/sessions/${session.uuid}/edit`}
                                            >
                                                Details
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="body1" align="center">
                    No recent sessions
                </Typography>
            )}
        </DashboardCard>
    );
};

export default RecentSessions;
