export function upperCaseFirst(string) {
    if (string === null) {
        return string;
    }
    return string.slice(0, 1).toUpperCase() + string.toLowerCase().substring(1);
}

export function humanize(string) {
    return _.startCase(string);
}

export function getInitial(string) {
    return string.toUpperCase().charAt(0);
}

export function buildLink(string) {
    if (string === undefined || string === null) return "";
    if (string.includes("http")) return string;
    return `https://${string}`;
}

export function truncateString(string, limit) {
    if (string.length <= limit) return string;
    return string.substring(0, limit) + "...";
}
