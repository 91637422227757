import React, { useState } from "react";
import { Grid, TextField, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import clsx from "clsx";
import PasswordField from "@global/PasswordField";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(2)
    },
    padded: {
        padding: theme.spacing(4)
    },
    alignRight: {
        textAlign: "right"
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    avatar: {
        minWidth: 200,
        minHeight: 200
    },
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlgin: "center"
    }
}));

const ChangePasswordForm = () => {
    const classes = useStyles();
    const { userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [values, setValues] = useState({
        old_password: "",
        password: "",
        password_confirmation: ""
    });

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("old_password", values.old_password);
        params.append("password", values.password);
        params.append("password_confirmation", values.password_confirmation);

        axios
            .post(`/api/v1/user/password`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Password Changed");
                window.location = `/user/password?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} md={8}>
                <Paper className={classes.padded}>
                    {/* <TextField
                        required
                        fullWidth
                        margin="normal"
                        type="password"
                        label="Old Password"
                        value={values.old_password}
                        onChange={handleChange("old_password")}
                    /> */}
                    {/* <TextField
                        required
                        fullWidth
                        margin="normal"
                        type="password"
                        label="New Password"
                        value={values.password}
                        onChange={handleChange("password")}
                    />
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        type="password"
                        label="Confirm New Password"
                        value={values.password_confirmation}
                        onChange={handleChange("password_confirmation")}
                    /> */}
                    <PasswordField
                        value={values.old_password}
                        handleChange={handleChange("old_password")}
                        label="Old Password"
                    />
                    <PasswordField
                        value={values.password}
                        handleChange={handleChange("password")}
                    />
                    <PasswordField
                        value={values.password_confirmation}
                        handleChange={handleChange("password_confirmation")}
                        confirm
                    />
                    <div
                        className={clsx(classes.alignRight, classes.marginTop)}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </Paper>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Grid>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            2000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default ChangePasswordForm;
