import React, { useEffect } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import DashboardLayout from "@layouts/DashboardLayout";
import ErrorBoundary from "@global/ErrorBoundary";
import UserNav from "@global/UserNav";
import { Grid, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import QuickStart from "./components/QuickStart";
import RecentSessions from "./components/RecentSessions";
import UserDecks from "./components/UserDecks";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    floatRight: {
        float: "right"
    },
    divider: {
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(4)}px`
    }
}));

const Index = () => {
    const classes = useStyles();
    const { user, sessions, auth } = reactProps;

    useEffect(() => {
        console.log("auth", auth);
    }, []);

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Dashboard
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider my={6} className={classes.divider} />
                    <Grid container spacing={4}>
                        {auth.subscriptions &&
                            auth.subscriptions.length > 0 &&
                            moment
                                .utc(auth.subscriptions[0].trial_ends_at)
                                .isAfter(moment()) && (
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        You have{" "}
                                        {moment
                                            .utc(
                                                auth.subscriptions[0]
                                                    .trial_ends_at
                                            )
                                            .fromNow(true)}{" "}
                                        remaining on your free trial.{" "}
                                        <a href="/manage-plan">
                                            Click here to update your
                                            subscription level or payment
                                            method.
                                        </a>
                                    </Alert>
                                </Grid>
                            )}
                        {/* <Grid item xs={12} md={8} lg={6}>
                            <QuickStart />
                        </Grid> */}
                        <Grid item xs={12} lg={8}>
                            <RecentSessions
                                sessions={sessions ? sessions : []}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <UserDecks />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("user-dashboard")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>,
        document.getElementById("user-dashboard")
    );
}
