import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import General from "../layouts/General";
import { makeStyles } from "@material-ui/core/styles";
import ErrorBoundary from "@global/ErrorBoundary";
import { Grid, Paper, Typography } from "@material-ui/core";
import { upperCaseFirst } from "@utils/stringUtils";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import PersonalInfoStep from "./components/PersonalInfoStep";
import OrganizationStep from "./components/OrganizationStep";
import PaymentStep from "./components/PaymentStep";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { find } from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(12),
        padding: theme.spacing(2)
    },
    paperRoot: {
        borderTop: "4px solid #05597B",
        padding: theme.spacing(2)
    }
}));

const Registration = () => {
    const classes = useStyles();
    const { type, plan, plans, prices, stripeKey } = reactProps;
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [stripePromise, setStripePromise] = useState(null);
    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        organization_name: "",
        organization_url: "",
        organization_description: "",
        paymentMethod: "",
        agree_to_terms: false
    });

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleNextStep = () => {
        setStep(prevStep => prevStep + 1);
    };

    const handlePreviousStep = () => {
        setStep(prevStep => prevStep - 1);
    };

    const getIntervalString = type => {
        if (type === "monthly") {
            return "month";
        }
        return "year";
    };

    const interval = getIntervalString(type);

    const selectedPlan = find(plans, function(p) {
        return p.name == upperCaseFirst(plan);
    });

    const selectedPrice = find(prices, function(p) {
        return (
            p.product == selectedPlan.id &&
            p.recurring &&
            p.recurring.interval == interval
        );
    });

    const getStripePromise = () => {
        let newStripePromise = loadStripe(stripeKey);
        setStripePromise(newStripePromise);
    };

    useEffect(() => {
        getStripePromise();
    }, []);

    return (
        <General
            maxWidth={false}
            style={{
                background: "url(/img/cards-background.png) center"
            }}
        >
            <div className={classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} md={6} lg={4}>
                        <Paper className={classes.paperRoot}>
                            <Typography
                                variant="h2"
                                gutterBottom
                                align="center"
                            >
                                {upperCaseFirst(plan)} - $
                                {selectedPrice
                                    ? parseInt(
                                          selectedPrice.unit_amount / 100
                                      ).toFixed(2)
                                    : "--"}
                                /{interval}
                            </Typography>
                            {step == 0 && (
                                <PersonalInfoStep
                                    values={values}
                                    setLoading={setLoading}
                                    handleChange={handleChange}
                                    handleNextStep={handleNextStep}
                                    getErrorMessage={getErrorMessage}
                                />
                            )}
                            {step == 1 && stripeKey && Boolean(stripePromise) && (
                                <>
                                    {plan == "team" ||
                                    plan == "enterprise" ||
                                    plan == "education" ? (
                                        <OrganizationStep
                                            values={values}
                                            setLoading={setLoading}
                                            handleChange={handleChange}
                                            handleNextStep={handleNextStep}
                                            getErrorMessage={getErrorMessage}
                                            handlePreviousStep={
                                                handlePreviousStep
                                            }
                                        />
                                    ) : (
                                        <Elements stripe={stripePromise}>
                                            <PaymentStep
                                                values={values}
                                                setValues={setValues}
                                                setLoading={setLoading}
                                                selectedPlan={selectedPlan}
                                                handleChange={handleChange}
                                                selectedPrice={selectedPrice}
                                                getErrorMessage={
                                                    getErrorMessage
                                                }
                                                setErrorMessage={
                                                    setErrorMessage
                                                }
                                                handlePrevious={
                                                    handlePreviousStep
                                                }
                                                getStripePromise={
                                                    getStripePromise
                                                }
                                            />
                                        </Elements>
                                    )}
                                </>
                            )}
                            {step == 2 && stripeKey && Boolean(stripePromise) && (
                                <Elements stripe={stripePromise}>
                                    <PaymentStep
                                        values={values}
                                        setValues={setValues}
                                        setLoading={setLoading}
                                        selectedPlan={selectedPlan}
                                        handleChange={handleChange}
                                        selectedPrice={selectedPrice}
                                        getErrorMessage={getErrorMessage}
                                        setErrorMessage={setErrorMessage}
                                        handlePrevious={handlePreviousStep}
                                        getStripePromise={getStripePromise}
                                    />
                                </Elements>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                {loading && <LoadingBackdrop open={loading} />}
                {errorMessage && errorMessage != "" && (
                    <MessageSnackbar variant="error" message={errorMessage} />
                )}
            </div>
        </General>
    );

    function getErrorMessage(response) {
        console.log("errorMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("plan-registration")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Registration />
        </ErrorBoundary>,
        document.getElementById("plan-registration")
    );
}
