import React from "react";
import { List } from "@material-ui/core";
import {
    Grid,
    Users,
    Layers,
    Layout,
    RefreshCcw,
    Image,
    Box
} from "react-feather";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import NavItem from "@global/NavItem";
import NavHeader from "@global/NavHeader";
import isCurrentPage from "@utils/isCurrentPage";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

const AdminNav = () => {
    const { has_original_user } = reactProps;

    const links = [
        { href: "/admin/dashboard", icon: <Grid />, title: "Dashboard" },
        {
            href: "/admin/sessions",
            icon: <Layout />,
            title: "Sessions"
        },
        { href: "/admin/decks", icon: <Layers />, title: "Decks" },
        { href: "/admin/cards", icon: <Image />, title: "Cards" },
        { href: "/admin/users", icon: <Users />, title: "Users" },
        {
            href: "/admin/organizations",
            icon: <Box />,
            title: "Organizations"
        },
        {
            href: "/admin/coupons",
            icon: <ConfirmationNumberIcon />,
            title: "Coupons"
        }
    ];

    return (
        <List>
            <NavHeader title="Admin" first />
            {links.map(link => {
                return (
                    <NavItem
                        current={isCurrentPage(link.href)}
                        href={link.href}
                        key={link.href}
                    >
                        {link.icon}
                        {link.title}
                    </NavItem>
                );
            })}
            {has_original_user && (
                <NavItem href="/users/switch-back">
                    <RefreshCcw />
                    Switch Back
                </NavItem>
            )}
        </List>
    );
};

export default AdminNav;
