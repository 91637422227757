import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

const LoadingBackdrop = ({ open }) => {
    return (
        <Backdrop open={open} style={{ zIndex: 9999 }}>
            <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
    );
};

export default LoadingBackdrop;
