import React, { useState, useEffect } from "react";
import {
    Button,
    DialogActions,
    DialogContent,
    Typography,
    TextField,
    Switch,
    Grid
} from "@material-ui/core";
import { find, filter } from "lodash";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import { makeStyles } from "@material-ui/core/styles";
import PlanComparison from "./PlanComparison";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    disabled: {
        fontSize: 12,
        color: "#999"
    },
    marginTop: {
        marginTop: theme.spacing(1)
    }
}));

const ChangePlanDialog = props => {
    const classes = useStyles();
    const { handleClose, selectedPlan } = props;
    const { plan, prices, plans, userToken } = reactProps;
    const [selectedInterval, setSelectedInterval] = useState("year");
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [alternatePrice, setAlternatePrice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [downGrading, setDownGrading] = useState(null);
    const [removingOrganization, setRemovingOrganization] = useState(null);
    const [addingOrganization, setAddingOrganizaiton] = useState(null);
    const [organizationName, setOrganizationName] = useState("");

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("planName", selectedPlan.name);
        params.append("planId", selectedPrice.id);

        if (downGrading) {
            params.append("downGrading", 1);
        }

        if (removingOrganization) {
            params.append("removingOrganization", 1);
        }

        if (addingOrganization) {
            params.append("addingOrganization", 1);
            params.append("organization_name", organizationName);
        }

        axios
            .post(`/api/v1/billing/change-plans`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Plan Updated");
                window.location = `/manage-plan?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const getSelectedPrice = () => {
        console.log("THIS IS THE SELECTED PLAN", selectedPlan);
        let newPrice = find(prices, function(price) {
            console.log("price", price);
            return (
                price.product == selectedPlan.id &&
                price.recurring.interval == selectedInterval
            );
        });
        console.log("newPrice", newPrice);
        setSelectedPrice(newPrice);
        setSelectedInterval(newPrice.recurring.interval);
        console.log("newPrice", newPrice);
        console.log("newPrice.recurring.interval", newPrice.recurring.interval);
    };

    const getAlternatePrice = () => {
        console.log("selectedPrice", selectedPrice);
        console.log("selectedPlan", selectedPlan);
        console.log("prices", prices);
        let newAlternatePrice = find(prices, function(price) {
            console.log("price", price);
            console.log("price.product", price.product);
            console.log("selectedPlan.id", selectedPlan.id);
            console.log("price.recurring.interval", price.recurring.interval);
            console.log(
                "selectedPrice.recurring.interval",
                selectedPrice.recurring.interval
            );

            return (
                price.product == selectedPlan.id &&
                price.recurring.interval != selectedPrice.recurring.interval
            );
        });
        console.log("newAlternativePrice", newAlternatePrice);
        setAlternatePrice(newAlternatePrice);
    };

    const toggleInterval = () => {
        let newInterval;
        let newSelectedPricing = alternatePrice;
        let newAlternatePrice = selectedPrice;

        if (selectedInterval == "year") {
            newInterval = "month";
        } else {
            newInterval = "year";
        }

        setSelectedPrice(newSelectedPricing);
        setAlternatePrice(newAlternatePrice);
        setSelectedInterval(newInterval);
    };

    const isDownGrading = () => {
        let newIsDowngrading;
        console.log("plan", plan);

        if (plan == "plus") {
            if (selectedPlan.name == "Basic") {
                newIsDowngrading = true;
            } else {
                newIsDowngrading = false;
            }
        } else if (plan == "professional") {
            if (selectedPlan.name == "Basic" || selectedPlan.name == "Plus") {
                newIsDowngrading = true;
            } else {
                newIsDowngrading = false;
            }
        } else if (plan == "team") {
            if (selectedPlan.name == "Enterprise") {
                newIsDowngrading = false;
            } else if (selectedPlan.name == "Education") {
                newIsDowngrading = false;
            } else {
                newIsDowngrading = true;
            }
        } else if (plan == "enterprise") {
            newIsDowngrading = true;
        } else if (plan == "education") {
            if (selectedPlan.name != "Enterprise") {
                newIsDowngrading = true;
            } else {
                newIsDowngrading = false;
            }
        } else {
            newIsDowngrading = false;
        }

        setDownGrading(newIsDowngrading);
    };

    const isRemovingOrganization = () => {
        if (plan == "team" || plan == "enterprise" || plan == "education") {
            if (
                selectedPlan.name == "Basic" ||
                selectedPlan.name == "Plus" ||
                selectedPlan.name == "Professional"
            ) {
                setRemovingOrganization(true);
            }
        } else {
            setRemovingOrganization(false);
        }
    };

    const isAddingOrganization = () => {
        if (plan != "team" && plan != "enterprise" && plan != "education") {
            if (
                selectedPlan.name == "Team" ||
                selectedPlan.name == "Enterprise" ||
                selectedPlan.name == "Education"
            ) {
                setAddingOrganizaiton(true);
            } else {
                setAddingOrganizaiton(false);
            }
        } else {
            setAddingOrganizaiton(false);
        }
    };

    useEffect(() => {
        console.log("plan", plan);
        console.log("selectedPlan", selectedPlan);
        console.log("plans", plans);
        console.log("prices", prices);
        console.log("downGrading", downGrading);

        if (!selectedPrice) {
            getSelectedPrice();
        }

        if (selectedPrice && !alternatePrice) {
            getAlternatePrice();
        }
    });

    useEffect(() => {
        isDownGrading();
        isRemovingOrganization();
        isAddingOrganization();
    }, []);

    return (
        <>
            <DialogContent>
                <>
                    {Boolean(selectedPrice) && Boolean(alternatePrice) && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {downGrading && (
                                    <Alert
                                        severity="warning"
                                        className={classes.marginTop}
                                    >
                                        <Typography gutterBottom align="center">
                                            <strong>
                                                Downgrading plans will archive
                                                some active and draft sessions.
                                                You will be able to un-archive
                                                and activate sessions based on
                                                the permissions of your new
                                                plan.
                                            </strong>
                                        </Typography>
                                    </Alert>
                                )}
                                {removingOrganization && (
                                    <Alert
                                        severity="warning"
                                        className={classes.marginTop}
                                    >
                                        <Typography gutterBottom align="center">
                                            <strong>
                                                Downgrading to this plan will
                                                remove your current
                                                organization. All users in your
                                                organization will be moved to a
                                                two week trial of the "Basic"
                                                plan.
                                            </strong>
                                        </Typography>
                                    </Alert>
                                )}
                                {addingOrganization && (
                                    <Alert
                                        severity="info"
                                        className={classes.marginTop}
                                    >
                                        <Typography gutterBottom align="center">
                                            <strong>
                                                Upgrading to this plan will
                                                require an organization name.
                                                This name will be used when
                                                notifying the users that you
                                                invite to join Climer Cards.
                                            </strong>
                                        </Typography>
                                    </Alert>
                                )}
                                <Typography
                                    gutterBottom
                                    align="center"
                                    className={classes.marginTop}
                                >
                                    Your new plan will charge the same card you
                                    have on file on the same date you are
                                    regularly charged.
                                    <br />
                                    The amount you are charged will be prorated
                                    for the first month of your new plan.
                                </Typography>
                            </Grid>
                            {addingOrganization && (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.marginTop}
                                >
                                    <TextField
                                        required
                                        fullWidth
                                        value={organizationName}
                                        label="Organization/Team Name"
                                        onChange={event => {
                                            setOrganizationName(
                                                event.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className={classes.marginTop}
                            >
                                <Typography component="label" align="center">
                                    <Grid
                                        component="label"
                                        container
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item>Monthly</Grid>
                                        <Grid item>
                                            <Switch
                                                color="primary"
                                                onChange={toggleInterval}
                                                checked={
                                                    selectedInterval == "year"
                                                }
                                            />
                                        </Grid>
                                        <Grid item>Yearly</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {selectedPrice && (
                                    <Typography gutterBottom variant="h4">
                                        $
                                        {parseInt(
                                            selectedPrice.unit_amount / 100
                                        ).toFixed(2)}{" "}
                                        per {selectedInterval}{" "}
                                        <span className={classes.disabled}>
                                            or select $
                                            {parseInt(
                                                alternatePrice.unit_amount / 100
                                            ).toFixed(2)}{" "}
                                            per{" "}
                                            {selectedInterval == "year"
                                                ? "month"
                                                : "year"}
                                        </span>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <PlanComparison
                                    currentPlan={plan}
                                    selectedPlan={selectedPlan}
                                />
                            </Grid>
                        </Grid>
                    )}
                </>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                        addingOrganization == true && organizationName == ""
                    }
                >
                    Confirm Plan Change
                </Button>
            </DialogActions>
            {(!Boolean(selectedPrice) ||
                !Boolean(alternatePrice) ||
                loading) && <LoadingBackdrop open={true} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default ChangePlanDialog;
