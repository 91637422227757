import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import General from "@layouts/General";
import Pusher from "pusher-js";
import CardList from "@global/CardList";
import CurrentPrompt from "./components/CurrentPrompt";
import PromptResponses from "./components/PromptResponses";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useWindowFocus from "use-window-focus";
import { v4 as tokenGenerate } from "uuid";
//import mobileWakeLock from '@utils/mobileWakeLock';
import { filter } from "lodash";
import { WaitFacilitator } from "./components/WaitFacilitator";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#FFF"
    }
}));

const Index = () => {
    const {
        auth,
        facilitator,
        pusher_app_key,
        pusher_app_cluster,
        pusher_app_host,
        pusher_app_scheme
    } = reactProps;
    const classes = useStyles();
    const [deck, setDeck] = useState(reactProps.deck);
    const [decks, setDecks] = useState([reactProps.deck]);
    const [flipped, setFlipped] = useState(null);
    const [open, setOpen] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [updateChannel, setUpdateChannel] = useState(null);
    const [addedChannel, setAddedChannel] = useState(null);
    const [currentPrompt, setCurrentPrompt] = useState(0);
    const [prompts, setPrompts] = useState(reactProps.session.prompts);
    const [showResponses, setShowResponses] = useState(false);
    const [values, setValues] = useState({
        name: "",
        response: "",
        token: auth ? auth.uuid : tokenGenerate()
    });
    const [hasResponded, setHasResponded] = useState(false);
    const [session, setSession] = useState(reactProps.session);
    const [facilitatorActive, setFacilitatorActive] = useState(
        session.facilitator_is_active
    );
    const windowFocused = useWindowFocus();
    const decksRef = useRef();

    // let enabled = prompts.length;
    // mobileWakeLock(Boolean(enabled));

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const nextDisabled =
        filter(prompts, function(prompt) {
            return prompt.order > parseInt(currentPrompt);
        }).length < 1;

    const currentPromptData = filter(prompts, function(prompt) {
        return prompt.order === parseInt(currentPrompt);
    });

    const currentPromptDeckId = currentPromptData[0]
        ? currentPromptData[0].deck_id
        : 1;

    const prevDisabled = parseInt(currentPrompt) === 0 || !currentPrompt;

    const handleNew = () => {
        if (
            currentPrompt === 0 &&
            prompts &&
            Object.keys(prompts).length === 1
        ) {
            setTimeout(
                function() {
                    setShowResponses(false);
                }.bind(this),
                400
            );
        }
    };

    const handleNext = () => {
        setCurrentPrompt(prevState => prevState + 1);

        if (open === true) {
            setOpen(false);
        }

        setTimeout(
            function() {
                setShowResponses(false);
            }.bind(this),
            400
        );
    };

    const handlePrevious = () => {
        setCurrentPrompt(prevState => prevState - 1);

        if (open === true) {
            setOpen(false);
        }

        setTimeout(
            function() {
                setShowResponses(false);
            }.bind(this),
            400
        );
    };

    const handlePromptUpdate = () => {
        axios
            .get(`/api/v1/sessions/${session.uuid}/get-active-prompt`)
            .then(response => {
                if (
                    parseInt(currentPrompt) !==
                    parseInt(response.data.currentPrompt)
                ) {
                    setShowResponses(false);
                }
                if (response.data.session.asynchronous === 0) {
                    setCurrentPrompt(parseInt(response.data.currentPrompt));
                }
                setSession(response.data.session);
            })
            .catch(error => {
                //console.log("error", error);
            });
    };

    const handlePromptsUpdate = () => {
        axios
            .get(`/api/v1/sessions/${session.uuid}/prompts`)
            .then(response => {
                if (prompts.length !== response.data.prompts.length) {
                    setPrompts(response.data.prompts);
                }
                // setPrompts(response.data.prompts);
                setSession(response.data.session);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const getFacilitatorActivity = () => {
        axios
            .get(`/api/v1/sessions/${session.uuid}/get-active-facilitator`)
            .then(response => {
                setSession(response.data.session);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const toggleShowResponses = () => {
        setShowResponses(prevState => !prevState);
    };

    const handleSessionEnded = () => {
        axios
            .get(`/api/v1/sessions/${session.uuid}/status`)
            .then(response => {
                if (response.data.status !== "active") {
                    let message = encodeURIComponent(
                        "The facilitator has ended the session"
                    );
                    window.location = `/session-ended/thank-you?message=${message}`;
                }
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const toggleFlipped = () => {
        axios
            .get(`/api/v1/sessions/${reactProps.session.uuid}/flip-status`)
            .then(response => {
                setFlipped(response.data.flipped);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const applyNewDeck = newDeck => {
        let deck = false;

        if (decksRef.current) {
            deck = decksRef.current.find(obj => {
                return obj.id === newDeck.id;
            });
        }

        if (!deck) {
            if (!decks) {
                setDecks([newDeck]);
            } else {
                setDecks([...decks, newDeck]);
            }
        }
    };

    const queryDeck = deck_id => {
        axios
            .get(`/api/v1/sessions/${session.uuid}/deck/${deck_id}`)
            // .get(`/api/v1/sessions/${reactProps.session.uuid}/deck/${deck_id}`)
            .then(response => {
                applyNewDeck(response.data.deck);
                setDeck(response.data.deck);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const setDeckById = deck_id => {
        let targetDeck = false;
        if (decksRef.current) {
            targetDeck = decksRef.current.find(d => d.id === deck_id);
        }
        if (!targetDeck) {
            queryDeck(deck_id);
        } else {
            setDeck(targetDeck);
        }
    };

    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(
            `is-facilitator-in-session-${reactProps.session.uuid}`
            // `is-facilitator-in-session-${session.uuid}`
        );
        channel.bind_global(function(event, data) {
            if (data) {
                let responseActive = data.facilitator_is_active;
                let responseAsynchronous = data.asynchronous;
                if (responseActive === undefined) {
                    setFacilitatorActive(session.facilitator_is_active);
                } else {
                    setFacilitatorActive(responseActive === "1");
                    getFacilitatorActivity();
                }
                if (responseAsynchronous !== undefined) {
                    getFacilitatorActivity();
                }
            }
        });
    }, []);

    // updating prompt if he was change
    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(
            `prompt-updated-${reactProps.session.uuid}`
        );
        //if session synchronous only facilitator can switch the prompt
        // if (!session.asynchronous) {
        if (session.asynchronous !== 1) {
            channel.bind_global(function(event, data) {
                if (data && data.hasOwnProperty("deck_id")) {
                    setDeckById(data.deck_id);
                }
                handlePromptUpdate();
            });
        } else {
            setDeckById(currentPromptDeckId);
        }

        setUpdateChannel(channel);
        setHasResponded(hasResponded);
    }, [currentPrompt]);

    useEffect(() => {
        handleNew();
    }, [prompts]);

    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(
            `prompt-added-in-session-${reactProps.session.uuid}`
        );

        channel.bind_global(function(event, data) {
            if (data && data.hasOwnProperty("deck_id") && data.deck_id) {
                if (parseInt(data.prompt_order) === 0) {
                    setDeckById(data.deck_id);
                }
            }
            handlePromptsUpdate();
        });

        setAddedChannel(channel);
        setHasResponded(hasResponded);
    }, []);

    // useEffect(() => {
    //     setDeckById(decks);
    // }, []);

    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(
            `session-ended-${reactProps.session.uuid}`
        );
        channel.bind_global(function() {
            handleSessionEnded();
        });

        setHasResponded(hasResponded);
    }, []);

    useEffect(() => {
        let params = {
            cluster: pusher_app_cluster,
            forceTLS: false
        };
        if (pusher_app_scheme === "https") {
            params.forceTLS = true;
            params.enabledTransports = ["ws", "wss"];
        }
        if (!!pusher_app_host && pusher_app_host !== "") {
            params.wsHost = pusher_app_host;
        }
        let pusher = new Pusher(pusher_app_key, params);
        let channel = pusher.subscribe(`${reactProps.session.uuid}-flip`);
        channel.bind_global(function() {
            toggleFlipped();
        });
    }, []);

    // useEffect(() => {
    //     if (Boolean(windowFocused)) {
    //         handlePromptsUpdate();
    //         handlePromptUpdate();
    //     }
    // }, [windowFocused]);
    //
    // useEffect(() => {
    //     if (Boolean(windowFocused)) {
    //         handlePromptUpdate();
    //     }
    // }, [prompts]);

    useEffect(() => {
        decksRef.current = decks;
    }, [decks]);

    return (
        <>
            {session.asynchronous ||
            (!session.asynchronous && facilitatorActive) ? (
                <General
                    maxWidth="xl"
                    handleNext={session.asynchronous ? handleNext : false}
                    nextDisabled={session.asynchronous ? nextDisabled : true}
                    handlePrevious={
                        session.asynchronous ? handlePrevious : false
                    }
                    prevDisabled={session.asynchronous ? prevDisabled : true}
                >
                    <div className={classes.root}>
                        {currentPrompt !== undefined &&
                            currentPrompt !== null && (
                                <CurrentPrompt
                                    prompts={prompts}
                                    key={currentPrompt}
                                    hasResponded={hasResponded}
                                    showResponses={showResponses}
                                    currentPrompt={currentPrompt}
                                    toggleShowResponses={toggleShowResponses}
                                />
                            )}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {showResponses ? (
                                    <PromptResponses
                                        deck={deck}
                                        prompts={prompts}
                                        token={values.token}
                                        currentPrompt={currentPrompt}
                                        toggleShowResponses={
                                            toggleShowResponses
                                        }
                                        hasResponded={hasResponded}
                                        setHasResponded={setHasResponded}
                                        session={session}
                                    />
                                ) : (
                                    <CardList
                                        deck={deck}
                                        values={values}
                                        prompts={prompts}
                                        token={values.token}
                                        flipped={flipped}
                                        setValues={setValues}
                                        handleChange={handleChange}
                                        toggleShowResponses={
                                            toggleShowResponses
                                        }
                                        hasResponded={hasResponded}
                                        setHasResponded={setHasResponded}
                                        session={session}
                                        participant={true}
                                        promptData={currentPromptData}
                                        currentPromptNumber={currentPrompt}
                                        // currentPrompt={currentPrompt}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </General>
            ) : (
                <WaitFacilitator />
            )}
        </>
    );
};

if (document.getElementById("session")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Index />
        </ErrorBoundary>,
        document.getElementById("session")
    );
}
