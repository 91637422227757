import React, { useState } from "react";
import {
    Grid,
    Paper,
    Table,
    Chip,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    IconButton,
    InputAdornment,
    Button
} from "@material-ui/core";
import { Search } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    }
}));

const CardsTable = () => {
    const { userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState(reactProps.cards);
    const [cardsCount, setCardsCount] = useState(reactProps.cardsCount);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [searchQuery, setSearchQuery] = useState("");
    const [text, setText] = useState("");
    const classes = useStyles();

    const handleSearchTextChange = event => {
        setText(event.target.value);
        event.persist();
    };

    const handleSearchKeyUp = event => {
        if (event.keyCode === 13 && event.target.value.length > 0) {
            handleSearchSubmit(event.target.value);
        }
        event.persist();
    };

    const clearSearch = () => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/cards?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=&token=${userToken}`
            )
            .then(response => {
                setSearchQuery("");
                setCards(response.data.cards);
                setCardsCount(reactProps.cardsCount);
                setPage(0);
                setLoading(false);
                setText("");
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSearchSubmit = searchText => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/cards?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=${searchText}&token=${userToken}`
            )
            .then(response => {
                setSearchQuery(searchText);
                setCards(response.data.cards);
                if (
                    response.data.cardsCount &&
                    response.data.cardsCount != cardsCount
                ) {
                    setCardsCount(response.data.cardsCount);
                }
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/cards?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=${rowsPerPage *
                    newPage}&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setCards(response.data.cards);
                setPage(newPage);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangeRowsPerPage = event => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/cards?sortBy=${orderBy}&sortDirection=${order}&take=${parseInt(
                    event.target.value,
                    10
                )}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setCards(response.data.cards);
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSort = property => event => {
        console.log("handleSort");
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        setLoading(true);
        let isAsc = orderBy == property && order == "asc";

        axios
            .get(
                `/api/v1/admin/cards?sortBy=${property}&sortDirection=${
                    !isAsc ? "asc" : "desc"
                }&take=${rowsPerPage}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setCards(response.data.cards);
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    return (
        <div>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.padded}>
                            <TextField
                                fullWidth
                                label="Search"
                                margin="normal"
                                value={text}
                                onKeyUp={handleSearchKeyUp}
                                onChange={handleSearchTextChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    handleSearchSubmit(text)
                                                }
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {searchQuery && searchQuery != "" && (
                                <Chip
                                    label={`Search: ${searchQuery}`}
                                    onDelete={clearSearch}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        aria-labelledby="cards-table"
                        size="medium"
                        aria-label="cards table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "name" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "name"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("name")}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "decks_count" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "decks_count"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("decks_count")}
                                    >
                                        Decks
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" padding="default">
                                    {" "}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cards.map(card => (
                                <TableRow key={card.id} hover>
                                    <TableCell>{card.name}</TableCell>
                                    <TableCell>{card.decks_count}</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            size="small"
                                            component="a"
                                            color="primary"
                                            variant="outlined"
                                            href={`/admin/cards/${card.uuid}/edit`}
                                        >
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={cardsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {loading && <LoadingBackdrop open={loading} />}
        </div>
    );
};

export default CardsTable;
