import React, { useState } from "react";
import { Grid, Paper, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactCardFlip from "react-card-flip";
import LoopIcon from "@material-ui/icons/Loop";
import CardImage from "@global/CardImage";
import CardDetailsDialog from "../../../global/CardDetailsDialog";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0)",
        border: "none",
        boxShadow: "none",
        display: "flex",
        cursor: "grab",
        height: 200,
        justifyContent: "center",
        margin: theme.spacing(2),
        padding: 0,
        position: "relative",
        transitionDuration: "0.25s",
        width: 200,
        "& > div": {
            borderRadius: 4,
            boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            overflow: "hidden"
        },
        "&:hover": {
            transform: "scale(1.15)",
            "& > div": {
                boxShadow:
                    "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)"
            },
            "& > span": {
                display: "inline-block"
            }
        },
        "&:focus-within": {
            transform: "scale(1.15)",
            "& > div": {
                boxShadow:
                    "0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)"
            },
            "& > span": {
                display: "inline-block"
            }
        },
        "& > span": {
            display: "none"
        }
    },
    flipButton: {
        backgroundColor: "#115a78cc",
        bottom: 0,
        borderRadius: "50%",
        color: theme.palette.secondary.main,
        position: "absolute",
        right: 0,
        "&:hover": {
            backgroundColor: "#115a78"
        }
    }
}));

const Card = props => {
    const { deck } = reactProps;
    const {
        card,
        editing,
        handleRemove,
        dragStartHandler,
        dragEndHandler,
        dragOverHandler,
        dropHandler
    } = props;
    const classes = useStyles();
    const [flipped, setFlipped] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [open, setOpen] = useState(null);

    const handleOpen = card_id => {
        setOpen(card_id);
    };

    const handleClose = () => setOpen(null);

    const handleFlip = event => {
        event.stopPropagation();
        setFlipped(!flipped);
    };

    const onEnter = () => {
        if (!card.has_back) return;

        setShowButton(true);
    };

    const onLeave = () => {
        if (!card.has_back) return;

        setShowButton(false);
    };

    const handleKeyUp = event => {
        if (event.key === " ") {
            event.preventDefault();
            handleOpen(card.id);
            return false;
        }
    };

    return (
        <Grid item style={{ textAlign: "center" }}>
            <div
                onClick={() => handleOpen(card.id)}
                tabIndex={0}
                onKeyUp={handleKeyUp}
                className={classes.root}
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
                onFocusCapture={onEnter}
                onBlur={onLeave}
                onDragStart={e => dragStartHandler(e, card)}
                onDragLeave={e => dragEndHandler(e)}
                onDragEnd={e => dragEndHandler(e)}
                onDragOver={e => dragOverHandler(e)}
                onDrop={e => dropHandler(e, card)}
                draggable={true}
            >
                {Boolean(deck.allow_back) && Boolean(card.has_back) ? (
                    <>
                        <ReactCardFlip
                            isFlipped={flipped}
                            flipDirection="horizontal"
                        >
                            <Paper elevation={2}>
                                <CardImage
                                    alt={card.alt_text}
                                    src={card.frontImage}
                                    orientation={card.orientation}
                                />
                            </Paper>
                            <Paper elevation={2}>
                                <CardImage
                                    alt={card.back_alt_text}
                                    src={card.backImage}
                                    orientation="tall"
                                />
                            </Paper>
                        </ReactCardFlip>
                        <IconButton
                            className={classes.flipButton}
                            component="span"
                            onClick={handleFlip}
                        >
                            <LoopIcon />
                        </IconButton>
                    </>
                ) : (
                    <Paper elevation={2}>
                        <CardImage
                            alt={card.alt_text}
                            src={card.frontImage}
                            orientation={card.orientation}
                        />
                    </Paper>
                )}
            </div>
            {editing && (
                <Button size="small" onClick={() => handleRemove(card.uuid)}>
                    Remove
                </Button>
            )}
            <CardDetailsDialog
                open={open}
                handleClose={handleClose}
                card={card}
            />
        </Grid>
    );
};

export default Card;
