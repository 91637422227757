import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    ButtonGroup,
    Button,
    Dialog,
    DialogTitle
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { loadCSS } from "fg-loadcss";
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "@utils/windowDimensions";
import { upperCaseFirst } from "@utils/stringUtils";
import AddPaymentMethodDialog from "./AddPaymentMethodDialog";
import UpdatePaymentMethodDialog from "./UpdatePaymentMethodDialog";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { find } from "lodash";
import CancelPlan from "./CancelPlan";
import CreditCardLogo from "./CreditCardLogo";
import UpcomingPricingDetails from "./UpcomingPricingDetails";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    floatRight: {
        float: "right"
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    linkStyles: {
        cursor: "pointer"
    }
}));

const PaymentMethodCard = props => {
    const { isDefault, paymentMethod } = props;
    const {
        plan,
        prices,
        subscription,
        onTrial,
        stripeKey,
        userToken,
        subscriptionStatus,
        defaultPaymentMethodId
    } = reactProps;
    const { width } = useWindowDimensions();
    const classes = useStyles();
    const [open, setOpen] = useState(null);
    const [stripePromise, setStripePromise] = useState(false);
    const [canceled, setCanceled] = useState(null);
    const [cancelAt, setCancelAt] = useState(null);
    const [canceledAt, setCanceledAt] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleOpen = newOpen => {
        setOpen(newOpen);
    };

    const handleClose = () => {
        setOpen(null);
    };
    let selectedPrice = null;

    selectedPrice = find(prices, function(price) {
        return subscription && price.id == subscription.stripe_plan;
    });

    if (!selectedPrice) {
        selectedPrice = {
            recurring: {
                interval: 30
            },
            unit_amount: 0
        };
    }

    const getSelectedInterval = interval => {
        if (interval == "month") {
            return "Monthly";
        }

        if (interval == "year") {
            return "Yearly";
        }

        return "";
    };

    const selectedInterval = selectedPrice
        ? getSelectedInterval(selectedPrice.recurring.interval)
        : null;

    const handleRemove = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("paymentMethod", paymentMethod.id);

        axios
            .post(`/api/v1/user/remove-payment-method`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Payment method removed");
                window.location = `/manage-plan?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleDefault = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("paymentMethod", paymentMethod.id);

        axios
            .post(`/api/v1/user/make-method-default`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent(
                    "Default payment method updated"
                );
                window.location = `/manage-plan?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    useEffect(() => {
        let node = loadCSS(
            "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
            document.querySelector("#font-awesome-css")
        );

        return () => {
            node.parentNode.removeChild(node);
        };
    }, []);

    useEffect(() => {
        if (stripeKey && !Boolean(stripePromise)) {
            setStripePromise(loadStripe(stripeKey));
        }
    });

    useEffect(() => {
        axios
            .get(`/api/v1/check-for-canceled`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                //console.log("response", response);
                setCanceled(Boolean(response.data.canceled));

                if (Boolean(response.data.canceled)) {
                    setCancelAt(response.data.cancel_at);
                    setCanceledAt(response.data.canceled_at);
                }
            })
            .catch(error => {
                //console.log("error", error);
            });
    }, []);

    return (
        <Card className={classes.root}>
            <CardContent>
                {isDefault ? (
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            {Boolean(paymentMethod) && (
                                <CreditCardLogo paymentMethod={paymentMethod} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div
                                className={
                                    width > 870 ? classes.floatRight : ""
                                }
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleOpen("update")}
                                >
                                    {Boolean(defaultPaymentMethodId)
                                        ? "Update Card"
                                        : "Add Default Card"}
                                </Button>
                            </div>
                        </Grid>
                        {subscriptionStatus == "Past Due" && (
                            <Grid item xs={12} className={classes.marginTop}>
                                <Alert severity="error">
                                    We were unable to charge your default
                                    payment method. Please try a different card
                                    or reach out if the issues persists.
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12} className={classes.marginTop}>
                            <Typography gutterBottom>
                                <strong>Current Pricing</strong>
                            </Typography>
                            <Typography gutterBottom>
                                Plan - {upperCaseFirst(plan)}{" "}
                                {selectedInterval != "" &&
                                    `(${selectedInterval})`}
                                <span className={classes.floatRight}>
                                    $
                                    {parseInt(
                                        selectedPrice.unit_amount / 100
                                    ).toFixed(2)}
                                </span>
                            </Typography>
                            <UpcomingPricingDetails />
                            <Typography gutterBottom>
                                {subscription.stripe_status == "trialing" &&
                                    Boolean(onTrial) &&
                                    `In Trial - trial ends ${moment(
                                        subscription.trial_ends_at
                                    ).fromNow()}`}
                            </Typography>
                        </Grid>
                        {!Boolean(canceled) && (
                            <Grid item xs={6} className={classes.marginTop}>
                                <a
                                    href="#"
                                    style={{ fontSize: 12, cursor: "pointer" }}
                                    onClick={() => handleOpen("add")}
                                >
                                    {Boolean(paymentMethod)
                                        ? "Add another payment method"
                                        : "Add a default payment method as soon as possible"}
                                </a>
                            </Grid>
                        )}
                        {canceled === false && (
                            <Grid item xs={6} className={classes.marginTop}>
                                <CancelPlan
                                    setLoading={setLoading}
                                    getErrorMessage={getErrorMessage}
                                />
                            </Grid>
                        )}
                        {canceled == true && (
                            <Grid item xs={12}>
                                <Typography gutterBottom>
                                    No upcoming charges are pending.
                                </Typography>
                                {Boolean(canceledAt) && Boolean(cancelAt) && (
                                    <Typography gutterBottom>
                                        Your plan is active but it was canceled
                                        at{" "}
                                        {moment
                                            .utc(canceledAt)
                                            .local()
                                            .format("LLL")}
                                        . You will have access to this service
                                        until{" "}
                                        {moment
                                            .utc(cancelAt)
                                            .local()
                                            .format("LLL")}
                                        .
                                    </Typography>
                                )}
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12}>
                            <CreditCardLogo paymentMethod={paymentMethod} />
                        </Grid>
                        <Grid item xs={12} className={classes.marginTop}>
                            <div className={classes.floatRight}>
                                <ButtonGroup size="small">
                                    <Button
                                        variant="outlined"
                                        onClick={handleRemove}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={handleDefault}
                                    >
                                        Make Default Payment Method
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
            {stripeKey && stripePromise && (
                <Elements stripe={stripePromise}>
                    <Dialog
                        fullWidth
                        maxWidth="md"
                        open={Boolean(open)}
                        onClose={handleClose}
                        aria-labelledby="card-dialog"
                    >
                        <DialogTitle id="card-dialog">
                            {open == "update"
                                ? "Update payment details"
                                : "Add a payment method"}
                        </DialogTitle>
                        {open == "update" ? (
                            <UpdatePaymentMethodDialog
                                handleClose={handleClose}
                            />
                        ) : (
                            <AddPaymentMethodDialog handleClose={handleClose} />
                        )}
                    </Dialog>
                </Elements>
            )}
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
        </Card>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

export default PaymentMethodCard;
