import React, { useRef } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    Input,
    InputLabel,
    Paper,
    Typography,
    Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PasswordField from "@global/PasswordField";
import LoadingBackdrop from "@global/LoadingBackdrop";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    reset: {
        display: "block",
        marginTop: theme.spacing(1),
        textAlign: "center"
    }
}));

const LoginForm = props => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const emailInput = useRef(null);
    const passwordInput = useRef(null);

    const handleSubmit = () => {
        if (
            emailInput.current.checkValidity() === true &&
            passwordInput.current.checkValidity() === true
        )
            setLoading(true);
    };

    return (
        <Paper className={classes.root} elevation={10} {...props}>
            <Typography variant="h4" component="h1" align="center">
                Login
            </Typography>
            <form method="post" action="/login">
                <input
                    name="_token"
                    className="d-none"
                    defaultValue={reactProps._token}
                />
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <Input
                        inputRef={emailInput}
                        id="email"
                        name="email"
                        autoComplete="email"
                        type="email"
                        autoFocus
                    />
                </FormControl>
                <PasswordField ref={passwordInput} />
                <FormControlLabel
                    control={<Checkbox name="remember" color="primary" />}
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Log In
                </Button>
            </form>
            <Link href="/password/reset" className={classes.reset}>
                Forgot password
            </Link>
            <LoadingBackdrop open={loading} />
        </Paper>
    );
};

export default LoginForm;
