import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import {
    Badge,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    withStyles,
    MuiThemeProvider
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import theme from "@assets/Theme";
import ContextualComponent from "@assets/ContextualComponent";

export default function Notifications() {
    const [subscribed, setSubscribed] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [active, setActive] = useState("default");

    const StyledMenu = withStyles({
        paper: {
            border: "1px solid #d3d4d5",
            width: "250px",
            maxHeight: "50%"
        }
    })(props => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            MenuListProps={{ style: { padding: 0 } }}
            {...props}
        />
    ));

    const ClearAllMenuItem = withStyles({
        root: {
            justifyContent: "center",
            fontWeight: "bold"
        }
    })(MenuItem);

    const StyledMenuItem = withStyles({
        root: {
            justifyContent: "center"
        }
    })(MenuItem);

    const StyledEmptyMenuItem = withStyles({
        root: {
            justifyContent: "center",
            borderBottom: "none"
        }
    })(MenuItem);

    useEffect(() => {
        if (!subscribed) {
            setSubscribed(true);
            getNotifications();

            setInterval(
                function() {
                    getNotifications();
                }.bind(this),
                30000
            );
        }
    });

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
        setActive("primary");
    }

    function handleClose() {
        setAnchorEl(null);
        setActive("default");
    }

    function getNotifications() {
        axios
            .get("/api/v1/admin/alerts?token=" + reactProps.userToken)
            .then(response => {
                setNotifications(response.data.alerts);
            })
            .catch(error => {});
    }

    function markAllAsRead() {
        axios
            .get("/api/v1/admin/alerts/seen?token=" + reactProps.userToken)
            .then(response => {
                setNotifications([]);
            })
            .catch(error => {});
    }

    function navigate(route) {
        window.location = route;
        markAllAsRead();
    }

    return (
        <ContextualComponent>
            <Tooltip title="Admin Notifications">
                <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color={active}
                >
                    {notifications.length == 0 ? (
                        <NotificationsIcon />
                    ) : (
                        <Badge
                            badgeContent={notifications.length}
                            color="error"
                        >
                            <NotificationsIcon />
                        </Badge>
                    )}
                </IconButton>
            </Tooltip>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                paper={{
                    maxWidth: "200px"
                }}
            >
                {notifications.length == 0 ? (
                    <StyledEmptyMenuItem onClick={handleClose}>
                        You have no new notifications.
                    </StyledEmptyMenuItem>
                ) : (
                    <div className="styled-menu-items-container">
                        {notifications.map(notification => {
                            return (
                                <StyledMenuItem
                                    key={notification.id}
                                    className="dropdown-item text-center p-2"
                                    style={{ wordWrap: "normal" }}
                                    onClick={() => navigate(notification.link)}
                                >
                                    {notification.message}
                                </StyledMenuItem>
                            );
                        })}
                        <ClearAllMenuItem onClick={markAllAsRead}>
                            Clear All Notifications
                        </ClearAllMenuItem>
                    </div>
                )}
            </StyledMenu>
        </ContextualComponent>
    );
}

if (document.getElementById("admin-notifications")) {
    ReactDOM.render(
        <Notifications />,
        document.getElementById("admin-notifications")
    );
}
