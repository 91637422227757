import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../../integrations/_integrations");
import ErrorBoundary from "@global/ErrorBoundary";
import UserNav from "@global/UserNav";
import { makeStyles } from "@material-ui/core/styles";
import DashboardLayout from "@layouts/DashboardLayout";
import {
    Grid,
    Typography,
    Breadcrumbs,
    Link,
    Divider,
    Tabs,
    Tab,
    Button,
    Paper,
    Tooltip
} from "@material-ui/core";
import Form from "./components/Form";
import Prompts from "./components/Prompts";
import LoadingBackdrop from "@global/LoadingBackdrop";
import MessageSnackbar from "@global/MessageSnackbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tabHead: {
        borderBottom: "1px solid #ccc",
        marginBottom: theme.spacing(2)
    },
    buttonContainer: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column"
    },
    endButton: {
        color: "#6e7518"
    },
    divider: {
        margin: `${theme.spacing(2)}px auto`
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    floatRight: {
        textAlign: "right"
    },
    formContainer: {
        padding: theme.spacing(2),
        "& input[disabled], textarea[disabled], select[disabled]": {
            color: theme.palette.text.primary
        }
    }
}));

const Edit = () => {
    const {
        userToken,
        session,
        rootUrl,
        auth,
        organization,
        plan
    } = reactProps;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const [tab, setTab] = useState(
        window.location.search.includes("details") ? 1 : 0
    );
    const [copied, setCopied] = useState(false);
    const [values, setValues] = useState({
        name: session ? session.name : "",
        slug: session ? session.slug : "",
        asynchronous: session ? Boolean(session.asynchronous) : false,
        description: session ? session.description : "",
        deck_id: session ? session.deck_id : head(decks).id,
        share: Boolean(session.organization_id),
        allow_facilitator_flip: session
            ? Boolean(session.allow_facilitator_flip)
            : false,
        allow_prompt_flip: session ? Boolean(session.allow_prompt_flip) : true,
        allow_response_flip: session
            ? Boolean(session.allow_response_flip)
            : true,
        minor_participants: session
            ? Boolean(session.minor_participants)
            : false,
        multiple_decks: session ? Boolean(session.multiple_decks) : false
    });

    const disableActions =
        Boolean(organization) &&
        session.user_id !== auth.id &&
        organization.user_id !== auth.id &&
        true;

    // Setup permissions for user plan
    const userLowPlan = plan === "basic" || plan === "plus";

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeDirectly = (key, value) => {
        setValues({
            ...values,
            [key]: value
        });
    };

    const handleSwitch = key => event => {
        setValues({
            ...values,
            [key]: event.target.checked
        });
    };

    const showCopied = text => {
        setCopied(true);

        navigator.clipboard.writeText(text);

        setTimeout(
            function() {
                setCopied(false);
            }.bind(this),
            3000
        );
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleEdit = () => {
        setLoading(true);
        console.log("values", values);
        let params = new FormData();
        params.append("name", values.name);
        params.append("slug", values.slug);
        params.append("asynchronous", values.asynchronous ? 1 : 0);
        params.append("description", values.description);
        params.append("deck_id", values.deck_id);
        params.append("organization_share", values.share === true ? 1 : 0);
        params.append(
            "allow_facilitator_flip",
            values.allow_facilitator_flip === true ? 1 : 0
        );
        params.append(
            "allow_prompt_flip",
            values.allow_prompt_flip === true ? 1 : 0
        );
        params.append(
            "allow_response_flip",
            values.allow_response_flip === true ? 1 : 0
        );
        params.append("minor_participants", values.minor_participants ? 1 : 0);

        axios
            .post(`/api/v1/sessions/${session.uuid}/edit`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session updated");
                window.location = `/sessions/${session.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleActivate = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", session.uuid);

        axios
            .post(`/api/v1/sessions/activate`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session status updated");
                window.location = `/sessions/${session.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleEndSession = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", session.uuid);

        axios
            .post(`/api/v1/sessions/archive`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session status updated");
                window.location = `/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleUnArchive = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", session.uuid);

        axios
            .post(`/api/v1/sessions/unarchive`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session status updated");
                window.location = `/sessions/${session.uuid}/edit?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const handleDelete = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("selected", session.uuid);

        axios
            .post(`/api/v1/sessions/deleted`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Session has been deleted");
                window.location = `/sessions?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    const getStatusTag = () => {
        switch (session.status) {
            case "active":
                return (
                    <span
                        style={{
                            backgroundColor: "#4786AA",
                            padding: "4px 6px",
                            color: "#fff",
                            fontSize: 16,
                            marginLeft: "1rem"
                        }}
                    >
                        Active
                    </span>
                );
            case "archived":
                return (
                    <span
                        style={{
                            backgroundColor: "#00000040",
                            padding: "4px 6px",
                            color: "#fff",
                            fontSize: 16,
                            marginLeft: "1rem"
                        }}
                    >
                        Archived
                    </span>
                );
            default:
                return (
                    <span
                        style={{
                            backgroundColor: "#A8D058",
                            padding: "4px 6px",
                            color: "#fff",
                            fontSize: 16,
                            marginLeft: "1rem"
                        }}
                    >
                        Draft
                    </span>
                );
        }
    };

    const handleResponsesXlsx = () => {
        setLoading(true);

        axios
            .get(`/api/v1/sessions/${session.uuid}/report/xlsx`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                setLoading(false);
                if (response.data && response.data.status) {
                    if (response.data.status === "success") {
                        window.location = `/download-report/${
                            response.data.report_id
                        }?report_name=${encodeURIComponent(
                            response.data.report_name
                        )}`;
                    } else {
                        alert(response.data.message || "Unknown error");
                    }
                } else {
                    alert("Unknown error");
                }
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    alert(error.response.data.message);
                } else {
                    alert("Unknown error");
                }
                setLoading(false);
            });
    };

    const setFacilitatorActivity = value => {
        let params = new URLSearchParams();
        params.append("facilitator_is_active", value);

        axios
            .post(
                `/api/v1/sessions/${session.uuid}/update-active-facilitator`,
                params
            )
            .then(response => {
                console.log("response-active-facilitator", response);
                // setSession(response.data.session);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    const handleResponsesPdf = () => {
        setLoading(true);

        axios
            .get(`/api/v1/sessions/${session.uuid}/report/pdf`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                if (response.data && response.data.status) {
                    if (response.data.status === "success") {
                        if (response.data.html) {
                            alert("Unknown error.");
                        } else {
                            window.location = `/download-report/${
                                response.data.report_id
                            }?report_name=${encodeURIComponent(
                                response.data.report_name
                            )}`;
                            setLoading(false);
                        }
                    } else {
                        alert(response.data.message || "Unknown error !");
                        setLoading(false);
                    }
                } else {
                    alert("Unknown error !!");
                }
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    alert(error.response.data.message);
                } else {
                    console.log(error);
                    alert("Unknown error !!!");
                }
                setLoading(false);
            });
    };

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Sessions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Breadcrumbs
                        aria-label="Sessions"
                        mt={2}
                        className={classes.breadcrumbs}
                    >
                        <Link component="a" href="/dashboard">
                            Dashboard
                        </Link>
                        <Link component="a" href="/sessions">
                            Sessions
                        </Link>
                        <Typography>Edit {session.name}</Typography>
                    </Breadcrumbs>
                    <Divider my={6} className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="overline">Name</Typography>
                            <Typography variant="h3" gutterBottom>
                                {session.name} {getStatusTag()}
                            </Typography>
                            <Typography variant="overline" display="block">
                                Access Code
                            </Typography>
                            <Typography color="primary" display="inline">
                                {session.slug}
                            </Typography>
                            {/* <CopyToClipboard
                                text={`${session.slug}`}
                                onCopy={showCopied}
                            > */}
                            <Tooltip title="This code can be entered on the app’s home page">
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        showCopied(`${session.slug}`)
                                    }
                                >
                                    Copy Code
                                </Button>
                            </Tooltip>
                            {/* </CopyToClipboard> */}
                        </Grid>
                        {session.status != "archived" ? (
                            <Grid
                                item
                                xs={4}
                                className={classes.buttonContainer}
                            >
                                {session.status == "upcoming" && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={disableActions}
                                        onClick={handleActivate}
                                    >
                                        Activate Session
                                    </Button>
                                )}
                                {session.status == "active" && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                            session.user_id === auth.id
                                                ? () =>
                                                      setFacilitatorActivity(1)
                                                : ""
                                        }
                                        href={`/${session.slug}${
                                            session.user_id === auth.id
                                                ? "/presentation"
                                                : ""
                                        }`}
                                    >
                                        {session.user_id === auth.id
                                            ? "Enter"
                                            : "Participate in"}{" "}
                                        Session
                                    </Button>
                                )}
                                <Button
                                    className={classes.endButton}
                                    color="inherit"
                                    disabled={disableActions}
                                    onClick={handleEndSession}
                                >
                                    Archive Session
                                </Button>
                                <Button
                                    style={{ marginTop: 20 }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenModal}
                                >
                                    Delete Session
                                </Button>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                xs={4}
                                className={classes.buttonContainer}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={disableActions}
                                    onClick={handleUnArchive}
                                >
                                    Activate Session
                                </Button>
                                <Button
                                    style={{ marginTop: 20 }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenModal}
                                >
                                    Delete Session
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Typography variant="overline" display="block">
                                Session Link
                            </Typography>
                            <Link
                                href={`${rootUrl}/${session.slug}`}
                                display="inline"
                            >
                                {`${rootUrl}/${session.slug}`}
                            </Link>
                            {/* <CopyToClipboard
                                text={`${rootUrl}/${session.slug}`}
                                onCopy={showCopied}
                            > */}
                            <Tooltip title="Copy this link to share with your participants">
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        showCopied(`${rootUrl}/${session.slug}`)
                                    }
                                >
                                    Copy Link
                                </Button>
                            </Tooltip>
                            {/* </CopyToClipboard> */}
                        </Grid>
                        {session.multiple_decks !== 1 ? (
                            <>
                                <Grid item xs={6}>
                                    <Typography variant="overline">
                                        Deck
                                    </Typography>
                                    <Link
                                        display="block"
                                        href={`/decks/${session.deck.uuid}/edit`}
                                    >
                                        {session.deck.name}
                                    </Link>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="overline">
                                        Description
                                    </Typography>
                                    <Typography variant="body1">
                                        {session.description}
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="overline">
                                        Description
                                    </Typography>
                                    <Typography variant="body1">
                                        {session.description}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {!userLowPlan && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="overline" display="block">
                                    Download report
                                </Typography>
                                <div
                                    className="report-download-icons"
                                    style={{ paddingTop: "5px" }}
                                >
                                    <Tooltip title="Download XLSX report">
                                        <Link
                                            style={{
                                                marginRight: "10px",
                                                cursor: "pointer"
                                            }}
                                            onClick={handleResponsesXlsx}
                                        >
                                            <img
                                                height="32px"
                                                src="/img/icons/xls-128.png"
                                                alt="Download XLSX report"
                                            />
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Download PDF report">
                                        <Link
                                            style={{ cursor: "pointer" }}
                                            onClick={handleResponsesPdf}
                                        >
                                            <img
                                                height="32px"
                                                src="/img/icons/pdf-128.png"
                                                alt="Download PDF report"
                                            />
                                        </Link>
                                    </Tooltip>
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                className={classes.tabHead}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Prompts" />
                                <Tab
                                    label={`${
                                        session.status == "archived"
                                            ? "View"
                                            : "Edit"
                                    } Details`}
                                />
                            </Tabs>
                            {tab == 0 && (
                                <Prompts disableActions={disableActions} />
                            )}
                            {tab == 1 && (
                                <Paper className={classes.formContainer}>
                                    <Typography variant="h4">
                                        Details
                                    </Typography>
                                    {session.status == "active" && (
                                        <Grid item xs={12}>
                                            <Alert severity="info">
                                                Any edits to settings during an
                                                active session will take affect
                                                on the next prompt. Toggle back
                                                and forth between prompts to
                                                apply setting changes to the
                                                current prompt.
                                            </Alert>
                                        </Grid>
                                    )}
                                    <Form
                                        session={session}
                                        setValues={setValues}
                                        handleChange={handleChange}
                                        handleSwitch={handleSwitch}
                                        handleChangeDirectly={
                                            handleChangeDirectly
                                        }
                                        values={values}
                                        disableActions={disableActions}
                                        userLowPlan={userLowPlan}
                                    />
                                    <div
                                        className={clsx(
                                            classes.floatRight,
                                            classes.marginTop
                                        )}
                                    >
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleEdit(values)}
                                            disabled={disableActions}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            {copied && (
                <MessageSnackbar
                    variant="success"
                    message="Shared URL Copied"
                />
            )}
            <Dialog
                open={Boolean(openModal)}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography
                        gutterBottom
                        style={{ fontSize: 20 }}
                        class="text-center"
                    >
                        <strong>
                            Are you sure you would like to delete selected
                            session?
                        </strong>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{ fontSize: 18 }}
                    >
                        This action is permanent and cannot be undone!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button
                        onClick={handleDelete}
                        disabled={disableActions}
                        variant="contained"
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );

    function getErrorMessage(response) {
        console.log("erroMessage", response);

        if (response.status == 400 || response.status == 419) {
            setErrorMessage("Form request expired.");
        }

        if (response.status == 401) {
            setErrorMessage("Cannot verify permissions.");
        }

        if (response.status == 422) {
            var err = response.data;
            let message = _.head(err[Object.keys(err)[0]]);
            setErrorMessage(message);
        }

        if (response.status == 404 && response.data && response.data.message) {
            setErrorMessage(response.data.message);
        }

        if (response.status == 500) {
            setErrorMessage("Something went wrong. Please try again later.");
        }

        setTimeout(
            function() {
                clearError();
            }.bind(this),
            4000
        );
    }

    function clearError() {
        setErrorMessage("");
    }
};

if (document.getElementById("facilitators-session-edit")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Edit />
        </ErrorBoundary>,
        document.getElementById("facilitators-session-edit")
    );
}
