import React, { useEffect } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { find } from "lodash";

const CurrentPrompt = props => {
    const {
        prompts,
        currentPrompt,
        toggleShowResponses,
        showResponses,
        hasResponded
    } = props;
    const presentPrompt = find(prompts, function(prompt) {
        return prompt.order == currentPrompt;
    });

    const isPresentPrompt = presentPrompt ? presentPrompt.prefix : null;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
                {currentPrompt !== undefined && currentPrompt !== null && (
                    <>
                        {isPresentPrompt ? (
                            <Typography
                                variant="h4"
                                component="h3"
                                align="center"
                                color="primary"
                            >
                                Select a card that represents...
                            </Typography>
                        ) : null}
                        <Typography
                            variant="h2"
                            component="p"
                            gutterBottom
                            align="center"
                            color="inherit"
                            style={{ color: "581e47" }}
                        >
                            {find(prompts, function(prompt) {
                                return prompt.order == parseInt(currentPrompt);
                            })
                                ? find(prompts, function(prompt) {
                                      return (
                                          prompt.order ==
                                          parseInt(currentPrompt)
                                      );
                                  }).description
                                : ""}
                        </Typography>
                        {hasResponded && (
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={toggleShowResponses}
                            >
                                {showResponses
                                    ? "Show Cards"
                                    : "Show Responses"}
                            </Button>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default CurrentPrompt;
