import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-perfect-scrollbar/dist/css/styles.css";
import MessageSnackbar from "@global/MessageSnackbar";
import { makeStyles } from "@material-ui/core/styles";
import theme from "./theme";
import clsx from "clsx";

const useStyles = makeStyles(originalTheme => ({
    container: {
        minHeight: "100vh",
        display: "flex",
        "@media all and (-ms-high-contrast:none)": {
            height: 0 // IE11 fix
        }
    },
    content: {
        flexGrow: 1,
        maxWidth: "100%",
        overflowX: "hidden",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    }
}));

const PrimaryTheme = props => {
    const { children } = props;
    const classes = useStyles();
    const { message, error, auth, admin } = reactProps;

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.content}>{children}</div>
                    </div>
                    {message && (
                        <MessageSnackbar message={message} variant="success" />
                    )}
                    {error && (
                        <MessageSnackbar message={error} variant="error" />
                    )}
                </div>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default PrimaryTheme;
