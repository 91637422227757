import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import MessageSnackbar from "./MessageSnackbar";
import LoadingBackdrop from "./LoadingBackdrop";

const useStyles = makeStyles(theme => ({
    switchPaper: {
        margin: "2rem auto",
        padding: theme.spacing(2),
        textAlign: "center",
        width: 290
    },
    popover: {
        pointerEvents: "none"
    },
    information: {
        padding: theme.spacing(2),
        textAlign: "center",
        width: 290
    }
}));

const PaperWithPopover = ({ isSubscriptions, setIsSubscriptions }) => {
    const { userToken } = reactProps;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const open = Boolean(anchorEl);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleCancelSubscriptions = () => {
        setLoading(true);

        axios
            .post(
                `/api/v1/cancel-subscriptions`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then(response => {
                console.log("response", response);
                if (response.data.canceled) {
                    setSuccessMessage(
                        "You have been successfully canceled your subscriptions"
                    );
                }
                setIsSubscriptions(false);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setErrorMessage(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (errorMessage !== "") {
            setTimeout(
                function() {
                    setErrorMessage("");
                }.bind(this),
                4000
            );
        }
    }, [errorMessage]);

    return (
        <>
            {isSubscriptions && (
                <div>
                    <Paper
                        style={{ marginTop: 45 }}
                        className={classes.switchPaper}
                        elevation={10}
                        aria-owns={open ? "mouse-over-popover" : undefined}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <Typography
                            variant="h4"
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                open ? handleCancelSubscriptions() : false
                            }
                        >
                            Cancel my current subscription
                        </Typography>
                        <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Typography className={classes.information}>
                                If your account is past due and/or you prefer to
                                cancel, click here.
                                <br />
                                You can re-activate at any time
                            </Typography>
                        </Popover>
                    </Paper>
                </div>
            )}
            {loading && <LoadingBackdrop open={loading} />}
            {errorMessage && errorMessage != "" && (
                <MessageSnackbar variant="error" message={errorMessage} />
            )}
            {successMessage && successMessage != "" && (
                <MessageSnackbar variant="success" message={successMessage} />
            )}
        </>
    );
};

export default PaperWithPopover;
