import React from "react";
import Page from "@global/Page";
import GeneralHeader from "@global/GeneralHeader";
import GeneralFooter from "@global/GeneralFooter";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        background: "url(/img/cards-background.png) center",
        display: "flex",
        flex: "1",
        justifyContent: "center"
    }
}));

const Auth = props => {
    const classes = useStyles();
    const { children } = props;

    return (
        <Page>
            <GeneralHeader />
            <div className={classes.root}>{children}</div>
            <GeneralFooter />
        </Page>
    );
};

export default Auth;
