import React, { useState, useEffect } from "react";
import {
    Grid,
    TextField,
    Paper,
    Button,
    FormControlLabel,
    Switch,
    MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { head, find } from "lodash";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    floatRight: {
        float: "right"
    }
}));

const Form = props => {
    const { roles, organizations, coupons } = reactProps;
    const { user, handleSubmit } = props;
    const classes = useStyles();
    const facilitatorRoleId = find(roles, function(role) {
        return role.name == "facilitator";
    })
        ? find(roles, function(role) {
              return role.name == "facilitator";
          }).id
        : null;
    const [values, setValues] = useState({
        name: user ? user.name : "",
        email: user ? user.email : "",
        role_id: facilitatorRoleId,
        belongsToOrganization: user ? Boolean(user.organization_id) : false,
        organizationOwner:
            user && user.organization
                ? user.organization.user_id == user.id
                : false,
        organizationName: "",
        organization_id:
            user && user.organization_id ? user.organization_id : "",
        planName: user ? user.planName : "Basic",
        subscription_type: "annual",
        coupon: "",
        trial_length: user ? "" : 30,
        max_facilitators: user?.max_facilitators ? user.max_facilitators : ""
    });

    const planNameDisabled =
        values.role_id != facilitatorRoleId ||
        (values.belongsToOrganization == true &&
            values.organizationOwner == false);

    const organizationIdDisabled =
        values.role_id != facilitatorRoleId ||
        !values.belongsToOrganization ||
        (values.belongsToOrganization == true &&
            values.organizationOwner == true);

    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeChecked = key => event => {
        setValues({
            ...values,
            [key]: event.target.checked
        });
    };

    const handleChangeAuto = (event, value) => {
        setValues({ ...values, organization_id: value.id });
    };

    const getCouponDescription = () => {
        let description = "";
        let selectedCoupon = find(coupons, function(coupon) {
            return coupon.stripe_coupon_id == values.coupon;
        });
        if (Boolean(selectedCoupon.details.amount_off)) {
            description = `$${parseFloat(
                selectedCoupon.details.amount_off / 100
            ).toFixed(2)} off `;
        }

        if (Boolean(selectedCoupon.details.percent_off)) {
            description = `${selectedCoupon.details.percent_off}% off `;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "once"
        ) {
            description = `${description} the user's first month`;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "forever"
        ) {
            description = `${description} as long as the user's subscription is active`;
        }

        if (
            Boolean(selectedCoupon.details.duration) &&
            selectedCoupon.details.duration == "repeating" &&
            Boolean(selectedCoupon.details.duration_in_months)
        ) {
            description = `${description} for the user's first ${selectedCoupon.details.duration_in_months} months`;
        }

        return description;
    };

    useEffect(() => {
        setTimeout(
            function() {
                if (values.belongsToOrganization == true) {
                    if (
                        values.planName == "Basic" ||
                        values.planName == "Plus" ||
                        values.planName == "Professional"
                    ) {
                        setValues({
                            ...values,
                            planName: "Team"
                        });
                    }
                } else {
                    if (
                        values.planName == "Team" ||
                        values.planName == "Enterprise" ||
                        values.planName == "Education"
                    ) {
                        setValues({
                            ...values,
                            planName: "Basic"
                        });
                    }
                }
            }.bind(this),
            400
        );
    }, [values.belongsToOrganization]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.padded}>
                    <TextField
                        required
                        fullWidth
                        label="Name"
                        margin="normal"
                        value={values.name}
                        onChange={handleChange("name")}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Email"
                        margin="normal"
                        value={values.email}
                        onChange={handleChange("email")}
                    />
                    <TextField
                        select
                        required
                        fullWidth
                        SelectProps={{
                            native: true
                        }}
                        margin="normal"
                        label="Role"
                        value={values.role_id}
                        onChange={handleChange("role_id")}
                    >
                        {roles.map(role => (
                            <option key={role.id} value={role.id}>
                                {role.label}
                            </option>
                        ))}
                    </TextField>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={values.belongsToOrganization}
                                        onChange={handleChangeChecked(
                                            "belongsToOrganization"
                                        )}
                                        color="primary"
                                    />
                                }
                                label="Belongs to an Organization"
                                disabled={values.role_id != facilitatorRoleId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={values.organizationOwner}
                                        onChange={handleChangeChecked(
                                            "organizationOwner"
                                        )}
                                        color="primary"
                                    />
                                }
                                label="Organization Owner"
                                disabled={
                                    values.role_id != facilitatorRoleId ||
                                    values.belongsToOrganization == false
                                }
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Organization Name"
                        required={values.organizationOwner}
                        disabled={!values.organizationOwner}
                        value={values.organizationName}
                        onChange={handleChange("organizationName")}
                        helperText="This will be the name of the organization created for this user's new plan"
                    />
                    <TextField
                        select
                        fullWidth
                        label="Plan"
                        margin="normal"
                        value={values.planName}
                        onChange={handleChange("planName")}
                        required={!planNameDisabled}
                        disabled={planNameDisabled}
                    >
                        <MenuItem
                            value="Basic"
                            disabled={values.belongsToOrganization}
                        >
                            Basic
                        </MenuItem>
                        {/* <MenuItem
                            value="Plus"
                            disabled={values.belongsToOrganization}
                        >
                            Plus
                        </MenuItem> */}
                        <MenuItem
                            value="Professional"
                            disabled={values.belongsToOrganization}
                        >
                            Professional
                        </MenuItem>
                        <MenuItem
                            value="Team"
                            disabled={!values.belongsToOrganization}
                        >
                            Team
                        </MenuItem>
                        {/* <MenuItem
                            value="Education"
                            disabled={!values.belongsToOrganization}
                        >
                            Education
                        </MenuItem> */}
                        <MenuItem
                            value="Enterprise"
                            disabled={!values.belongsToOrganization}
                        >
                            Enterprise
                        </MenuItem>
                    </TextField>
                    {!user && (
                        <TextField
                            select
                            fullWidth
                            margin="normal"
                            label="Subscription Type"
                            disabled={planNameDisabled}
                            value={values.subscription_type}
                            onChange={handleChange("subscription_type")}
                        >
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="annual">Yearly</MenuItem>
                        </TextField>
                    )}
                    {values?.planName == "Enterprise" && (
                        <TextField
                            fullWidth
                            margin="normal"
                            value={values.max_facilitators}
                            placeholder="Unlimited"
                            label={`Enter max number of Facilitators for ${values.planName} Plan (otherwise it will be unlimited)`}
                            onChange={handleChange("max_facilitators")}
                        />
                    )}
                    {!user && (
                        <TextField
                            select
                            fullWidth
                            margin="normal"
                            label="Coupon Code"
                            value={values.coupon}
                            onChange={handleChange("coupon")}
                            disabled={planNameDisabled}
                            required={!planNameDisabled}
                            helperText={
                                values.coupon == ""
                                    ? ""
                                    : getCouponDescription()
                            }
                        >
                            <MenuItem value="">None</MenuItem>
                            {coupons.map(coupon => (
                                <MenuItem
                                    value={coupon.stripe_coupon_id}
                                    key={coupon.id}
                                >
                                    {coupon.name} - {coupon.promotion_code}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Trial Length (days)"
                        value={values.trial_length}
                        disabled={planNameDisabled}
                        required={!planNameDisabled}
                        onChange={handleChange("trial_length")}
                    />
                    <Autocomplete
                        options={organizations}
                        required={!organizationIdDisabled}
                        disabled={organizationIdDisabled}
                        getOptionLabel={option =>
                            typeof option === "string" ? option : option.name
                        }
                        onChange={handleChangeAuto}
                        renderInput={params => (
                            <TextField
                                {...params}
                                fullWidth
                                // onChange={handleChange("organization_id")}
                                margin="normal"
                                label="Organization"
                                helperText="This will be the existing organization that the user will belong to."
                            />
                        )}
                    />
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    handleSubmit(values, facilitatorRoleId)
                                }
                                className={clsx(
                                    classes.floatRight,
                                    classes.marginTop
                                )}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Form;
