import React from "react";
import { List, ListItem } from "@material-ui/core";
import {
    Grid,
    Users,
    Layers,
    Layout,
    RefreshCcw,
    Image,
    Inbox,
    BookOpen,
    Send,
    User,
    Box
} from "react-feather";
import NavItem from "@global/NavItem";
import NavHeader from "@global/NavHeader";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import PaymentIcon from "@material-ui/icons/Payment";
import isCurrentPage from "@utils/isCurrentPage";
import FeedbackIcon from "@material-ui/icons/Feedback";
import ContactSupport from "@material-ui/icons/ContactSupport";
import PeopleOutline from "@material-ui/icons/PeopleOutline";

const UserNav = () => {
    const { auth, organization, has_original_user, plan } = reactProps;

    return (
        <List>
            <NavHeader title="Sessions" first />
            <NavItem
                href="/dashboard"
                current={
                    isCurrentPage("/dashboard") ||
                    isCurrentPage("/user/dashboard")
                }
            >
                <Grid />
                Dashboard
            </NavItem>
            <NavItem href="/sessions" current={isCurrentPage("/sessions")}>
                <Layout />
                My Sessions
            </NavItem>
            {organization && (
                <NavItem
                    href="/all-sessions"
                    current={isCurrentPage("/all-sessions")}
                >
                    <User />
                    Shared Sessions
                </NavItem>
            )}
            <NavItem href="/archived" current={isCurrentPage("/archived")}>
                <Inbox />
                Archived
            </NavItem>
            <NavItem href="/decks" current={isCurrentPage("/decks")}>
                <Layers />
                Decks
            </NavItem>
            <NavItem href="/cards" current={isCurrentPage("/cards")}>
                <Image />
                Cards
            </NavItem>
            <NavHeader title="Resources" />
            <NavItem
                href="https://climercards.com/support-for-virtual-climer-cards/"
                target="_blank"
                current={false}
            >
                <BookOpen />
                Instructions
            </NavItem>
            {/* <NavItem href="/activities" current={isCurrentPage("/activities")}>
                <Send />
                Activities
            </NavItem> */}
            <NavHeader title="Account & Settings" />
            <NavItem
                href="/user/settings"
                current={isCurrentPage("/user/settings")}
            >
                <User />
                Profile
            </NavItem>
            {organization && (
                <NavItem
                    href="/organization"
                    current={isCurrentPage("/organization")}
                >
                    <Box />
                    Organization
                </NavItem>
            )}
            {organization && (
                <NavItem href="/users" current={isCurrentPage("/users")}>
                    <PeopleOutline />
                    Users
                </NavItem>
            )}
            {(!organization || auth.id == organization.user_id) && (
                <NavItem
                    href="/manage-plan"
                    current={isCurrentPage("/manage-plan")}
                >
                    <PaymentIcon />
                    Billing
                </NavItem>
            )}
            {has_original_user && (
                <NavItem href="/users/switch-back" current={false}>
                    <RefreshCcw />
                    Switch Back
                </NavItem>
            )}
            <NavItem href="/feedback" current={isCurrentPage("/feedback")}>
                <FeedbackIcon />
                Feedback
            </NavItem>
            <NavItem
                href="https://climercards.com/contact-us/"
                current={isCurrentPage("/contact")}
            >
                <ContactSupport />
                Contact Us
            </NavItem>
        </List>
    );
};

export default UserNav;
