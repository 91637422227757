import React from "react";
import { Grid, TextField, ButtonGroup, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PasswordField from "../../global/PasswordField";

const useStyles = makeStyles(theme => ({
    floatRight: {
        float: "right",
        marginTop: theme.spacing(2)
    }
}));

const PersonalInfoStep = props => {
    const classes = useStyles();
    const {
        values,
        setLoading,
        handleChange,
        handleNextStep,
        getErrorMessage
    } = props;

    const disabled =
        values.name == "" ||
        values.email == "" ||
        values.password == "" ||
        values.password_confirmation == "" ||
        values.password != values.password_confirmation;

    const attemptHandleNext = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("email", values.email);
        params.append("password", values.password);
        params.append("password_confirmation", values.password_confirmation);

        axios
            .post(`/api/v1/registration/steps/personal-info`, params)
            .then(response => {
                console.log("response", response);
                handleNextStep();
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange("name")}
                />
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Email Address"
                    name="email"
                    value={values.email}
                    onChange={handleChange("email")}
                />
                {/* <TextField
                    required
                    fullWidth
                    type="password"
                    margin="normal"
                    label="Password"
                    value={values.password}
                    onChange={handleChange("password")}
                /> */}
                {/* <TextField
                    required
                    fullWidth
                    type="password"
                    margin="normal"
                    label="Password Confirmation"
                    value={values.password_confirmation}
                    onChange={handleChange("password_confirmation")}
                /> */}
                <PasswordField
                    value={values.password}
                    handleChange={handleChange("password")}
                    margin="normal"
                />
                <PasswordField
                    value={values.password_confirmation}
                    handleChange={handleChange("password_confirmation")}
                    margin="normal"
                    confirm
                />
                <div className={classes.floatRight}>
                    <ButtonGroup>
                        <Button disabled variant="contained">
                            Back
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={disabled}
                            onClick={attemptHandleNext}
                        >
                            Next
                        </Button>
                    </ButtonGroup>
                </div>
            </Grid>
        </Grid>
    );
};

export default PersonalInfoStep;
