import React, { useState } from "react";
import ReactDOM from "react-dom";
require("../../integrations/_integrations");
import DashboardLayout from "@layouts/DashboardLayout";
import ErrorBoundary from "@global/ErrorBoundary";
import UserNav from "@global/UserNav";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OrganizationInfo from "./components/OrganizationInfo";
import UsersTable from "../facilitators/users/components/UsersTable";

const useStyles = makeStyles(theme => ({
    root: {},
    container: {
        maxWidth: "80%",
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "95%",
            margin: `${theme.spacing(1)}px auto`
        }
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    image: {
        height: 100,
        width: "auto",
        marginBottom: theme.spacing(2)
    },
    formContainer: {
        padding: theme.spacing(4),
        "& input[disabled], textarea[disabled], select[disabled]": {
            color: theme.palette.text.primary
        }
    },
    alignRight: {
        textAlign: "right"
    },
    avatar: {
        backgroundColor: "#a8d058",
        height: 75,
        marginBottom: theme.spacing(3),
        width: 75
    },
    logoContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
    },
    justifyBetween: {
        display: "flex",
        justifyContent: "space-between"
    },
    divider: {
        marginBottom: theme.spacing(3)
    }
}));

const Organization = () => {
    const { organization, user, auth } = reactProps;
    const classes = useStyles();
    const canEdit =
        organization.user_id == auth.id ||
        (auth.organization_id == organization.id &&
            Boolean(auth.can_edit_organization));

    return (
        <DashboardLayout sideNav={<UserNav />}>
            <Grid container className={classes.container}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Organization
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.alignRight}>
                    <Button
                        component="a"
                        color="primary"
                        variant="contained"
                        href="/users/invite"
                        disabled={!canEdit}
                    >
                        Invite User
                    </Button>
                </Grid>
                {organization ? (
                    <Grid item container xs={12}>
                        <OrganizationInfo
                            organization={organization}
                            user={user}
                            classes={classes}
                        />
                        <Grid item xs={12}>
                            <UsersTable />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="h4" component="p" align="center">
                            This user does not belong to an organization
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </DashboardLayout>
    );
};

if (document.getElementById("user-organization")) {
    ReactDOM.render(
        <ErrorBoundary>
            <Organization />
        </ErrorBoundary>,
        document.getElementById("user-organization")
    );
}
