import React, { useState, useEffect } from "react";
import {
    Grid,
    Button,
    Dialog,
    TextField,
    Typography,
    DialogTitle,
    DialogContent
} from "@material-ui/core";
import axios from "axios";

const ResponseModal = props => {
    const {
        card,
        classes,
        currentPrompt,
        back,
        valuesLoading,
        setValuesLoading,
        participant,
        backSelected,
        setCurrentCard,
        setBackSelected,
        promptData,
        toggleShowResponses,
        token,
        setOpen,
        getErrorMessage,
        handleClose
    } = props;
    const { session, auth } = reactProps;
    const [values, setValues] = useState({
        name: auth && auth.name ? auth.name : "",
        response: ""
    });
    const isPresentPrompt = currentPrompt?.prefix || null;
    const handleChange = key => event => {
        setValues({
            ...values,
            [key]: event.target.value
        });
    };

    const handleChangeDirectly = (key, value) => {
        setValues({
            ...values,
            [key]: value
        });
    };

    const handleNameChange = event => {
        let value = event.target.value;
        if (session.minor_participants == true && value && value.length > 5) {
            value = value.slice(0, 5);
            console.log("value after substr: ", value);
        }
        handleChangeDirectly("name", value);
    };

    // const handleClose = () => {
    //     setOpen(false);
    //     setCurrentCard(null);
    // };

    const handleSubmit = () => {
        if (!valuesLoading) {
            setValuesLoading(true);
        }

        let params = new URLSearchParams();
        params.append("name", values.name);
        params.append("description", values.response);
        params.append("card_id", card.id);
        params.append("token", token);
        if (backSelected === true) params.append("back_of_card", true);

        if (!participant) {
            axios
                .post(`/api/v1/session/${session.uuid}/response/create`, params)
                .then(response => {
                    console.log("response", response);
                    setValuesLoading(false);
                    setTimeout(
                        function() {
                            setOpen(false);
                            setCurrentCard(null);
                            setBackSelected(false);
                            setValues({
                                ...values,
                                response: ""
                            });
                            toggleShowResponses();
                        }.bind(this),
                        500
                    );
                })
                .catch(error => {
                    console.log("error", error);
                    getErrorMessage(error.response);
                    setValuesLoading(false);
                });
        } else {
            axios
                .post(
                    `/api/v1/prompts/${promptData[0].uuid}/response/asinchronous-create`,
                    params
                )
                .then(response => {
                    console.log("response-asinchronous-create", response);
                    setValuesLoading(false);
                    setTimeout(
                        function() {
                            setOpen(false);
                            setCurrentCard(null);
                            setBackSelected(false);
                            setValues({
                                ...values,
                                response: ""
                            });
                            toggleShowResponses();
                        }.bind(this),
                        500
                    );
                })
                .catch(error => {
                    console.log("error", error);
                    getErrorMessage(error.response);
                    setValuesLoading(false);
                });
        }
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="responses-modal-title"
            aria-describedby="responses-modal-description"
        >
            <DialogTitle
                className={classes.srDisplay}
                id="response-modal-description"
            >
                Your Response
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} className={classes.alignCenter}>
                        <img
                            alt={back ? card.back_alt_text : card.alt_text}
                            src={back ? card.backImage : card.frontImage}
                            className={classes.cardImage}
                        />
                    </Grid>
                    {!Boolean(currentPrompt) ? (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.alignCenter}
                        >
                            <Typography
                                gutterBottom
                                component="p"
                                align="center"
                                variant="caption"
                            >
                                Please wait until there is a prompt to respond
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.alignCenter}
                        >
                            <div>
                                {!!isPresentPrompt && (
                                    <Typography>
                                        Select a card that represents...
                                    </Typography>
                                )}
                                <Typography
                                    variant="h4"
                                    component="p"
                                    color="primary"
                                    gutterBottom
                                    style={{ fontWeight: "bold" }}
                                >
                                    {currentPrompt.description}
                                </Typography>
                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Enter Your Name"
                                    size="small"
                                    margin="dense"
                                    onChange={handleNameChange}
                                    inputProps={{
                                        type: "text",
                                        maxLength:
                                            session.minor_participants == true
                                                ? 5
                                                : undefined
                                    }}
                                />
                                <TextField
                                    rows={4}
                                    fullWidth
                                    multiline
                                    size="small"
                                    margin="dense"
                                    name="response"
                                    label="Why I chose this card"
                                    onChange={handleChange("response")}
                                    error={values.response.length > 500}
                                    helperText={
                                        values.response.length > 500
                                            ? "Your response must be less than 500 characters."
                                            : ""
                                    }
                                />
                                <div
                                    className={classes.center}
                                    style={{ marginTop: 18 }}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        disabled={
                                            !Boolean(currentPrompt) ||
                                            values.name == "" ||
                                            values.response == "" ||
                                            values.response.length > 500
                                        }
                                    >
                                        Create Response
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ResponseModal;
