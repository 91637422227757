import React, { useState } from "react";
import {
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button
} from "@material-ui/core";

const ReActivatePlan = props => {
    const { setLoading, getErrorMessage, align = "right" } = props;
    const { userToken } = reactProps;
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(prevState => !prevState);
    };

    const handleSubmit = () => {
        setLoading(true);
        let params = new URLSearchParams();
        params.append("immediate", 0);
        axios
            .post(`/api/v1/reactivate-membership`, params, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(response => {
                console.log("response", response);
                let message = encodeURIComponent("Plan Reactivated");
                window.location = `/manage-plan?message=${message}`;
            })
            .catch(error => {
                console.log("error", error);
                getErrorMessage(error.response);
                setLoading(false);
            });
    };

    return (
        <div>
            <Typography
                variant="caption"
                component="p"
                align={align}
                style={{ marginTop: 5 }}
            >
                <a
                    href="#"
                    onClick={toggleOpen}
                    style={{
                        fontSize: 12,
                        cursor: "pointer",
                        color: "#e53935"
                    }}
                >
                    Reactivate Plan
                </a>
            </Typography>
            <Dialog
                fullWidth
                open={open}
                maxWidth="sm"
                onClose={toggleOpen}
                aria-labelledby="confirmation-modal"
            >
                <DialogTitle id="confirmation-modal">
                    Reactivate Subscription Confirmation
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        Your subscription will be reactivated and you will
                        continue to be charged automatically.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={toggleOpen}>
                        Back
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Reactivate Subscription
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ReActivatePlan;
