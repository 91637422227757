import React, { useState } from "react";
import {
    Grid,
    Paper,
    Table,
    Chip,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    IconButton,
    InputAdornment,
    Button
} from "@material-ui/core";
import { Search } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBackdrop from "@global/LoadingBackdrop";
import { upperCaseFirst } from "@utils/stringUtils";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    padded: {
        padding: theme.spacing(4)
    }
}));

const SessionsTable = () => {
    const { userToken } = reactProps;
    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState(reactProps.sessions);
    const [sessionsCount, setSessionsCount] = useState(
        reactProps.sessionsCount
    );
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("created_at");
    const [searchQuery, setSearchQuery] = useState("");
    const [text, setText] = useState("");
    const classes = useStyles();

    const handleSearchTextChange = event => {
        setText(event.target.value);
        event.persist();
    };

    const handleSearchKeyUp = event => {
        if (event.keyCode === 13 && event.target.value.length > 0) {
            handleSearchSubmit(event.target.value);
        }
        event.persist();
    };

    const clearSearch = () => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/sessions?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=&token=${userToken}`
            )
            .then(response => {
                setSearchQuery("");
                setSessions(response.data.sessions);
                setSessionsCount(reactProps.sessionsCount);
                setPage(0);
                setLoading(false);
                setText("");
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSearchSubmit = searchText => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/sessions?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=0&searchQuery=${searchText}&token=${userToken}`
            )
            .then(response => {
                setSearchQuery(searchText);
                setSessions(response.data.sessions);
                if (
                    response.data.sessionsCount &&
                    response.data.sessionsCount != sessionsCount
                ) {
                    setSessionsCount(response.data.sessionsCount);
                }
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/sessions?sortBy=${orderBy}&sortDirection=${order}&take=${rowsPerPage}&skip=${rowsPerPage *
                    newPage}&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setSessions(response.data.sessions);
                setPage(newPage);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleChangeRowsPerPage = event => {
        setLoading(true);
        axios
            .get(
                `/api/v1/admin/sessions?sortBy=${orderBy}&sortDirection=${order}&take=${parseInt(
                    event.target.value,
                    10
                )}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setSessions(response.data.sessions);
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const handleSort = property => event => {
        console.log("handleSort");
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        setLoading(true);
        let isAsc = orderBy == property && order == "asc";

        axios
            .get(
                `/api/v1/admin/sessions?sortBy=${property}&sortDirection=${
                    !isAsc ? "asc" : "desc"
                }&take=${rowsPerPage}&skip=0&searchQuery=${searchQuery}&token=${userToken}`
            )
            .then(response => {
                console.log("response", response);
                setSessions(response.data.sessions);
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
                setPage(0);
                setLoading(false);
            })
            .catch(error => {
                console.log("error", error);
                setLoading(false);
            });
    };

    return (
        <div>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.padded}>
                            <TextField
                                fullWidth
                                label="Search"
                                margin="normal"
                                value={text}
                                onKeyUp={handleSearchKeyUp}
                                onChange={handleSearchTextChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    handleSearchSubmit(text)
                                                }
                                            >
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {searchQuery && searchQuery != "" && (
                                <Chip
                                    label={`Search: ${searchQuery}`}
                                    onDelete={clearSearch}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        aria-labelledby="sessions-table"
                        size="medium"
                        aria-label="sessions table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sortDirection={
                                        orderBy == "created_at" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "created_at"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("created_at")}
                                    >
                                        Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "name" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "name"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("name")}
                                    >
                                        Session Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "slug" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "slug"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("slug")}
                                    >
                                        Slug
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "status" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "status"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("status")}
                                    >
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="default"
                                    sortDirection={
                                        orderBy == "user" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy == "user"}
                                        direction={
                                            order == "asc" ? "desc" : "asc"
                                        }
                                        onClick={handleSort("user")}
                                    >
                                        User
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="left" padding="default">
                                    Deck
                                </TableCell>
                                <TableCell align="right" padding="default">
                                    {" "}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sessions.map(session => (
                                <TableRow key={session.id} hover>
                                    <TableCell>
                                        {moment(session.created_at).format("L")}
                                    </TableCell>
                                    <TableCell>{session.name}</TableCell>
                                    <TableCell>{session.slug}</TableCell>
                                    <TableCell>
                                        {session.status == "active" && (
                                            <span
                                                style={{
                                                    backgroundColor: "#4786AA",
                                                    padding: "4px 6px",
                                                    color: "#fff"
                                                }}
                                            >
                                                Active
                                            </span>
                                        )}
                                        {session.status == "archived" && (
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        "#00000040",
                                                    padding: "4px 6px",
                                                    color: "#fff"
                                                }}
                                            >
                                                Archived
                                            </span>
                                        )}
                                        {session.status != "active" &&
                                            session.status != "archived" && (
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            "#A8D058",
                                                        padding: "4px 6px",
                                                        color: "#fff"
                                                    }}
                                                >
                                                    Draft
                                                </span>
                                            )}
                                    </TableCell>
                                    <TableCell>{session.user.name}</TableCell>
                                    <TableCell>{session.deck.name}</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            size="small"
                                            component="a"
                                            color="primary"
                                            variant="outlined"
                                            href={`/admin/sessions/${session.uuid}/edit`}
                                        >
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={sessionsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {loading && <LoadingBackdrop open={loading} />}
        </div>
    );
};

export default SessionsTable;
